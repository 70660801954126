import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { closeWebsocket, disposeWebsocket, startWebsocket } from '../../../utils/net'
import { ChatBoard } from '../../Board/ChatBoard'
import { DetailBoard } from '../../Board/DetailBoard'
import { GenerateBoard } from '../../Board/GenerateBoard'
import { Image3dProdBoard } from '../../Board/Image3dProdBoard'
import style from './modal.module.css'
import {
	taskInitAtom,
	taskDetailAtom,
	chatHistoryAtom,
	chatGuessAtom,
	promptAtom,
	stopChatAtom,
	meshProfileAtom,
	assistantChatStatusAtom,
	guessChatStatusAtom,
	chatTextAtom,
	chatLangAtom,
	needStartWsAtom,
	isShowModalAtom,
	enteredMeshProfileAtom,
	setGenerateAtom,
	showProgressAtom,
	showDownloadAtom,
	posterGenerateAtom,
	chatLoadingAtom,
	chatImageURLAtom,
	modalObjAtom,
	cameraParamsAtom,
	isOpenImg3dGenerateAtom
} from '../../../store'
import { exportToImage } from '../../Board/utils'
import { useTips } from '../../GlobalTips'
import { isNotEmpty } from '../../../utils/format'

function Modal() {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search)
	const dataStr = queryParams.get('data')
	const dataObj = JSON.parse(decodeURIComponent(dataStr))

	const taskInit = useRecoilValue(taskInitAtom)
	const [chatHistory, setChatHistory] = useRecoilState(chatHistoryAtom)
	const setChatGuess = useSetRecoilState(chatGuessAtom)
	const [meshProfile, setMeshProfile] = useRecoilState(meshProfileAtom)
	const [assistantChatStatus, setAssistantChatStatus] = useRecoilState(assistantChatStatusAtom)
	const setGuessChatStatus = useSetRecoilState(guessChatStatusAtom)
	const [prompt, setPrompt] = useRecoilState(promptAtom)
	const [stopChat, setStopChat] = useRecoilState(stopChatAtom)
	const [chatText, setChatText] = useRecoilState(chatTextAtom)
	const [chatImageURL, setChatImageURL] = useRecoilState(chatImageURLAtom)
	const chatLang = useRecoilValue(chatLangAtom)
	const [needStartWs, setNeedStartWs] = useRecoilState(needStartWsAtom)
	const isListenRef = useRef(false)
	const chatHistoryRef = useRef({})
	const chatGuessRef = useRef('')
	const promptRef = useRef('')
	const [isShowModal, setIsShowModal] = useRecoilState(isShowModalAtom)
	const [enteredMeshProfile, setEnteredMeshProfile] = useRecoilState(enteredMeshProfileAtom)
	const [taskDetail, setTaskDetail] = useRecoilState(taskDetailAtom)
	const [generate, setGenerate] = useRecoilState(setGenerateAtom)
	const [showProgress, setShowProgress] = useRecoilState(showProgressAtom)
	const setGlobalTips = useTips()
	const [showDownload, setShowDownload] = useRecoilState(showDownloadAtom)
	const [posterGenerate, setPosterGenerate] = useRecoilState(posterGenerateAtom)
	const [loading, setLoading] = useState(chatLoadingAtom)
	const [modalObj, setModalObj] = useRecoilState(modalObjAtom);
	const [typeValue, setTypeValue] = useState(null)
	const [taskUUid, setTaskUUid] = useState(null)
	const setTaskInit = useSetRecoilState(taskInitAtom)
	const dialogRef = useRef(null)
	const [cameraParams, setCameraParams] = useRecoilState(cameraParamsAtom);
	const [isOpenImg3dGenerate, setIsOpenImg3dGenerate] = useRecoilState(isOpenImg3dGenerateAtom);

	useEffect(() => {
		document.documentElement.style.overflowY = isShowModal ? 'hidden' : 'overlay'
		setTypeValue(modalObj.type)
		setTaskUUid(modalObj.taskUUid)
	}, [modalObj])

	useEffect(() => {
		chatHistoryRef.current = { ...chatHistory }
	}, [chatHistory])

	useEffect(() => {
		promptRef.current = prompt
	}, [prompt])

	useEffect(() => {
		if (stopChat) {
			closeWebsocket()
		}
	}, [stopChat])

	useEffect(() => {
		if (!needStartWs) return
		setChatGuess([])
		setPrompt('')
		setChatHistory({})
		setChatText('')
		setAssistantChatStatus('')
		setStopChat(false)
		isListenRef.current = false
		chatHistoryRef.current = {}
		chatGuessRef.current = ''
		promptRef.current = ''
	}, [needStartWs])

	useEffect(() => {
		if (!taskInit) {
			closeWebsocket()
			disposeWebsocket()
			return
		}
		; (async () => {
			if (isListenRef.current) return
			isListenRef.current = true
			console.log(isListenRef.current, 'startWebsocket');
			const ws = await startWebsocket(taskInit.subscription, taskInit.task_uuid, chatLang)

			bindWsListeners(ws)
		})()
	}, [taskInit])

	useEffect(() => {
		if (!taskDetail) return
		isNotEmpty(taskDetail.chat_history) && setChatHistory(
			taskDetail.chat_history.reduce(
				(res, cur) => ({
					...res,
					[cur.chat_uuid]: { ...cur, timeStamp: new Date(cur.time).getTime() },
				}),
				{}
			)
		)
		setPrompt(taskDetail.prompt)
		// setIsShowModal(true)
		setMeshProfile({
			...taskDetail.resources,
			task_uuid: taskDetail.task_uuid,
			time: Date.now(),
		})
	}, [taskDetail])

	const exportDialog = async () => {
		await exportToImage(dialogRef.current, 'dialog')
	}

	const handleClose = (ev) => {
		if (showProgress === true) {
			setGlobalTips({
				type: 'error',
				content: 'Please wait until completion',
			})
		} else {
			setMeshProfile(false)
			setGuessChatStatus('')
			setLoading(true)
			setIsShowModal(false)
			setEnteredMeshProfile(false)
			setGenerate(false)
			setPosterGenerate(false)
			setChatImageURL(false)
			setShowProgress(false)
			setShowDownload(false)
			setCameraParams(false)
			if (isOpenImg3dGenerate) {
				setIsOpenImg3dGenerate(false)
			}
			document.documentElement.style.overflowY = "auto";
			// window.history.pushState(null, '', '/')
		}
	}

	const bindWsListeners = (ws) => {
		setNeedStartWs(false)
		ws.off('assistant')
		ws.off('guess')
		ws.off('summary')
		ws.on('assistant', (ev) => {
			const currentChat = { ...(chatHistoryRef.current[ev.chat_uuid] || {}) }
			setAssistantChatStatus(ev.content)
			// console.log(ev.content)
			if (ev.content === '[START]') {
				currentChat.chat_uuid = ev.chat_uuid
				currentChat.provider = ev.provider
				currentChat.timeStamp = Date.now()
				currentChat.content = ''
			} else if (ev.content !== '[END]') {
				currentChat.content += ev.content
			}
			setChatHistory({
				...chatHistoryRef.current,
				[ev.chat_uuid]: currentChat,
			})
		})

		ws.on('guess', (ev) => {
			setGuessChatStatus(ev.content)
			if (ev.content === '[START]') {
				chatGuessRef.current = ''
			} else if (ev.content !== '[END]') {
				chatGuessRef.current += ev.content
				setChatGuess(chatGuessRef.current.split('\n'))
			}
		})
		ws.on('summary', (ev) => {
			if (ev.content === '[START]') {
				promptRef.current = ''
			} else if (ev.content !== '[END]') {
				promptRef.current += ev.content
				setPrompt(promptRef.current)
			}
		})
	}

	window.exportDialog = exportDialog
	return (
		isShowModal && <div>
			<div className={style.mask} onPointerDown={handleClose}></div>
			<div
				className={style.cardBox}
				onPointerDown={(ev) => ev.stopPropagation()}
				ref={dialogRef}>
				{typeValue === 'generate' && <>
					<GenerateBoard width={'52%'} />
					<ChatBoard width={'48%'} />
				</>}
				{typeValue === 'detail' && <>
					<DetailBoard width={'52%'} type="textto3d" />
					<ChatBoard width={'48%'} />
				</>}
				{typeValue === 'imgto3d' && <>
					<DetailBoard width={'55%'} type="imageto3d" />
					<Image3dProdBoard width={'45%'} />
				</>}
			</div>
		</div>
	)
}

export { Modal }
