import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  taskInitAtom,
  cardsAtom,
  logInfoAtom,
  privateCouterAtom,
} from "../../../store";
import {
  purchaseLicense,
  getUserInfo,
  setPrivate,
  getTaskDetail,
} from "../../../utils/net";
import beforelicenseIcon from "../../../assets/beforeLisenceicon.png";
import beforelicensebg from "../../../assets/beforeLicense.png";
import afterlicensebg from "../../../assets/afterlicense.png";
import afterlicenseIcon from "../../../assets/afterLisenceicon.png";
import style from "./license.module.css";
import { useTips } from "../../GlobalTips";

const License = forwardRef((props, ref) => {
  const {
    style: myStyle,
    largest,
    isLisenced,
    uuid,
    cancel = true,
    immediate = true,
  } = props;
  const [isLicenseHovered, setIsLicenseHovered] = useState(false);
  const [isLicense, setIsLicense] = useState(isLisenced);
  const taskInit = useRecoilValue(taskInitAtom);
  const [cards, setCards] = useRecoilState(cardsAtom);
  const [isPay, setIsPay] = useState(false);
  const [privateCouter, setPrivateCouter] = useRecoilState(privateCouterAtom);
  const [logInfo, setLogInfo] = useRecoilState(logInfoAtom);
  const popupRef = useRef();
  const tip = useTips();
  /* 
    1. generate: 应该先license再private
    2. detail：如果是license，设置private不用管，如果是已经private，此时设置license需要执行private    
  */
  const handleHoverLicenseEnter = () => {
    if (isLicense && !cancel) return;
    setIsLicenseHovered(true);
  };
  const handleHoverLicenseLeave = () => {
    setIsLicenseHovered(false);
  };

  const handlePopupMouseLeave = () => {
    setIsLicenseHovered(false);
  };
  const handlerLicense = async () => {
    if (isLicense) {
      setIsLicenseHovered(false);
      return;
    }
    try {
      const data = await purchaseLicense({ task_uuid: uuid });
      if (!data.data.error) {
        setIsLicense(true);
        //如果is_private，需要退回其private次数
        const response = await getTaskDetail(uuid);
        if (response.data.is_private) {
          const res = await setPrivate(uuid);
          if (!res.data.error) {
            const newInfo = await getUserInfo({
              user_uuid: localStorage.getItem("user_uuid"),
            });
            setPrivateCouter(
              newInfo.data.meta.chances_left_to_set_private_task
            );
          }
        }
      } else {
        tip({ type: "error", content: "You don't have enough tokens" });
      }
      setIsLicenseHovered(false);
    } catch (_) { }
  };

  const handlerLicenseDelay = async () => {
    // tip({
    //   type: "primary",
    //   content:
    //     "You will be able to set your asserts as licensed after generated",
    // });
    setIsLicenseHovered(false);

    if (isLicense) {
      setIsLicenseHovered(false);
      return
    }
    try {
      setIsLicense(true)
      setIsPay(true)
      setIsLicenseHovered(false);
      const userData = (
        await getUserInfo({ user_uuid: localStorage.getItem("user_uuid") })
      ).data;
      if (userData.error) throw new Error(userData.error);
      setLogInfo({ ...userData.meta, token: localStorage.getItem("token") });
    } catch (_) { }

  };
  const handlerLicenseCanler = () => {
    //取消接口
    setIsPay(false);
    setIsLicense(false);
    setIsLicenseHovered(false);
  };

  const changeCards = () => {
    const newCards = JSON.parse(JSON.stringify(cards));
    const index = newCards.findIndex((item) => item.task_uuid === uuid);
    if (index === -1) return;
    newCards[index].lisenced = isLicense;
    setCards(newCards);
  };

  useImperativeHandle(ref, () => ({
    pay: async (task_uuid) => {
      if (!isPay) return;
      const data = await purchaseLicense({ task_uuid: task_uuid });
      if (!data.data.error) {
        setIsLicense(true);
        const userData = (
          await getUserInfo({ user_uuid: localStorage.getItem("user_uuid") })
        ).data;
        if (userData.error) throw new Error(userData.error);
        setLogInfo({ ...userData.meta, token: localStorage.getItem("token") });
      }
    },
  }));

  useEffect(() => {
    if (isLicense) changeCards();
  }, [isLicense]);

  return (
    <div
      className={`${style.licenseBox} ${largest ? style.largest : ""}`}
      style={{
        ...myStyle,
        backgroundImage: `url(${isLicense ? afterlicensebg : beforelicensebg})`,
      }}
      onMouseEnter={handleHoverLicenseEnter}
      onMouseLeave={handleHoverLicenseLeave}
    >
      <img src={isLicense ? afterlicenseIcon : beforelicenseIcon} alt="" />
      <span>License</span>
      <div
        className={style.hoverWrapper}
        style={{ display: isLicenseHovered ? "block" : "none" }}
        onMouseLeave={handlePopupMouseLeave}
        ref={popupRef}
      >
        <br></br>
        <div
          style={{
            width: "324px",
            backgroundColor: "#fff",
          }}
        ></div>
        <div className={style.hoverBox}>
          <div
            className={style.licenseTitle}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <span className={style.licenseTip}>License</span>
            <span className={style.price}>400&nbsp;</span>
            <span>/&nbsp;asset</span>
          </div>
          <p
            style={{
              marginBottom: isLicense ? "8px" : "24px",
            }}
          >
            You will be granted the permission to use the asset commercially in accordance with the 'CC-SA 4.0' license.
            For full agreement details, review{" "}
            <a style={{ color: "#5D1BE3" }} href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank">
              here
            </a>
            .
          </p>
          <div
            onPointerDown={handlerLicenseCanler}
            style={{
              display: isLicense ? "block" : "none",
            }}
            className={style.licenseCanler}
          >
            Not available yet
          </div>
          <div
            onPointerDown={immediate ? handlerLicense : handlerLicenseDelay}
            className={style.licenseButton}
          >
            Confirm
          </div>
        </div>
      </div>
    </div>
  );
});

export default License;
