/* global _czc */

import React from 'react';
import './popup.css';
import { AiFillEdit, AiOutlineKey, AiOutlineUser, AiOutlineLogout } from 'react-icons/ai';
import { logout } from '../../../utils/net'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { initialLoginStageAtom, showLoginAtom } from '../../../store'

export const UserPopup = React.forwardRef(({ className, userInfo }, ref) => {
    const [initialLoginStage, setInitialLoginStage] = useRecoilState(initialLoginStageAtom);
    const setShowLogin = useSetRecoilState(showLoginAtom)

    const handleLogout = async () => {
        _czc.push(['_trackEvent', 'Pop Up', 'Logout Icon', 'User Panel']);
        localStorage.setItem('user_uuid', '')
        localStorage.setItem('token', '')
        await logout();
        window.location.href = '/';
    };

    const handleChangeAvatar = () => {
        _czc.push(['_trackEvent', 'Pop Up', 'Change Avatar Icon', 'User Panel']);
        setShowLogin(true);
        setInitialLoginStage(6);
    };

    const handleChangePassword = () => {
        _czc.push(['_trackEvent', 'Pop Up', 'Change Password Icon', 'User Panel']);
        setShowLogin(true);
        setInitialLoginStage(5);
    };

    const handleChangeUsername = () => {
        _czc.push(['_trackEvent', 'Pop Up', 'Change Username Icon', 'User Panel']);
        setShowLogin(true);
        setInitialLoginStage(4);
    };

    return (
        <div className={`user-popup ${className}`} ref={ref}>
            {userInfo && (
                <div className="user-info">
                    <img
                        src={userInfo.avatar_url}
                        alt="avatar"
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "../../assets/defaultAvatar.png";
                        }}
                        style={{
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundColor: "transparent",
                            backgroundImage: `url('../../assets/defaultAvatar.png')`,
                        }}
                    />

                    <div>
                        <div className="user-name">{userInfo.username}</div>
                    </div>
                </div>
            )}
            <button onClick={handleChangeUsername}>
                <AiFillEdit />
                <span>Change Username</span>
            </button>
            <button onClick={handleChangeAvatar}>
                <AiOutlineUser />
                <span>Change Avatar</span>
            </button>
            <button onClick={handleChangePassword} >
                <AiOutlineKey />
                <span>Change Password</span>
            </button>
            <button onClick={handleLogout}>
                <AiOutlineLogout />
                <span>Logout</span>
            </button>

        </div>
    );
});
