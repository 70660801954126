import { atom } from 'recoil'

export const isLoadingAtom = atom({
  key: 'isLoadingAtom',
  default: false,
})
export const curThemeAtom = atom({
  key: 'curThemeAtom',
  default: 'dark',
})
