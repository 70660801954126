export const colorPaletteArr = [
  { name: 'Auto', color: '#f6f6f6' },
  { name: 'Dataset', color: '#f6f6f6' },
  { name: 'Pale Ivory', color: 'rgb(254,227,198)' },
  { name: 'Warm Ivory', color: 'rgb(253,231,171)' },
  { name: 'Sand', color: 'rgb(248,217,152)' },
  { name: 'Rose Beige', color: 'rgb(249,212,160)' },
  { name: 'Limestone', color: 'rgb(236,192,145)' },
  { name: 'Beige', color: 'rgb(242,194,128)' },
  { name: 'Sienna', color: 'rgb(212,158,122)' },
  { name: 'Amber', color: 'rgb(187,100,54)' },
  { name: 'Honey', color: 'rgb(207,150,95)' },
  { name: 'Band', color: 'rgb(173,138,96)' },
  { name: 'Almond', color: 'rgb(147,95,55)' },
  { name: 'Bronze', color: 'rgb(115,63,23)' },
  { name: 'Umber', color: 'rgb(178,102,68)' },
  { name: 'Golden', color: 'rgb(127,68,34)' },
  { name: 'Espresso', color: 'rgb(95,51,16)' },
  { name: 'Chocolate', color: 'rgb(41,23,9)' },
] 