import * as header from './headerStore';
import * as gallery from './galleryStore';
import * as Board from './boardStore';
import * as welcome from './welcomeStore';
import * as widget from './widgetStore';
import * as app from './appStore';

/* app */
export const isMobileAtom = app.isMobileAtom;
export const currentNavAtom = app.currentNavAtom;
export const transitioningAtom = app.transitioningAtom;
export const modalObjAtom = app.modalObjAtom;
export const privateCouterAtom = app.privateCouterAtom;
export const userPermissionAtom = app.userPermissionAtom;

/* headerStore */
export const logInfoAtom = header.logInfoAtom;
export const filterListAtom = header.filterListAtom;
export const outHistoryAtom = header.outHistoryAtom;
export const inHistoryAtom = header.inHistoryAtom;
export const filterTypeAtom = header.filterTypeAtom;
export const showLoginAtom = header.showLoginAtom;
export const currentPageCounter = header.currentPageCounter;
export const currentPageAtom = header.currentPageAtom;
export const totalPageAtom = header.totalPageAtom;
export const showUserAtom = header.showUserAtom;
export const initialLoginStageAtom = header.initialLoginStageAtom;
export const showHistoryAtom = header.showHistoryAtom;
export const totalHistoryAtom = header.totalHistoryAtom;

/* galleryStore */
export const cardsAtom = gallery.cardsAtom;
export const cardsTypeAtom = gallery.cardsTypeAtom;
export const cardsTypeConst = gallery.cardsTypeConst;
export const searchKeyWordAtom = gallery.searchKeyWordAtom;
export const rodinSearchKeyWordAtom = gallery.rodinSearchKeyWordAtom;
export const showSearchAtom = gallery.showSearchAtom;
export const rodinCardsTypeAtom = gallery.rodinCardsTypeAtom;
export const isImgto3dAtom = gallery.isImgto3dAtom;
export const showCardsConAtom = gallery.showCardsConAtom;
export const bodyOpacityTransitionAtom = gallery.bodyOpacityTransitionAtom;
export const isEditorOpenAtom = gallery.isEditorOpenAtom;
export const panoramaCardsTypeAtom = gallery.panoramaCardsTypeAtom;

/* boardStore */
export const taskInitAtom = Board.taskInitAtom;
export const chatLoadingAtom = Board.chatLoadingAtom;
export const seedsAtom = Board.seedsAtom;
export const downloadStageAtom = Board.downloadStageAtom;
export const posterPromptAtom = Board.posterPromptAtom;
export const lastGenerateUUIDAtom = Board.lastGenerateUUIDAtom;
export const setGenerateAtom = Board.setGenerateAtom;
export const modelSelectedAtom = Board.modelSelectedAtom;
export const taskDetailAtom = Board.taskDetailAtom;
export const chatHistoryAtom = Board.chatHistoryAtom;
export const chatGuessAtom = Board.chatGuessAtom;
export const promptAtom = Board.promptAtom;
export const stopChatAtom = Board.stopChatAtom;
export const meshProfileAtom = Board.meshProfileAtom;
export const assistantChatStatusAtom = Board.assistantChatStatusAtom;
export const guessChatStatusAtom = Board.guessChatStatusAtom;
export const chatTextAtom = Board.chatTextAtom;
export const chatLangAtom = Board.chatLangAtom;
export const generateProgressAtom = Board.generateProgressAtom;
export const needStartWsAtom = Board.needStartWsAtom;
export const isShowModalAtom = Board.isShowModalAtom;
export const chatImageURLAtom = Board.chatImageURLAtom;
export const posterGenerateAtom = Board.posterGenerateAtom;
export const previewImageURLAtom = Board.previewImageURLAtom;
export const showSharePopupAtom = Board.showSharePopupAtom;
export const enteredMeshProfileAtom = Board.enteredMeshProfileAtom;
export const showProgressAtom = Board.showProgressAtom;
export const showDownloadAtom = Board.showDownloadAtom;
export const downloadProgressAtom = Board.downloadProgressAtom;
export const isOpenImg3dGenerateAtom = Board.isOpenImg3dGenerateAtom;
export const imgUrlsArrAtom = Board.imgUrlsArrAtom;
export const showPayCardAtom = Board.showPayCardAtom;
export const currentPreviewIndexAtom = Board.currentPreviewIndexAtom;
export const cameraParamsAtom = Board.cameraParamsAtom;
export const showThreePalaceGridAtom = Board.showThreePalaceGridAtom;
export const showRegenerateAtom = Board.showRegenerateAtom;
export const showPanoramaBordAtom = Board.showPanoramaBordAtom;
export const clickHideAtom = Board.clickHideAtom;
export const cardsProgressStateAtom = Board.cardsProgressStateAtom;
export const isPurchasedAtom = Board.isPurchasedAtom;

/* welcomeStore */
export const workflowSelectedAtom = welcome.workflowSelectedAtom;
export const rodinSearchClickedAtom = welcome.rodinSearchClickedAtom;
export const fileListAtom = welcome.fileListAtom;
export const hashAtom = welcome.hashAtom;
export const urlListAtom = welcome.urlListAtom;
export const fileMapAtom = welcome.fileMapAtom;
export const cardLoadingAtom = welcome.cardLoadingAtom;
export const croppedImageAtom = welcome.croppedImageAtom;
export const currentDBAtom = welcome.currentDBAtom;
export const imageListAtom = welcome.imageListAtom;
export const taskUUidAtom = welcome.taskUUidAtom;


/* widgetStore */
export const isLoadingAtom = widget.isLoadingAtom;
export const curThemeAtom = widget.curThemeAtom;

