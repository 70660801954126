import style from "./Img3dGenerateBoard.module.css";
import { useRecoilState } from "recoil";
import { useEffect, useState, useRef } from "react";
import Telephoto from "../../../assets/0000.jpg";
import MediumShot from "../../../assets/0002.jpg";
import DetailShot from "../../../assets/0005.jpg";
import {
  isOpenImg3dGenerateAtom,
  showThreePalaceGridAtom,
  generateProgressAtom,
  modalObjAtom,
  isShowModalAtom,
  imgUrlsArrAtom,
  taskInitAtom,
  taskDetailAtom,
  showRegenerateAtom,
  taskUUidAtom
} from "../../../store";
import {
  generateDetailImageTo3D,
  getGenerateProgress,
  getTaskDetail,
} from "../../../utils/net";
import deletWights from "../../../assets/deletWights.png";
import deletWightsActive from "../../../assets/deletWightsActive.png";
import { useTips } from "../../GlobalTips";
import { addAndSubtract } from '../../../utils/format'
import { XScroll } from '../../widgets/XScroll'
import { colorPaletteArr } from '../../../utils/map'

const Img3dGenerateBoard = () => {
  const [taskUUid, setTaskUUid] = useRecoilState(taskUUidAtom);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentLength, setCurrentLength] = useState(5);
  const [generating, setGenerating] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [isShowModal, setIsShowModal] = useRecoilState(isShowModalAtom);
  const [modalObj, setModalObj] = useRecoilState(modalObjAtom);
  const leftControler = useRef(null);
  const rightControler = useRef(null);
  const [imgUrlsArr, setImgUrlsArr] = useRecoilState(imgUrlsArrAtom);
  const [showImgUrlsArr, setShowImgUrlsArr] = useState([]);
  const [tipAnimal, setTipAnimal] = useState(false);
  const [tipAnimalId, setTipAnimalId] = useState(null);
  const controlerItemRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const photoItemRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [isOpenImg3dGenerate, setIsOpenImg3dGenerate] = useRecoilState(
    isOpenImg3dGenerateAtom
  );

  const [colorArr, setColorArr] = useState([
    "#4737FF",
    "#FF9737",
    "#D7FF37",
    "#3787FF",
    "#9B37FF",
    "#FFD337",
    "#FF3797",
  ]);
  const [pointerArr, setPointerArr] = useState([
    7, 78.2, 149.4, 220.6, 291.8, 363,
  ]);
  const [pointerDefaultArrArr, setPointerDefaultArrArr] = useState([]);
  const [taskDetail, setTaskDetail] = useRecoilState(taskDetailAtom);
  const intervalRef = useRef(null);
  const advanceOptionRef = useRef(null);
  const customRef = useRef(null);
  const [cameraOption, setCameraOption] = useState(200);
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [focusInput, setFocusInput] = useState(false);
  const [currentCameraIndex, setCurrentCameraIndex] = useState(1);
  const [showThreePalaceGrid, setShowThreePalaceGrid] = useRecoilState(showThreePalaceGridAtom);
  const [showRegenerate, setShowRegenerate] = useRecoilState(showRegenerateAtom);
  const [inputValue, setInputValue] = useState("");
  const [progressChange, setProgressChange] = useState(0);
  const [deletHover, setDeletHover] = useState(false);
  const [clickIng, setClickIng] = useState(false);
  const [calculateRecord, setCalculateRecord] = useState([]);
  const scrollRef = useRef(null);
  const tip = useTips();
  const options = ["Keep the head size", "Optimize the jawline"];
  const [checkboxStates, setCheckboxStates] = useState({
    "Keep the head size": false,
    "Optimize the jawline": false,
  });

  const handleCheckboxChange = (option) => {
    setCheckboxStates({
      ...checkboxStates,
      [option]: !checkboxStates[option],
    });
  };
  useEffect(() => {
    if (imgUrlsArr.length) {
      setShowImgUrlsArr(imgUrlsArr.slice())
    }
    if (generating) return;
    calculateDefaultPointer(imgUrlsArr.length, true);
    document.documentElement.style.overflowY = "hidden";
    return () => {
      document.documentElement.style.overflowY = "overlay";
      setImgUrlsArr([]);
    };
  }, []);

  useEffect(() => {
    if (currentIndex !== -1) {
      const box = photoItemRefs[currentIndex].current;
      box.scrollIntoView({
        behavior: 'smooth',
        inline: 'start'
      });
    }
  }, [currentIndex]);

  const handleClose = () => {
    if (generating) return;
    setIsOpenImg3dGenerate(false);
  };

  const handlerOtherClick = (e) => {
    const refArr = [
      ...controlerItemRefs,
      ...photoItemRefs,
      leftControler,
      rightControler,
    ];
    !refArr.some((item) => item.current === e.target) && setCurrentIndex(-1);
  };

  const handlerDefault = () => {
    if (generating) return;
    setPointerArr(pointerDefaultArrArr);
    setCurrentIndex(-1);
  };

  const handlerLeftMouseDown = (event) => {
    if (event.button !== 0) return; // 只响应鼠标左键事件
    const minSize = 10;
    const startX = event.clientX;
    const pointerIndex = currentIndex;
    let newPointerArr = pointerArr.slice();
    const curPosition = pointerArr[pointerIndex];
    document.onmousemove = function (e) {
      const endX = e.clientX;
      let diffX = endX - startX;
      if (diffX < 0 && newPointerArr[pointerIndex] >= pointerIndex * minSize) {
        const copyPointer = pointerArr.slice();
        // 左按钮左移：影响自身元素和左侧元素，可能影响左侧全部元素，最多移到左侧边缘
        newPointerArr[pointerIndex] = curPosition + diffX;
        for (let i = pointerIndex - 1; i > 0; i--) {
          let gap = copyPointer[i] - copyPointer[pointerIndex];
          if (diffX + minSize < gap) {
            newPointerArr[i] =
              curPosition + diffX - (pointerIndex - i) * minSize;
          } else {
            break;
          }
        }
      } else if (
        diffX > 0 &&
        newPointerArr[pointerIndex + 1] - newPointerArr[pointerIndex] >= minSize
      ) {
        //左按钮右移：影响自身元素和左侧第一个元素，最多移到和右按钮重叠
        newPointerArr[pointerIndex] = curPosition + diffX;
      }
      setPointerArr(newPointerArr.slice());
    };
    document.onmouseup = function () {
      document.onmousemove = null;
      document.onmouseup = null;
    };
    if (event.preventDefault) {
      event.preventDefault();
    }
  };

  const handlerRightMouseDown = (event) => {
    if (event.button !== 0) return; // 只响应鼠标左键事件
    const startX = event.clientX;
    const minSize = 10;
    const pointerIndex = currentIndex + 1;
    let newPointerArr = pointerArr.slice();
    const curPosition = pointerArr[pointerIndex];
    //记录后续元素的原始宽度
    document.onmousemove = function (e) {
      const endX = e.clientX;
      const diffX = endX - startX;
      if (
        diffX < 0 &&
        newPointerArr[pointerIndex] - newPointerArr[pointerIndex - 1] >= minSize
      ) {
        newPointerArr[pointerIndex] = curPosition + diffX;
      } else if (
        diffX > 0 &&
        newPointerArr[pointerIndex] <=
        363 - (pointerArr.length - 1 - pointerIndex) * minSize
      ) {
        const copyPointer = pointerArr.slice();
        newPointerArr[pointerIndex] = curPosition + diffX;
        for (let i = pointerIndex; i < pointerArr.length - 2; i++) {
          const gap = copyPointer[i + 1] - copyPointer[pointerIndex];
          if (diffX + (i + 1 - pointerIndex) * minSize > gap) {
            newPointerArr[i + 1] =
              curPosition + diffX + (i + 1 - pointerIndex) * minSize;
          } else {
            break;
          }
        }
      }
      setPointerArr(newPointerArr.slice());
    };
    document.onmouseup = function () {
      document.onmousemove = null;
      document.onmouseup = null;
    };
    if (event.preventDefault) {
      event.preventDefault();
    }
  };

  const calculateResultFromPointerArr = () => {
    const sum = pointerArr
      .slice(1)
      .reduce((acc, value, index) => acc + (value - pointerArr[index]), 0);
    let tempSum = 0
    let resultArr = pointerArr
      .slice(1)
      .map((value, index) => {
        let itemNum = Number(((value - pointerArr[index]) / sum).toFixed(2))
        if (index === pointerArr.length - 2) {
          return addAndSubtract(1, tempSum, '-')
        }
        tempSum = addAndSubtract(itemNum, tempSum, '+')
        return itemNum
      }
      );
    for (let i = 0; i < calculateRecord.length; i++) {
      if (calculateRecord[i]) {
        resultArr.splice(i, 0, 0);
      }
    }
    return resultArr;
  };

  const handlerGenerate = async () => {
    if (generating) return;

    if (isCustom && (inputValue < 15 || inputValue > 135)) {
      console.log('generate');
      tip({
        type: "warning",
        content: "Custom focal must be between 15mm and 135mm.",
      });
      setTipAnimalDebounce()
      return
    }
    setGenerating(true);
    generateDetailImageTo3D(
      taskUUid,
      cameraOption,
      calculateResultFromPointerArr(),
      checkboxStates["Keep the head size"],
      checkboxStates["Optimize the jawline"],
      getSkinSelectColor(currentColorIndex),
      isCustom
    );
    setModalObj({
      ...modalObj,
      type: "imgto3d",
    });
    clearInterval(intervalRef.current);
    setTimeout(() => {
      intervalRef.current = setInterval(async () => {
        const { data } = await getGenerateProgress(taskUUid);
        if (data.stage === "Done") {
          setProgress(100);
          clearInterval(intervalRef.current);
          const response = await getTaskDetail(taskUUid);
          setTaskDetail(response.data);
          setTimeout(() => {
            setShowThreePalaceGrid(true);
            setIsShowModal(true);
            setShowRegenerate(true);
            setGenerating(false);
          }, 600);
        } else {
          if (data.percentage) {
            setProgressChange(data.percentage);
          }
        }
      }, 1000);
    }, 1000);
  };

  useEffect(() => {
    if (generating) {
      setProgress(progressChange);
    }
  }, [progressChange]);

  const handlerSelectCamera = (index) => {
    setCurrentCameraIndex(index);
    if (index < 3) {
      setFocusInput(false);
      customRef.current.value = "";
      setInputValue("");
      setIsCustom(false)
      const valueArr = [1200, 200, 60];
      setCameraOption(valueArr[index]);
    } else {
      setFocusInput(true);
      setIsCustom(true)
      customRef.current.focus();
    }
  };

  const handlerClickOther = (e) => {
    // if (generating) {
    //   return
    // }
    if (advanceOptionRef.current.contains(e.target)) return;
    setShowOptions(false);
  };

  const setTipAnimalDebounce = () => {
    if (tipAnimalId) clearTimeout(tipAnimalId)
    setTipAnimal(true)
    setTipAnimalId(setTimeout(() => {
      setTipAnimal(false)
    }, 1500))
  }
  const handleInputChange = (event) => {
    const value = event.target.value;
    let numericValue = value.replace(/\D/g, "");
    // 如果 numericValue 大于 135，设为 135
    if (numericValue > 135) {
      numericValue = 135;
      tip({
        type: "warning",
        content: "Custom focal must be between 15mm and 135mm.",
      });
      setTipAnimalDebounce()
    }
    // 如果 numericValue 以 0 开头，设为 1
    if (numericValue.toString().startsWith("0")) {
      numericValue = 1;
      tip({
        type: "warning",
        content: "Input must not start with 0.",
      });
      setTipAnimalDebounce()
    }
    event.target.value = numericValue;
    setInputValue(event.target.value);
    setCameraOption(event.target.value);
  };


  const handlerDeletWeights = (index) => {
    if (pointerArr.length <= 2) {
      tip({
        type: "warning",
        content: "Weights cannot be empty",
      });
      return;
    }
    const newColorArr = colorArr.slice(0, currentLength);
    const newShowImgUrlsArr = showImgUrlsArr.slice()
    const newCalculateRecord = calculateRecord.slice();
    const deleteColor = newColorArr.splice(index, 1);
    newCalculateRecord[
      [
        "#4737FF",
        "#FF9737",
        "#D7FF37",
        "#3787FF",
        "#9B37FF",
        "#FFD337",
        "#FF3797",
      ].findIndex((item) => deleteColor[0] === item)
    ] = true;
    newShowImgUrlsArr.splice(index, 1)
    setCalculateRecord(newCalculateRecord);
    photoItemRefs[index].current.style.opacity = 0;
    setTimeout(() => {
      setShowImgUrlsArr(newShowImgUrlsArr)
      setImgUrlsArr(newShowImgUrlsArr)
      setColorArr(newColorArr);
      calculateDefaultPointer(pointerArr.length - 2);
      photoItemRefs[index].current.style.opacity = 1;
    }, 400);
  };

  const calculateDefaultPointer = (len, initialization = false) => {
    const newArr = new Array(len + 1);
    const leftPoSition = 7
    const rightPosition = 363
    newArr[0] = leftPoSition;
    newArr[newArr.length - 1] = rightPosition;
    const diff = (rightPosition - leftPoSition) / len;
    setCurrentLength(len);
    let startPointer = leftPoSition;
    for (let i = 1; i < len; i++) {
      newArr[i] = addAndSubtract(diff, startPointer, '+')
      startPointer = newArr[i]
    }
    setPointerDefaultArrArr(newArr);
    if (initialization) {
      setPointerArr(newArr);
    }
  };

  useEffect(() => {
    setPointerArr(pointerDefaultArrArr)
  }, [pointerDefaultArrArr])

  const handlerClickPhoto = (index, e) => {
    if (!generating) {
      setCurrentIndex(index);
      setClickIng(true)
    }
  }

  const getSkinSelectColor = (index) => {
    switch (index) {
      case 0:
        return -1;
      case 1:
        return '';
      default:
        return index - 2;
    }
  }

  return (
    <div onClick={handlerClickOther}>
      {!isShowModal && (
        <div className={style.mask} onPointerDown={handleClose}></div>
      )}
      <div className={style.Img3dGenerateWrapper} onClick={handlerOtherClick}>
        <div className={style.titleBox}>
          <div className={style.title}>Image-to-3D</div>
        </div>
        <div
          className={`${style.mainBox} ${generating ? style.unable : ""}`}
          style={{
            pointerEvents: generating ? "none" : "auto",
          }}
        >
          <div className={style.settingBox}>
            <div className={style.settingTitle}>Settings</div>
            <div className={style.toolbar}>
              <div>Weight</div>
              <div className={style.defaultBox} onClick={handlerDefault}>
                <span>Default</span>
              </div>
            </div>
            <div className={style.controlerBox}>
              {colorArr.slice(0, currentLength).map((item, index, arr) => {
                return (
                  <div
                    key={item}
                    ref={controlerItemRefs[index]}
                    style={{
                      borderRadius:
                        index === 0
                          ? pointerArr.length >= 3
                            ? "6px 0px 0px 6px"
                            : "6px"
                          : index === arr.length - 1
                            ? "0px 6px 6px 0px"
                            : "",
                      width:
                        currentIndex === index
                          ? `${pointerArr[index + 1] - pointerArr[index] + 2}px`
                          : `${pointerArr[index + 1] - pointerArr[index]}px`,
                      backgroundColor: `${item}`,
                      left:
                        currentIndex === index && currentIndex !== 0
                          ? `${pointerArr[index] - 1}px`
                          : `${pointerArr[index]}px`,
                      right: index === pointerArr - 2 ? "7px" : "",
                      zIndex: 0,
                    }}
                    className={`${currentIndex === index ? style.controlerSelect : ""
                      }`}
                    onClick={(e) => {
                      if (!generating) {
                        setClickIng(true)
                        setCurrentIndex(index);
                      }
                    }}
                  >
                    {currentIndex === index && (
                      <>
                        <span
                          className={style.leftControler}
                          style={currentIndex === 0 ? { display: "none" } : {}}
                          ref={leftControler}
                          onMouseDown={handlerLeftMouseDown}
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                        </span>
                        <span
                          className={style.rightControler}
                          ref={rightControler}
                          onMouseDown={handlerRightMouseDown}
                          style={
                            currentIndex === arr.length - 1
                              ? { display: "none" }
                              : {}
                          }
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                        </span>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
            <XScroll
              customStyle={{
                width: '100%',
                height: '112px',
                padding: '0 7px',
                margin: '12px 0 24px'
              }}
              scrollBar={true}
              ref={scrollRef}
            >
              <div className={style.photoList}>
                {colorArr.slice(0, currentLength).map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={style.photoitem}
                      ref={photoItemRefs[index]}
                      style={
                        currentIndex === index
                          ? {
                            borderRight: `2PX solid ${item}`,
                            borderTop: `2PX solid ${item}`,
                            borderBottom: `2PX solid ${item}`,
                            borderLeft: `4px solid ${item}`,
                          }
                          : { border: `2px solid transparent` }
                      }
                      onClick={handlerClickPhoto.bind(null, index)}
                    >
                      <img src={showImgUrlsArr[index]} alt="" />
                      {currentIndex !== index && (
                        <span
                          className={style.normalItem}
                          style={{
                            backgroundColor: `${item}`,
                          }}
                        ></span>
                      )}
                      <div
                        className={style.deletWights}
                        onClick={handlerDeletWeights.bind(null, index)}
                        onMouseEnter={() => setDeletHover(true)}
                        onMouseLeave={() => setDeletHover(false)}
                      >
                        <img
                          src={deletHover ? deletWightsActive : deletWights}
                          alt="deletWights"
                        />
                      </div>

                    </div>
                  );
                })}
              </div>
            </XScroll>
          </div>
          <div className={style.cameraBox}>
            <div className={style.cameraTitle}>
              <div className={style.leftTitle}>Camera Params</div>
              <div
                className={style.rightTitle}
                ref={advanceOptionRef}
                onClick={() => {
                  setShowOptions(true);
                }}
              >
                Advanced Options
                <div
                  className={`${style.optionsBox} ${showOptions ? style.showOption : style.hideOption
                    }`}
                >
                  {options.map((item, index) => {
                    return (
                      <div key={index} className={style.option}>
                        <div className={style.checkbox}>
                          <input
                            type="checkbox"
                            id={"cb" + index}
                            className={style.hiddenxsup}
                            checked={checkboxStates[item]}
                            onChange={() => handleCheckboxChange(item)}
                          />
                          <label
                            htmlFor={"cb" + index}
                            className={style.label}
                          ></label>
                        </div>
                        <div className={style.inputInfo}>{item}</div>
                      </div>
                    );
                  })}
                  <div className={style.skinToneLine}>
                    <div style={{
                      textAlign: 'left',
                      paddingLeft: '5px',
                      color: '#999',
                      marginBottom: '5px'
                    }}>Skin Tones</div>
                  </div>
                  <div className={style.colorPalette}>
                    {colorPaletteArr.map((item, index) => {
                      return <div
                        className={`${[0, 1].includes(index) ? style.specialItem : style.nomarlItem} ${style.colorItem}`}
                        style={{
                          backgroundColor: item.color,
                          outline: currentColorIndex === index ? '2px solid #4a00e0' : 'none'
                        }}
                        onClick={() => { setCurrentColorIndex(index) }}
                        key={index}>
                        {[0, 1].includes(index) ? item.name : ''}
                      </div>
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className={style.cameraMainBox}>
              <div className={style.cameraLeftBox}>
                <div className={style.selectBox}>
                  {[
                    {
                      method: "Telephoto",
                      distance: "8500",
                      focal: "8.5m",
                    },
                    {
                      method: "MediumShot",
                      distance: "1500",
                      focal: "1.5m",
                    },
                    {
                      method: "DetailShot",
                      distance: "500",
                      focal: "50cm",
                    },
                    {},
                  ].map((item, index) => {
                    return index < 3 ? (
                      <div
                        onClick={handlerSelectCamera.bind(null, index)}
                        className={`${style.cameraSelect} ${currentCameraIndex === index ? style.selected : ""
                          }`}
                        key={index}
                      >
                        <span>{item.method}</span>
                      </div>
                    ) : (
                      <div
                        key={3}
                        onClick={handlerSelectCamera.bind(null, index)}
                        className={`${style.cameraSelect} ${style.customBox} ${focusInput ? style.hideBefore : ""
                          } ${tipAnimal ? style.tipAnimal : ''}`}
                      >
                        <input
                          onChange={handleInputChange}
                          className={style.customInput}
                          ref={customRef}
                          type="text"
                        />
                        <span
                          style={{
                            display: focusInput ? "block" : "none",
                          }}
                          className={style.customspan}
                        >
                          mm
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={style.cameraRightBox}>
                <div className={style.exampleDiagram} style={{ position: 'relative' }}>

                  {
                    currentCameraIndex < 3 ? <img
                      src={
                        [Telephoto, MediumShot, DetailShot][currentCameraIndex]
                      }
                      alt=""
                    /> :
                      <div className={style.isCustom}>
                        <div>
                          <p>36&nbsp;&nbsp;mm</p>
                          <p>focal&nbsp;&nbsp;length</p>
                        </div>
                        <p style={{
                          height: '22px',
                          display: 'flex',
                          alignItems: 'flex-end'
                        }}>
                          <span className={style.inputValue}>{inputValue}</span>&nbsp;
                          <span style={{
                            display: 'inline-block',
                          }}>mm</span></p>
                      </div>
                  }


                </div>
              </div>
            </div>
          </div>
          <div className={style.generateBtn} onClick={handlerGenerate}>
            <div
              className={generating ? `${style.generateProgressGenerating}` : `${style.generateProgress}`}
            ></div>
            <span>{generating ? `Generating...${progress === 100 ? "" : progress + "%"}` : "Generate"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Img3dGenerateBoard };
