import axios from "axios";
import { io } from "socket.io-client";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

let BASE_URL =
  process.env.REACT_APP_ROUTE === "proxy"
    ? process.env.REACT_APP_PROXY_URL
    : process.env.NODE_ENV === "production"
      ? "https://hyperhuman.deemos.com/api"
      : "http://10.219.33.10:3005/api";

const RODIN_URL =
  process.env.NODE_ENV === "production"
    ? "https://hyperhuman.deemos.com"
    : "https://hyperhuman.deemos.com";

const isMock = false;
const suffix = isMock ? ".json" : "";
const isImageRequest = (url) => /\.(jpe?g|png|gif|bmp)$/i.test(url);

let axiosClient = axios;

if (localStorage.getItem("token")) {
  axiosClient = axios.create({
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

const exponentialBackoff = async (fn) => {
  const retries = 10;
  for (let i = 0; i < retries; i++) {
    try {
      return await fn();
    } catch (error) {
      if (i === retries - 1) {
        throw error;
      }
      const delay = Math.pow(2, i) * 3000;
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
};

const exponentialBackoffForFile = async (fn) => {
  const retries = 10;
  for (let i = 0; i < retries; i++) {
    const response = await fn();
    const fileNotFound = response.data?.url === "FILE_NOT_FOUND";

    if (!fileNotFound || i === retries - 1) {
      return response;
    }
    ;
    const delay = Math.pow(2, i) * 1500;
    await new Promise((resolve) => setTimeout(resolve, delay));
  }
};

const initNet = (token) => {
  if (token)
    axiosClient = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  axiosClient.interceptors.response.use(
    async (response) => {
      const fileNotFound = response.data?.url === "FILE_NOT_FOUND";

      if (fileNotFound) {
        const retryRequest = async () => axios.request(response.config);
        //console.log("Exponential Backoff: " + response.config.url);
        return await exponentialBackoffForFile(retryRequest);
      }
      return response;
    },
    async (error) => {
      if (
        error.response &&
        (error.response.status === 404 || error.response.status === 400) &&
        (error.request.responseType === "blob" ||
          error.request.responseType === "arraybuffer" ||
          isImageRequest(error.config.url))
      ) {
        toastr.error("Request unsuccessful. Retrying...");

        const retryRequest = async () => axios.request(error.config);
        //console.log("Exponential Backoff: " + error.config.url);
        return await exponentialBackoff(retryRequest);
      }
      return Promise.reject(error);
    }
  );
};

const login = (payload) => axiosClient.post(`${BASE_URL}/user/login`, payload);

const sendCode = (payload) =>
  axiosClient.post(`${BASE_URL}/user/send_email_verification_code`, {
    ...payload,
    type: "Register",
  });

const sendResetCode = (payload) =>
  axiosClient.post(`${BASE_URL}/user/send_email_verification_code`, {
    ...payload,
    type: "ResetPassword",
  });

const signUp = (payload) =>
  axiosClient.post(`${BASE_URL}/user/register`, payload);

const register = ({
  username,
  email,
  emailVerificationCode,
  invitationCode,
  password,
}) =>
  axiosClient.post(`${BASE_URL}/user/register`, {
    username,
    email,
    email_verification_code: emailVerificationCode,
    invitation_code: invitationCode,
    password,
  });

const resetPassword = ({ email, emailVerificationCode, password }) =>
  axiosClient.post(`${BASE_URL}/user/reset_password`, {
    email,
    email_verification_code: emailVerificationCode,
    new_password: password,
  });

const send_email_verification_code = ({ email, type }) =>
  axiosClient.post(`${BASE_URL}/user/send_email_verification_code`, {
    email,
    type,
  });

const getUserInfo = (payload) =>
  axiosClient.post(`${BASE_URL}/user/get_info`, payload);

let ws;
const startChat = async () => {
  if (ws) return false;
  //console.log('start chat')
  return axiosClient.get(`${BASE_URL}/chat${suffix}`);
};

const wsSend = async ({ task_uuid, content, language, summary }) => {
  if (!ws || ws.disconnected) return Promise.reject("not connected");

  //console.log('ws send')

  return ws.emit("message", {
    content,
    summary,
    task_uuid,
    provider: "user",
    language: language,
  });
};
const startWebsocket = async (subscription, task_uuid, language) => {
  if (ws) return ws;

  //console.log('start ws')

  ws = io(`${BASE_URL}/chat_socket`, {
    query: {
      subscription,
    },
    path: "",
    transports: ["websocket", "polling"],
  });
  await new Promise((res, rej) => {
    ws.on("connect", async () => {
      await wsSend({
        task_uuid,
        content: "[KICKOFF]",
        language,
      });
      res();
    });
  });
  return ws;
};

const reconnectWebsocket = () => {
  if (ws && ws.disconnected) {
    ws.connect();
  }
};

const getWs = () => ws;

const closeWebsocket = () => {
  if (ws && ws.connected) {
    //console.log('close ws')
    ws.close();
    // console.log(ws)
  }
};

const disposeWebsocket = () => {
  if (ws) {
    //console.log('dispose ws')
    ws = null;
  }
};

//task
const generateDetail = ({ task_uuid, prompt, seed }) =>
  axiosClient.post(`${BASE_URL}/task/generate`, {
    task_uuid,
    prompt,
    settings: { seed },
  });

const generateDownload = (payload) =>
  axiosClient.post(`${BASE_URL}/task/unlock`, payload);

const getGenerateProgress = (task_uuid) =>
  axiosClient.post(`${BASE_URL}/task/check_progress/${task_uuid}`);

const getCards = ({ type, page_num, task_type }) =>
  axiosClient.post(`${BASE_URL}/task/cards`, { type, page_num, task_type });
const search = ({ keyword, page_num }) =>
  axiosClient.post(`${BASE_URL}/task/search`, { keyword, page_num, });

const getTaskDetail = (task_uuid) =>
  axiosClient.post(`${BASE_URL}/task/card/${task_uuid}`);

const likeCard = (task_uuid) =>
  axiosClient.post(`${BASE_URL}/like/${task_uuid}`);

const generateDetailImageTo3D = (task_uuid, cameraOption, view_weights, constrain_skull_distance, jawline, skin_color_selected, isCustom) => {
  const settings = { view_weights, constrain_skull_distance, jawline, skin_color_selected };
  if (isCustom) {
    settings.f_36 = cameraOption;
  } else {
    settings.f_36_box = cameraOption;
  }
  return axiosClient.post(`${BASE_URL}/task/imagineface_generate`, {
    task_uuid,
    settings,
  });
};

const getTaskDownload = ({ task_uuid, type, name }) =>
  axiosClient
    .post(`${BASE_URL}/task/get_download`, { task_uuid, type, name })
    .then((data) => {
      // console.log(file_uuid, data.data.url)
      return data.data;
    });

const selectCandidate = (task_uuid, candidateIndex) =>
  axiosClient.post(`${BASE_URL}/task/select_candidate`, {
    uuid: task_uuid,
    selected_id: candidateIndex,
  });

const getExternalRedirectUrl = (provider) =>
  axiosClient.get(`${BASE_URL}/user/external/${provider}`);

const authorizeExternal = (provider, params) =>
  axiosClient.get(`${BASE_URL}/user/external/${provider}/authorize`, {
    params,
  });

const paymentValidationPaypal = (params) =>
  axiosClient.get(`${BASE_URL}/wallet/charge_paypal_return`, { params });

const paymentValidationAlipay = (params) =>
  axiosClient.get(`${BASE_URL}/wallet/charge_alipay_return`, { params });

const fetchPricePaypal = (token_amount) =>
  axiosClient.get(
    `${BASE_URL}/wallet/charge_paypal?token_amount=${token_amount}`
  );

const fetchPriceAlipay = (token_amount) =>
  axiosClient.get(
    `${BASE_URL}/wallet/charge_alipay?token_amount=${token_amount}`
  );

const fetchPriceAlipayCNY = (currency_cny_cents) =>
  axiosClient.get(
    `${BASE_URL}/wallet/charge_alipay?currency_cny_cents=${currency_cny_cents}`
  );

const fetchPricePaypalUSD = (currency_usd_cents) =>
  axiosClient.get(
    `${BASE_URL}/wallet/charge_paypal?currency_usd_cents=${currency_usd_cents}`
  );

const fetchPaymentURLPaypal = ({ token_amount }) =>
  axiosClient.post(`${BASE_URL}/wallet/charge_paypal`, { token_amount });

const fetchPaymentURLAlipay = ({ token_amount }) =>
  axiosClient.post(`${BASE_URL}/wallet/charge_alipay`, { token_amount });

const logout = () => axiosClient.post(`${BASE_URL}/user/logout`);

const updatePassword = ({ user_uuid, old_password, new_password }) =>
  axiosClient.post(`${BASE_URL}/user/update_password`, {
    user_uuid,
    old_password,
    new_password,
  });

const updateInfo = ({ user_uuid, username }) =>
  axiosClient.post(`${BASE_URL}/user/update_info`, { user_uuid, username });

const reedemCode = ({ tcode }) =>
  axiosClient.post(`${BASE_URL}/wallet/cash_tcode`, { tcode });

const getPosterImage = ({ task_uuid, type, name }) =>
  axiosClient.post(`${BASE_URL}/task/get_download`, { task_uuid, type, name });

const getMagicPrompt = () =>
  axiosClient.get(`${BASE_URL}/chat/get_chat_settings`);

const getTotal = () => axiosClient.get(`${BASE_URL}/task/static`);

const getTradeHistory = async ({ user_uuid, username }) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axiosClient.get(
      `${BASE_URL}/wallet/get_charge_history?userUUID=${user_uuid}&username=${username}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getExpenseHistory = async ({ user_uuid, username }) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axiosClient.get(
      `${BASE_URL}/wallet/get_expense_history?userUUID=${user_uuid}&username=${username}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};

const setPrivate = (task_uuid) =>
  axiosClient.post(`${BASE_URL}/task/set_private`, { task_uuid });

const purchaseLicense = ({ task_uuid }) =>
  axiosClient.post(`${BASE_URL}/task/purchase_license`, { task_uuid });

const updateAvatar = (formData) =>
  axiosClient.post(`${BASE_URL}/user/update_info`, formData, {
    //headers: {
    //	'Content-Type': 'multipart/form-data',
    //},
  });

const searchModels = async ({
  database_name,
  input_type,
  fileMap,
  prompt,
  page_num,
  hash,
  onUploadProgress,
}) => {
  //console.log(database_name);
  const form = new FormData();
  form.append("database_name", database_name ? database_name : "sketchfab");
  if (!hash) {
    form.append("input_type", input_type);
    if (input_type === "text") {
      form.append("prompt", prompt);
    } else {
      fileMap.forEach((value, key) => {
        form.append("files", value);
      });
    }
  }

  if (hash) {
    form.append("hash", hash);
  }

  form.append("page_num", page_num);

  const response = await axiosClient.post(`${RODIN_URL}/search`, form, {
    onUploadProgress: onUploadProgress,
  });
  return response;
};

//imagineface

const imaginefaceSubmit = async (formData, onUploadProgress) => axiosClient.post(`${BASE_URL}/task/imagineface_submit`, formData, { onUploadProgress })

const imaginefaceViewSelection = async (task_uuid) =>
  axiosClient.post(`${BASE_URL}/task/imagineface_view_selection`, {
    task_uuid,
  });

const imaginefacePreview = async (task_uuid) =>
  axiosClient.post(`${BASE_URL}/task/imagineface_preview`, { task_uuid });

const imaginefaceGenerate = async (formData) =>
  axiosClient.post(`${BASE_URL}/task/imagineface_generate`, formData, {});

//panorama


const getBanner = () => axiosClient.get(`${BASE_URL}/task/get_banner`)

const queryPanaromaCards = (page_num = 0, type = 'Recent') => axiosClient.post(`${BASE_URL}/task/cards_panorama`, { page_num, type })

const getPanoromaDetail = (task_uuid) => axiosClient.post(`${BASE_URL}/task/card_panorama/${task_uuid}`)

const panoramaGenerate = (prompt, style, generate_depth = false, prompt_image, remix_id) => axiosClient.post(`${BASE_URL}/task/panorama_generate`, { prompt, style, generate_depth, prompt_image, remix_id })

//Canary
const creatCanary = (user_uuid, name) => axiosClient.post(`${BASE_URL}/canary/create`, { user_uuid, name })

const deleteCanary = (user_uuid, name) => axiosClient.post(`${BASE_URL}/canary/delete`, { user_uuid, name })

const checkCanary = (user_uuid, name) => axiosClient.post(`${BASE_URL}/canary/check`, { user_uuid, name })

export {
  initNet,
  logout,
  getTotal,
  fetchPaymentURLAlipay,
  fetchPriceAlipayCNY,
  fetchPricePaypalUSD,
  fetchPaymentURLPaypal,
  fetchPriceAlipay,
  fetchPricePaypal,
  paymentValidationPaypal,
  paymentValidationAlipay,
  updateAvatar,
  getMagicPrompt,
  getPosterImage,
  reedemCode,
  updatePassword,
  updateInfo,
  generateDetailImageTo3D,
  getExternalRedirectUrl,
  authorizeExternal,
  login,
  sendCode,
  signUp,
  register,
  resetPassword,
  send_email_verification_code,
  getUserInfo,
  searchModels,
  startChat,
  startWebsocket,
  getWs,
  wsSend,
  reconnectWebsocket,
  closeWebsocket,
  disposeWebsocket,
  generateDetail,
  generateDownload,
  getGenerateProgress,
  getTaskDetail,
  getCards,
  search,
  likeCard,
  getTaskDownload,
  selectCandidate,
  sendResetCode,
  getTradeHistory,
  getExpenseHistory,
  setPrivate,
  purchaseLicense,
  imaginefaceSubmit,
  imaginefaceViewSelection,
  imaginefaceGenerate,
  imaginefacePreview,
  panoramaGenerate,
  getPanoromaDetail,
  queryPanaromaCards,
  getBanner,
  creatCanary,
  deleteCanary,
  checkCanary,
};

