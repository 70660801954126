/* global _czc */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import style from "./detailBoard.module.css";
import {
  generateProgressAtom,
  meshProfileAtom,
  promptAtom,
  stopChatAtom,
  taskDetailAtom,
  modelSelectedAtom,
  showSharePopupAtom,
  showProgressAtom,
  posterGenerateAtom,
  showDownloadAtom,
  logInfoAtom,
  showLoginAtom,
  cardsAtom,
  showPayCardAtom,
  modalObjAtom,
  cameraParamsAtom,
  isPurchasedAtom,
  isImgto3dAtom,
  showThreePalaceGridAtom
} from "../../../store";
import { getTaskDownload, likeCard } from "../../../utils/net";
import {
  global_render_target_injector,
  load_profile,
} from "../../../render/sssss_rendering";
import { exportToImage } from "../utils";
import Private from "../../widgets/Private";
import License from "../../widgets/License";
import ShareRender from "../SharePoster";
import { useTips } from "../../GlobalTips";
import { BundlePanelFade } from "../BundlePanelFade";
import { BundlePanel } from "../BundlePanel";
import { AiFillHeart, AiFillCheckCircle } from "react-icons/ai";
import { FaLink } from "react-icons/fa";
import { HiArrowNarrowRight } from "react-icons/hi";
import { HiFolderArrowDown } from "react-icons/hi2";
import { useLocation } from "react-router-dom";
import { useDebounce } from '../../../utils/hooks'

function DetailBoard({ width, type }) {
  const location = useLocation();
  const setStopChat = useSetRecoilState(stopChatAtom);
  const [taskDetail, setTaskDetail] = useRecoilState(taskDetailAtom);
  const [isImgto3d, setIsImgto3d] = useRecoilState(isImgto3dAtom);
  const prompt = useRecoilValue(promptAtom);
  const [meshProfile, setMeshProfile] = useRecoilState(meshProfileAtom);
  const logInfo = useRecoilValue(logInfoAtom);
  const [showProgress, setShowProgress] = useRecoilState(showProgressAtom);
  const [isLike, setIsLike] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const [cards, setCards] = useRecoilState(cardsAtom);
  const [modelSelected, setModelSelected] = useRecoilState(modelSelectedAtom);
  const [showSharePopup, setShowSharePopup] =
    useRecoilState(showSharePopupAtom);
  const [generateProgress, setGenerateProgress] =
    useRecoilState(generateProgressAtom);
  const setShowLogin = useSetRecoilState(showLoginAtom);
  const [showDownload, setShowDownload] = useRecoilState(showDownloadAtom);
  const tip = useTips();
  const [posterGenerate, setPosterGenerate] =
    useRecoilState(posterGenerateAtom);
  const modelRef = useRef(null);
  const promptRef = useRef(null);
  const [isPurchased, setIsPurchased] = useRecoilState(isPurchasedAtom);
  const [showThreePalaceGrid, setShowThreePalaceGrid] = useRecoilState(
    showThreePalaceGridAtom
  );
  const [showPayCard, setShowPayCard] = useRecoilState(showPayCardAtom);
  const [cameraParams, setCameraParams] = useRecoilState(cameraParamsAtom);
  const [modelHover, setModelHover] = useState(false);
  const [freeView, setFreeView] = useState(true);
  const [modalObj, setModalObj] = useRecoilState(modalObjAtom);

  window.exportModelView = async () => {
    await exportToImage(modelRef.current, "model");
  };
  window.exportPrompt = async () => {
    await exportToImage(promptRef.current, "prompt");
  };

  const handleLike = async (ev) => {
    _czc.push(["_trackEvent", "Card", "Like Icon", "Detail Card"]);
    if (!logInfo) {
      setShowLogin(true);
      return;
    }
    try {
      const res = await likeCard(taskDetail.task_uuid);
      if (
        res.data.message === "SUCCESS_LIKE" ||
        res.data.message === "SUCCESS_DELIKE"
      ) {
        setIsLike(!isLike);
        const newCards = JSON.parse(JSON.stringify(cards));
        const index = cards.findIndex(
          (item) => item.task_uuid === taskDetail.task_uuid
        );
        if (index === -1) return;
        newCards[index].is_like = res.data.message === "SUCCESS_LIKE";
        newCards[index].num_like =
          res.data.message === "SUCCESS_LIKE"
            ? taskDetail.num_like + 1
            : taskDetail.num_like - 1;
        setCards(newCards);
      } else {
        throw new Error(res.data.message);
      }
    } catch (e) {
      tip({
        type: "error",
        content: "Something went wrong: " + e.message,
      });
    }
  };

  const handleShare = async (ev) => {
    _czc.push(["_trackEvent", "Card", "Share Icon", "Detail Card"]);
    try {
      setPosterGenerate(true);
      const url = `${window.location.origin}/${location.pathname.includes("/imageto3d") ? "imageto3d/" : "chatavatar/"
        }${taskDetail.task_uuid}`;
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(url);
      } else {
        const textarea = document.createElement("textarea");
        document.body.appendChild(textarea);
        textarea.style.position = "fixed";
        textarea.zIndex = -999;
        textarea.style.top = "10px";
        textarea.value = url;
        textarea.select();
        document.execCommand("copy", true);
        document.body.removeChild(textarea);
      }
      setIsShared(true);
      setShowSharePopup(true);
      setTimeout(() => {
        setIsShared(false);
      }, 1500);
    } catch (e) {
      tip({
        type: "error",
        content: "Something went wrong: " + e.message,
      });
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target.tagName === "BUTTON") {
      return;
    }
    setShowSharePopup(false);
  };

  const handleDownloadBtnClick = async (ev) => {
    _czc.push(["_trackEvent", "Card", "Pack Icon", "Detail Card"]);
    if (!logInfo) {
      setShowLogin(true);
      return;
    }

    if (taskDetail.user_state === "None") {
      tip({
        type: "info",
        content: "Sorry! Permission denied!",
      });
      return;
    }
    setShowDownload(!showDownload);
  };

  useEffect(() => {
    //console.log('Detailboard - Loading')
    setStopChat(true);
    const updateBodyClass = () => {
      if (window.isDraggingModel) {
        setModelSelected(true);
      } else {
        setModelSelected(false);
      }
    };
    window.addEventListener("mousedown", updateBodyClass);
    window.addEventListener("mouseup", updateBodyClass);
    return () => {
      // 	setStopChat(false)
      setShowPayCard(false);
      setIsShared(false);
      setShowSharePopup(false);
      setMeshProfile(false);
      window.removeEventListener("mousedown", updateBodyClass);
      window.removeEventListener("mouseup", updateBodyClass);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!taskDetail) {
      //console.log('ShowProgess')
      //setShowProgress(true)
    } else {
      setIsLike(taskDetail.is_like);
      setShowProgress(false);
    }
  }, [taskDetail]);

  useEffect(() => {
    if (showSharePopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showSharePopup]);

  const updateCamera = (intrinsic, extrinsic) => {
    if (window.static_project) {
      window.static_project.update_camera_from_calibration_matrix(
        intrinsic,
        extrinsic
      );
    }
  };

  useEffect(() => {
    console.log(cameraParams, 'cameraParams');
    setTimeout(() => {
      if (cameraParams && cameraParams.intrinsic && cameraParams.extrinsic) {
        updateCamera(cameraParams.intrinsic, cameraParams.extrinsic);
      }
    }, 50);
  }, [cameraParams]);

  const handleModelClick = (e) => {
    const elementUnderMouse = document.elementFromPoint(e.clientX, e.clientY);
    if (elementUnderMouse && elementUnderMouse.nodeName === "CANVAS") {
      window.static_project.update_camera_from_calibration_matrix();
    }
  };

  const meshProfileChange = () => {
    const effect_name = "[[meshProfile]]";
    //console.log(`${effect_name} mount`)
    let ignore_load = false;
    const unmount_handler = () => {
      ignore_load = true;
    };
    // 同样profile直接显示
    if (window.static_project && window.last_uuid === meshProfile.task_uuid) {
      console.log('same');
      window.static_project.show_scene();
      document
        .querySelector("#webglcontainer")
        .replaceWith(window.static_project.content.container);
      return unmount_handler;
    }
    // 不同profile，先隐藏之前的
    if (window.static_project) {
      window.static_project.hide_scene();
    }
    // 没有profile，什么都不干
    if (!meshProfile) {
      return unmount_handler;
    }
    const urlPromise = {
      model: getTaskDownload({
        type: "PreviewPack",
        task_uuid: meshProfile.task_uuid,
        name: "model",
      }),
      diffuse: getTaskDownload({
        type: "PreviewPack",
        task_uuid: meshProfile.task_uuid,
        name: "texture_diffuse",
      }),
      normal: getTaskDownload({
        type: "PreviewPack",
        task_uuid: meshProfile.task_uuid,
        name: "texture_normal",
      }),
      roughness: getTaskDownload({
        type: "PreviewPack",
        task_uuid: meshProfile.task_uuid,
        name: "texture_specular",
      }),
    };

    const getUrlPromise = async (urlPromise) => {
      const keys = Object.keys(urlPromise);
      const results = await Promise.all(keys.map((key) => urlPromise[key]));
      console.log(keys, results, 'keys');
      return keys.reduce((acc, key, index) => {
        acc[key] = results[index].url;
        return acc;
      }, {});
    };

    getUrlPromise(urlPromise)
      .then((urls) => {
        if (urls.model === undefined) {
          setIsPurchased(false);
          if (isImgto3d) {
            setShowThreePalaceGrid(true)
          }
          const webglContainer = document
            .querySelector("#webglcontainer")
            .replaceWith(document.createElement("div"));
          if (webglContainer) {
            webglContainer.remove();
          }
          return unmount_handler;
        } else {
          const webglContainer = document.querySelector("#webglcontainer");
          if (!webglContainer) {
            let newWebglContainer = document.createElement("div");
            newWebglContainer.id = "webglcontainer";
            newWebglContainer.className = style.modelView;
            newWebglContainer.ref = modelRef;
            if (document.querySelector("#detailboard")) {
              document
                .querySelector("#detailboard")
                .appendChild(newWebglContainer);
            }
          }
          setIsPurchased(true);
          // 有profile，加载
          if (window.static_project) {
            document
              .querySelector("#webglcontainer")
              .replaceWith(window.static_project.content.container);
          }
        }
        //console.log(`${effect_name} urlPromise loaded`)
        setShowProgress(false);
        global_render_target_injector.enabled = false;
        //console.log("Model Loading")
        //console.log(window.static_project.content.depthRenderer._renderTarget)
        load_profile(urls, () => {
          window.last_uuid = meshProfile.task_uuid
          if (window.static_project) window.static_project.show_scene();
        });
      })
      .catch((e) => {
        tip({
          type: "error",
          content: "Something went wrong",
        });
        console.log(e);

        return unmount_handler;
      });
  }

  const debounceMesh = useDebounce(meshProfileChange, 200)

  useEffect(() => {
    debounceMesh()
  }, [meshProfile]);

  const handlerShowCard = () => {
    setShowPayCard(true);
  };

  const compareDate = (create_time, default_time = '2023-07-01 00:00:00') => {
    const creatdate = new Date(create_time);
    const defaultdate = new Date(default_time)
    const create_timestamp = Math.floor(creatdate.getTime() / 1000)
    const default_timestamp2 = Math.floor(defaultdate.getTime() / 1000)
    return create_timestamp - default_timestamp2 >= 0
  }

  return (
    <div
      className={`${style.detailBox} ${Math.random()}`}
      id="detailboard"
      onPointerDown={handleModelClick}
      style={
        modelSelected
          ? { userSelect: "none", width: width }
          : {
            width: width,
          }
      }
    >
      <div className={style.toolbarBox}>
        <div className={style.creatorBox}>
          <div className={style.avatar}>
            {taskDetail ? (
              <img
                src={taskDetail?.author?.avatar_url}
                alt="avatar"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../../assets/defaultAvatar.png";
                }}
                style={{
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundColor: "transparent",
                  backgroundImage: `url('../../assets/defaultAvatar.png')`,
                }}
              />
            ) : null}
          </div>
          <div className={`${style.creatorInfoCon}`}>
            <div className={style.creatorName}>
              {taskDetail?.author?.username?.length > 13
                ? taskDetail?.author?.username?.substring(0, 13) + "..."
                : taskDetail?.author?.username}
            </div>

            <div className={`${style.creatorInfo}`}>
              {taskDetail?.author?.username?.length > 13
                ? taskDetail?.author?.username?.substring(0, 13) + "..."
                : taskDetail?.author?.username}
            </div>
          </div>
        </div>

        {/* 自适应 */}
        <div className={style.spaceholder}></div>
        {taskDetail ? (
          <div className={style.iconWrapper}>
            {/* share */}
            {isShared ? (
              <div
                className={`${style.titleBtn} ${style.shareCon} ${isShared ? style.shared : style.unshared
                  }`}
              >
                <AiFillCheckCircle
                  style={{ color: "green", fontSize: "22px" }}
                />
                <div className={`${style.hideOnSmallScreen}`}>
                  &nbsp; Copied
                </div>
              </div>
            ) : (
              <div
                className={`${style.titleBtn} ${style.shareCon}`}
                onClick={handleShare}
              >
                <FaLink style={{ fontSize: "22px", color: "#d5d5d5" }} />
                <div className={`${style.hideOnSmallScreen}`}>&nbsp; Share</div>
              </div>
            )}
            {/* download */}
            {isPurchased && (
              <div
                className={`${taskDetail.user_state === "DoneHigh" ||
                  taskDetail.user_state === "DoneBasic" ||
                  taskDetail.user_state === "Generating"
                  ? style.titleBtnSuccess
                  : style.titleBtn
                  }  ${style.downloadBtn} ${showDownload ? style.download : style.undownload
                  }`}
                onPointerDown={handleDownloadBtnClick}
              >
                <HiFolderArrowDown
                  style={{ color: "#d5d5d5", fontSize: "23px" }}
                />
              </div>
            )}
            {/* like设置 */}
            <div
              className={`${style.titleBtn} ${isLike ? style.like : style.unLike
                } `}
              onPointerDown={handleLike}
            >
              {isLike ? (
                <AiFillHeart style={{ color: "red", fontSize: "23px" }} />
              ) : (
                <AiFillHeart
                  style={{ color: "rgb(206, 206, 206)", fontSize: "23px" }}
                />
              )}
            </div>
            {/* private设置 */}
            {taskDetail.author.user_uuid ===
              localStorage.getItem("user_uuid") && (
                <div className={style.titleBtn}>
                  <Private card={{ ...taskDetail }} type={"detail"}></Private>
                </div>
              )}

            {showDownload ? <BundlePanel /> : null}
          </div>
        ) : null}
      </div>
      <div
        style={{
          position: "absolute",
          left: "53%",
          top: "4rem",
          transform: "translate(-50%, 0)",
          backgroundColor: "white",
          zIndex: 1000,
          padding: 15,
          borderRadius: 8,
          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
          animation: "fadeIn 0.3s",
          visibility: showSharePopup ? "" : "hidden",
        }}
      >
        <ShareRender
          type={location.pathname.includes("/imageto3d") ? "image" : "text"}
        />
      </div>

      <div className={style.modelWrapper}>

        {meshProfile && (
          <div
            className={style.modelView}
            id="webglcontainer"
            ref={modelRef}
          ></div>
        )}

        {!isPurchased && (
          <div className={style.packBoxView}>
            <div
              className={`${style.packBox} ${showPayCard ? style.fadeOut : ""}`}
            >
              <div className={style.permissionDenied}>
                Satisfied with the sketch?
              </div>
              <div className={style.purchaseInfo}>
                Packed the model for online preview or download
              </div>
              <div className={style.assetsBox}>
                <div className={style.assets}>Package assets</div>
                <div className={style.assetsHoverInfo}>
                  You will be granted usage permissions in accordance with the
                  'CC-NC-SA 4.0' license. You cannot use the asset commercially
                  without purchasing an commercial license. For full agreement
                  details,{" "}
                  <a
                    href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    review here.
                  </a>
                </div>
              </div>
              <div className={style.packWrapper}>
                <div className={style.arrowWrapper} onClick={handlerShowCard}>
                  <div className={style.arrowIconBox}>
                    <HiArrowNarrowRight color={"#fff"} fontSize={"18px"} />
                  </div>
                  <div className={style.packNow}>pack now</div>
                </div>
                <span className={style.packageButton}>Pack</span>
              </div>
            </div>
            <div
              className={`${style.BundlePanelBox} ${showPayCard ? style.fadeIn : ""
                }`}
            >
              <BundlePanelFade />
            </div>
          </div>
        )}

        <div
          style={{
            position: "absolute",
            zIndex: -100,
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <div id="info"></div>
          <div id="preloader" className="preloader">
            <div id="preloaderBar" className="vAligned">
              Loading...
              <div className="preloaderBorder">
                <div
                  id="preloaderProgress"
                  className="preloaderProgress"
                  style={{ width: "85%" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* seed */}
      {taskDetail?.settings?.seed && (
        <div className={style.modelSeedsCon}>
          <div className={style.seedsWrapper}>
            <div className={`${style.modelSeeds}`} ref={promptRef}>
              Seed {`${taskDetail?.settings?.seed}${compareDate(taskDetail.time) ? '' : '-old'}`}
            </div>
          </div>
        </div>
      )}
      {/* license */}
      {isPurchased && !showProgress && (
        <div className={style.licenseBox}>
          <License
            largest={true}
            isLisenced={taskDetail.lisenced}
            cancel={false}
            uuid={taskDetail.task_uuid}
          ></License>
        </div>
      )}

      <div className={style.modelInfoCon}>
        {showProgress ? (
          <>
            <div className={style.progressInfo}>{generateProgress.stage}</div>
            <div className={style.progressTrack}>
              <div className={style.progressAnimation}></div>
              <div
                className={style.progressThumb}
                style={{ width: `${generateProgress.percent}%` }}
              ></div>
            </div>
          </>
        ) : null}
        {prompt && !showProgress && (
          <div className={style.modelPromptWrapper}>
            <div className={`${style.modelPrompt}`} ref={promptRef}>
              {prompt}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export { DetailBoard };
