import React, { useState, useEffect } from 'react';
import { authorizeExternal } from '../../utils/net';
import { useTips } from '../GlobalTips';

const OAuthHandler = () => {
  const [urlParams, setUrlParams] = useState(null);
  const [error, setError] = useState(null);
  const [provider, setProvider] = useState(null);
  const tip = useTips();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const provider = params.get('oauth');
    setError(null);
    setProvider(provider);
    if (params.toString()) {
      setUrlParams(Object.fromEntries(params));
    }
  }, []);

  useEffect(() => {
    //console.log(urlParams)
    if (urlParams) {
      handleOauthLogin();
    }
  }, [urlParams]);

  const handleOauthLogin = async () => {
    try {
      const { data } = await authorizeExternal(provider, urlParams);
      if (data.error) {
        throw new Error(data.error);
      } else {
        localStorage.setItem('user_uuid', data.user_uuid);
        localStorage.setItem('token', data.token);
        window.location.href = '/';
      }
    } catch (e) {
      tip({
        type: 'error',
        content: 'Something went wrong: ' + e.message,
      });
      setError(e.message);
    }
  };

  const pageStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'white',
    color: 'black',
    fontSize: '20px',
  };

  const errorStyle = {
    fontSize: '20px',
  };

  const errorStyleDetail = {
    fontSize: '10px',
  };

  return (
    <div style={pageStyle}>
      {error ? (
        <>
          {console.log(error)}
          <div style={errorStyle}>Login Failed</div>
          <br></br>
          <div style={errorStyleDetail}>{error}</div>
        </>
      ) : (
        'Redirecting...'
      )}
    </div>
  );
};

export default OAuthHandler;
