/* global _czc */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { CardClickContext } from "./components/Context";
import "./App.css";
import { LoginPanel } from "./components/LoginPanel";
import {
  logInfoAtom,
  showLoginAtom,
  isMobileAtom,
  isShowModalAtom,
  curThemeAtom,
  privateCouterAtom,
  taskDetailAtom,
  taskInitAtom,
  modalObjAtom,
  userPermissionAtom,
  isImgto3dAtom,
  cameraParamsAtom
} from "./store";
import Nav from "./components/Nav";
import { getUserInfo, initNet, getTaskDetail, checkCanary, creatCanary } from "./utils/net";
import { GlobalTips, useTips } from "./components/GlobalTips";
import { Modal } from "./components/widgets/Modal";
import { useNavigate } from "react-router-dom";
import { matchPath } from "react-router";
import { getBrowserInfo } from './utils/format'
import { useDebounce } from './utils/hooks'

function App() {
  const [taskInit, setTaskInit] = useRecoilState(taskInitAtom);
  const [taskDetail, setTaskDetail] = useRecoilState(taskDetailAtom);
  const showLogin = useRecoilValue(showLoginAtom);
  const [curTheme, setCurThem] = useRecoilState(curThemeAtom);
  const [logInfo, setLogInfo] = useRecoilState(logInfoAtom);
  const [privateCouter, setPrivateCouter] = useRecoilState(privateCouterAtom);
  const [showGallery, setShowGallery] = useState(false);
  const isMobile = useRecoilValue(isMobileAtom);
  const [modalObj, setModalObj] = useRecoilState(modalObjAtom);
  const [isShowModal, setIsShowModal] = useRecoilState(isShowModalAtom);
  const [userPermission, setUserPermission] = useRecoilState(userPermissionAtom);
  const [cameraParams, setCameraParams] = useRecoilState(cameraParamsAtom);
  const location = useLocation();
  const navigate = useNavigate();
  const accessMap = {
    rodin_access: "/rodin",
    panorama_access: "/panorama",
    chatavatar_text_access: "/chatavatar",
    chatavatar_image_access: "/imageto3d",
  };
  const tip = useTips();

  useEffect(() => {
    if (process.env.REACT_APP_ACL_SU === "true") return;

    const currentAccessKey = Object.entries(accessMap).find(
      ([accessSymbol, accessUrl]) => {
        if (location.pathname.startsWith(accessUrl)) return true;
        return false;
      }
    )?.[0];
    if (!currentAccessKey) return;
    if (!userPermission[currentAccessKey]) {
      navigate("/error");
    }
  }, [location, userPermission, navigate, accessMap]);

  useEffect(() => {
    const versionLimitMap = new Map([['Chrome', 88], ['Edge', 88], ['Safari', 15], ['Firefox', 89], ['Opera', 74]])
    const curVersion = getBrowserInfo()
    if (curVersion.name !== 'Unknown' && versionLimitMap.get(curVersion.name) > curVersion.version) {
      tip({
        type: "primary",
        content: "Please upgrade your browser for your experience",
      });
    }
  }, [])

  const naviCard = async () => {
    if (!logInfo && localStorage.getItem("user_uuid")) {
      await getUserInfo({ user_uuid: localStorage.getItem("user_uuid") })
        .then(async (data) => {
          if (data.data.error) return Promise.reject(data.data.error);
          setLogInfo({
            ...data.data.meta,
            token: localStorage.getItem("token"),
          });
          setPrivateCouter(data.data.meta.chances_left_to_set_private_task);
          const res = await checkCanary(data.data.meta.user_uuid, 'imagineface')
          if (Object.keys(res.data).length === 0) {
            setUserPermission(prevState => ({
              ...prevState,
              chatavatar_image_access: true
            }));
          } else {
            setUserPermission(prevState => ({
              ...prevState,
              chatavatar_image_access: false
            }));
          }
          if (localStorage.getItem("unremember")) localStorage.clear();
          initNet(localStorage.getItem("token"));
        })
        .catch((err) => {
          localStorage.clear();
        })
        .finally(() => {
          setShowGallery(true);
        });
    } else {
      setUserPermission(prevState => ({
        ...prevState,
        chatavatar_image_access: false
      }));
      setShowGallery(true);
    }
  };

  const handleClickCard = async (task_uuid, img_1, img_2) => {
    _czc.push([
      "_trackPageview",
      "/chatavatar/detail/" + task_uuid,
      window.location.href,
    ]);
    _czc.push(["_trackEvent", "Index", "Card Click", "Gallery Component"]);
    try {
      const rep = await getTaskDetail(task_uuid);
      console.log(rep.data, 'rep.data');
      setTaskDetail(rep.data);
      setTaskInit(false);
      if (location.pathname.includes("/imageto3d")) {
        setModalObj({
          ...modalObj,
          type: "imgto3d",
          taskuuid: task_uuid,
        });
      } else if (
        location.pathname.includes("/chatavatar") ||
        location.pathname.includes("/result/detail")
      ) {
        setModalObj({
          ...modalObj,
          type: "detail",
          taskuuid: task_uuid,
        });
      } else {
        setModalObj({
          ...modalObj,
          type: "detail",
          taskuuid: task_uuid,
        });
      }
      setIsShowModal(true);
    } catch (e) {
      tip({
        type: "error",
        content: "Something went wrong: " + e.message,
      });
    }
  };

  useEffect(() => {
    if (!logInfo) naviCard();
    // eslint-disable-next-line
  }, [logInfo]);

  const checkAccess = (permission) => {
    if (userPermission.length === 0) return false;
    const curPath = location.pathname.split("/")[1];
    const curPermission = userPermission[permission];
    if (curPermission) return true;
    return false;
  };

  return (
    <CardClickContext.Provider value={useDebounce(handleClickCard, 300)}>
      <div
        className="App"
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        {showLogin && !isMobile ? <LoginPanel /> : ""}
        <Nav isMobile={isMobile} />
        <Outlet />
        {isShowModal && <Modal />}
        <GlobalTips />
      </div>
    </CardClickContext.Provider>
  );
}

export default App;
