/* global _czc */


import { useParams, useLocation } from "react-router-dom";
import React, { useRef, useState, useEffect, useContext } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import heic2any from "heic2any";
import {
  startChat,
  getTaskDetail,
  getTotal,
} from "../../utils/net";
import {
  chatTextAtom,
  needStartWsAtom,
  taskDetailAtom,
  taskInitAtom,
  lastGenerateUUIDAtom,
  logInfoAtom,
  showLoginAtom,
  rodinSearchClickedAtom,
  fileListAtom,
  fileMapAtom,
  croppedImageAtom,
  cardsTypeAtom,
  cardsTypeConst,
  searchKeyWordAtom,
  isShowModalAtom,
  currentNavAtom,
  transitioningAtom,
  modalObjAtom,
  isImgto3dAtom
} from "../../store";
import style from "./chatAvatar.module.css";
import chatAvatarBg from "../../assets/background.png";
import aiLogo from "../../assets/ai-logo.png";
import {
  AiOutlineSearch,
  AiOutlineCloudUpload,
  AiFillDelete,
  AiOutlineFileText,
} from "react-icons/ai";
import { HiOutlineCursorArrowRays } from "react-icons/hi2";
import { useTips } from "../GlobalTips";
import Viewer from "../widgets/render/viewer";
import { LineWave, Grid } from "react-loader-spinner";
import { Gallery } from "../Gallery/ChatAvatar";
import { ImgButton } from '../widgets/ImgButton'
import { CardClickContext } from '../Context';

function ChatAvatar({ scrollPercentage, isMobile }) {
  const [description, setDescription] = useState("");
  const [totalModel, setTotalModel] = useState(0);
  const [uploadType, setUploadType] = useState(undefined);
  const [showUploadPreview, setShowUploadPreview] = useState(false);
  const [uploadHover, setUploadHover] = useState(false);
  const [inputExpanded, setInputExpanded] = useState(false);
  const uploadMessage = "Drop your image or 3D model";
  const [uploadMessageIndex, setUploadMessageIndex] = useState(30);
  const [uploadPreviewActive, setUploadPreviewActive] = useState(false);
  const [uploadPreviewUrl, setUploadPreviewUrl] = useState(undefined);
  const [uploadFileExtension, setUploadFileExtension] = useState(undefined);
  const [isDragging, setIsDragging] = useState(false);
  const [currentUploadingMtl, setCurrentUploadingMtl] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [cropEnabled, setCropEnabled] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [originalFileMap, setOriginalFileMap] = useState(new Map());
  const [rodinSearchClicked, setRodinSearchClicked] = useRecoilState(rodinSearchClickedAtom);
  const [fileList, setFileList] = useRecoilState(fileListAtom);
  const [fileMap, setFileMap] = useRecoilState(fileMapAtom);
  const [objectFileMap, setObjectFileMap] = useRecoilState(croppedImageAtom);
  const [taskInit, setTaskInit] = useRecoilState(taskInitAtom);
  const [lastGenerateUUID, setLastGenerateUUID] = useRecoilState(lastGenerateUUIDAtom);
  const [taskDetail, setTaskDetail] = useRecoilState(taskDetailAtom);
  const setNeedStartWs = useSetRecoilState(needStartWsAtom);
  const logInfo = useRecoilValue(logInfoAtom);
  const setChatText = useSetRecoilState(chatTextAtom);
  const setCardsType = useSetRecoilState(cardsTypeAtom);
  const setSearchKeyWord = useSetRecoilState(searchKeyWordAtom);
  const setShowLogin = useSetRecoilState(showLoginAtom);
  const setIsShowModal = useSetRecoilState(isShowModalAtom);
  const [currentNav, setCurrentNav] = useRecoilState(currentNavAtom);
  const [transitioning, setTransitioning] = useRecoilState(transitioningAtom);
  const [isImgto3d, setIsImgto3d] = useRecoilState(isImgto3dAtom)
  const [modalObj, setModalObj] = useRecoilState(modalObjAtom);
  const inputRef = useRef();
  const fileInputRef = useRef(undefined);
  const timeStampRef = useRef(0);
  const tip = useTips();
  const handleCardClick = useContext(CardClickContext);
  const params = useParams();
  useEffect(() => {
    setModalObj({
      type: 'detail',
    })
    if (params.taskUUid) {
      handleCardClick(params.taskUUid)
    }
  }, []);


  useEffect(() => {
    if (objectFileMap.size === 0) {
      setOriginalFileMap(new Map());
    }
  }, [objectFileMap]);

  useEffect(() => {
    if (
      uploadHover &&
      uploadMessageIndex >= 0 &&
      uploadMessageIndex < uploadMessage.length
    ) {
      const timer = setTimeout(() => {
        setUploadMessageIndex((prevIndex) => prevIndex + 1);
      }, 50);

      return () => clearTimeout(timer);
    }
  }, [uploadHover, uploadMessageIndex]);

  useEffect(() => {
    setTotalModelInTitle();
    setRodinSearchClicked(false);
    setObjectFileMap(new Map());
    setFileList([]);

    _czc.push(["_trackEvent", "ChatAvatar - Index", "Switch Tab", "Welcome Component"]);
    // window.history.pushState(null, "", "/chatavatar");
  }, []);

  useEffect(() => {
    if (!showUploadPreview) {
      setTimeout(() => {
        setUploadPreviewActive(false);
      }, 400);
    } else {
      setUploadPreviewActive(true);
    }
  }, [showUploadPreview]);

  useEffect(() => {
    if (inputExpanded) {
      inputRef.current.focus();
    }
  }, [inputExpanded]);

  const handleMtlFilesUpload = (event) => {
    _czc.push(["_trackEvent", "Rodin - Index", "Upload", "Preview Component"]);
    const files = event.target.files;
    let newMtlFilesArray = [];

    for (let i = 0; i < files.length; i++) {
      const newFile = files[i];

      let totalSize = 0;
      for (let file of fileMap.values()) {
        totalSize += file.size / 1024 / 1024;
      }

      if (totalSize > 20) {
        tip({
          type: "warning",
          content:
            "The total size of files exceeds 20MB. Please reduce file size.",
        });
      } else {
        setFileMap((prevMap) => {
          const newMap = new Map(prevMap);
          newMap.set(currentUploadingMtl.toLocaleLowerCase(), newFile);
          return newMap;
        });
        newMtlFilesArray.push(newFile);
      }
    }
    event.target.value = "";
  };

  const setTotalModelInTitle = async () => {
    const res = await getTotal();
    setTotalModel(res.data.total);
  };

  const handleInput = (ev) => { setDescription(ev.currentTarget.value); };

  const naviCard = async () => {
    try {
      if (lastGenerateUUID) {
        setNeedStartWs(false);
        const rep = await getTaskDetail(lastGenerateUUID);
        setTaskDetail(rep.data);
        setModalObj({
          ...modalObj,
          type: 'detail',
          taskuuid: lastGenerateUUID
        })
        setIsShowModal(true)
        // window.history.pushState(null, "", "/");
      } else {
        if (!taskInit) {
          setTaskDetail(false);
          setNeedStartWs(true);
        }
        setIsShowModal(true)
        setModalObj({
          ...modalObj,
          type: 'generate',
        })
        // window.history.pushState(null, "", "/");
      }
    } catch (e) {
      tip({
        type: "error",
        content: "Something went wrong: " + e.message,
      });
    }
  };

  const handleGenerate = (ev) => {
    if (!logInfo) {
      setShowLogin(true);
      return;
    }
    //create a chat
    startChat()
      .then((data) => {
        if (data) {
          const init = data.data;
          setTaskInit(init);
          setTimeout(() => {
            setChatText(description);
          }, 100);
          setDescription("");
        }
        naviCard();
      })
      .catch((e) => {
        tip({
          type: "error",
          content: "Something went wrong: " + e.message,
        });
      })
      .finally();
  };

  const handleSearch = async (e) => {
    if (Date.now() - timeStampRef.current >= 1000) {
      if (description === "") return;
      setCardsType(cardsTypeConst.Search);
      setSearchKeyWord(description);
    }
  };

  const handleReUpload = () => {
    _czc.push(["_trackEvent", "Rodin - Index", "Re-upload", "Preview Component"]);
    setObjectFileMap(new Map());
    setFileMap(new Map());
    setFileList([]);
    fileInputRef.current.click();
  };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const maxSize = 20 * 1024 * 1024;
      if (file.size > maxSize) {
        tip({
          type: "warning",
          content: "File size exceeds the limit of 20MB.",
        });
        return;
      }

      const allowedExtensions = ["jpg", "jpeg", "png", "obj", "fbx", "gltf", "glb", "dae", "ply", "stl", "heic",];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        if (
          fileExtension === "jpg" ||
          fileExtension === "jpeg" ||
          fileExtension === "png" ||
          fileExtension === "heic"
        ) {
          setObjectFileMap(new Map([["image", file]]));
          setOriginalFileMap(new Map([["image", file]]));
          setUploadType("image");

          const reader = new FileReader();
          reader.onload = async (e) => {
            if (fileExtension === "heic") {
              tip({
                type: "primary",
                content:
                  "Please note that the HEIC image may take some time to load.",
              });
              let jpegUrl = "";
              let jpegBlob = "";
              await fetch(e.target.result)
                .then((res) => res.blob())
                .then((blob) =>
                  heic2any({
                    blob,
                    toType: "image/jpeg",
                    quality: 0.8,
                  })
                )
                .then((conversionResult) => {
                  jpegBlob = conversionResult;
                  jpegUrl = URL.createObjectURL(jpegBlob);
                });
              setObjectFileMap(new Map([["image", jpegBlob]]));
              setOriginalFileMap(new Map([["image", jpegBlob]]));
              setUploadPreviewUrl(jpegUrl);
            } else {
              setUploadPreviewUrl(e.target.result);
            }
          };

          reader.readAsDataURL(file);
        } else {
          setObjectFileMap(new Map([["model", file]]));
          setOriginalFileMap(new Map([["model", file]]));
          setUploadType("3d");
          const reader = new FileReader();
          reader.onload = async (e) => {
            setUploadPreviewUrl(e.target.result);
            setUploadFileExtension(fileExtension);
          };
          reader.readAsDataURL(file);
        }
        setShowUploadPreview(true);
      } else {
        tip({
          type: "error",
          content:
            "Invalid file format. Please upload .jpg, .jpeg, .png, .obj, .fbx, .gltf, .glb, .dae, .ply, .stl, .heic files.",
        });
      }
    }
    event.target.value = "";
  };

  const handleCloseTextureWindow = (e) => {
    setUploadPreviewUrl("");
    setShowUploadPreview(false);
    setUploadingFiles(false);
    setUploadType("text");
    setFileMap(new Map());
    setFileList([]);
  };

  const confirmClick = async () => {
    _czc.push(["_trackEvent", "Rodin - Index", "Confirm", "Preview Component"]);
    let confirmStatus = false;
    for (let name of fileList) {
      if (name.split("|").pop() === "CRITICAL") {
        confirmStatus = true;
      }
    }
    if (confirmStatus) {
      tip({
        type: "warning",
        content: "Please add mandatory texture for better match.",
      });
      return;
    }

    let totalSize = 0;
    for (let file of fileMap.values()) {
      totalSize += file.size / 1024 / 1024;
    }

    if (totalSize > 20) {
      tip({
        type: "warning",
        content:
          "The total size of files exceeds 20MB. Please reduce file size.",
      });
      return;
    }

    setUploadingFiles(true);
    setUploadPercentage(0);

    await handleSearch(); // Search
    setUploadingFiles(false);
    setShowUploadPreview(false);
    setUploadType("text");
    setUploadPreviewUrl("");
    setRodinSearchClicked(true);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleUploadTexture = (e, str) => {
    document.getElementById("mtl-file-upload").click();
    setCurrentUploadingMtl(str.split("|").slice(-2, -1)[0]);
  };

  const handleDeleteTexture = (key) => {
    setFileMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.delete(key);
      return newMap;
    });
  };

  const handleFileDropTextureUpload = (event) => {
    event.target.value = "";
  };

  const handleFileDropTexture = (event) => {
    event.preventDefault();
    event.target.value = "";
  };


  return (
    <div
      className={style.con}
      onClick={handleCloseTextureWindow}
    >
      <div className={style.conBgImg}>
        <img alt="bg img" src={chatAvatarBg} />
      </div>

      <ImgButton></ImgButton>

      <div className={`${transitioning ? style.transitioning : ""} ${style.titleBox}`}>
        <div className={style.title}>{isMobile ? `Chat\nAvatar` : "ChatAvatar "}</div>
        <div className={`${style.versionTitle}`}>V0.2 (Beta)</div>
      </div>
      <div
        style={{
          transform: `translateY(${scrollPercentage * -200}px)`,
          opacity: 1 - scrollPercentage * 4,
        }}
      >
        <div >
          Progressive Generation Of Animatable 3D Faces <br></br> Under Text
          Guidance{" "}
        </div>
      </div>
      <input
        type="file"
        key={Math.random}
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileInputChange}
        accept=".jpg, .jpeg, .png, .obj, .fbx, .gltf, .glb, .dae, .ply, .stl, .heic"
      />
      <div
        className={`${showUploadPreview ? style.iptConWithoutHover : style.iptCon
          } ${style.animated}`}
        style={{
          marginTop: `${Math.max(4 - scrollPercentage * 15, -1)}rem`,
          width: isMobile ? "22rem" : "35rem",
          height: "4rem",
          borderRadius: "2rem",
        }}
        onDrop={handleFileDrop}
        onDragOver={(event) => {
          event.preventDefault();
          setIsDragging(true);
        }}
        onDragEnter={(event) => {
          event.preventDefault();
          setIsDragging(true);
        }}
        onDragLeave={handleDragLeave}
      >
        <>
          <input
            className={`${style.ipt}`}
            placeholder={isMobile ? "Describe the model" : "Describe the model you want to generate"}
            value={description}
            onChange={handleInput}
          // onClick={_czc.push(['_trackEvent', 'Index', 'Input Area Change', 'Welcome Component'])}
          />
          <img
            onPointerDown={(ev) => {
              handleGenerate();
              _czc.push(["_trackEvent", "Index", "Input Area Logo", "Welcome Component",]);
            }}
            alt="ai logo"
            src={aiLogo}
          />
        </>
      </div>
      <div className={`${style.btnCon}`}>
        <div
          className={`${style.btn} ${style.animated}`}
          onPointerDown={(ev) => {
            handleSearch();
            _czc.push(["_trackEvent", "Index", "Search", "Welcome Component"]);
          }}
        >
          <AiOutlineSearch size="1.2em" />
          &nbsp; Search
        </div>
        <div
          className={`${style.btn}  ${style.animated} ${logInfo ? "" : style.disabled
            }`}
          onPointerDown={(ev) => {
            handleGenerate();
            _czc.push([
              "_trackEvent",
              "Index",
              "Generate",
              "Welcome Component",
            ]);
          }}
        >
          <HiOutlineCursorArrowRays size="1.2em" />
          &nbsp; Generate
        </div>
      </div>
      {
        uploadPreviewActive && (
          <>
            <div
              className={`${style.uploadPreview} ${showUploadPreview
                ? style.uploadPreviewActive
                : style.uploadPreviewHide
                }`}
              style={{ zIndex: "10000" }}
              onClick={(e) => e.stopPropagation()}
            >
              {uploadingFiles === true && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(240, 240, 240, 0.9)",
                    position: "absolute",
                    zIndex: 2,
                    backdropFilter: "blur(5px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    borderRadius: "0.5rem",
                  }}
                >
                  <React.Fragment>
                    {uploadPercentage < 100 ? (
                      <div
                        style={{
                          position: "absolute",
                          marginLeft: "10rem",
                          marginTop: "-10rem",
                          zIndex: 1000,
                        }}
                      >
                        <LineWave
                          height="400"
                          width="400"
                          color="rgba(72, 0, 220)"
                          ariaLabel="line-wave"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          firstLineColor=""
                          middleLineColor=""
                          lastLineColor=""
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          marginLeft: "1rem",
                          marginTop: "-3rem",
                          zIndex: 1000,
                        }}
                      >
                        <Grid
                          color="rgba(72, 0, 220)"
                          width="400"
                          visible={true}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        width: "100%",
                        height: `${uploadPercentage}%`,
                        backgroundColor: "rgba(72, 0, 220, 0.05)",
                        position: "absolute",
                        bottom: 0,

                        borderRadius: "inherit",
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                      }}
                    />

                    <div
                      style={{
                        fontSize: "18px",
                        fontFamily: "Arial-Regular, Arial",
                        color: "rgba(72, 0, 220)",
                        marginTop: "7rem",
                        marginLeft: "1.5rem",
                      }}
                    >
                      {uploadPercentage < 100 ? "Uploading..." : "Processing..."}
                      <div
                        style={{
                          marginTop: "5px",
                        }}
                      ></div>
                    </div>
                  </React.Fragment>
                </div>
              )}

              {showUploadPreview ? (
                <Viewer
                  src={uploadPreviewUrl}
                  fileSuffix={uploadFileExtension}
                  uploadType={uploadType}
                  cropMode={cropEnabled}
                />
              ) : (
                ""
              )}

              <div className={style.uploadPreviewTitle}>
                {uploadType === "image"
                  ? "Confirm upload the image?"
                  : "Confirm upload the model?"}
              </div>
              {uploadType === "image" && (
                <div className={style.switch}>
                  <input
                    type="checkbox"
                    checked={cropEnabled}
                    onChange={() => setCropEnabled(!cropEnabled)}
                  />
                  <label>Custom cropping</label>
                </div>
              )}

              <div className={style.uploadPreviewButtons}>
                <div
                  className={`${style.button} ${style.reUploadButton}`}
                  onClick={handleReUpload}
                >
                  Re-upload
                </div>
                <div
                  className={`${style.button} ${style.confirmButton}`}
                  onClick={() => confirmClick()}

                >
                  Confirm
                </div>
              </div>
            </div>
            {uploadType === "3d" && fileList.size > 0 && (
              <div
                className={`${style.textureWindow}  ${showUploadPreview
                  ? style.uploadPreviewActive
                  : style.uploadPreviewHide
                  }`}
                style={{ zIndex: "10000" }}
                onClick={(e) => e.stopPropagation()}
                onDrop={handleFileDropTexture}
                onDragEnter={(event) => event.preventDefault()}
                onDragOver={(event) => {
                  event.preventDefault();
                }}
              >
                <div
                  className={style.mtlUploadArea}
                  onPointerDown={(e) =>
                    document.getElementById("mtl-file-upload-text").click()
                  }
                >
                  <AiOutlineCloudUpload className={style.uploadIconTexture} />
                  <div>Auto match</div>
                </div>

                {fileList.size > 0 && (
                  <div className={style.mtlTitle}>
                    <div>Click to upload</div>
                  </div>
                )}

                <div className={style.mtlFileListList}>
                  {[...fileList].map((file, index) => (
                    <div
                      key={index}
                      className={style.mtlFileItemList}
                      onClick={(e) => {
                        handleUploadTexture(e, file.toString());
                      }}
                      title={file.toString().split(/[/\\]/).pop()}
                    >
                      <div
                        className={style.hintBar}
                        style={{
                          background:
                            file.split("|").pop() === "CRITICAL"
                              ? "rgb(242, 67, 97)"
                              : "#4b00e09d",
                        }}
                      >
                        {file.split("|").pop() === "CRITICAL" ? "MUP" : "OPT"}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <AiOutlineFileText className={style.fileIconList} />
                        {file
                          .toString()
                          .split(/[/\\]/)
                          .pop()
                          .split("|")
                          .slice(-2, -1)[0]
                          .slice(0, 15)}
                        {file
                          .toString()
                          .split(/[/\\]/)
                          .pop()
                          .split("|")
                          .slice(-2, -1)[0].length > 15
                          ? "..."
                          : ""}
                      </div>
                    </div>
                  ))}
                  {Array.from(fileMap).map(([key, file], index) => (
                    <div
                      key={index}
                      className={style.mtlFileItem}
                      title={file.name}
                    >
                      <AiOutlineFileText className={style.fileIcon} />
                      <span>
                        {file.name.slice(0, 8).split("/").pop()}
                        {file.name.length > 8 ? "..." : ""}
                      </span>
                      <AiFillDelete
                        className={style.mtlDeleteBtn}
                        onPointerDown={() => {
                          handleDeleteTexture(key);
                        }}
                      />
                    </div>
                  ))}
                </div>

                <input
                  type="file"
                  id="mtl-file-upload"
                  style={{ display: "none" }}
                  accept=".mtl, .png, .jpg, .png, .jpeg, .bin"
                  onChange={handleMtlFilesUpload}
                />

                <input
                  type="file"
                  id="mtl-file-upload-text"
                  style={{ display: "none" }}
                  accept=".mtl, .png, .jpg, .png, .jpeg, .bin"
                  onChange={handleFileDropTextureUpload}
                  multiple
                />
              </div>
            )}
          </>
        )
      }
      <Gallery />
    </div >
  );
}
export default ChatAvatar;
