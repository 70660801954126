
import React, { useEffect, useRef, useState } from 'react'
import style from './xscroll.module.css'
import { useDebounce } from '../../../utils/hooks.js'

const XScroll = React.forwardRef(({ customStyle, children, scrollBar, contentStyle }, ref) => {
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const [wrapperHeight, setWrapperHeight] = useState(0)
  const wrapperRef = useRef(null)

  const handleResize = () => {
    if (wrapperRef.current) {
      const { width, height } = wrapperRef.current.getBoundingClientRect();
      setWrapperWidth(width);
      setWrapperHeight(height);
    }
  };

  useEffect(() => {
    debounceWheel()
  }, [wrapperRef])

  useEffect(() => {
    window.addEventListener('resize', debounceWheel);
    return () => {
      window.removeEventListener('resize', debounceWheel);
    };
  }, []);
  const debounceWheel = useDebounce(handleResize, 300)

  return <div
    style={customStyle}
    className={style.wrapper}
    ref={wrapperRef}
  >
    {/* 关键点：
      1.动态获取父容器的宽高
      2.设置子容器的宽度为父容器的高度，高度为父元素的宽度
      3.旋转子容器，注意旋转中心如何改变
     */}
    <div className={`${style.scrollBox} ${scrollBar ? style.showBar : style.hideBar}`}
      ref={ref}
      style={{
        width: wrapperHeight,
        height: wrapperWidth,
        transform: `translateY(${wrapperHeight}px) rotate(-90deg)`
      }}>
      <div className={style.content}
        style={contentStyle === 'widthAuto' ? {
          width: wrapperWidth,
          height: 'auto',
        } : { width: '100%', }
        }>
        {wrapperRef.current && children}
      </div>
    </div>

  </div>

})

export { XScroll }