import React, { useEffect, useRef } from "react";
import { atom, useRecoilState } from "recoil";
import style from "./tips.module.css";
import { IoClose } from "react-icons/io5";
import {
  IoAlertCircleOutline,
  IoWarningOutline,
  IoCheckmarkCircleOutline,
  IoInformationCircleOutline,
} from "react-icons/io5";

const tipsAtom = atom({
  default: [
    // {
    // 	type: 'error', //error | info |
    // 	content: 'one test info message',
    // 	yes: (ev) => ev,
    // 	no: (ev) => ev,
    // },
  ],
  key: "globalTipsAtom",
});

const useTips = () => {
  const [tips, setTips] = useRecoilState(tipsAtom);
  const tipsRef = useRef(tips);

  useEffect(() => {
    tipsRef.current = tips;
  }, [tips]);

  return (newTip) => {
    newTip.id = Date.now();
    setTips([...tips, newTip]);
    setTimeout(() => {
      setTips(tipsRef.current.filter((tip) => tip.id !== newTip.id));
    }, 3000);
  };
};

const typeToIconColor = {
  error: { icon: IoAlertCircleOutline, color: "rgb(189, 77, 69)" },
  warning: { icon: IoWarningOutline, color: "rgb(240, 173, 78)" },
  success: { icon: IoCheckmarkCircleOutline, color: "rgb(92, 184, 92)" },
  primary: { icon: IoInformationCircleOutline, color: "rgb(144, 111, 240)" },
};

function GlobalTips() {
  const [tips, setTips] = useRecoilState(tipsAtom);

  const handleYes = (yes, tipId) => async (ev) => {
    await yes();
    setTips(tips.filter((tip) => tip.id !== tipId));
  };
  const handleNo = (no, tipId) => async (ev) => {
    await no();
    setTips(tips.filter((tip) => tip.id !== tipId));
  };

  return (
    <div className={style.tipsCon}>
      {tips.map((tip) => (
        <div
          className={`${style.tip}`}
          style={{ backgroundColor: typeToIconColor[tip?.type ? tip?.type : "primary"]?.color }}
          key={tip.id}
        >
          <div className={style.row1}>
            <div>
              <div className={style.icon}>
                {React.createElement(typeToIconColor[tip?.type ? tip?.type : "primary"]?.icon, {
                  style: { color: "white" },
                })}
              </div>
              <div className={style.notificationMessage}>{tip.content}</div>
            </div>
            {tip.yes && tip.no ? null : (
              <IoClose
                className={style.close}
                onPointerDown={(ev) =>
                  setTips(tips.filter((t) => t.id !== tip.id))
                }
              />
            )}
          </div>
          {tip.yes && tip.no ? (
            <div className={style.row2}>
              <div
                className={`${style.btn} ${style.yes}`}
                onPointerDown={handleYes(tip.yes, tip.id)}
              >
                OK
              </div>
              <div
                className={`${style.btn}`}
                onPointerDown={handleNo(tip.no, tip.id)}
              >
                Dismiss
              </div>
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
}

export { GlobalTips, useTips };
