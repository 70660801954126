import React, { useRef, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import QRCode from "qrcode";
import { useTips } from "../../GlobalTips";
import style from "./sharePoster.module.css";
import {
  chatImageURLAtom,
  taskDetailAtom,
  posterGenerateAtom,
  previewImageURLAtom,
  posterPromptAtom,
  promptAtom,
  isShowModalAtom,
  chatLoadingAtom,
  currentPreviewIndexAtom,
  showSharePopupAtom,
} from "../../../store";
import { getPosterImage, imaginefacePreview } from "../../../utils/net";

const ShareRender = ({ qrCodeValue, type }) => {
  // const [imageRendered, setImageRendered] = useState([false, false, false]);
  // const [imagesLoaded, setImagesLoaded] = useState(0);
  // const [posterGenerate, setPosterGenerate] = useRecoilState(posterGenerateAtom)
  // const lastEffectedVar = useRef([])
  // const [isShowModal, setIsShowModal] = useRecoilState(isShowModalAtom)
  const canvasRef = useRef(null);
  const [chatImageURL, setChatImageURL] = useRecoilState(chatImageURLAtom);
  const taskDetail = useRecoilValue(taskDetailAtom);
  const [prompt, setPrompt] = useRecoilState(promptAtom);
  const posterPrompt = useRecoilValue(posterPromptAtom);
  const previewImageURL = useRecoilValue(previewImageURLAtom);
  const [loading, setLoading] = useState(chatLoadingAtom);
  const [qrCodeImg, setQrCodeImg] = useState(null);
  const [currentPreviewIndex, setCurrentPreviewIndex] = useRecoilState(currentPreviewIndexAtom);
  const [showSharePopup, setShowSharePopup] =
    useRecoilState(showSharePopupAtom);
  const tip = useTips();
  const loadedImgUrls = useRef({
    leftUp: "null",
    leftDown: "null",
    right: "null",
  });
  const img = useRef({ leftUp: "null", leftDown: "null", right: "null" });
  const previewImgRef = useRef({
    face: "null",
    render: "null",
    renderStatue: "null",
  });

  // const loadedBase64Images = useRef({
  //   leftUp: null,
  //   leftDown: null,
  //   right: null,
  // });

  // const setLoadedImgUrls = (newUrls) => {
  //   loadedImgUrls.current = newUrls;
  // };

  // const loadImage = (url, onLoad) => {
  //   const img = new Image();
  //   img.crossOrigin = 'anonymous';
  //   img.onload = () => onLoad(img);
  //   img.src = url;
  // };

  const setImg = (newImg) => {
    img.current = newImg;
  };

  const setPreviewRefImg = (newImg) => {
    previewImgRef.current = newImg;
  };

  const drawRoundedRect = (ctx, x, y, width, height, radius) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.arcTo(x + width, y, x + width, y + radius, radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
    ctx.lineTo(x + radius, y + height);
    ctx.arcTo(x, y + height, x, y + height - radius, radius);
    ctx.lineTo(x, y + radius);
    ctx.arcTo(x, y, x + radius, y, radius);
    ctx.closePath();
    ctx.fill();
  };

  const posterRender = async (canvas, ctx) => {
    try {
      const rep1 = await getPosterImage({
        task_uuid: taskDetail.task_uuid,
        type: "Static",
        name: "image",
      });
      const rep2 = await getPosterImage({
        task_uuid: taskDetail.task_uuid,
        type: "Static",
        name: "video",
      });
      const rep3 = await getPosterImage({
        task_uuid: taskDetail.task_uuid,
        type: "Static",
        name: "geometry",
      });

      setImg({
        leftUp: rep2.data.url,
        leftDown: rep3.data.url,
        right: rep1.data.url,
      });

      if (type === "image") {
        const rep4 = await imaginefacePreview(taskDetail.task_uuid);

        if (!rep4.data.files[currentPreviewIndex]) {
          tip({
            type: "error",
            content:
              "Something went wrong: can not find current preview image src",
          });
          return;
        }
        setPreviewRefImg({
          face: rep4.data.files[currentPreviewIndex].face,
          render: rep4.data.files[currentPreviewIndex].render,
          renderStatue: rep4.data.files[currentPreviewIndex].render_statue,
        });
      }

      //Init
      console.log("----------Rending Start----------");

      const drawPreviewImg = (src, x, y, end) => {
        const item = new Image();
        // item.setAttribute('crossorigin', 'anonymous');
        item.src = previewImgRef.current[src];

        item.onload = () => {
          ctx.save();
          const size = Math.min(item.width, item.height);
          const sx = (item.width - size) / 2;
          const sy = 0;
          const xLeftUp = x;
          const yLeftUp = y;
          const width = 290;
          const height = 290;
          const roundRadius = 10;
          ctx.rect(xLeftUp, yLeftUp, width, height);
          drawRoundedRect(ctx, xLeftUp, yLeftUp, width, height, roundRadius);
          ctx.clip();
          ctx.drawImage(
            item,
            sx,
            sy,
            size,
            size,
            xLeftUp,
            yLeftUp,
            width,
            height
          );
          ctx.restore();
          //onImageLoaded();
          if (end) {
            console.log("loaded2");
            setLoading(false);
          }
        };
      };

      //Text Part
      //console.log("Rending Text Part")

      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.font = "24px sans-serif";
      ctx.fillStyle = "rgb(131, 131, 131)";
      ctx.fillText("Progressive Generation Of", 20, 50);
      if (type === "text") {
        ctx.fillText("Animatable 3D Faces Under Text Guidance", 20, 90);
      } else {
        ctx.fillText("Animatable 3D Faces From Image", 20, 90);
      }
      const gradient = ctx.createLinearGradient(20, 380, 200, 460);
      gradient.addColorStop(0, "rgb(177, 161, 231)");
      gradient.addColorStop(1, "rgb(255, 255, 255)");
      ctx.fillStyle = gradient;
      drawRoundedRect(ctx, 28, 186, 440, 40, 10);

      ctx.fillStyle = "rgb(242, 238, 250)";
      drawRoundedRect(ctx, 20, 256, type === "text" ? 178 : 215, 70, 10);
      ctx.fillStyle = "rgb(125, 95, 202)";
      ctx.font = "bold 30px sans-serif";
      ctx.fillText(type === "text" ? "Text to 3D" : "Image to 3D", 36, 302);

      ctx.font = "23px sans-serif";
      ctx.fillStyle = "rgb(191, 191, 191)";
      ctx.fillText(
        "Scan QRCode get the avatar",
        type === "text" ? 962 : 865,
        type === "text" ? 180 : 302
      );
      ctx.fillText(
        "And generate your own",
        type === "text" ? 1018 : 915,
        type === "text" ? 210 : 332
      );

      ctx.fillStyle = "rgb(0, 0, 0)";
      ctx.font = "120px CourierNewCustom";
      ctx.fillText("ChatAvatar", 20, 214);

      ctx.beginPath();
      ctx.fillStyle = "rgb(74, 0, 224)";
      ctx.lineJoin = "round";
      ctx.save();
      ctx.translate(720, 145);
      ctx.rotate((-60 * Math.PI) / 180);
      ctx.fillRect(0, 0, 20, 8);
      ctx.restore();

      ctx.beginPath();
      ctx.fillStyle = "rgb(74, 0, 224)";
      ctx.lineJoin = "round";
      ctx.save();
      ctx.translate(730, 150);
      ctx.rotate((-45 * Math.PI) / 180);
      ctx.fillRect(0, 0, 30, 8);
      ctx.restore();

      ctx.beginPath();
      ctx.fillStyle = "rgb(74, 0, 224)";
      ctx.lineJoin = "round";
      ctx.save();
      ctx.translate(740, 160);
      ctx.rotate((-25 * Math.PI) / 180);
      ctx.fillRect(0, 0, 20, 8);
      ctx.restore();

      if (type === "image") {
        ctx.beginPath();
        ctx.moveTo(330, 620);
        ctx.lineTo(355, 660);
        ctx.lineTo(330, 700);
        ctx.lineJoin = "round";
        ctx.lineWidth = 15;
        ctx.strokeStyle = "#CACACA";
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(355, 620);
        ctx.lineTo(380, 660);
        ctx.lineTo(355, 700);
        ctx.lineJoin = "round";
        ctx.lineWidth = 15;
        ctx.strokeStyle = "#CACACA";
        ctx.stroke();
      }

      //QR Code Part
      //console.log("Rending QRCode Part")

      qrCodeValue =
        `http://hyperhuman.deemos.com/${type === "text" ? "chatavatar" : "imageto3d"
        }/` + taskDetail.task_uuid;
      QRCode.toDataURL(
        qrCodeValue === undefined ? "https://deemos.com" : qrCodeValue,
        { width: type === "text" ? 160 : 200, height: type === "text" ? 160 : 200 },
        (error, url) => {
          if (error) console.error(error);
          const qrCodeImg = new Image();
          qrCodeImg.crossOrigin = "anonymous";
          qrCodeImg.src = url;
          qrCodeImg.onload = () => {
            ctx.drawImage(
              qrCodeImg,
              type === "text" ? 1120 : 990,
              type === "text" ? 2 : 84,
              type === "text" ? 160 : 200,
              type === "text" ? 160 : 200,
            );
            //onImageLoaded();
          };
        }
      );

      // LeftUp Image
      //console.log("Rending LeftUp Image", img.current.leftUp)

      const leftUp = new Image();
      // CORS
      leftUp.crossOrigin = "anonymous";
      leftUp.src = img.current.leftUp;
      // Onload
      leftUp.onload = () => {
        //console.log("LeftUp Image Onload")
        // Check if same as last loaded image
        if (leftUp.src !== loadedImgUrls.current.leftUp) {
          //("Left Up Image Not Repeat", leftUp.src, loadedImgUrls.current.leftUp)
          loadedImgUrls.current = {
            ...loadedImgUrls.current,
            leftUp: leftUp.src,
          };
        } else {
          //console.log("Left Up Image Repeat", leftUp.src, loadedImgUrls.current.leftUp)
        }

        // Img Render
        ctx.save();
        const size = Math.min(leftUp.width, leftUp.height);
        const sx = (leftUp.width - size) / 2;
        const sy = 0;
        const xLeftUp = type === "text" ? 20 : 400;
        const yLeftUp = 360;
        const width = 290;
        const height = 290;
        const roundRadius = 10;

        ctx.rect(xLeftUp, yLeftUp, width, height);
        drawRoundedRect(ctx, xLeftUp, yLeftUp, width, height, roundRadius);
        ctx.clip();
        ctx.drawImage(
          leftUp,
          sx,
          sy,
          size,
          size,
          xLeftUp,
          yLeftUp,
          width,
          height
        );
        ctx.restore();

        // Image
        //onImageLoaded();
      };

      // LeftDown Image
      //console.log("Rending LeftDown Image")

      const leftDown = new Image();
      leftDown.crossOrigin = "anonymous";
      leftDown.src = img.current.leftDown;
      leftDown.onload = () => {
        if (leftDown.src !== loadedImgUrls.current.leftDown) {
          // console.log("Left Up Image Not Repeat", leftDown.src, loadedImgUrls.current.leftDown)
          loadedImgUrls.current = {
            ...loadedImgUrls.current,
            leftDown: leftDown.src,
          };
        } else {
          // console.log("Left Up Image Repeat", leftDown.src, loadedImgUrls.current.leftDown)
        }
        ctx.save();
        const size = Math.min(leftDown.width, leftDown.height);
        const sx = (leftDown.width - size) / 2;
        const sy = 0;
        const xLeftUp = type === "text" ? 20 : 400;
        const yLeftUp = 672;
        const width = 290;
        const height = 290;
        const roundRadius = 10;
        ctx.rect(xLeftUp, yLeftUp, width, height);
        drawRoundedRect(ctx, xLeftUp, yLeftUp, width, height, roundRadius);
        ctx.clip();
        ctx.drawImage(
          leftDown,
          sx,
          sy,
          size,
          size,
          xLeftUp,
          yLeftUp,
          width,
          height
        );
        ctx.restore();
        //onImageLoaded();
      };

      // Right Image
      //console.log("Rending Right Image")

      const right = new Image();
      right.crossOrigin = "anonymous";
      right.src = img.current.right;
      right.onload = () => {
        if (right.src !== loadedImgUrls.current.right) {
          // console.log("Left Up Image Not Repeat", right.src, loadedImgUrls.current.right)
          loadedImgUrls.current = {
            ...loadedImgUrls.current,
            right: right.src,
          };
        } else {
          //("Left Up Image Repeat", right.src, loadedImgUrls.current.right)
        }
        ctx.save();
        const xLeftUp = type === "text" ? 338 : 718;
        const yLeftUp = 360;
        const width = 460;
        const height = 600;
        const roundRadius = 10;
        drawRoundedRect(ctx, xLeftUp, yLeftUp, width, height, roundRadius);
        ctx.clip();
        ctx.drawImage(right, xLeftUp, yLeftUp, width, height);
        ctx.restore();
        //onImageLoaded();
      };

      // Prompt
      // console.log("Rending Prompt", prompt, "posterPrompt", posterPrompt)

      if (type === "text") {
        var promptText = "";
        if (prompt !== false) {
          promptText = prompt;
        } else {
          promptText = posterPrompt;
        }

        const maxWidth = 420;
        const ellipsis = "...";
        ctx.fillStyle = "rgb(248, 248, 248)";
        drawRoundedRect(ctx, 820, 890, 460, 70, 10);
        ctx.fillStyle = "rgb(170, 170, 170)";
        ctx.font = "20px sans-serif";
        const textMetrics = ctx.measureText(promptText);
        if (textMetrics.width > maxWidth) {
          let currentWidth = 0;
          let truncatedText = "";
          for (let i = 0; i < promptText.length; i++) {
            const currentChar = promptText[i];
            const charWidth = ctx.measureText(currentChar).width;
            if (
              currentWidth + charWidth + ctx.measureText(ellipsis).width <=
              maxWidth
            ) {
              truncatedText += currentChar;
              currentWidth += charWidth;
            } else {
              break;
            }
          }
          promptText = truncatedText + ellipsis;
        }

        const newTextMetrics = ctx.measureText(promptText);
        const textX = 840 + (maxWidth - newTextMetrics.width) / 2;
        const textY = 934;

        ctx.fillText(promptText, textX, textY);
        //Chat Image
        //console.log("Rending Chat Image")

        const chat = new Image();
        chat.crossOrigin = "anonymous";
        chat.src = chatImageURL;
        chat.onload = () => {
          ctx.save();
          const xLeftUp = 820;
          const yLeftUp = 360;
          const width = 460;
          const height = 520;
          const roundRadius = 10;
          drawRoundedRect(ctx, xLeftUp, yLeftUp, width, height, roundRadius);
          ctx.clip();
          //console.log("Chat Image Info", chat.width, chat.height)
          ctx.drawImage(chat, xLeftUp, yLeftUp, width, height);
          ctx.restore();
          console.log("Loaded");
          setLoading(false);
          setTimeout(() => {
            console.log("Timeout");
            uploadImage();
          }, 1000);
        };
      } else {
        drawPreviewImg("face", 20, 360);
        drawPreviewImg("render", 20, 672, true);
      }

      //console.log("----------Rending Done----------")
      //setImagesLoaded(0);
      //setPosterGenerate(false);
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   if (isShowModal === lastEffectedVar.current[0] && prompt === lastEffectedVar.current[1] && chatImageURL === lastEffectedVar.current[2]) {
  //     console.log("Nothing Changed")
  //     //setPosterGenerate(true)
  //   } else {
  //     //console.log("Something Changed",
  //     //"LastVisible", lastEffectedVar.current[0] , "CurrentVisible", isShowModal,
  //     //"Last Prompt", lastEffectedVar.current[1], "Current Prompt", prompt,
  //     // "ChatImageURL", lastEffectedVar.current[2], "Current URL", chatImageURL)
  //     //Invalid Case
  //     if (isShowModal === false) return;

  //     //imageto3d prompt === ''
  //     // if (prompt.length <= 0 || prompt === false) return;

  //     //Render
  //     setLoading(true)
  //     console.log('start--posterRender');
  //     posterRender();
  //     console.log('end--posterRender');
  //     //Reset Last Value
  //     lastEffectedVar.current[0] = isShowModal
  //     lastEffectedVar.current[1] = prompt
  //     lastEffectedVar.current[2] = chatImageURL
  //   }
  //   console.log(lastEffectedVar);
  // }, [isShowModal, prompt, chatImageURL]);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      setLoading(true);
      console.log("start--posterRender");
      posterRender(canvas, ctx);
      console.log("end--posterRender");
    }
  }, [canvasRef, chatImageURL, showSharePopup]);

  const downloadImage = () => {
    const canvas = canvasRef.current;
    const imageURI = canvas.toDataURL("image/jpeg");
    const link = document.createElement("a");
    link.href = imageURI;
    link.download = "poster.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    specialEditionQRCode();
  }, [taskDetail]);

  const handleUpload = async (data, imageBlob) => {
    // upload
    let retryCount = 0;
    const maxRetries = 3;

    while (retryCount < maxRetries) {
      try {
        const response = await fetch("https://waic.deemos.aigleam.com/upload", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          if (response.status === 409) {
            return;
          } else {
            retryCount++;
          }
          continue;
        }
        return;
      } catch (error) {
        console.error("Error during image upload:", error);
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 409) {
          return;
        } else {
          retryCount++;
        }
      }
    }
    console.error("Max retries reached. Upload failed.");
  };


  const specialEditionQRCode = () => {
    qrCodeValue = `https://waic.deemos.aigleam.com/` + taskDetail.task_uuid;
    QRCode.toDataURL(
      qrCodeValue === undefined ? "https://deemos.com" : qrCodeValue,
      { width: 300, height: 300 },
      (error, url) => {
        if (error) console.error(error);
        const qrCodeImg = new Image();
        qrCodeImg.crossOrigin = "anonymous";
        qrCodeImg.src = url;
        setQrCodeImg(qrCodeImg);
      }
    );
  };

  const uploadImage = async () => {
    const banner = new Image();
    banner.crossOrigin = "anonymous";
    let bannerImage = null;
    banner.onload = () => {
      bannerImage = banner;
      const bannerHeight = 244;
      const bannerWidth =
        (bannerImage.width / bannerImage.height) * bannerHeight;
      const prevCanvas = canvasRef.current;
      const ctx = prevCanvas.getContext("2d");
      const newCanvas = document.createElement("canvas");
      const newCtx = newCanvas.getContext("2d");
      newCanvas.width = Math.max(prevCanvas.width, bannerWidth) - 2;
      newCanvas.height = 1220;
      newCtx.drawImage(bannerImage, 0, 0, bannerWidth, bannerHeight);
      newCtx.drawImage(prevCanvas, 0, bannerHeight);
      const imageURL = newCanvas.toDataURL("image/jpeg", 0.5);
      newCanvas.toBlob((imageBlob) => {
        const imageURL = newCanvas.toDataURL("image/jpeg", 0.5);
        const base64Image = imageURL.split(";base64,").pop();
        const data = {
          name: `${taskDetail.task_uuid}.png`,
          body: base64Image,
        };
        //handleUpload(data, imageBlob);
      });
    };
  };

  return (
    <>
      {loading && (
        <div
          style={{
            width: type === "text" ? 460 : 405,
          }}
          className={style.loadingOverlay}
        >
          Loading...
        </div>
      )}

      <div className={style.shareBox}>
        <div className={style.canvasBox}>
          <canvas
            ref={canvasRef}
            width={type === "text" ? 1300 : 1200}
            height={980}
            style={{
              transform: "scale(0.35)",
              position: "absolute",
              left: type === "text" ? -451 : -405,
              top: -322,
            }}
          />
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            downloadImage();
          }}
          className={style.downloadImgBtn}
          style={{
            width: type === "text" ? "455px" : "405px",
          }}
        >
          Download
        </button>
      </div>
    </>
  );
};

export default ShareRender;
