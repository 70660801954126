import { atom } from 'recoil'

export const taskInitAtom = atom({
  key: 'taskInitAtom',
  default: false,
})

export const taskDetailAtom = atom({
  key: 'taskDetailAtom',
  default: false,
})

export const meshProfileAtom = atom({
  key: 'meshProfileAtom',
  default: false,
})

export const chatHistoryAtom = atom({
  key: 'chatHistoryAtom',
  default: {},
})

export const chatGuessAtom = atom({
  key: 'chatGuessAtom',
  default: [],
})

export const promptAtom = atom({
  key: 'promptAtom',
  default: false,
})

export const stopChatAtom = atom({
  key: 'stopChatAtom',
  default: false,
})

export const assistantChatStatusAtom = atom({
  key: 'assistantChatStatusAtom',
  default: '',
})
export const chatImageURLAtom = atom({
  key: 'chatImageURLAtom',
  default: '',
})
export const previewImageURLAtom = atom({
  key: 'previewImageURLAtom',
  default: '',
})
export const posterGenerateAtom = atom({
  key: 'posterGenerateAtom',
  default: false,
})
export const guessChatStatusAtom = atom({
  key: 'guessChatStatusAtom',
  default: '',
})

export const chatTextAtom = atom({
  key: 'chatTextAtom',
  default: '',
})

export const chatLangAtom = atom({
  key: 'chatLangAtom',
  default:
    Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone === 'Asia/Shanghai'
      ? 'Chinese'
      : 'English',
})

export const generateProgressAtom = atom({
  key: 'generateProgressAtom',
  default: {},
})
export const enteredMeshProfileAtom = atom({
  key: 'enteredMeshProfile',
  default: false,
})

export const needStartWsAtom = atom({
  key: 'needStartWsAtom',
  default: false,
})

export const isShowModalAtom = atom({
  key: 'isShowModalAtom',
  default: false,
})
export const showDownloadAtom = atom({
  key: 'showDownloadAtom',
  default: false,
})
export const modelSelectedAtom = atom({
  default: false,
  key: 'modelSelected',
})
export const showSharePopupAtom = atom({
  default: false,
  key: 'showSharePopupAtom',
})

export const setGenerateAtom = atom({
  default: false,
  key: 'setGenerateAtom',
})

export const lastGenerateUUIDAtom = atom({
  default: false,
  key: 'lastGenerateUUIDAtom',
})

export const showProgressAtom = atom({
  key: 'showProgressAtom',
  default: false,
})

export const posterPromptAtom = atom({
  key: 'posterPromptAtom',
  default: false,
})

export const downloadStageAtom = atom({
  key: 'downloadStageAtom',
  default: 2,
})


export const chatLoadingAtom = atom({
  key: 'chatLoadingAtom',
  default: false,
})

export const downloadProgressAtom = atom({
  key: 'downloadProgressAtom',
  default: 0,
})

export const seedsAtom = atom({
  key: 'seedsAtom',
  default: 0,
})

export const isOpenImg3dGenerateAtom = atom({
  key: 'isOpenImg3dGenerateAtom',
  default: false,
})

export const imgUrlsArrAtom = atom({
  key: 'imgUrlsArrAtom',
  default: [],
})

export const showPayCardAtom = atom({
  key: 'showPayCardAtom',
  default: false,
})

export const currentPreviewIndexAtom = atom({
  key: 'currentPreviewIndexAtom',
  default: 0,
})

export const cameraParamsAtom = atom({
  key: 'cameraParamsAtom',
  default: false,
})

export const showThreePalaceGridAtom = atom({
  key: 'showThreePalaceGridAtom',
  default: true,
})

export const showRegenerateAtom = atom({
  key: 'showRegenerateAtom',
  default: false,
})

export const showPanoramaBordAtom = atom({
  key: 'showPanoramaBordAtom',
  default: false,
})

export const clickHideAtom = atom({
  key: 'clickHideAtom',
  default: false,
})

export const cardsProgressStateAtom = atom({
  key: 'cardsProgressStateAtom',
  default: [],
})

export const isPurchasedAtom = atom({
  key: 'isPurchasedAtom',
  default: true,
})