import style from './loding.module.css'
import React from "react";
import { isLoadingAtom } from '../../../store'
import { useRecoilValue } from 'recoil'

const Loading = () => {
  const isLoading = useRecoilValue(isLoadingAtom)
  return (
    <div className={`${isLoading ? style.show : ''} ${style.hide}`}>
      <div className={style.loadingMask}></div>
      <div className={`${isLoading ? style.showLoading : ''} ${style.loadingBox}`}>
        <div className={style.loadingContainer}>
          <div className={style.loading}></div>
          <div>Loading...</div>
        </div>
      </div>
    </div >

  );
};
export default Loading;