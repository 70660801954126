/* global _czc */

// import { redirect } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import {
	login,
	sendCode,
	signUp,
	getExternalRedirectUrl,
	resetPassword,
	sendResetCode,
	updatePassword,
	updateInfo,
	updateAvatar,
} from '../../utils/net'
import { showLoginAtom, initialLoginStageAtom } from '../../store'
import { CSSTransition } from 'react-transition-group'
import style from './login.module.css'
import { AiOutlineGoogle, AiOutlineGithub } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useTips } from '../GlobalTips'
import AvatarEditor from 'react-avatar-editor'
import googleIcon from '../../assets/google.svg'

function LoginPanel() {
	const setShowLogin = useSetRecoilState(showLoginAtom)
	const [loginStage, setLoginStage] = useState(0)
	const [username, setUsername] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [code, setCode] = useState('')
	const [invitation, setInvitation] = useState('')
	const [isRemember, setIsRemember] = useState(true)
	const [tips, setTips] = useState('')
	const showLogin = useRecoilValue(showLoginAtom)
	const [sidebarVisible, setSidebarVisible] = useState(false)
	const loginConRef = useRef(null)
	const animationFrameIdRef = useRef(null)
	const [resetPasswordStage, setResetPasswordStage] = useState(0)
	const [resetCode, setResetCode] = useState('')
	const [resetEmailSent, setResetEmailSent] = useState(false)
	const [newUsername, setNewUsername] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [avatarFile, setAvatarFile] = useState(null)
	const [initialLoginStage, setInitialLoginStage] = useRecoilState(initialLoginStageAtom)
	const [tipsColor, setTipsColor] = useState('')
	const navi = useNavigate()
	const tip = useTips()
	const editorRef = useRef(null)
	const conRef = useRef(null)

	const handleClose = (ev) => {
		setShowLogin(false)
	}

	function animateSidebar() {
		if (!loginConRef.current) return
		const startTime = performance.now()
		const startValue = showLogin ? -100 : 0
		const endValue = showLogin ? 0 : -100
		const duration = 200

		const step = (currentTime) => {
			const progress = (currentTime - startTime) / duration
			const value = startValue + (endValue - startValue) * progress
			loginConRef.current.style.transform = `translateX(${value}%)`


			if (progress < 1) {
				animationFrameIdRef.current = requestAnimationFrame(step)
			} else {
				cancelAnimationFrame(animationFrameIdRef.current)
				animationFrameIdRef.current = null
			}
		}

		animationFrameIdRef.current = requestAnimationFrame(step)


	}

	useEffect(() => {
		document.documentElement.style.overflowY = 'hidden'
		return () => (document.documentElement.style.overflowY = 'overlay')
	}, [])

	useEffect(() => {
		animateSidebar()
		setTimeout(() => conRef.current.style.transform = `translateX(-8px)`, 500)
	}, [showLogin])

	useEffect(() => {
		if (showLogin) {
			setSidebarVisible(true)
		} else {
			setTimeout(() => {
				setSidebarVisible(false)
			}, 300)
		}
	}, [showLogin])

	const handleSwitch = (stage) => (ev) => {
		setTips('')
		setPassword('')
		setIsRemember(true)
		setLoginStage(stage)
		setNewUsername('')
		setNewPassword('')
		setAvatarFile(null)
	}

	useEffect(() => {
		//console.log(initialLoginStage)
		if (initialLoginStage) {
			setLoginStage(initialLoginStage)
		}
	}, [initialLoginStage])

	const handleLogin = async (ev) => {
		_czc.push(['_trackEvent', 'Pop Up', 'Sign In', 'Login Panel'])
		setTips('')
		if (!email) {
			setTips('Please provide your email address')
			tip({
				type: 'warning',
				content: 'Please provide your email address',
			})
			return
		}

		if (!password) {
			setTips('Please provide your password')
			tip({
				type: 'warning',
				content: 'Please provide your password',
			})
			return
		}
		//执行login
		try {
			const res = await login({
				password,
				...(email ? { email } : null),
			})

			if (res.data?.error?.length > 0) {
				throw new Error(res.data.error)
			} else {
				localStorage.setItem('user_uuid', res.data.user_uuid)
				localStorage.setItem('token', res.data.token)
				if (!isRemember) localStorage.setItem('remember', 1)
				navi('/')
				window.location.reload(true)
			}
		} catch (e) {
			handleSwitch(loginStage)()
			setTips(e.message)
			setTipsColor('red')
			tip({
				type: 'error',
				content: 'Something went wrong: ' + e.message,
			})
		}
	}

	const handleUpdateInfo = async () => {
		_czc.push(['_trackEvent', 'Pop Up', 'Change Username', 'User Panel'])
		try {
			const res = await updateInfo({
				user_uuid: localStorage.getItem('user_uuid'),
				username: newUsername,
			})
			if (res.data?.error?.length > 0) {
				throw new Error(res.data.error)
			} else {
				setTips('Username updated successfully, please refresh.')
				setTipsColor('green')
				tip({
					type: 'success',
					content: 'Username updated successfully, please refresh.',
				})
			}
		} catch (e) {
			setTips(e.message)
			setTipsColor('red')
			tip({
				type: 'error',
				content: 'Something went wrong: ' + e.message,
			})
		}
	}

	const handleUpdatePassword = async () => {
		_czc.push(['_trackEvent', 'Pop Up', 'Change Password', 'User Panel'])
		try {
			const res = await updatePassword({
				user_uuid: localStorage.getItem('user_uuid'),
				old_password: password,
				new_password: newPassword,
			})
			if (res.data?.error?.length > 0) {
				throw new Error(res.data.error)
			} else {
				setTips('Password updated successfully.')
				setTipsColor('green')
				tip({
					type: 'success',
					content: 'Password updated successfully.',
				})
			}
		} catch (e) {
			setTips(e.message)
			setTipsColor('red')
			tip({
				type: 'error',
				content: 'Something went wrong: ' + e.message,
			})
		}
	}

	const handleUpdateAvatar = async () => {
		_czc.push(['_trackEvent', 'Pop Up', 'Change Avatar', 'User Panel'])
		if (editorRef.current) {
			const canvas = editorRef.current.getImage()
			canvas.toBlob(async (blob) => {
				//console.log("Blob object:", blob);
				const formData = new FormData()
				const multerFile = new File([blob], avatarFile.name, { type: avatarFile.type })
				formData.append('file', multerFile)

				try {
					const res = await updateAvatar(formData)
					if (res.data?.error?.length > 0) {
						//console.log(res.data.error);
						throw new Error(res.data.error)
					} else {
						setTips('Avatar updated successfully, please refresh.')
						setTipsColor('green')
						tip({
							type: 'success',
							content: 'Avatar updated successfully, please refresh.',
						})
					}
				} catch (e) {
					setTips(e.message)
					setTipsColor('red')
					tip({
						type: 'error',
						content: 'Something went wrong: ' + e.message,
					})
				}
			})
		}
	}

	const handleSendCode = async (ev) => {
		_czc.push(['_trackEvent', 'Pop Up', 'Send Code', 'Login Panel'])
		setTips('')
		if (!email) {
			setTips('Enter a email')
			return
		}

		try {
			const res = await sendCode({ email })

			if (res.data?.error?.length > 0) {
				throw new Error(res.data.error)
			} else {
				handleSwitch(2)()
			}
		} catch (e) {
			handleSwitch(loginStage)()
			setTips(e.message)
			setTipsColor('red')
			tip({
				type: 'error',
				content: 'Something went wrong: ' + e.message,
			})
		}
	}

	const handleSignup = async (ev) => {
		setTips('')
		if (!username) {
			setTips('Invalid username')
			return
		}

		if (!email) {
			setTips('Invalid email')
			return
		}

		if (!code) {
			setTips('Invalid verification code')
			return
		}

		if (!password) {
			setTips('Invalid password')
			return
		}

		try {
			const res = await signUp({
				username,
				email,
				password,
				email_verification_code: code,
				...(invitation ? { invitation_code: invitation } : null),
			})

			if (res.data?.error?.length > 0) {
				throw new Error(res.data.error)
			} else {
				localStorage.setItem('user_uuid', res.data.user_uuid)
				localStorage.setItem('token', res.data.token)
				if (!isRemember) localStorage.setItem('unremember', 1)
				window.location.reload(true)
			}
		} catch (e) {
			handleSwitch(loginStage)()
			setTips(e.message)
			setTipsColor('red')
			tip({
				type: 'error',
				content: 'Something went wrong: ' + e.message,
			})
		}
	}

	const renderCSSTransition = (condition, children) => (
		<CSSTransition
			in={condition}
			timeout={300}
			classNames={{
				enter: style.fadeInFadeOutEnter,
				enterActive: style.fadeInFadeOutEnterActive,
				exit: style.fadeInFadeOutExit,
				exitActive: style.fadeInFadeOutExitActive,
			}}
			unmountOnExit>
			{children}
		</CSSTransition>
	)

	const handleExternalLogin = async (provider) => {
		//setTips(`Login with ${provider} failed.`);
		//return

		if (provider === 'google') {
			_czc.push(['_trackEvent', 'Pop Up', 'OAuth Google', 'Login Panel'])
			// tip({
			// 	type: 'error',
			// 	content: 'Sorry, Google OAuth Disabled',
			// })
			// return
		}

		if (provider === 'github') {
			_czc.push(['_trackEvent', 'Pop Up', 'OAuth Github', 'Login Panel'])
		}

		try {
			const { data: redirectUrl } = await getExternalRedirectUrl(provider)
			window.location.href = redirectUrl.redirect_url
		} catch (e) {
			//console.log(error)
			setTips(`Login with ${provider} failed.`)
			tip({
				type: 'error',
				content: 'Something went wrong: ' + e.message,
			})
		}
	}

	const handleGithubLogin = () => handleExternalLogin('github')
	const handleGoogleLogin = () => handleExternalLogin('google')

	const handleResetPassword = async (ev) => {
		setTips('')
		if (!email) {
			setTips('Invalid email')
			return
		}
		if (!resetCode) {
			setTips('Invalid email verification code')
			return
		}
		if (!password) {
			setTips('Invalid new password')
			return
		}
		//console.log({ email, emailVerificationCode: resetCode, password })
		try {
			const res = await resetPassword({ email, emailVerificationCode: resetCode, password })
			if (res.data?.error?.length > 0) {
				setTips(res.data.error)
			} else {
				setLoginStage(0)
				setTips('Password reset successfully, please login.')
				tip({
					type: 'success',
					content: 'Password reset successfully, please login.',
				})
				setResetPasswordStage(0)
			}
		} catch (e) {
			setTips(e.message)
			setTipsColor('red')
			tip({
				type: 'error',
				content: 'Something went wrong: ' + e.message,
			})
		}
	}

	const handleForgetPassword = async () => {
		if (!email) {
			setTips('Invalid email')
			return
		}

		try {
			const res = await sendResetCode({ email })
			if (res.data?.error?.length > 0) {
				throw new Error(res.data.error)
			} else {
				setResetEmailSent(true)
				setLoginStage(3)
			}
		} catch (e) {
			setTips(e.message)
			setTipsColor('red')
			tip({
				type: 'error',
				content: 'Something went wrong: ' + e.message,
			})
		}
	}

	return (
		<div className={style.con} ref={conRef} style={{ zIndex: "10000" }}>
			<div className={style.mask} onPointerDown={handleClose}></div>
			<div
				ref={loginConRef}
				className={style.loginCon}
				style={{
					transform: showLogin ? 'translateX(0%)' : 'translateX(100%)',
				}}>
				<div className={style.title}>ChatAvatar</div>

				{loginStage === 2 ? (
					<>
						<div className={style.label}>Username</div>
						<input
							className={style.ipt}
							placeholder='Enter username'
							value={username}
							onChange={(ev) => setUsername(ev.currentTarget.value)}
						/>
					</>
				) : null}

				{loginStage === 0 ? (
					<>
						<div className={style.label}>Email Address</div>
						<input
							className={style.ipt}
							placeholder='Your email address'
							value={email}
							onChange={(ev) => setEmail(ev.currentTarget.value)}
						/>
					</>
				) : null}

				{loginStage === 0 || loginStage == 2 ? (
					<>
						<div className={style.label}>Password</div>
						<input
							className={style.ipt}
							placeholder='Your password'
							type='password'
							value={password}
							onChange={(ev) => setPassword(ev.currentTarget.value)}
						/>
					</>
				) : null}

				{loginStage === 2 ? (
					<>
						<div className={style.label}>Vetification Code</div>
						<input
							className={style.ipt}
							placeholder='Email verification code'
							value={code}
							onChange={(ev) => setCode(ev.currentTarget.value)}
						/>
					</>
				) : null}

				{loginStage === 3 ? (
					<>
						<div className={style.label}>Verification Code</div>
						<input
							className={style.ipt}
							placeholder='Email verification code'
							value={resetCode}
							onChange={(ev) => setResetCode(ev.currentTarget.value)}
						/>
					</>
				) : null}

				{loginStage === 3 ? (
					<>
						<div className={style.label}>New Password</div>
						<input
							className={style.ipt}
							placeholder='Your password'
							type='password'
							value={password}
							onChange={(ev) => setPassword(ev.currentTarget.value)}
						/>
					</>
				) : null}

				{loginStage === 2 ? (
					<>
						<div className={style.label}>Invitation Code(optional)</div>
						<input
							className={style.ipt}
							placeholder='Your invitation code'
							value={invitation}
							onChange={(ev) => setInvitation(ev.currentTarget.value)}
						/>
					</>
				) : null}

				{loginStage === 1 ? (
					<>
						<div className={style.label}>Email</div>
						<input
							className={style.ipt}
							placeholder='Your email address'
							value={email}
							onChange={(ev) => setEmail(ev.currentTarget.value)}
						/>
					</>
				) : null}

				{loginStage === 4 ? (
					<>
						<div className={style.label}>New Username</div>
						<input
							className={style.ipt}
							placeholder='Your username'
							value={newUsername}
							onChange={(ev) => setNewUsername(ev.currentTarget.value)}
						/>
						<button className={style.btn} onClick={handleUpdateInfo}>
							Update Username
						</button>
					</>
				) : null}

				{loginStage === 5 ? (
					<>
						<div className={style.label}>Current Password</div>
						<input
							className={style.ipt}
							placeholder='Your current password'
							type='password'
							value={password}
							onChange={(ev) => setPassword(ev.currentTarget.value)}
						/>
						<div className={style.label}>New Password</div>
						<input
							className={style.ipt}
							placeholder='Your new password'
							type='password'
							value={newPassword}
							onChange={(ev) => setNewPassword(ev.currentTarget.value)}
						/>
						<button className={style.btn} onClick={handleUpdatePassword}>
							Update Password
						</button>
					</>
				) : null}

				{loginStage === 6 ? (
					<>
						<div className={style.label}>Upload New Avatar</div>
						<label className={style.fileInputWrapper}>
							Choose File
							<input
								className={style.fileInput}
								type='file'
								onChange={(ev) => setAvatarFile(ev.target.files[0])}
							/>
						</label>
						{avatarFile && (
							<div>
								<AvatarEditor
									ref={editorRef}
									image={avatarFile}
									width={300}
									height={300}
									border={50}
									borderRadius={150}
									scale={1.2}
								/>
							</div>
						)}
						<button className={style.btn} onClick={handleUpdateAvatar}>
							Update Avatar
						</button>
					</>
				) : null}

				{loginStage === 0 ? (
					<div className={style.remCon}>
						<input
							type='checkbox'
							checked={isRemember}
							onPointerDown={(ev) => setIsRemember(!isRemember)}
							onChange={(e) => e}
						/>
						<span
							onPointerDown={(ev) => {
								setIsRemember(!isRemember)
								_czc.push(['_trackEvent', 'Pop Up', 'Remember Me', 'Login Panel'])
							}}>
							Remember me
						</span>
						<span className={style.spaceholder}></span>
						<span
							onPointerDown={(ev) => {
								handleForgetPassword()
								_czc.push([
									'_trackEvent',
									'Pop Up',
									'Forget Password',
									'Login Panel',
								])
							}}
							className={style.forgetPassword}>
							Forget password?
						</span>
					</div>
				) : null}

				{loginStage === 0 ? (
					<div className={`${style.btn} ${style.sign}`} onPointerDown={handleLogin}>
						Sign In
					</div>
				) : null}

				{loginStage === 1 ? (
					<div className={`${style.btn} ${style.sign}`} onPointerDown={handleSendCode}>
						Send Code
					</div>
				) : null}

				{loginStage === 2 ? (
					<div
						className={`${style.btn} ${style.sign}`}
						onPointerDown={(ev) => {
							handleSignup()
							_czc.push(['_trackEvent', 'Pop Up', 'Sign Up', 'Login Panel'])
						}}>
						Sign Up
					</div>
				) : null}

				{loginStage === 3 ? (
					<div
						className={`${style.btn} ${style.sign}`}
						onPointerDown={handleResetPassword}>
						Reset Password
					</div>
				) : null}

				<br></br>
				{tips ? (
					<div className={style.tips} style={{ color: tipsColor }}>
						{tips}
					</div>
				) : null}
				{loginStage === 0 || loginStage === 1 ? (
					<>
						<div className={style.splitCon}>
							<div className={style.split}></div>
							<div className={style.text}>OR</div>
						</div>

						<div className={`${style.btn} ${style.google}`} onClick={handleGoogleLogin}>
							<img src={googleIcon} alt='google' /> &nbsp;Sign in with Google
						</div>

						<div className={`${style.btn} ${style.github}`} onClick={handleGithubLogin}>
							<AiOutlineGithub size='1.5em' />
							Sign in with Github
						</div>
					</>
				) : null}
				<div className={style.spaceholder}></div>

				{loginStage === 0 ? (
					<div className={style.foot}>
						<span
							onClick={handleSwitch(1)}
							onPointerDown={(ev) => {
								_czc.push([
									'_trackEvent',
									'Pop Up',
									'Bottom Sign Up',
									'Login Panel',
								])
							}}
							style={{ fontWeight: 'bold' }}>
							Sign Up
						</span>{' '}
						to create a new account!
					</div>
				) : null}

				{loginStage === 1 || loginStage === 2 || loginStage === 3 ? (
					<div className={style.foot}>
						Already have an account?{' '}
						<span
							onClick={handleSwitch(0)}
							onPointerDown={(ev) => {
								_czc.push([
									'_trackEvent',
									'Pop Up',
									'Bottom Sign In',
									'Login Panel',
								])
							}}
							style={{ fontWeight: 'bold' }}>
							Sign In!
						</span>
					</div>
				) : null}
			</div>
		</div>
	)
}

export { LoginPanel }
