import { useState, useEffect } from "react";
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { ColladaLoader } from "three/examples/jsm/loaders/ColladaLoader";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { useTips } from "../../GlobalTips";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { fileListAtom, fileMapAtom } from "../../../store";

const useModelLoader = (url, type, textures = []) => {
  const [fileList, setFileList] = useRecoilState(fileListAtom);
  const [scene, setScene] = useState(null);
  const tip = useTips();
  const [loadStatus, setLoadStatus] = useState(false);
  const [fileMap, setFileMap] = useRecoilState(fileMapAtom);
  let loadedModel;
  let mtlUrl;
  let textureUrls;

  useEffect(() => {
    loadModel();
  }, [url, type, fileMap]);

  const getCurrentUrl = () => {
    const { protocol, host } = window.location;
    return `${protocol}//${host}`;
  };

  const extractFileName = (text) => {
    const match =
      text.match(/-bm\s*([\d.]*)\s*([^ ]+)/i) || text.match(/([^ ]+)$/);
    return match ? match[match.length - 1].trim().toLowerCase() : "";
  };

  const processMaterialsCreatorTextures = async (materialsCreator) => {
    const missingFiles = new Set();
    const invalidFiles = new Set();
    const processedFiles = new Set();

    const processTexture = (materialInfo, textureType) => {
      let symbol = "OPTIONAL";
      if (textureType === "map_kd") {
        //symbol = "CRITICAL";
        symbol = "OPTIONAL";
      }
      if (materialInfo[textureType]) {
        const fileName = extractFileName(materialInfo[textureType]);
        let textureFile = undefined;
        if (fileMap.has(fileName)) {
          textureFile = fileMap.get(fileName);
        }
        if (textureFile) {
          const url = URL.createObjectURL(textureFile);
          const currentUrl = getCurrentUrl();
          const replaced = url.replace(`blob:${currentUrl}/`, "");
          materialInfo[textureType] = replaced;
          processedFiles.add(fileName + "|" + symbol);
        } else {
          missingFiles.add(fileName.toLowerCase() + "|" + symbol);
        }
      }
    };

    for (const materialName in materialsCreator.materialsInfo) {
      const materialInfo = materialsCreator.materialsInfo[materialName];
      processTexture(materialInfo, "map_kd");
      processTexture(materialInfo, "map_bump");
      processTexture(materialInfo, "map_ke");
    }

    processedFiles.forEach((file) => invalidFiles.delete(file));
    setFileList(missingFiles);
    if (missingFiles.size > 0) {
    }
  };

  const getGltfRequestFile = async (url) => {
    const response = await fetch(url);
    const gltfData = await response.json();
    let missingFiles = [];

    const isMapTexture = (textureIndex) => {
      if (gltfData.materials) {
        for (const material of gltfData.materials) {
          if (
            material.pbrMetallicRoughness &&
            material.pbrMetallicRoughness.baseColorTexture &&
            material.pbrMetallicRoughness.baseColorTexture.index ===
            textureIndex
          ) {
            return true;
          }
        }
      }
      return false;
    };

    if (gltfData.buffers) {
      gltfData.buffers = gltfData.buffers.map((buffer) => {
        if (buffer.uri) {
          const fileName = buffer.uri.toLowerCase();
          if (!fileName.startsWith("data:")) {
            //missingFiles.push(fileName + "|CRITICAL");
            missingFiles.push(fileName + "|OPTIONAL");
          }
        }
      });
    }
    if (gltfData.images) {
      gltfData.images = gltfData.images.map((image, index) => {
        if (image.uri) {
          const fileName = image.uri.toLowerCase();
          if (!fileName.startsWith("data:")) {
            if (isMapTexture(index)) {
              //missingFiles.push(fileName + "|CRITICAL");
              missingFiles.push(fileName + "|OPTIONAL");
            } else {
              missingFiles.push(fileName + "|OPTIONAL");
            }
          }
        }
      });
    }
    return missingFiles;
  };

  const setData = async (url, fileMap) => {
    const response = await fetch(url);
    const gltfData = await response.json();
    let successStatus = true;

    const createGrayImage = () => {
      const canvas = document.createElement("canvas");
      canvas.width = 1;
      canvas.height = 1;
      const ctx = canvas.getContext("2d");
      ctx.fillStyle = "gray";
      ctx.fillRect(0, 0, 1, 1);
      return canvas.toDataURL();
    };

    if (gltfData.buffers) {
      gltfData.buffers = gltfData.buffers.map((buffer) => {
        if (buffer.uri) {
          const fileName = buffer.uri.split("/").pop().toLowerCase();
          const file = fileMap.get(fileName);
          if (file) {
            buffer.uri = URL.createObjectURL(file);
            setFileList((prevFileList) => {
              const newFileList = new Set(prevFileList);
              newFileList.delete(fileName + "|CRITICAL");
              newFileList.delete(fileName + "|OPTIONAL");
              return newFileList;
            });
          } else {
            successStatus = false;
          }
        }
        return buffer;
      });
    }
    if (gltfData.images) {
      gltfData.images = gltfData.images.map((image) => {
        if (image.uri) {
          const fileName = image.uri.split("/").pop().toLowerCase();
          const file = fileMap.get(fileName);
          if (file) {
            image.uri = URL.createObjectURL(file);
            setFileList((prevFileList) => {
              const newFileList = new Set(prevFileList);
              newFileList.delete(fileName + "|CRITICAL");
              newFileList.delete(fileName + "|OPTIONAL");
              return newFileList;
            });
          } else {
            image.uri = createGrayImage();
          }
        }
        return image;
      });
    }
    setLoadStatus(true);
    return JSON.stringify(gltfData);
  };

  const loadModel = async () => {
    // try {
    setLoadStatus(false);
    switch (type) {
      case "gltf":
        const gltfLoader = new GLTFLoader();
        let gltfFileList = await getGltfRequestFile(url);
        let fileNames = gltfFileList.map((path) => {
          const fileName = path.split("/").pop();
          const fileExtension = fileName.split(".").pop();

          return fileName;
        });
        setFileList(new Set(fileNames));
        gltfLoader.parse(await setData(url, fileMap), "", (gltf) => {
          loadedModel = gltf.scene;
          setScene(loadedModel);
        });

        break;

      case "glb": // Done
        const glbLoader = new GLTFLoader();
        const glb = await glbLoader.loadAsync(url);
        loadedModel = glb.scene;
        setScene(loadedModel);
        setLoadStatus(true);
        break;

      case "fbx": // Done
        const fbxLoader = new FBXLoader();
        const fbx = await fbxLoader.loadAsync(url);
        loadedModel = fbx;
        const requiredTextureNames = new Set();
        loadedModel.traverse((child) => {
          if (child.material) {
            const materials = Array.isArray(child.material)
              ? child.material
              : [child.material];
            materials.forEach((material) => {
              if (material.map && material.map.name) {
                //console.log(material.map);
                requiredTextureNames.add(
                  material.map.name.toLowerCase() + "|OPTIONAL"
                );
              } else {
                requiredTextureNames.add("diffuse|OPTIONAL");
              }
            });
          }
        });
        setFileList(requiredTextureNames);
        loadedModel.traverse((child) => {
          console.log(child);
          if (child.material) {
            const materials = Array.isArray(child.material)
              ? child.material
              : [child.material];

            materials.forEach((material) => {
              let textureName = "";
              if (material.map && material.map.name) {
                textureName = material.map.name.toLowerCase();
              } else {
                textureName = "diffuse";
              }
              console.log(textureName);
              if (fileMap.has(textureName)) {
                const newMap = new THREE.TextureLoader().load(
                  URL.createObjectURL(fileMap.get(textureName))
                );
                material.map = newMap;
                console.log(material.map);
                material.map.needsUpdate = true;
                setFileList((prevFileList) => {
                  const newFileList = new Set(prevFileList);
                  newFileList.delete(textureName + "|OPTIONAL");
                  newFileList.delete(textureName + "|CRITICAL");
                  return newFileList;
                });
              }
            });
          }
        });

        setScene(loadedModel);
        setLoadStatus(true);
        break;

      case "dae":
        const colladaLoader = new ColladaLoader();
        const collada = await colladaLoader.loadAsync(url);
        loadedModel = collada.scene;
        const requiredColladaTextureNames = new Set();
        loadedModel.traverse((child) => {
          if (child.material) {
            const materials = Array.isArray(child.material)
              ? child.material
              : [child.material];
            materials.forEach((material) => {
              if (material.map) {
                requiredColladaTextureNames.add(
                  material.map.name
                    ? material.map.name + "|OPTIONAL"
                    : material.name + "|OPTIONAL"
                );
                tip({
                  type: "primary",
                  content:
                    "We don't fully support Collada format yet. The 3D render may not update in real-time.",
                });
              } else {
                requiredColladaTextureNames.add("diffuse|OPTIONAL");
              }
            });
          }
        });
        setFileList(requiredColladaTextureNames);
        loadedModel.traverse((child) => {
          if (child.material) {
            const materials = Array.isArray(child.material)
              ? child.material
              : [child.material];

            materials.forEach((material) => {
              let textureName = "";
              if (material.map) {
                textureName = material.map.name.toLowerCase()
                  ? material.map.name.toLowerCase()
                  : material.name;
              } else {
                textureName = "diffuse";
              }
              if (fileMap.has(textureName)) {
                const newMap = new THREE.TextureLoader().load(
                  URL.createObjectURL(fileMap.get(textureName))
                );
                material.map = newMap;
                material.map.needsUpdate = true;
                setFileList((prevFileList) => {
                  const newFileList = new Set(prevFileList);
                  newFileList.delete(textureName + "|OPTIONAL");
                  newFileList.delete(textureName + "|CRITICAL");
                  return newFileList;
                });
              }
            });
          }
        });
        setScene(loadedModel);
        setLoadStatus(true);
        break;
      case "ply": // Done
        const plyLoader = new PLYLoader();
        const ply = await plyLoader.loadAsync(url);
        loadedModel = ply;
        const material = new THREE.MeshStandardMaterial({
          vertexColors: true,
        });
        const mesh = new THREE.Mesh(loadedModel, material);
        mesh.rotateX(-Math.PI / 2);
        setScene(mesh);
        setLoadStatus(true);
        break;
      case "stl": // Done
        const stlLoader = new STLLoader();
        const stl = await stlLoader.loadAsync(url);
        loadedModel = stl;
        const mesh_stl = new THREE.Mesh(
          loadedModel,
          new THREE.MeshStandardMaterial()
        );
        mesh_stl.rotateX(-Math.PI / 2);
        setScene(mesh_stl);
        setLoadStatus(true);
        break;
      case "obj": // Done
        const objLoader = new OBJLoader();
        const mtlFile = Array.from(fileMap.values()).find((file) =>
          file.name.endsWith(".mtl")
        );
        let obj = "";
        if (mtlFile) {
          const materialsCreator = await new Promise((resolve) => {
            new MTLLoader().load(URL.createObjectURL(mtlFile), (materials) => {
              resolve(materials);
            });
          });
          await processMaterialsCreatorTextures(materialsCreator);
          objLoader.setMaterials(materialsCreator);
          obj = await objLoader.loadAsync(url);
        } else {
          const textureFile = Array.from(fileMap.values()).find((file) =>
            file.type.startsWith("image/")
          );

          setFileList(new Set(["mtl|OPTIONAL"]));
          obj = await objLoader.loadAsync(url);
          if (textureFile) {
            const texture = new THREE.TextureLoader().load(
              URL.createObjectURL(textureFile)
            );
            obj.traverse((child) => {
              if (child instanceof THREE.Mesh) {
                const material = new THREE.MeshBasicMaterial({ map: texture });
                child.material = material;
              }
            });
          } else {
            setFileList(new Set(["diffuse|OPTIONAL", "mtl|OPTIONAL"]));
          }
        }

        loadedModel = obj;

        setScene(loadedModel);
        setLoadStatus(true);
        break;
      default:
        console.log(
          "Unsupported 3D model format, please use obj, gltf, glb, fbx, dae, ply, stl, or 3ds."
        );
        break;
    }
    /*} catch {
      tip({
        type: "error",
        content: "Render error occurred.",
      });
      setLoadStatus(false);
    }*/
  };
  return { model: scene, status: loadStatus };
};

export default useModelLoader;
