/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RecoilRoot, useRecoilState } from "recoil";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ErrorPage } from "./components/ErrorPage";
import { isMobileAtom, bodyOpacityTransitionAtom } from "./store";
import OAuthHandler from "./components/Provider/oauth";
import PaymentHandler from "./components/Provider/payment";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import ChatAvatar from "./components/ChatAvatar";
import { ImageTo3D } from "./components/ImageTo3D";
import Rodin from "./components/Rodin";
import Panorama from "./components/Panorama";
import { SharePraser } from "./components/SharePraser";

const root = ReactDOM.createRoot(document.getElementById("root"));

function Outter() {
  const [isFixed, setIsFixed] = useState(false);
  const [bodyOpacityTransition, setBodyOpacityTransition] = useRecoilState(bodyOpacityTransitionAtom);
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom);

  useEffect(() => {
    const checkMobile = () => {
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    };
    const handleScroll = () => {
      const maxScrollDistance = 300;
      const scrollPercentage = Math.min(window.scrollY / maxScrollDistance, 1);
      setIsFixed(scrollPercentage);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", checkMobile);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (bodyOpacityTransition) {
      document.body.style.opacity = 0
      setTimeout(() => {
        setBodyOpacityTransition(false)
      }, 500)
    } else {
      document.body.style.opacity = 1
    }
  }, [bodyOpacityTransition])

  return (
    <Router>
      {!isMobile ? (<Header />) : null}
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<ChatAvatar scrollPercentage={isFixed} isMobile={isMobile} />} />
          <Route path='/chatavatar/:taskUUid?' element={<ChatAvatar scrollPercentage={isFixed} isMobile={isMobile} />} />
          <Route path='/imageto3d/:taskUUid?' element={<ImageTo3D scrollPercentage={isFixed} />} />
          <Route path='/result/detail/:taskUUid' element={<SharePraser scrollPercentage={isFixed} isMobile={isMobile} />} />
          <Route path='/rodin' element={<Rodin scrollPercentage={isFixed} isMobile={isMobile} />} />
          <Route path='/panorama' element={<Panorama scrollPercentage={isFixed} isMobile={isMobile} />} />
          <Route path='*' element={<ChatAvatar scrollPercentage={isFixed} isMobile={isMobile} />} />
        </Route>
        <Route path="/login" element={<OAuthHandler />} />
        <Route path="/payment" element={<PaymentHandler />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer isMobile={isMobile} />
    </Router>
  );
}

root.render(
  <RecoilRoot>
    <Outter />
  </RecoilRoot>
);
