
import style from './imgbutton.module.css'
import puperlmsgpic from '../../../assets/puperlmsg.png'
import whitemsgpic from '../../../assets/whitemsg.png'
import picselect from '../../../assets/picselect.png'
import picunselect from '../../../assets/picunselect.png'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { isImgto3dAtom, currentNavAtom, curThemeAtom, modalObjAtom, userPermissionAtom } from '../../../store'
const ImgButton = () => {
  const [isImgto3d, setIsImgto3d] = useRecoilState(isImgto3dAtom)
  const [curTheme, setCurThem] = useRecoilState(curThemeAtom)
  const currentNav = useRecoilValue(currentNavAtom)
  const [currentTopPic, setCurrentTopPic] = useState(whitemsgpic)
  const [currentDownPic, setCurrentDownPic] = useState(picselect)
  const [modalObj, setModalObj] = useRecoilState(modalObjAtom);
  const userPermission = useRecoilValue(userPermissionAtom);

  const navi = useNavigate()
  const location = useLocation()
  const btnRef = useRef()

  useEffect(() => {
    if (location.pathname.includes('/imageto3d')) {
      setIsImgto3d(true)
      setCurrentTopPic(puperlmsgpic)
      setCurrentDownPic(picselect)
      document.documentElement.dataset.theme = 'light'
      setCurThem('light')
    } else if (location.pathname.includes('/panorama')) {
      setIsImgto3d(false)
      setCurrentTopPic(whitemsgpic)
      setCurrentDownPic(picunselect)
      document.documentElement.dataset.theme = 'panorama'
      setCurThem('panorama')
    } else {
      setIsImgto3d(false)
      setCurrentTopPic(whitemsgpic)
      setCurrentDownPic(picunselect)
      document.documentElement.dataset.theme = 'dark'
      setCurThem('dark')
    }
  }, [location])

  const handleToImgto3d = () => {
    if (isImgto3d) return
    setModalObj({
      type: 'imgto3d',
    })
    navi('/imageto3d')
  }
  const handleToChatAvatar = () => {
    if (!isImgto3d) return
    setModalObj({
      type: 'detail',
    })
    navi('/chatavatar')
  }
  return <div
    className={style.btnWrapper}
    ref={btnRef}>
    {userPermission.chatavatar_text_access &&
      <div onClick={handleToChatAvatar} className={`${style.btnTop} ${isImgto3d ? style.btnTopActive : style.btnTopUnActive}`} >
        <div className={style.iconInTop}>
          <img src={currentTopPic} alt="chatavtar" />
        </div>
      </div>
    }
    {userPermission.chatavatar_image_access &&
      <div onClick={handleToImgto3d} className={`${style.btnDown} ${isImgto3d ? style.btnDownActive : style.btnDownUnActive}`}>
        <div className={style.iconInDown}><img src={currentDownPic} alt="img3d" /></div>
      </div>
    }

  </div >

}

export { ImgButton }