/* global _czc */

import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import restartIcon from '../../../assets/icon_restart@2x.png'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { closeWebsocket, disposeWebsocket, reconnectWebsocket, startChat, wsSend } from '../../../utils/net'
import style from './chatBoard.module.css'
import {
	assistantChatStatusAtom,
	chatGuessAtom,
	chatHistoryAtom,
	chatTextAtom,
	guessChatStatusAtom,
	stopChatAtom,
	taskDetailAtom,
	taskInitAtom,
	chatLangAtom,
	needStartWsAtom,
	meshProfileAtom,
	chatImageURLAtom,
	posterGenerateAtom,
	isShowModalAtom,
	setGenerateAtom,
	lastGenerateUUIDAtom,
	promptAtom,
	showProgressAtom, logInfoAtom, modalObjAtom
} from '../../../store'
import { exportToImage } from '../utils'
import { HiRefresh } from 'react-icons/hi'
import * as htmlToImage from 'html-to-image'
import { useTips } from '../../GlobalTips'
import { XScroll } from '../../widgets/XScroll'

function ChatBoard() {
	const [modalObj, setModalObj] = useRecoilState(modalObjAtom);
	const [chatHistory, setChatHistory] = useRecoilState(chatHistoryAtom)
	const [chatGuess, setChatGuess] = useRecoilState(chatGuessAtom)
	const prompt = useRecoilValue(promptAtom)
	const [showGuess, setShowGuess] = useState(true)
	const [taskInit, setTaskInit] = useRecoilState(taskInitAtom)
	const setTaskDetail = useSetRecoilState(taskDetailAtom)
	const setMeshProfile = useSetRecoilState(meshProfileAtom)
	const [stopChat, setStopChat] = useRecoilState(stopChatAtom)
	const [chatText, setChatText] = useRecoilState(chatTextAtom)
	const [showScrollBar, setShowScrollBar] = useState(false)
	const taskDetail = useRecoilValue(taskDetailAtom)
	const guessChatStatus = useRecoilValue(guessChatStatusAtom)
	const [assistantChatStatus, setAssistantChatStatus] = useRecoilState(assistantChatStatusAtom)
	const [chatLang, setChatLang] = useRecoilState(chatLangAtom)
	const [chatImageURL, setChatImageURL] = useRecoilState(chatImageURLAtom)
	const setNeedStartWs = useSetRecoilState(needStartWsAtom)
	const [posterGenerate, setPosterGenerate] = useRecoilState(posterGenerateAtom)
	const [isShowModal, setIsShowModal] = useRecoilState(isShowModalAtom)
	const chatMessagesRef = useRef()
	const [generate, setGenerate] = useRecoilState(setGenerateAtom)
	const [lastGenerateUUID, setLastGenerateUUID] = useRecoilState(lastGenerateUUIDAtom)
	const [logInfo, setLogInfo] = useRecoilState(logInfoAtom)
	const [showProgress, setShowProgress] = useRecoilState(showProgressAtom)
	const textAreaRef = useRef(null)
	const setTimeOutIdRef = useRef(null)
	const [avatarURL, setAvatarURL] = useState("../../assets/defaultAvatar.png")
	const tip = useTips()
	const chatRef = useRef()

	const handleIpt = (ev) => {
		setChatText(ev.currentTarget.value)
	}

	const handleSend = (ev) => {
		if (!chatText || assistantChatStatus !== '[END]') return
		_czc.push(['_trackEvent', 'Card', 'Send', 'Generate Card']);
		wsSend({
			task_uuid: taskInit.task_uuid,
			content: chatText,
			language: chatLang,
			summary: prompt,
		})

		setChatHistory({
			...chatHistory,
			[Date.now()]: {
				chat_uuid: Date.now(),
				provider: 'user',
				content: chatText,
				timeStamp: Date.now(),
			},
		})

		setChatText('')
		setShowGuess(false)
		setAssistantChatStatus(false)
	}

	const handleSelectTip = (value) => (ev) => {
		setChatText(value.substring(3))
	}

	useEffect(() => {
		// if (stopChat !== 2) return
		if (guessChatStatus === '[START]') setShowGuess(true)
	}, [guessChatStatus])

	const handleChangeLang = (lang) => {
		_czc.push(['_trackEvent', 'Card', 'Language Change', 'Generate Card']);
		setChatLang(lang)
		handleRestart()
	}

	const handleRestart = async (ev) => {
		_czc.push(['_trackEvent', 'Card', 'Restart', 'Generate Card']);
		closeWebsocket()
		disposeWebsocket()
		const response = await startChat()
		setNeedStartWs(true)
		setStopChat(false)
		setTaskInit(response.data)
		setShowProgress(false)
		setTaskDetail(false)
		setLastGenerateUUID(false)
		setPosterGenerate(false)

		setModalObj({
			...modalObj,
			type: 'generate',
		})
		setIsShowModal(true)
		// window.history.pushState(null, '', '/')
	}

	const handleRegenerate = () => {
		_czc.push(['_trackEvent', 'Card', 'Regenerate', 'Generate Card']);
		setTaskDetail(false)
		setStopChat(false)
		setLastGenerateUUID(false)
		reconnectWebsocket()
		setPosterGenerate(false)

		setModalObj({
			...modalObj,
			type: 'generate',
		})
		setIsShowModal(true)
		// window.history.pushState(null, '', '/')
	}

	window.exportChat = async () => {
		await exportToImage(chatRef.current, 'chat')
	}

	const allAssistantMessages = Object.values(chatHistory).every(
		(chat) => chat.provider === 'assistant'
	)

	const downloadChatAsImage = () => {
		const originalOverflow = chatRef.current.style.overflow
		chatRef.current.style.overflow = 'hidden'

		htmlToImage
			.toPng(chatRef.current)
			.then((dataUrl) => {
				const link = document.createElement('a')
				link.download = 'chat.png'
				link.href = dataUrl
				link.click()

				chatRef.current.style.overflow = originalOverflow
			})
			.catch((e) => {
				tip({
					type: 'error',
					content: 'Something went wrong: ' + e.message,
				})
				chatRef.current.style.overflow = originalOverflow
			})
	}

	const cropImage = async (blob, targetWidth, targetHeight) => {
		const img = await createImageBitmap(blob);
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");
		const scale = targetWidth / img.width;
		const scaledHeight = img.height * scale;
		const scaledWidth = img.width * scale;
		canvas.width = targetWidth;
		canvas.height = targetHeight;
		ctx.drawImage(
			img,
			0,
			0,
			img.width,
			img.height,
			0,
			0,
			scaledWidth,
			scaledHeight
		);

		return new Promise((resolve) => {
			canvas.toBlob((newBlob) => resolve(newBlob));
		});
	};

	const setChatImage = async (originalAvatarURL) => {
		const targetWidth = '460px';
		const targetHeight = '520px';
		chatRef.current.style.transition = "all 500ms"
		chatRef.current.style.opacity = 0;
		const originalWidth = chatRef.current.style.width;
		const originalHeight = chatRef.current.style.height;
		chatRef.current.style.width = targetWidth;
		chatRef.current.style.minHeight = targetHeight;
		chatRef.current.style.maxHeight = targetHeight;
		chatRef.current.style.height = targetHeight;
		chatRef.current.style.maxWidth = targetWidth;
		chatRef.current.style.minWidth = targetWidth;
		chatRef.current.style.scrollbarWidth = '0px';
		chatRef.current.style.overflowY = "hidden";
		chatMessagesRef.current.style.overflowY = "hidden";

		htmlToImage
			.toBlob(chatRef.current, { width: 460 })
			.then((blob) => {
				cropImage(blob, 460, 520)
				chatRef.current.style.width = originalWidth;
				chatRef.current.style.maxWidth = originalWidth;
				chatRef.current.style.minWidth = originalWidth;

				chatRef.current.style.height = originalHeight;
				chatRef.current.style.minHeight = originalHeight;
				chatRef.current.style.maxHeight = originalHeight;

				chatRef.current.style.opacity = 100;
				chatRef.current.style.overflowY = "auto";
				chatMessagesRef.current.style.overflowY = "auto";
				setAvatarURL(originalAvatarURL)
				setChatImageURL(URL.createObjectURL(blob));
			})
			.catch((error) => {
				console.error('Error occurred while save chat as image:', error);
				chatRef.current.style.width = originalWidth;
				chatRef.current.style.maxWidth = originalWidth;
				chatRef.current.style.minWidth = originalWidth;

				chatRef.current.style.height = originalHeight;
				chatRef.current.style.minHeight = originalHeight;
				chatRef.current.style.maxHeight = originalHeight;

				chatRef.current.style.opacity = 100;
				chatRef.current.style.overflowY = "auto";
				chatMessagesRef.current.style.overflowY = "auto";

				setAvatarURL(originalAvatarURL)
				tip({
					type: 'error',
					content: 'Something went wrong: ' + error.message,
				})
			});
	};


	useEffect(() => {
		if (posterGenerate === true) {
			const originalAvatarURL = avatarURL
			setAvatarURL("../../assets/defaultAvatar.png")
			setTimeout(() => {
				if (isShowModal === true) {
					setChatImage(originalAvatarURL)
				} else if (generate === true) {
					setChatImage(originalAvatarURL)
				}
			}, 500)
		}
	}, [posterGenerate, generate])

	useEffect(() => {
		setAvatarURL(taskDetail?.author?.avatar_url ? taskDetail.author.avatar_url : logInfo.avatar_url)

	}, [isShowModal])

	useEffect(() => {
		if (chatMessagesRef.current) {
			chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight
		}
	}, [chatHistory, chatGuess])

	const handlerWheel = (e) => {
		clearTimeout(setTimeOutIdRef.current)
		setShowScrollBar(true)
		setTimeOutIdRef.current = setTimeout(() => {
			setShowScrollBar(false)
		}, 1500);
	};

	useEffect(() => {
		return () => {
			clearTimeout(setTimeOutIdRef.current)
		}
	}, [])
	return (
		<div className={style.chatBox}>
			<div className={style.chatTitleBox}>
				<div style={{
					padding: '3px 0'
				}} onClick={downloadChatAsImage}> Chat</div>
				{taskInit ? (
					<div className={style.restart} onPointerDown={handleRestart}>
						{/* <HiRefresh size='1em' /> */}
						<img className={style.restartIcon} src={restartIcon} alt="restart" />
						Restart
					</div>
				) : null}

				<div className={style.spaceholder}></div>

				{taskDetail || stopChat ? null : (
					<>
						<div
							onPointerDown={() => handleChangeLang('Chinese')}
							className={`${style.lang} ${chatLang === 'Chinese' ? style.selected : ''
								}`}>
							中文
						</div>
						<div>|</div>
						<div
							onPointerDown={() => handleChangeLang('English')}
							className={`${style.lang} ${style.en} ${chatLang === 'English' ? style.selected : ''
								}`}>
							English
						</div>
					</>
				)}

				{(taskDetail && taskInit) ? (
					<div
						className={style.regene}
						onPointerDown={(ev) =>
							tip({
								type: 'primary',
								content: 'Your current model will be replaced. Sure?',
								yes: handleRegenerate,
								no: (f) => f,
							})
						}>
						Regenerate
					</div>
				) : null}
			</div>

			<div className={style.chatCon} ref={chatRef}>
				<div className={`${style.chatMsgCon} ${style.noScrollbar}`} ref={chatMessagesRef}>
					{(!allAssistantMessages || !stopChat) &&
						Object.values(chatHistory)
							.sort((a, b) => a.timeStamp - b.timeStamp)
							.filter((chat, idx, arr) => {
								return (
									!(chat.provider === 'assistant' && idx === arr.length - 1) ||
									!stopChat
								)
							})
							.map((chat) => (
								<div
									key={chat.chat_uuid}
									className={`${style.chatMsgRow} ${chat.provider === 'user' ? style.user : ''
										}`}>
									{chat.provider === 'user' ? (
										<div className={style.avatar}>
											<img
												src={avatarURL}
												//src='../../assets/defaultAvatar.png'
												alt='avatar'
												onError={(e) => {
													e.target.src = '../../assets/defaultAvatar.png';
												}}
												style={{
													backgroundSize: 'cover',
													backgroundPosition: 'center',
													backgroundColor: 'transparent',
													backgroundImage: `url('../../assets/defaultAvatar.png')`,
												}}
											/>
										</div>

									) : (
										<div className={style.avatarAssistant}>
											<img
												src='../../assets/avatar.png'
												alt='avatar'
												style={{
													backgroundSize: 'cover',
													backgroundPosition: 'center',
													backgroundColor: 'transparent',
													backgroundImage: `url('../../assets/avatar.png')`,
												}}
											/>
										</div>
									)}

									<div
										className={`${style.bubble} ${stopChat ? style.unactive : ''
											}`}>
										{chat.content}
									</div>
								</div>
							))}
					{stopChat && (Object.keys(chatHistory).length <= 1 || allAssistantMessages) && (
						<div className={`${style.assistant} ${style.notificationText}`}>
							<div className={`${style.notificationBubble} ${style.unactive}`}>
								Seems like we don't have any conversations here
							</div>
						</div>
					)}

					{stopChat &&
						!(Object.keys(chatHistory).length <= 1 || allAssistantMessages) && (
							<div className={`${style.assistant}`}>
								<div className={`${style.notificationBubble} ${style.unactive}`}>
									End of the conversation
								</div>
							</div>
						)}

					{!assistantChatStatus && !stopChat && (
						<div className={`${style.chatMsgRow} ${style.assistant}`}>
							<div className={style.avatarAssistant}>
								{
									<img
										src='../../assets/avatar.png'
										alt='avatar'
									/>
								}
							</div>
							<div className={`${style.bubble}`}>
								<span className={style.dot} style={{ animationDelay: '0s' }}>
									.
								</span>
								<span className={style.dot} style={{ animationDelay: '0.33s' }}>
									.
								</span>
								<span className={style.dot} style={{ animationDelay: '0.66s' }}>
									.
								</span>
							</div>
						</div>
					)}
				</div>

				{!stopChat ? (
					<div className={style.chatIptCon} onWheel={handlerWheel}>
						<XScroll
							customStyle={{
								width: '100%',
								height: '80px',
							}}
							scrollBar={showScrollBar}
						>
							<div className={style.chatTipsCon}>
								{chatGuess
									.filter((c) => c)
									.map((guess) => {
										const substringGuess = guess.substring(3)
										if (substringGuess && showGuess) {
											return (
												<div
													className={style.chatTips}
													key={guess}
													onClick={handleSelectTip(guess)}
													onPointerDown={(ev) => {
														_czc.push(['_trackEvent', 'Card', 'Choose Hint', 'Generate Card']);
													}}>
													{substringGuess}
												</div>
											)
										}
										return null
									})}
							</div></XScroll>

						<div className={style.chatRowCon}>
							<div className={style.iptLineCon}>
								<div className={style.iptSpaceholder}>{chatText || 'X'}</div>
								<textarea
									className={style.ipt}
									ref={textAreaRef}
									onChange={(e) => {
										setChatText(e.currentTarget.value);
									}}
									value={chatText}
									placeholder='Describe the face'
									onKeyDown={(e) => {
										handleIpt(e);
										if (e.key === "Enter") {
											e.preventDefault();
											if (e.shiftKey) {
												setChatText((prevChatText) => prevChatText + "\n");
											} else {
												handleSend();
											}
										}
									}}
								/>
							</div>

							<div
								className={`${style.btn} ${!chatText || assistantChatStatus !== '[END]'
									? style.disabled
									: ''
									}`}
								onPointerDown={handleSend}>
								Send
							</div>
						</div>
					</div>
				) : null}
			</div>
		</div >
	)
}

export { ChatBoard }
