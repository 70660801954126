/* global _czc */
import React, { useEffect, useState } from 'react'
import style from './historyPopup.module.css'
import Pagination from '../../widgets/Pagination'
import { useRecoilState, useRecoilValue } from "recoil"
import { getTaskDetail } from "../../../utils/net"
import { useTips } from '../../GlobalTips'
import { useNavigate, Link } from 'react-router-dom'
import { showHistoryAtom, currentPageCounter, currentPageAtom, filterListAtom, totalPageAtom, totalHistoryAtom, inHistoryAtom, outHistoryAtom, filterTypeAtom } from '../../../store'

const filterArr = ['All', 'In', 'Out']

const Popup = ({ title, children }) => {
  const navi = useNavigate()
  const [showHistory, setShowHistory] = useRecoilState(showHistoryAtom)
  const [currentPage, setCurrentPage] = useRecoilState(currentPageAtom)
  const [showFilter, setShowFilter] = useState(false)
  const [currentFilterIndex, setCurrentFilterIndex] = useState(0)
  const [totalPages, setTotalPages] = useRecoilState(totalPageAtom)
  const currentPageList = useRecoilValue(currentPageCounter)
  const [filterType, setFilterType] = useRecoilState(filterTypeAtom)
  const totalHistoryList = useRecoilValue(totalHistoryAtom);
  const inHistoryList = useRecoilValue(inHistoryAtom);
  const outHistoryList = useRecoilValue(outHistoryAtom);
  const [filterList, setFilterList] = useRecoilState(filterListAtom)

  const tip = useTips()
  useEffect(() => {
    document.documentElement.style.overflowY = showHistory ? 'hidden' : 'overlay'
  }, [showHistory])

  useEffect(() => {
    setTotalPages(Math.ceil(filterList.length / 12))
    setCurrentPage(1)
  }, [filterType])

  const handleClose = () => {
    setShowHistory(false)
  }
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const checkfilterType = (index) => {
    setShowFilter(false)
    setFilterType(filterArr[index])
    setCurrentFilterIndex(index)
    index === 0 && setFilterList(totalHistoryList)
    index === 1 && setFilterList(inHistoryList)
    index === 2 && setFilterList(outHistoryList)
  }
  return (
    <div className={`${showHistory ? style.historyshow : style.historyhide}`}>
      <div className={style.overlay} onClick={handleClose}></div>
      <div className={style.popupContainer}>
        <div className={style.popup}>
          <div className={style.popupHeader}>
            <h2>{title}</h2>
            <span onPointerDown={() => { setShowFilter(true) }} className={style.filter}>{filterArr[currentFilterIndex]}</span>
            <div className={`${showFilter ? style.show : ''} ${style.filterBox}`}>{
              filterArr.map((item, index) => {
                return <div onPointerDown={() => { checkfilterType(index) }} className={style.filterItem} key={index}>{item}</div>
              })}</div>
          </div>
          <div className={style.boder}></div>
          <div className={style.popupBody}>
            <div className={style.popupTags}>
              {['Date', 'Token', 'Source'].map((item, index) => <div key={index}>{item}</div>)}
            </div>
            {currentPageList.map((item, index) => {
              return <div className={style.popupLine} key={item.date}>
                <div>{item.date.split('T')[0] + ' ' + item.date.split('T')[1].slice(0, 5)}</div>
                <div>{item.amount}</div>
                {item.target
                  ?
                  !['license', 'private'].includes(item.target) ?
                    <Link
                      to={`/chatavatar/${item.detail}`}
                      target='_blank'
                      className={style.historyTaget}>{item.target}</Link>
                    : <div className={style.historySource}>{item.target}</div>
                  :
                  <div className={style.historySource}>{item.source}</div>}
              </div>
            })}
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        ></Pagination>
      </div>
    </div>

  )
}

export default Popup