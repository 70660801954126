/* global _czc */

import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import style from "./rodinGallery.module.css";
import { search, searchModels } from "../../../utils/net";
import { useNavigate } from "react-router-dom";
import {
  rodinSearchKeyWordAtom,
  urlListAtom,
  hashAtom,
  cardLoadingAtom,
  currentDBAtom,
  imageListAtom,
  rodinCardsTypeAtom,
} from "../../../store";
import { useTips } from "../../GlobalTips";
import { FaDownload, FaShareAlt } from "react-icons/fa";
import { BsShareFill } from "react-icons/bs";
import { Menu } from '../../widgets/Menu'

function RodinGallery() {
  const [cards, setCards] = useState([]);
  const [canMore, setCanMore] = useState(true);
  const searchKeyWord = useRecoilValue(rodinSearchKeyWordAtom);
  //const searchKeyWord = useState("trump");
  const pageRef = useRef(0);
  const timeStampRef = useRef(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggestionCards, setSuggestionCards] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const skeletonCardWidth = 390;
  const numberOfSkeletonCards = Math.min(
    4,
    Math.floor(windowWidth / skeletonCardWidth)
  );
  const [loadingMore, setLoadingMore] = useState(false);
  const setGlobalTips = useTips();
  const checkedTask = useRef([]);
  const tip = useTips();
  const cardsConRef = useRef(null);
  const [iframeLoaded, setIframeLoaded] = useState([]);
  const [rodinCardsType, setRodinCardsType] = useRecoilState(rodinCardsTypeAtom);
  const [urlList, setUrlList] = useRecoilState(urlListAtom);
  const [hashTag, setHashTag] = useRecoilState(hashAtom);
  const [cardLoading, setCardLoading] = useRecoilState(cardLoadingAtom);
  const [currentDB, setCurrentDB] = useRecoilState(currentDBAtom);
  const [isMobile, setIsMobile] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [imageList, setImageList] = useRecoilState(imageListAtom);
  const getDisplayPages = () => {
    let start = currentPage - 4;
    let end = currentPage + 5;
    if (start < 0) {
      end = end - start;
      start = 0;
    }
    if (end > 125) {
      start = start - (end - 125);
      end = 125;
    }
    return Array.from({ length: end - start }, (_, i) => start + i);
  };

  const displayPages = getDisplayPages();

  useEffect(() => {
    const checkMobile = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  useEffect(() => {
    if (cardLoading === true) {
      setCards([]);
      setLoading(true);
      setIsAnimating(true);
      setIframeLoaded([]);
    } else {
      setLoading(false);
      setIsAnimating(false);
    }
  }, [cardLoading]);

  const changeDB = async () => {
    setCards([]);
    setIframeLoaded([]);
    setLoadingMore(false);
    setLoading(true);
    const params = {
      database_name: currentDB,
      hash: hashTag,
      page_num: 0,
    };
    const response = await searchModels({
      ...params,
    });
    setUrlList(response.data.url);

    setHashTag(response.data.hash);
  };

  useEffect(() => {
    _czc.push(["_trackEvent", "Rodin - Gallery", currentDB + " Show Result", "Gallery Component"]);
    if (hashTag) {

      changeDB();
    }
  }, [currentDB]);

  useEffect(() => {
    setCurrentPage(0);
    pageRef.current = 0;
    if (rodinCardsType === "Sketchfab") {
      setCurrentDB("sketchfab");
    } else if (rodinCardsType === "Free3D") {
      setCurrentDB("free3d");
    } else if (rodinCardsType === "BlendSwap") {
      setCurrentDB("blendswap");
    }
  }, [rodinCardsType]);

  const loadMore = async () => {
    _czc.push(["_trackEvent", "Rodin - Gallery", "Load More", "Gallery Component"]);
    _czc.push(["_trackPageview", "/rodin", window.location.href]);
    if (cards.length === 0) {
      setCanMore(false);
      return;
    }
    if (!loadingMore) {
      setLoadingMore(true);
      let rep;

      //rep = await search({
      //  keyword: searchKeyWord,
      //  page_num: pageRef.current + 1,
      //});

      const params = {
        database_name: currentDB,
        hash: hashTag,
        page_num: pageRef.current + 1,
      };
      //console.log(params);
      rep = await searchModels(params);
      pageRef.current += 1;
      setTimeout(() => {
        if (rep.data.url.length === 0) {
          setCanMore(false);
        } else {
          setCanMore(true);
        }

        const updatedCards = rep.data.url;
        setCards([...cards, ...updatedCards]);
        setLoadingMore(false);
      }, 100);
    }
  };

  const setPage = async () => {
    _czc.push(["_trackEvent", "Rodin - Gallery", "Page Change", "Gallery Component"]);
    _czc.push(["_trackPageview", "/rodin", window.location.href]);
    let rep;
    pageRef.current = currentPage;
    setIframeLoaded([]);
    const params = {
      database_name: currentDB,
      hash: hashTag ? hashTag : "E",
      page_num: currentPage,
    };
    rep = await searchModels(params);
    setTimeout(() => {
      if (rep.data.url.length === 0) {
        setCanMore(false);
      } else {
        setCanMore(true);
      }
      const updatedCards = rep.data.url.map((url) => url.split("/").pop());
      setCards([...updatedCards]);
    }, 100);
  };

  useEffect(() => {
    if (rodinCardsType === "Sketchfab") {
      setPage();
    }
  }, [currentPage]);

  const fetchData = async () => {
    //const data = await search({
    //  keyword: searchKeyWord,
    //  page_num: pageRef.current,
    //});
    //console.log("urllist", urlList);
    let updatedCards = {};
    if (rodinCardsType === "Sketchfab") {
      updatedCards = urlList.map((url) => url.split("/").pop());
    } else {
      updatedCards = urlList;
    }

    setCards([...updatedCards]);
    if (updatedCards.length > 0) {
      setLoading(false);
    }
    setIsAnimating(false);
  };

  useEffect(() => {

    setCurrentPage(0);
    setCards([]);
    setLoading(true);
    pageRef.current = 0;
    timeStampRef.current = 0;
    setIsAnimating(true);
    fetchData();
  }, [urlList]);

  // Utility functions
  const createTooltip = (e, id, text, posiRight) => {
    const tooltip = document.createElement("div");
    tooltip.setAttribute("id", id);
    tooltip.style.position = "absolute";
    tooltip.style.right = posiRight;
    tooltip.style.top = "25px";
    tooltip.style.backgroundColor = "rgb(0, 0, 0, 0.6)";
    tooltip.style.color = "white";
    tooltip.style.padding = "5px";
    tooltip.style.borderRadius = "5px";
    tooltip.style.fontSize = "12px";
    tooltip.textContent = text;
    e.currentTarget.appendChild(tooltip);
  };

  const removeTooltip = (id) => {
    const tooltip = document.getElementById(id);
    if (tooltip) {
      tooltip.parentNode.removeChild(tooltip);
    }
  };

  const updateTooltip = (id, newText) => {
    const tooltip = document.getElementById(id);
    if (tooltip) {
      tooltip.textContent = newText;
    }
  };

  return (
    <div className={style.con}>
      {!isMobile && <Menu menuType={'Rodin'}></Menu>}
      <div className={style.cardsCon} ref={cardsConRef}>
        {loading &&
          Array.from({ length: numberOfSkeletonCards }).map((_, index) => (
            <div key={index} className={style.skeleton}></div>
          ))}

        {cards.length === 0 && !loading && (
          <div className={style.emptyContainer}>
            <div className={style.emptyList}>
              We currently do not have any results in this tab.
            </div>
          </div>
        )}

        {cards.length > 0
          ? cards.map((card, index) => (
            <div
              className={`${style.card}`}
              style={{ opacity: isAnimating ? 0 : 1 }}
            >
              {!iframeLoaded[index] && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(240, 240, 240, 0.9)",
                    position: "absolute",
                    backdropFilter: "blur(5px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    borderRadius: "0.5rem",
                    zIndex: "10"
                  }}
                >
                  <React.Fragment>
                    <svg
                      width="150"
                      height="150"
                      viewBox="0 0 49 49"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.5 0C38.031573 0 49 10.957452 49 24.471613c0 13.517276-10.968427 24.473393-24.5 24.473393S0 37.98889 0 24.471613C0 10.957453 10.968427 0 24.5 0zm11.959118 16.936752L24.5 9.619472l-11.958673 7.31728L24.5 24.253585l11.959118-7.316833zM12.027273 20.28993v12.179297l10.69091 6.66275V26.952235l-10.69091-6.662305zm13.80909 18.842047l11.136364-6.66275V20.28993l-11.136363 6.662305v12.179742z"
                        fillRule="evenodd"
                        fillOpacity=".03"
                      />
                    </svg>
                    <div
                      style={{
                        fontSize: "20px",
                        fontFamily: "Arial-Regular, Arial",
                        color: "rgba(210, 210, 210, 0.9)",
                        marginTop: "15px",
                      }}
                    >
                      Loading
                    </div>
                  </React.Fragment>
                </div>
              )}
              {currentDB === "sketchfab" && (
                <div>
                  <iframe
                    onLoad={() => {
                      setIframeLoaded((prevIframeLoaded) => {
                        const tempIframeLoaded = [...prevIframeLoaded];
                        tempIframeLoaded[index] = true;
                        return tempIframeLoaded;
                      });
                    }}
                    title="Rodin"
                    frameBorder="0"
                    width="390px"
                    height="290px"
                    allow="autoplay; xr-spatial-tracking"
                    style={{ borderRadius: "0.5rem", overflow: "hidden" }}
                    src={"https://sketchfab.com/models/" + card + "/embed"}
                  ></iframe>
                </div>
              )}
              {(currentDB === "free3d" || currentDB === "blendswap") && (
                <div className={`${style.imageCard}`}>
                  {iframeLoaded[index] && (
                    <div
                      style={{
                        position: "absolute",
                        right: "1.3rem",
                        top: "1rem",
                        zIndex: "10"
                      }}
                    >
                      <div
                        onClick={() => window.open(card.url, "_blank")}
                        style={{
                          color: "#fff",
                          fontSize: "13px",
                          display: "inline-block",
                          marginRight: "24px",

                        }}
                        onMouseEnter={(e) =>
                          createTooltip(e, "download-tooltip", "Download", "-25px")
                        }
                        onMouseLeave={(e) =>
                          removeTooltip("download-tooltip")
                        }
                      >
                        <FaDownload
                          style={{
                            filter: "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5))",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          color: "#fff",
                          fontSize: "13px",
                          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
                          display: "inline-block",
                        }}
                        onClick={(e) => {
                          navigator.clipboard.writeText(card.url);
                          updateTooltip("share-tooltip", "Copied");
                        }}
                        onMouseEnter={(e) =>
                          createTooltip(e, "share-tooltip", "Share", "-14px")
                        }
                        onMouseLeave={(e) => removeTooltip("share-tooltip")}
                      >
                        <BsShareFill
                          style={{
                            filter: "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5))",
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <img
                    onLoad={() => {
                      setIframeLoaded((prevIframeLoaded) => {
                        const tempIframeLoaded = [...prevIframeLoaded];
                        tempIframeLoaded[index] = true;
                        return tempIframeLoaded;
                      });
                    }}
                    onError={() => {
                      console.log("Image Load Failed");
                    }}
                    width="390px"
                    height="290px"
                    style={{ borderRadius: "0.5rem", overflow: "hidden", background: "rgb(37, 37, 37)", border: "hidden", objectFit: "cover" }}
                    src={`${card.image}`}
                    onClick={() => window.open(card.url, "_blank")}
                  />
                </div>
              )}
            </div>
          ))
          : suggestionCards.map((card) => <div></div>)}

        {canMore ? (
          loadingMore ? (
            <div className={style.more}>Loading...</div>
          ) : rodinCardsType === "Sketchfab" ? (
            <div className={style.selector}>
              {currentPage > 0 && (
                <div
                  className={style.pageNext}
                  onPointerDown={() => setCurrentPage(pageRef.current - 1)}
                >
                  Prev
                </div>
              )}
              {displayPages.map((pageIndex) => (
                <div
                  key={pageIndex}
                  onClick={() => setCurrentPage(pageIndex)}
                  style={{
                    background:
                      pageIndex === currentPage ? "rgba(72, 0, 220, 1)" : "",
                  }}
                  className={style.pageNumber}
                >
                  {pageIndex + 1}
                </div>
              ))}
              {currentPage < 124 && (
                <div
                  className={style.pageNext}
                  onPointerDown={() => setCurrentPage(pageRef.current + 1)}
                >
                  Next
                </div>
              )}
            </div>
          ) : (
            <div className={style.more} onPointerDown={loadMore}>
              More
            </div>
          )
        ) : (
          canMore === false && <div className={style.more}>That's all</div>
        )}
      </div>
    </div>
  );
}

export { RodinGallery };
