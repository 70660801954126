/* global _czc */

import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import style from './chatAvatarGallery.module.css'
import { getCards, search, getGenerateProgress } from '../../../utils/net'
import { logInfoAtom, modalObjAtom, showSearchAtom, isShowModalAtom, curThemeAtom, cardsAtom, cardsTypeAtom, cardsTypeConst, searchKeyWordAtom, isImgto3dAtom, cardsProgressStateAtom } from '../../../store'
import { useTips } from '../../GlobalTips'
import { Card } from '../../widgets/Card'
import { Menu } from '../../widgets/Menu'
import { useDebounce } from '../../../utils/hooks'

function Gallery() {
	const logInfo = useRecoilValue(logInfoAtom)
	const [cardsType, setCardsType] = useRecoilState(cardsTypeAtom)
	const [cards, setCards] = useRecoilState(cardsAtom)
	const [canMore, setCanMore] = useState(true)
	const searchKeyWord = useRecoilValue(searchKeyWordAtom)
	const [showSearch, setShowSearch] = useRecoilState(showSearchAtom)
	const pageRef = useRef(0)
	const timeStampRef = useRef(0)
	const [isAnimating, setIsAnimating] = useState(false)
	const [loading, setLoading] = useState(false)
	const [suggestionCards, setSuggestionCards] = useState([])
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const skeletonCardWidth = 250
	const numberOfSkeletonCards = Math.min(8, Math.floor(windowWidth / skeletonCardWidth))
	const [isShowModal, setIsShowModal] = useRecoilState(isShowModalAtom)
	const [loadingMore, setLoadingMore] = useState(false)
	const [filterOption, setFilterOption] = useState('All')
	const setGlobalTips = useTips()
	const checkedTask = useRef([])
	const tip = useTips()
	const cardsProgress = useRef([]);
	const [cardsProgressState, setCardsProgressState] = useRecoilState(cardsProgressStateAtom);
	const [filteredCards, setFilteredCards] = useState([]);
	const cardsConRef = useRef(null);
	const [loadedImages, setLoadedImages] = useState(0);
	let updateCardStatusInMineInterval = useRef();
	const [isImgto3d, setIsImgto3d] = useRecoilState(isImgto3dAtom)
	const optionsArr = ['All', 'Generated', 'Liked', 'Packing', 'Packed']
	const [currentOptionIndex, setCurrentOptionIndex] = useState(0)
	const [showOptions, setShowOptions] = useState(false)
	const [curTheme, setCurThem] = useRecoilState(curThemeAtom)


	const filterCards = (cards) => {

		let filteredCards = cards;

		if (isImgto3d) {
			filteredCards = filteredCards.filter((card) => card.prompt === "");
			if (cardsType === cardsTypeConst.Search) {
				setCardsType(cardsTypeConst.Featured)
			}
		} else {
			filteredCards = filteredCards.filter((card) => card.prompt !== "");
		}

		switch (filterOption) {
			case 'All':
				return filteredCards;
			case 'Liked':
				return filteredCards.filter((card) => card.is_like);
			case 'Packing':
				return filteredCards.filter((card) => card.user_state === 'Generating');
			case 'Packed':
				return filteredCards.filter((card) => (card.user_state === 'DoneHigh' || card.user_state === 'DoneBasic' || card.user_state === 'Generating'));
			case 'Generated':
				return filteredCards.filter((card) => card.user_state === 'DonePreview');
			default:
				return filteredCards;
		}
	}

	const loadMore = async () => {
		if (!loadingMore) {
			setLoadingMore(true)
			_czc.push(['_trackEvent', 'Index', 'Load More', 'Gallery Component']);
			let rep
			if (cardsType === cardsTypeConst.Mine) {
				rep = await getCards({ type: cardsType, page_num: pageRef.current, task_type: (isImgto3d ? "ImagineFace" : "DreamFace") })
				setCards(rep.data)
				setLoadingMore(false)
				return
			} else if (cardsType === cardsTypeConst.Search) {
				rep = await search({ keyword: searchKeyWord, page_num: pageRef.current + 1 })
			} else {
				rep = await getCards({ type: cardsType, page_num: pageRef.current + 1, task_type: (isImgto3d ? "ImagineFace" : "DreamFace") })
				//TODO: 预加载图片
			}
			pageRef.current += 1
			setTimeout(() => {
				//console.log("Length", rep.data.length)
				if (rep.data.length === 0) {
					setCanMore(false)
					if (cardsType === cardsTypeConst.Featured) {
						setCardsType(cardsTypeConst.Recent)
						setCards([])
					}
				} else {
					setCanMore(true)
					_czc.push(["_trackPageview", "/page/" + pageRef.current], window.location.href);
				}
				setCards([...cards, ...rep.data])
				setLoadingMore(false)
			}, 100)
		}
	}

	const checkGenerateProgress = async (task_uuid) => {
		const intervalId = setInterval(async () => {
			//console.log(cardsType);
			const response = await getGenerateProgress(task_uuid);
			//更新最新的card信息
			const updatedProgress = [...cardsProgress.current];
			const index = updatedProgress.findIndex((item) => item.task_uuid === task_uuid);
			if (index !== -1) {
				const updatedObject = { ...updatedProgress[index], progress: response };
				updatedProgress[index] = updatedObject;
			} else {
				updatedProgress.push({ task_uuid, progress: response });
			}
			cardsProgress.current = updatedProgress;
			//console.log(cardsProgress)

			if (response.data.stage === "Done") {
				//console.log(task_uuid, "Finished");
				setGlobalTips({
					type: "success",
					content: 'Packing Done. Please check in "Mine" tab.',
				});

				const updatedCards = cards.map((card) => {
					if (card.task_uuid === task_uuid) {
						return { ...card, user_state: "DoneHigh" };
					}
					return card;
				});
				setCards(updatedCards);
				clearInterval(intervalId);
			} else {
				if (cardsType !== cardsTypeConst.Mine) {
					clearInterval(intervalId);
				}
				//console.log(response);
			}
		}, 5000);
	};

	const fetchData = async () => {
		if (cardsType === cardsTypeConst.Search) {
			isImgto3d ? setShowSearch(false) : setShowSearch(true)
			const data = await search({ keyword: searchKeyWord, page_num: pageRef.current })
			setCards(data.data)
			setLoading(false)
		} else {
			const data = await getCards({ type: cardsType, page_num: pageRef.current, task_type: (isImgto3d ? "ImagineFace" : "DreamFace") })
			setCards(data.data)
			if (cardsType === cardsTypeConst.Mine) {
				let maxTask = 10;
				let assignedTask = 0;
				let taskUUID = [];
				data.data.forEach((card) => {
					if (card.user_state === 'Generating' || card.user_state === 'Waiting') {
						if (!checkedTask.current.includes(card.task_uuid)) {
							assignedTask = assignedTask + 1;
							if (assignedTask > maxTask) {
								tip({
									type: 'error',
									content: 'Too many tasks. Refresh manually for updates.',
								})
								taskUUID = [];
							} else {
								taskUUID.push(card.task_uuid);
								checkedTask.current = [...checkedTask.current, card.task_uuid]
							}
						}
					}
				});

				for (let i = 0; i < taskUUID.length; i++) {
					checkGenerateProgress(taskUUID[i]);
				}
			}

			if (data.data.length === 0) {
				const suggestionData = await getCards({
					type: 'Recent',
					page_num: 0,
					task_type: (isImgto3d ? "ImagineFace" : "DreamFace")
				})
				setSuggestionCards(suggestionData.data.slice(0, numberOfSkeletonCards))
				//console.log(suggestionData)
			}
			setLoading(false)
		}

		setIsAnimating(false)
	}

	const handlerSelectOption = (index) => {
		setCurrentOptionIndex(index)
		setFilterOption(optionsArr[index])
		_czc.push(["_trackPageview", "/tab/mine/" + optionsArr[index], window.location.href]);
		setShowOptions(false)
	}

	useEffect(() => {
		setFilteredCards(filterCards(cards))
	}, [cards])

	const fetch = () => {
		setCards([])
		setLoading(true)
		pageRef.current = 0
		timeStampRef.current = 0
		setIsAnimating(true)
		setFilterOption('All')
		setLoadedImages(0);
		fetchData()
	}

	const debouncedFetch = useDebounce(fetch, 100)

	useEffect(() => {
		debouncedFetch()
	}, [cardsType, searchKeyWord, isImgto3d])

	useEffect(() => {
		// setCardsType('Featured')
	}, [curTheme])

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth)
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
			// setCards([])
		}
	}, [])

	useEffect(() => {
		if (isShowModal === false && cardsType === cardsTypeConst.Mine) {
			//console.log("Reload Card")
			fetchData()
		}
	}, [isShowModal])

	useEffect(() => {
		setCanMore(true)
		if (cardsType === cardsTypeConst.Mine) {
			updateCardStatusInMineInterval.current = setInterval(async () => {
				setCardsProgressState(cardsProgress.current);
			}, 3000);
		} else {
			//console.log("Clear Interval");
			clearInterval(updateCardStatusInMineInterval.current);
		}

		return () => {
			clearInterval(updateCardStatusInMineInterval.current);
		};
	}, [cardsType]);


	return (
		<div className={style.con}>
			<Menu logInfo={logInfo} menuType={'chatAvatar'}></Menu>
			<div className={style.cardsCon} ref={cardsConRef}>
				{loading &&
					Array.from({ length: numberOfSkeletonCards }).map((_, index) => (
						<div key={index} className={style.skeleton}></div>
					))}

				{!isImgto3d && cardsType === cardsTypeConst.Mine && !loading && (
					<div className={style.dropdownContainer}>
						<div className={style.dropdown} >
							<div className={style.select} onClick={() => setShowOptions(!showOptions)}>
								<div>{filterOption}</div>
								<div className={style.arrow}></div>
								{<div className={`${style.options} ${showOptions ? style.showOptions : ""}`}>
									{optionsArr.map((item, index) => {
										return <div style={{
											color: currentOptionIndex === index ? '#232323' : '',
										}} onClick={handlerSelectOption.bind(null, index)} key={index} className={style.option}>{item}</div>
									})}
								</div>}
							</div>
						</div></div>
				)}

				{filteredCards.length === 0 && !loading && (
					<div className={style.emptyContainer}>
						<div className={style.emptyList}>
							We currently do not have any results in this tab.
						</div>
					</div>
				)}
				{filteredCards.length > 0
					&& filteredCards.map((card, index) => {
						return <Card card={card}
							index={index}
							key={card.task_uuid}
						></Card>

					})
				}

				{
					canMore ? (
						loadingMore ? (
							<div className={style.more}>Loading...</div>
						) : (
							<div className={style.more} onPointerDown={loadMore}>
								{cardsType === cardsTypeConst.Mine ? 'Refresh' : 'More'}
							</div>
						)
					) : (
						canMore === false && <div className={style.more}>That's all</div>
					)
				}
			</div >
			{/* {cardsType === cardsTypeConst.Featured ? (
				<div className={style.cardsCon}>2</div>
			) : null}
			{cardsType === cardsTypeConst.Mine ? (
				<div className={style.cardsCon}>3</div>
			) : null} */}
		</div >
	)
}

export { Gallery }
