import React, { useEffect, useRef } from "react";
import style from "./image3dProdBoard.module.css";
import rendering from "../../../assets/rendering.png";
import views from "../../../assets/views.png";
import { useRecoilState, useRecoilValue } from "recoil";
import { useState } from "react";
import { imaginefacePreview } from "../../../utils/net";
import {
  imgUrlsArrAtom,
  showRegenerateAtom,
  isShowModalAtom,
  taskDetailAtom,
  currentPreviewIndexAtom,
  cameraParamsAtom,
  showThreePalaceGridAtom,
  isOpenImg3dGenerateAtom,
  isPurchasedAtom
} from "../../../store";
import { useTips } from "../../GlobalTips";
import { ImageOverlay } from "../../widgets/ImageOverlay";
import { XScroll } from '../../widgets/XScroll'
import { isNotEmpty } from '../../../utils/format'

const Image3dProdBoard = () => {
  const grayColorArr = [
    "#C8C3FF",
    "#FFE0C3",
    "#F3FFC3",
    "#C4DCFF",
    "#E1C3FF",
    "#FFF2C3",
    "#FFC3E0",
  ];
  const [currentPreviewIndex, setCurrentPreviewIndex] = useRecoilState(currentPreviewIndexAtom);
  const [isPurchased, setIsPurchased] = useRecoilState(isPurchasedAtom);
  const [imgUrlsArr, setImgUrlsArr] = useState([]);
  const taskDetail = useRecoilValue(taskDetailAtom);
  const [isOpenImg3dGenerate, setIsOpenImg3dGenerate] = useRecoilState(
    isOpenImg3dGenerateAtom
  );
  const [showThreePalaceGrid, setShowThreePalaceGrid] = useRecoilState(
    showThreePalaceGridAtom
  );
  const [cameraParams, setCameraParams] = useRecoilState(cameraParamsAtom);
  const [isShowModal, setIsShowModal] = useRecoilState(isShowModalAtom);
  const [showRegenerate, setShowRegenerate] =
    useRecoilState(showRegenerateAtom);
  const [cameraInfoRaw, setCameraInfoRaw] = useState({
    intrinsic: [],
    extrinsic: [],
  });
  const [previewImgArr, setPreviewImgArr] = useState([]);
  const [prevData, setPrevData] = useState(-1);
  const [cursorPos, setCursorPos] = useState({ x: 160, y: 130 });
  const topBoxRef = useRef(null);
  const mainBoxRef = useRef(null);
  const prevewImgBoxRef = useRef(null);
  const filterBoxRef = useRef(null);
  const intervalRef = useRef(null);
  const timeoutId = useRef(null);
  const photoItemRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [grabbing, setGrabbing] = useState(false);
  const [informationUrl, setInformationUrl] = useState(null);
  const [done, setDone] = useState(false);
  const [infomationDone, setInfomationDone] = useState(false);
  const clipPathValueA = `inset(0px calc(100%-${cursorPos.x}px) 0px 0px)`;
  const clipPathValueB = `inset(0px 0px calc(100% - ${cursorPos.y}px) ${cursorPos.x}px)`;

  useEffect(() => {
    if (taskDetail) {
      let filteredUrls = [];
      if (taskDetail.image_urls) {
        let counter = 0;
        let firstNonDeletedIndex = null;
        while (counter < taskDetail.image_urls.length) {
          console.log("counter+1", counter);
          if (taskDetail.view_weights[counter] !== 0) {
            filteredUrls.push(taskDetail.image_urls[counter]);

            if (firstNonDeletedIndex === null) {
              firstNonDeletedIndex = counter;
            }
          } else {
            filteredUrls.push("deleted");
          }
          counter++;
        }
        setImgUrlsArr(filteredUrls);

        // 设置currentPreviewIndex为第一个未被删除的视角的索引
        if (firstNonDeletedIndex !== null) {
          setCurrentPreviewIndex(firstNonDeletedIndex);
        }
      } else {
        setImgUrlsArr([""]);
      }
    }
    return () => {
      if (!isOpenImg3dGenerate) {
        setImgUrlsArr([]);
      }
      clearInterval(intervalRef.current);
      setShowRegenerate(false);
      setShowThreePalaceGrid(true);
      setCurrentPreviewIndex(0)
    };
  }, []);

  useEffect(() => {
    if (imgUrlsArr.length && !done) {
      const fun = async () => {
        try {
          //interval to query renderImg
          let res;

          const intervalQuery = async () => {
            clearInterval(intervalRef.current);
            intervalRef.current = setInterval(async () => {
              res = await imaginefacePreview(taskDetail.task_uuid);
              //return interval
              if (!res.data.error) {
                const filesResult = res.data.files;
                setInformationUrl(res.data.information);
                console.log(filesResult, "filesResult");
                if (filesResult.length >= imgUrlsArr.length) {
                  let flag = true;

                  const updatePreviewImgArr = async () => {
                    const updatedPreviewImgArr = [];
                    for (let i = 0; i < filesResult.length; i++) {
                      const accessItem = filesResult[i];
                      if (previewImgArr[i]?.access) {
                        updatedPreviewImgArr.push(previewImgArr[i]);
                        continue;
                      }
                      if (
                        accessItem.face !== "FILE_NOT_FOUND" &&
                        accessItem.render !== "FILE_NOT_FOUND" &&
                        accessItem.render_statue !== "FILE_NOT_FOUND"
                      ) {
                        updatedPreviewImgArr.push({
                          face: accessItem.face,
                          render: accessItem.render,
                          render_statue: accessItem.render_statue,
                          access: true,
                          loaded: [false, false, false],
                        });
                      } else {
                        flag = false;
                        updatedPreviewImgArr.push(previewImgArr[i]);
                      }
                    }

                    await Promise.all(updatedPreviewImgArr);

                    setPreviewImgArr(updatedPreviewImgArr);
                  };

                  updatePreviewImgArr();
                  if (flag) clearInterval(intervalRef.current);
                }
              }
            }, 1000);
          };
          await intervalQuery();
        } catch (error) {
          console.log(error, "imaginefacePreview_error");
        }
      };
      fun();
      setDone(true);
    }
  }, [imgUrlsArr]);

  useEffect(() => {
    if (informationUrl && !infomationDone) {
      const queryCamera = async () => {
        const fetchCamInfo = async (url) => {
          try {
            const response = await fetch(url);
            const data = await response.json();
            return data;
          } catch (error) {
            console.error(error);
          }
        };
        const information = await fetchCamInfo(informationUrl);
        if (information.intrinsic?.length && information.extrinsic?.length) {
          setInfomationDone(true);
          setCameraInfoRaw({
            intrinsic: information.intrinsic,
            extrinsic: information.extrinsic,
          });

        }
      };
      queryCamera();
    }
  }, [informationUrl]);

  useEffect(() => {
    if (isNotEmpty(cameraInfoRaw.intrinsic) && isNotEmpty(cameraInfoRaw.extrinsic)) {
      setCameraParams({
        intrinsic: cameraInfoRaw.intrinsic[currentPreviewIndex],
        extrinsic: cameraInfoRaw.extrinsic[currentPreviewIndex],
      })
    }
  }, [cameraInfoRaw, currentPreviewIndex])

  useEffect(() => {
    const defaultX = filterBoxRef.current.clientWidth / 2;
    const defaultY = filterBoxRef.current.clientHeight / 2;
    setCursorPos({ x: defaultX, y: defaultY });
  }, [showThreePalaceGrid, currentPreviewIndex, showRegenerate]);

  useEffect(() => {
    if (prevData !== -1) {
      const box = photoItemRefs[prevData].current;
      box.scrollIntoView({
        behavior: 'smooth',
        inline: 'start'
      });
    }
  }, [currentPreviewIndex])

  useEffect(() => {
    if (showThreePalaceGrid) {
      clearTimeout(timeoutId.current)
      prevewImgBoxRef.current.style.opacity = 0;
      prevewImgBoxRef.current.style.filter = "blur(10px)";
      prevewImgBoxRef.current.style.zIndex = 0;
      timeoutId.current = setTimeout(() => {
        filterBoxRef.current.style.opacity = 1;
        filterBoxRef.current.style.filter = "";
        filterBoxRef.current.style.zIndex = 2;
      }, 200);
    } else {
      clearTimeout(timeoutId.current)
      filterBoxRef.current.style.opacity = 0;
      filterBoxRef.current.style.filter = "blur(10px)";
      filterBoxRef.current.style.zIndex = 0;
      timeoutId.current = setTimeout(() => {
        prevewImgBoxRef.current.style.opacity = 1;
        prevewImgBoxRef.current.style.filter = "";
        prevewImgBoxRef.current.style.zIndex = 2;
      }, 200);
    }
  }, [showThreePalaceGrid]);

  const getSrc = (key) => {
    if (
      previewImgArr.length &&
      previewImgArr[currentPreviewIndex] &&
      previewImgArr[currentPreviewIndex].access
    ) {
      switch (key) {
        case 0:
          return previewImgArr[currentPreviewIndex].face;
        case 1:
          return previewImgArr[currentPreviewIndex].render;
        case 2:
          return previewImgArr[currentPreviewIndex].render_statue;
        default:
          break;
      }
    }
  };

  const handleDragStart = (e) => {
    if (e.button !== 0) return;
    setGrabbing(true)
    const startX = e.clientX;
    const startY = e.clientY;
    const InitialX = cursorPos.x
    const InitialY = cursorPos.y
    const maxX = filterBoxRef.current.clientWidth
    const maxY = filterBoxRef.current.clientHeight
    document.onmousemove = function (e) {
      const endX = e.clientX;
      const endY = e.clientY;
      let diffX = endX - startX + InitialX;
      let diffY = endY - startY + InitialY;
      let newX = diffX;
      let newY = diffY;

      if (diffX < 0) {
        newX = 0;
      } else if (diffX > maxX) {
        newX = maxX;
      }

      if (diffY < 0) {
        newY = 0;
      } else if (diffY > maxY) {
        newY = maxY;
      }

      setCursorPos({ x: newX, y: newY });
    };
    document.onmouseup = function () {
      setGrabbing(false)
      document.onmousemove = null;
      document.onmouseup = null;
    };
    if (e.preventDefault) {
      e.preventDefault();
    }
  };

  const handlerClickPhoto = (index, e) => {
    /*if (prevData === index) {
      console.log("reset camera params")
      setCameraParams("reset")
      setPrevData(-1)
      return
    }*/
    if (index === currentPreviewIndex) {
      setCameraParams({
        intrinsic: cameraInfoRaw.intrinsic[currentPreviewIndex],
        extrinsic: cameraInfoRaw.extrinsic[currentPreviewIndex],
      })
      return;
    }
    setPrevData(index);
    setTimeout(() => {
      setCurrentPreviewIndex(index);
    }, 200);
    if (showThreePalaceGrid) {
      clearTimeout(timeoutId.current)
      filterBoxRef.current.style.opacity = 0.3;
      filterBoxRef.current.style.filter = "blur(10px)";
      filterBoxRef.current.style.zIndex = 0;
      timeoutId.current = setTimeout(() => {
        filterBoxRef.current.style.opacity = 1;
        filterBoxRef.current.style.filter = "";
        filterBoxRef.current.style.zIndex = 1;
      }, 200);
    } else {
      clearTimeout(timeoutId.current)
      prevewImgBoxRef.current.style.opacity = 0.3;
      prevewImgBoxRef.current.style.filter = "blur(10px)";
      prevewImgBoxRef.current.style.zIndex = 0;
      timeoutId.current = setTimeout(() => {
        prevewImgBoxRef.current.style.opacity = 1;
        prevewImgBoxRef.current.style.filter = "";
        prevewImgBoxRef.current.style.zIndex = 1;
      }, 200);
    }
  };

  const handlerRegenerate = () => {
    setIsShowModal(false);
  };

  const handlerImgLoaded = (index) => {
    if (
      previewImgArr.length &&
      previewImgArr[currentPreviewIndex] &&
      previewImgArr[currentPreviewIndex].access
    ) {
      const newArr = previewImgArr.slice();
      newArr[currentPreviewIndex].loaded[index] = true;
      setPreviewImgArr(newArr);
    }
  };

  return (
    <div className={style.Img3dProdWrapper}>
      <div className={style.titleBox}>
        <div className={style.title}>Image-to-3D</div>
        {showRegenerate && (
          <div onClick={handlerRegenerate} className={style.reGenerate}>
            Regenerate
          </div>
        )}
      </div>
      <div className={style.mainBox} ref={mainBoxRef}>
        <div className={style.switchBox}>
          <div>{showThreePalaceGrid ? "Original" : "Preview"} </div>
          {isPurchased && <div
            className={style.switch}
            onClick={() => setShowThreePalaceGrid(!showThreePalaceGrid)}
          >
            <img src={showThreePalaceGrid ? views : rendering} alt="" />
          </div>}

        </div>
        <div
          className={style.topBox}
          onMouseDown={handleDragStart}
          ref={topBoxRef}
        >
          <div ref={filterBoxRef} className={style.filterBox}
            style={{
              cursor: grabbing ? 'grabbing' : 'grab'
            }} >
            {previewImgArr[currentPreviewIndex] &&
              previewImgArr[currentPreviewIndex].loaded.every((item) => item) ? (
              <>
                {new Array(3).fill(0).map((item, index) => {
                  return (
                    <div
                      key={`preview${index}`}
                      className={style.imgBox}
                      style={{
                        clipPath: `${index === 0
                          ? `var(--clip-path-value, ${clipPathValueA})`
                          : index === 1
                            ? `var(--clip-path-value, ${clipPathValueB})`
                            : `inset(${cursorPos.y}px 0px 0px ${cursorPos.x}px)`
                          }`,
                      }}
                    >
                      <img src={getSrc(index)} alt="" />
                    </div>
                  );
                })}
                <div
                  style={{
                    position: "absolute",
                    top: `${cursorPos.y}px`,
                    left: `${cursorPos.x}px`,
                    width: `100%`,
                    height: "1px",
                    backgroundColor: "white",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    width: "1px",
                    height: "100%",
                    backgroundColor: "white",
                    left: `${cursorPos.x}px`,
                  }}
                ></div>
                <div
                  style={{
                    top: `${cursorPos.y}px`,
                    left: `${cursorPos.x}px`,
                  }}
                  className={style.FrameBox}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </>
            ) : (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "auto",
                  aspectRatio: "1/1.15",
                }}
              >
                <div className={style.overlayBox}>
                  <div className={style.skeleton}></div>
                </div>
              </div>
            )}
            {new Array(3).fill(0).map((item, index) => {
              return (
                <img
                  src={getSrc(index)}
                  key={index}
                  alt=""
                  onLoad={handlerImgLoaded.bind(null, index)}
                  style={{ display: "none" }}
                />
              );
            })}
          </div>
          <div ref={prevewImgBoxRef} className={style.prevewImgBox}>
            <ImageOverlay
              src={imgUrlsArr[currentPreviewIndex]}
              style={{
                position: "relative",
                width: "100%",
                height: "auto",
                aspectRatio: "1/1.15",
              }}
              alt="Image alt text"
            />
          </div>
        </div>
        <div className={style.placeHodler} ></div>
        <XScroll customStyle={{
          width: '100%',
          height: '17%',
        }}
          scrollBar={true}>
          <div className={style.photoList}>
            {[
              "#4737FF",
              "#FF9737",
              "#D7FF37",
              "#3787FF",
              "#9B37FF",
              "#FFD337",
              "#FF3797",
            ]
              .slice(0, imgUrlsArr.length)
              .map((item, index) => {
                if (imgUrlsArr[index] === "deleted") {
                  return null;
                }

                return (
                  <div
                    className={style.photoImgBox}
                    key={index}
                    ref={photoItemRefs[index]}
                    onClick={handlerClickPhoto.bind(null, index)}
                  >
                    <ImageOverlay
                      src={imgUrlsArr[index]}
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "auto",
                        // maxHeight: '150px',
                        aspectRatio: "1/1",
                      }}
                      alt="Image alt text"
                    />
                    <span
                      className={style.normalItem}
                      style={
                        currentPreviewIndex === index
                          ? {
                            backgroundColor: `${item}`,
                          }
                          : {
                            // backgroundColor: `${grayColorArr[index]}`,
                            backgroundColor: `rgb(180, 177, 175)`,
                          }
                      }
                    ></span>
                  </div>
                );
              })}
          </div>
        </XScroll>
      </div>
    </div>
  );
};

export { Image3dProdBoard };
