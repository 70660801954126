/* global _czc */

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  getGenerateProgress,
  getTaskDetail,
  generateDetail,
  selectCandidate,
  getMagicPrompt,
  setPrivate,
} from "../../../utils/net";
import { useTips } from "../../GlobalTips";
import style from "./generateBoard.module.css";
import Private from "../../widgets/Private";
import License from "../../widgets/License";
import {
  generateProgressAtom,
  promptAtom,
  stopChatAtom,
  taskDetailAtom,
  taskInitAtom,
  setGenerateAtom,
  lastGenerateUUIDAtom,
  isShowModalAtom,
  showProgressAtom,
  seedsAtom,
  modalObjAtom,
} from "../../../store";
import { AiOutlineCheck } from "react-icons/ai";
import { useLocation } from "react-router-dom";

function GenerateBoard() {
  const location = useLocation();
  const [prompt, setPrompt] = useRecoilState(promptAtom);
  const taskInit = useRecoilValue(taskInitAtom);
  const [taskDetail, setTaskDetail] = useRecoilState(taskDetailAtom);
  const navi = useNavigate();
  const intervalRef = useRef(null);
  const [candidates, setCandidates] = useState([]);
  const [generateProgress, setGenerateProgress] =
    useRecoilState(generateProgressAtom);
  const [stopChat, setStopChat] = useRecoilState(stopChatAtom);
  const [countdown, setCountdown] = useState(0);
  const [progress, setProgress] = useState(100);
  const [generate, setGenerate] = useRecoilState(setGenerateAtom);
  const [lastGenerateUUID, setLastGenerateUUID] =
    useRecoilState(lastGenerateUUIDAtom);
  const [isShowModal, setIsShowModal] = useRecoilState(isShowModalAtom);
  const [showProgress, setShowProgress] = useRecoilState(showProgressAtom);
  const [selectedStrings, setSelectedStrings] = useState([]);
  const [allStrings, setAllStrings] = useState([]);
  const [showStringSelection, setShowStringSelection] = useState(true);
  const tip = useTips();
  const [seeds, setSeeds] = useRecoilState(seedsAtom);
  const popupRef = useRef();
  const LicenseRef = useRef(null);
  const PrivateRef = useRef(null);
  const [modalObj, setModalObj] = useRecoilState(modalObjAtom);

  const setMagicPrompt = async (ev) => {
    _czc.push(["_trackEvent", "Card", "Choose Magic Prompt", "Generate Card"]);
    const res = await getMagicPrompt();
    //console.log(res.data.settings.magic_prompts)
    setAllStrings(res.data.settings.magic_prompts);
  };

  useEffect(() => {
    if (isShowModal) setMagicPrompt();
    setSeeds("");
  }, [isShowModal]);

  useEffect(() => {
    // console.log(taskDetail)
    if (taskDetail || !stopChat) {
      clearInterval(intervalRef.current);
      return;
    }

    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(async () => {
      const { data } = await getGenerateProgress(taskInit.task_uuid);
      if (data.candidates) setCandidates(data.candidates);
      if (data.stage === "Done" || data.stage === "Preview") {
        setGenerateProgress({
          stage: "Downloading",
          percent: 100,
          payload: data,
        });
        clearInterval(intervalRef.current);
        const response = await getTaskDetail(taskInit.task_uuid);
        setTaskDetail(response.data);
      } else {
        setGenerateProgress({
          stage: data.stage,
          percent: data.percentage || 0,
          payload: data,
        });
      }
    }, 1000);
    // eslint-disable-next-line
  }, [taskDetail, stopChat]);

  const handleGenerate = async (ev) => {
    if (!prompt || stopChat) return;
    _czc.push(["_trackEvent", "Card", "Generate", "Generate Card"]);
    _czc.push(["_trackPageview", "/chatavatar/generate", window.location.href]);

    const modifiedPrompt =
      prompt +
      (selectedStrings.length > 0 ? ", " : "") +
      selectedStrings.join(", ");
    setShowStringSelection(false);
    setStopChat(true);
    setGenerate(true);
    setPrompt(prompt);

    try {
      const seed =
        seeds && seeds.length > 0 ? seeds : Math.floor(Math.random() * 10000);
      const rep = await generateDetail({
        task_uuid: taskInit.task_uuid,
        prompt: modifiedPrompt,
        seed,
      });
      if (rep.data.error) {
        throw new Error(rep.data.error);
      } else {
        await LicenseRef.current.pay(taskInit.task_uuid);
        await PrivateRef.current.setPrivateDelay(taskInit.task_uuid);
      }
    } catch (e) {
      tip({
        type: "error",
        content: "Something went wrong: " + e.message,
      });
      setStopChat(false);
    }

    if (!stopChat) {
      //console.log("countdown reset")
      setCountdown(10);
    }
  };

  useEffect(() => {
    var timer = undefined;
    if (countdown > 0 && stopChat && generateProgress.stage !== "Waiting") {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
        //console.log(countdown)
        setProgress((countdown - 1) * 10);
        if (countdown === 1) {
          setCandidates([]);
          setLastGenerateUUID(taskInit.task_uuid);
          setShowProgress(true);
          setModalObj({
            ...modalObj,
            type: "detail",
          });
        }
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      clearTimeout(timer);
      setCountdown(0);
    }
  }, [countdown, stopChat, generate, generateProgress]);

  const handleIpt = (ev) => {
    setPrompt(ev.currentTarget.value);
  };

  const handleSeeds = (ev) => {
    setSeeds(ev.currentTarget.value);
  };

  const handleSelectCandidate = async (candidateIndex) => {
    // return null
    await selectCandidate(taskInit.task_uuid, candidateIndex);
    setCandidates([]);
    setCountdown(0);
    setLastGenerateUUID(taskInit.task_uuid);
    setShowProgress(true);
    setModalObj({
      ...modalObj,
      type: "detail",
    });
    if (location.pathname.includes("/waic")) {
      navi(`/waic`, {
        state: {
          type: "detail",
          taskuuid: taskInit.task_uuid,
        },
      });
    } else {
      navi(`/chatavatar`, {
        state: {
          type: "detail",
          taskuuid: taskInit.task_uuid,
        },
      });
    }
    // window.history.pushState(null, '', '/')
  };

  const handleMouseEnter = (event) => {
    event.currentTarget.classList.add(style.highlight);
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.classList.remove(style.highlight);
  };

  const renderStringSelections = () => {
    const strings = allStrings;

    const handleClick = (string) => {
      if (selectedStrings.includes(string)) {
        setSelectedStrings(selectedStrings.filter((item) => item !== string));
      } else {
        setSelectedStrings([...selectedStrings, string]);
      }
    };

    return (
      <div className={style.stringSelectionContainer}>
        {strings.map((string, index) => (
          <button
            key={index}
            className={`${style.stringSelectionButton} ${selectedStrings.includes(string) ? style.selected : ""
              }`}
            onClick={() => handleClick(string)}
          >
            {string}
            {selectedStrings.includes(string) && (
              <AiOutlineCheck className={style.checkIcon} />
            )}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className={style.generateBox}>
      <div className={style.titleBox}>
        <div className={style.title}>Prompt</div>

        <License
          style={{
            top: "2px",
          }}
          immediate={false}
          ref={LicenseRef}
        ></License>
        <div className={style.spaceholder}></div>

        <Private type={"generate"} ref={PrivateRef}></Private>
      </div>
      <textarea
        className={style.iptArea}
        value={prompt}
        placeholder={"Prompt will be generated"}
        onChange={handleIpt}
        onPointerDown={(ev) => {
          _czc.push([
            "_trackEvent",
            "Card",
            "Customize Prompt",
            "Generate Card",
          ]);
        }}
      />
      <div
        className={`${style.btn}  ${!prompt || stopChat ? style.disabled : ""}`}
        onPointerDown={handleGenerate}
      >
        Generate
      </div>
      {showStringSelection && (
        <>
          {renderStringSelections()}
          <div className={`${style.generateTips}`}>
            <p>
              Emphasize/weigh part of your prompt with parentheses as so: a baby
              deer with (big eyes)
            </p>
            <p>
              De-emphasize part of your prompt as so: a [baby] deer with big
              eyes
            </p>
            <p>
              Precisely weigh part of your prompt as so: a baby deer with (big
              eyes:1.3){" "}
            </p>
          </div>
          <input
            className={style.seedsArea}
            value={seeds}
            placeholder={"Customized Seed"}
            onChange={handleSeeds}
            onPointerDown={(ev) => {
              _czc.push([
                "_trackEvent",
                "Card",
                "Customize Seed Change",
                "Generate Card",
              ]);
            }}
            style={{ textAlign: "center" }}
            type="number"
          />
        </>
      )}
      <div className={style.progressBarContainer}>
        <div
          className={style.progressBar}
          style={{ width: `${progress}%` }}
        ></div>
      </div>

      <div className={style.candidateCon}>
        <div className={style.candidateCol}>
          {candidates.map((item, index) =>
            index >= 0 && index < 3 ? (
              <img
                key={index}
                src={`data:image/png;base64,${item}`}
                alt={item}
                onClick={() => handleSelectCandidate(index)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            ) : null
          )}
        </div>
        <div className={style.candidateCol}>
          <div style={{ height: "4rem", marginBottom: "1rem" }}></div>
          {candidates.map((item, index) =>
            index >= 3 && index < 6 ? (
              <img
                key={index}
                src={`data:image/png;base64,${item}`}
                alt={item}
                onClick={() => handleSelectCandidate(index)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            ) : null
          )}
        </div>
        <div className={style.candidateCol}>
          {candidates.map((item, index) =>
            index >= 6 && index < 9 ? (
              <img
                key={index}
                src={`data:image/png;base64,${item}`}
                alt={item}
                onClick={() => handleSelectCandidate(index)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            ) : null
          )}
        </div>
      </div>

      {stopChat ? (
        <div
          className={`${stopChat && countdown > 0 && generateProgress.stage !== "Waiting"
            ? style.modelInfoConWithBackground
            : style.modelInfoCon
            }`}
        >
          {stopChat && countdown > 0 && generateProgress.stage !== "Waiting" ? (
            <>
              <div className={style.progressInfo}>
                Default model generated if no choice made in {countdown}s
              </div>
              <div className={style.progressTrack}>
                <div
                  className={style.progressThumb}
                  style={{ width: `${countdown * 10}%` }}
                ></div>
              </div>
            </>
          ) : null}
          {generateProgress.stage === "Waiting" ? (
            <div className={style.progressInfo}>
              Waiting in queue,{" "}
              {generateProgress.payload.waiting_num
                ? generateProgress.payload.waiting_num
                : 0}{" "}
              tasks remain...
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export { GenerateBoard };
