/* global _czc */

import style from './footer.module.css'
import logoSrc from '../../assets/ai-logo.png'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

function Footer(props) {
	const [showPopup, setShowPopup] = useState(false)
	const { isMobile } = props
	useEffect(() => {
		document.documentElement.style.overflowY = showPopup ? 'hidden' : 'overlay'
	}, [showPopup])


	const location = useLocation();
	
	if (location.pathname === '/panorama') {
		return null;
	}
	  

	return (
		<>
			<div className={`${style.con} ${isMobile ? style.mobileCon : ''}`}>
				<div
					className={style.nameCon}
					onPointerDown={(ev) => {
						_czc.push(['_trackEvent', 'Index', 'Logo', 'Footer Component']);
						window.open('https://deemos.com', '_blank');
					}}>
					<img src={logoSrc} alt='icon' />
					<div> &copy; Deemos, Inc.</div>
				</div>
				{!isMobile && (<>
					<div>
						contact: <a href='mailto:hello@deemos.com' onPointerDown={(ev) => {
							_czc.push(['_trackEvent', 'Index', 'Contact', 'Footer Component']);
						}}>hello@deemos.com</a>
					</div>
					<div
						onPointerDown={(ev) => {
							window.open(
								Intl.DateTimeFormat().resolvedOptions().timeZone === 'Asia/Shanghai'
									? 'https://cowtransfer.com/s/1994387abbd74f'
									: 'https://drive.google.com/drive/folders/1kyhe5ZI40yrGyyNfzboTF_6cU539p10m?usp=share_link',
								'_blank'
							);
							_czc.push(['_trackEvent', 'Index', 'Shader Examples', 'Footer Component']);
						}
						}>
						Shader Examples
					</div>
					<div
						className={style.redeem}
						onPointerDown={(ev) => {
							window.open(
								'https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAANAAUIQFilUNjNGM0NLUUhEUEkxVlRFTkw0OVA3NzlVNS4u',
								'_blank'
							)
							_czc.push(['_trackEvent', 'Index', 'Get Redeem', 'Footer Component']);
						}}>
						Get Redeem
					</div></>)}

				<span className={style.spaceholder}></span>
				{isMobile && (<div style={{ marginLeft: "0.5rem" }}>
					<a href='mailto:hello@deemos.com' onPointerDown={(ev) => {
						_czc.push(['_trackEvent', 'Index', 'Contact', 'Footer Component']);
					}}>Contact</a>
				</div>)}
				<div onPointerDown={(ev) => {
					setShowPopup('Privacy')
					_czc.push(['_trackEvent', 'Index', 'Privacy', 'Footer Component']);
				}}>Privacy</div>
				<div onPointerDown={(ev) => {
					setShowPopup('Terms')
					_czc.push(['_trackEvent', 'Index', 'Terms', 'Footer Component']);
				}
				}>Terms</div>
			</div>
			{showPopup ? (
				<div className={style.popupCon} onPointerDown={(ev) => setShowPopup(false)}>
					<iframe
						className={style.popup}
						onPointerDown={(ev) => ev.stopPropagation()}
						src={`/${showPopup}` + '.html'}></iframe>
				</div>
			) : null}
		</>
	)
}

export { Footer }
