import { atom } from 'recoil'

export const isMobileAtom = atom({
  key: 'isMobileAtom',
  default: false,
})

export const currentNavAtom = atom({
  key: 'currentNavAtom',
  default: "Chatavatar",
})

export const transitioningAtom = atom({
  key: 'transitioningAtom',
  default: false,
})
//开启模态框，可以传参，可以组合打开chat，generate，detail，img3d等
export const modalObjAtom = atom({
  key: 'modalObjAtom',
  default: {
    type: 'generate',
    taskUUid: ''
  },
})
export const privateCouterAtom = atom({
  key: 'privateCouterAtom',
  default: 0,
})
export const userPermissionAtom = atom({
  key: 'userPermissionAtom',
  default: { "rodin_access": true, "panorama_access": false, "chatavatar_text_access": true, "chatavatar_image_access": true },
})