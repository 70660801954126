/**
 * Get the browser name and version from the user agent string
 * @returns {object} - Object containing the browser name and version
 */
export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  let browserName, browserVersion;
  if (userAgent.includes("Chrome")) {
    browserName = "Chrome";
    browserVersion = userAgent.match(/Chrome\/(\d+)/)[1];
  } else if (userAgent.includes("Edg")) {
    browserName = "Edge";
    browserVersion = userAgent.match(/Edg\/(\d+)/)[1];
  } else if (userAgent.includes("Safari")) {
    browserName = "Safari";
    browserVersion = userAgent.match(/Version\/(\d+)/)[1];
  } else if (userAgent.includes("Opera")) {
    browserName = "Opera";
    browserVersion = userAgent.match(/OPR\/(\d+)/)[1];
  } else if (userAgent.includes('Firefox')) {
    browserName = "Firefox";
    browserVersion = userAgent.match(/Firefox\/(\d+)/)[1];
  } else {
    browserName = "Unknown";
    browserVersion = "Unknown";
  }
  return {
    name: browserName,
    version: browserVersion
  };
}


/**
 * Perform precise addition or subtraction of decimals
 * @param {number} num1 
 * @param {number} num2
 * @param {string} operation - The operator, can be "+" or "-"
 * @returns {number} - The calculated result
 */
export const addAndSubtract = (num1, num2, operation) => {
  const decimalPlaces = Math.max(
    num1.toString().split('.')[1]?.length ?? 0,
    num2.toString().split('.')[1]?.length ?? 0
  );
  const multiplier = 10 ** decimalPlaces;
  const result = operation === '+' ?
    (num1 * multiplier + num2 * multiplier) / multiplier :
    (num1 * multiplier - num2 * multiplier) / multiplier;

  return result;
}




//check empty
export const isNotEmpty = (value) => {
  if (typeof value === 'object' && value !== null) {
    // object
    if (Array.isArray(value)) {
      // array obj
      return value.length !== 0;
    } else {
      // other obj
      return Object.keys(value).length !== 0;
    }
  } else {
    // others
    return !(
      value === null ||
      value === undefined ||
      value === '' ||
      (typeof value === 'number' && value === 0) ||
      (typeof value === 'string' && value.trim() === '')
    );
  }
}

/**
 * Perform deep cloning of an object or array
 * @param {object|array} obj - The object or array to be cloned
 * @returns {object|array} - The cloned object or array
 */
export const deepClone = (obj) => {
  if (typeof obj !== 'object' || obj == null) return obj
  let result
  if (obj instanceof Array) {
    result = []
  } else {
    result = {}
  }
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = deepClone(obj[key])
    }
  }
  return result
}
