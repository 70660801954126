import React, {
  useImperativeHandle,
  useEffect,
  forwardRef,
  useRef,
  useState,
} from "react";
import privateurl from "../../../assets/private.png";
import unPrivateurl from "../../../assets/unPrivate.png";
import bordlockedPrivate from "../../../assets/bordlockedPrivate.png";
import gallerylockedPrivate from "../../../assets/gallerylockedPrivate.png";
import style from "./private.module.css";
import { useRecoilState } from "recoil";
import { privateCouterAtom, taskDetailAtom, cardsAtom } from "../../../store";
import { setPrivate, getUserInfo } from "../../../utils/net";
import { useTips } from "../../GlobalTips";

const Private = forwardRef((props, ref) => {
  const {
    card: { is_private = false, task_uuid: uuid, lisenced: licensed } = {},
    style: myStyle,
    type,
  } = props;
  const [isPrivateHovered, setIsPrivateHovered] = useState(false);
  const [taskDetail, setTaskDetail] = useRecoilState(taskDetailAtom);
  const [isPrivate, setIsPrivate] = useState(is_private);
  const [isSetPrivate, setIsSetPrivate] = useState(false);
  const [tip, setTip] = useState(false);
  const [lockedPrivate, setLockedPrivate] = useState(false);
  const [cards, setCards] = useRecoilState(cardsAtom);
  const [privateCouter, setPrivateCouter] = useRecoilState(privateCouterAtom);
  const [couter, setCouter] = useState(privateCouter);
  const popupRef = useRef();
  const globalTip = useTips();
  /* 
    1. card 只展示is_private === true 状态
    2. generate curcounter===0  ？置灰
                curcounter > 0  ？延迟设置
    3. detail  curcounter===0 --isPrivate===false ? 置灰
                              --isPrivate===true ? 可以取消    
               curcounter > 0  ？ 正常取消和设置 立即设置，并且card得同步状态 
    */
  /*
  useEffect(() => {
    if (privateCouter <= 0 && !is_private) {
      setLockedPrivate(false)
    } else {
      setLockedPrivate(false)
    }
  }, [privateCouter])
*/

  const handleHoverPrivateEnter = () => {
    if (lockedPrivate || type === "generate") return;
    setIsPrivateHovered(true);
  };

  const handleHoverPrivateLeave = () => {
    setIsPrivateHovered(false);
  };

  const handlerPrivate = async () => {
    if (lockedPrivate) {
      setTip(true);
      setTimeout(() => {
        setTip(false);
      }, 1000);
      return;
    }
    let curCouter = privateCouter;
    const res = await setPrivate(taskDetail.task_uuid);
    if (!res.data.error) {
      const newInfo = await getUserInfo({
        user_uuid: localStorage.getItem("user_uuid"),
      });
      if (isPrivate && curCouter >= 0) {
        setIsPrivate(false);
        changeCards(false);
      } else if (!isPrivate) {
        setIsPrivate(true);
        changeCards(true);
      }
      setPrivateCouter(newInfo.data.meta.chances_left_to_set_private_task);
    } else if (res.data.error === "INSUFFICIENT_CHANCES") {
      globalTip({
        type: "error",
        content:
          "You have reached the maximum number of free private assets. To set this asset as private, please purchase a license.",
      });
    } else {
      globalTip({
        type: "error",
        content: "Something went wrong. Please try again later.",
      });
    }
  };
  const handlerPrivateDelay = async () => {
    // globalTip({
    //   type: "primary",
    //   content: "You will be able to set your assert as private after generated",
    // });
    if (lockedPrivate) {
      setTip(true)
      setTimeout(() => {
        setTip(false)
      }, 1000)
      return
    }
    if (isPrivate) {
      setIsPrivate(false)
      setCouter(couter + 1)
      setIsSetPrivate(false)
    } else if (!isPrivate) {
      setIsPrivate(true)
      setCouter(couter - 1)
      setIsSetPrivate(true)
    }
  };
  const changeCards = (flag) => {
    const newCards = JSON.parse(JSON.stringify(cards));
    const index = newCards.findIndex((item) => item.task_uuid === uuid);
    if (index === -1) return;
    newCards[index].is_private = flag;
    setCards(newCards);
  };
  useEffect(() => {
    setIsPrivate(is_private);
  }, [is_private]);

  useImperativeHandle(ref, () => ({
    setPrivateDelay: async (task_uuid) => {
      if (!isSetPrivate) return;
      const data = await setPrivate(task_uuid);
      if (!data.data.error) {
        const newInfo = await getUserInfo({
          user_uuid: localStorage.getItem("user_uuid"),
        });
        setPrivateCouter(newInfo.data.meta.chances_left_to_set_private_task);
        setIsPrivate(true);
      }
    },
  }));
  return (
    <>
      {type === "card" && (
        <div
          style={{
            ...myStyle,
            background: "rgba(35,93,247,0.15)",
          }}
          className={style.privateBox}
        >
          <img src={privateurl} alt="private" />
        </div>
      )}
      {type !== "card" && (
        <div
          style={{
            ...myStyle,
            background: isPrivate ? "rgba(35,93,247,0.15)" : "#ffffff80",
          }}
          className={style.privateBox}
          onPointerDown={
            type === "detail" ? handlerPrivate : handlerPrivateDelay
          }
          onMouseEnter={handleHoverPrivateEnter}
          onMouseLeave={handleHoverPrivateLeave}
        >
          {lockedPrivate ? (
            <img src={bordlockedPrivate} alt="locked" />
          ) : (
            <img src={isPrivate ? privateurl : unPrivateurl} alt="" />
          )}
          <div
            className={style.speechBubble}
            style={{ display: isPrivateHovered ? "block" : "none" }}
            onMouseLeave={handleHoverPrivateLeave}
            ref={popupRef}
          >
            <div className={style.privatehoverBox}>
              {!licensed ? (
                <>
                  <div className={style.privateTips}>
                    Keep your assets private for free
                  </div>
                  <div className={style.spaceholder}></div>
                  <div className={style.privateCount}>
                    <span>
                      {type === "detail" ? privateCouter : couter}&nbsp;
                    </span>
                    times
                  </div>
                </>
              ) : (
                <div className={style.privateTips}>
                  You have license of this asset. Set private for free.
                </div>
              )}
            </div>
          </div>
          {
            <div
              className={`${tip ? style.show : style.hide} ${style.bordtip}`}
            >
              <span>You have reached your limit.</span>
            </div>
          }
        </div>
      )}
    </>
  );
});

export default Private;
