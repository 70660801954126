/* global _czc */
import React, { useState, useEffect } from "react";
import {
  authorizeExternal,
  paymentValidationAlipay,
  paymentValidationPaypal,
} from "../../utils/net";
import { useTips } from "../GlobalTips";

const OAuthHandler = () => {
  const [urlParams, setUrlParams] = useState(null);
  const [error, setError] = useState(null);
  const [provider, setProvider] = useState(null);
  const [status, setStatus] = useState(null);
  const tip = useTips();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setError(null);
    setProvider(params.get("source"));
    setStatus(params.get("status"));
    if (params.toString()) {
      setUrlParams(Object.fromEntries(params));
    }
  }, []);

  useEffect(() => {
    //console.log(urlParams)
    if (urlParams) {
      handlePayment();
    }
  }, [urlParams]);

  const handlePayment = async () => {
    try {
      if (urlParams.status === "success") {
        if (urlParams.source === "alipay") {
          _czc.push(["_trackEvent", "Callback", "Alipay", "TopUp"]);
          const { data } = await paymentValidationAlipay(urlParams);
          if (data.error) {
            throw new Error(data.error);
          } else {
            window.location.href = "/";
          }
        } else if (urlParams.source === "paypal") {
          _czc.push(["_trackEvent", "Callback", "Paypal", "TopUp"]);
          const { data } = await paymentValidationPaypal(urlParams);
          if (data.error) {
            throw new Error(data.error);
          } else {
            window.location.href = "/";
          }
        }

      } else {
        throw new Error("Payment Failed");
      }
    } catch (e) {
      setError(e.message);
    }
  };

  const pageStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "white",
    color: "black",
    fontSize: "20px",
  };

  const errorStyle = {
    fontSize: "20px",
  };

  const errorStyleDetail = {
    fontSize: "10px",
  };

  return (
    <div style={pageStyle}>
      {error ? (
        <>
          {console.log(error)}
          <div style={errorStyle}>Payment Failed</div>
          <br></br>
          <div style={errorStyleDetail}>{error}</div>
        </>
      ) : (
        "Top Up Successful. Redirecting..."
      )}
    </div>
  );
};

export default OAuthHandler;
