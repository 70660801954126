/* global _czc */

import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { cardsTypeAtom, cardsTypeConst, showSearchAtom, rodinCardsTypeAtom, panoramaCardsTypeAtom, showCardsConAtom, clickHideAtom } from '../../../store'
import style from './menu.module.css'

const Menu = ({ logInfo, menuType }) => {
  const [loadedImages, setLoadedImages] = useState(0);
  const [rouletteOutHover, setRouletteOutHover] = useState(false);
  const [showSearch, setShowSearch] = useRecoilState(showSearchAtom)
  const [cardsType, setCardsType] = useRecoilState(cardsTypeAtom)
  const [rodinCardsType, setRodinCardsType] = useRecoilState(rodinCardsTypeAtom);
  const [panoramaCardsType, setPanoramaCardsType] = useRecoilState(panoramaCardsTypeAtom);
  const [showCardsCon, setShowCardsCon] = useRecoilState(showCardsConAtom);
  const [clickHide, setClickHide] = useRecoilState(clickHideAtom);

  const handleCloseSearch = (ev) => {
    setShowSearch(false)
    setCardsType(cardsTypeConst.Recent)
  }


  return <div className={`${style.menuCon} ${showCardsCon ? style.hideMenuCon : ''} ${clickHide ? style.conHide : ''}`}>
    {showSearch && (
      <div
        onPointerDown={(ev) => {
          setCardsType(cardsTypeConst.Search);
          setLoadedImages(0);
        }}
        className={`${style.menu} ${cardsType === cardsTypeConst.Search ? style.selected : ''
          }`}>
        {cardsTypeConst.Search}
        <div className={style.close} onPointerDown={handleCloseSearch}>
          ×
        </div>
      </div>
    )}

    {logInfo && (
      <div
        onPointerDown={(ev) => {
          setCardsType(cardsTypeConst.Mine)
          setLoadedImages(0);
          _czc.push(['_trackEvent', 'Index', 'Mine Tab', 'Gallery Component']);
          _czc.push(["_trackPageview", "/tab/mine", window.location.href]);
        }}
        className={`${style.menu} ${cardsType === cardsTypeConst.Mine ? style.selected : ''
          }`}>
        {cardsTypeConst.Mine}
      </div>
    )}

    {menuType === "chatAvatar" && ["Featured", "Recent",].map((item, index) => {
      return <div
        key={index}
        onPointerDown={(ev) => {
          setCardsType(cardsTypeConst[item])
          setLoadedImages(0);
          _czc.push(['_trackEvent', 'Index', `${item} Tab`, 'Gallery Component']);
          _czc.push(["_trackPageview", `/tab/${item.toLocaleLowerCase()}`, window.location.href]);
        }}
        className={`${style.menu} ${cardsType === cardsTypeConst[item] ? style.selected : ""
          }`}
      >
        {cardsTypeConst[item]}
      </div>
    })}
    {menuType === "Rodin" && ["Sketchfab", "Free3D", "BlendSwap"].map((item, index) => {
      return <div
        key={index}
        onPointerDown={(ev) => {
          setRodinCardsType(item);
        }}
        className={`${style.menu} ${rodinCardsType === item ? style.selected : ""
          }`}
      >
        {item}
      </div>
    })}
    {menuType === "panorama" && <div className={style.panoramaWrapper}>
      <div className={`${style.RouletteOut} ${rouletteOutHover ? style.menuShow : ''}`}
        onMouseEnter={() => setRouletteOutHover(true)}
        onMouseLeave={() => setRouletteOutHover(false)}>
        <div style={{
          backgroundColor: rouletteOutHover ? '#ccc6c6' : 'rgba(255,255,255,0.6)'
        }} className={`${style.RouletteIn} ${rouletteOutHover ? style.menuShow : ''}`}></div>
        {rouletteOutHover &&
          <div className={style.circleContainer}>
            {['Mine', 'Featured', 'Recent'].map((item, index) => <div
              key={index}
              className={style.sectorBox}
              onClick={() => { setPanoramaCardsType(item); setShowCardsCon(true) }}>
              <span
                style={index === 1 ? {
                  bottom: '60%',
                  left: '10%',
                } : {
                  left: "20%",
                  top: "30%",
                }}>{item}</span>
            </div>)}
          </div>
        }
      </div>
    </div>
    }
  </div >
}

export { Menu }