import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { clickHideAtom, showPanoramaBordAtom } from '../../../store'
import { Canvas, useFrame, extend, useThree } from "@react-three/fiber";
import { useRecoilState } from "recoil";

extend({ OrbitControls });

const Panorama = ({ autoRotate }) => {
  const ref = useRef();
  const { camera, gl } = useThree();
  const [isClicking, setIsClicking] = useState(false);
  const [autorotateTimeout, setAutorotateTimeout] = useState(null);

  useFrame(() => {
    ref.current.update();
  });

  useEffect(() => {
    ref.current.autoRotate = autoRotate;
    const startLisenter = () => {
      if (autorotateTimeout) clearTimeout(autorotateTimeout)
      ref.current.autoRotate = false;
    }
    const endLisenter = () => {
      setAutorotateTimeout(setTimeout(function () {
        ref.current.autoRotate = true;
      }, 1000))
    }
    ref.current.addEventListener('start', startLisenter);
    ref.current.addEventListener('end', endLisenter);
  }, [])

  return (
    <orbitControls
      ref={ref}
      args={[camera, gl.domElement]}
      enableDamping
      dampingFactor={0.1}
      rotateSpeed={-0.3}
      autoRotateSpeed={0.4}
      autoRotate={autoRotate}
    />

  );
};

const PanoramaViewer = ({ autoRotate, imgURL }) => {
  const [grabbing, setGrabbing] = useState(false)
  return (
    <div
      style={{ width: "100vw", height: "100vh", cursor: grabbing ? 'grabbing' : 'grab' }}
      onMouseDown={() => setGrabbing(true)}
      onMouseUp={() => setGrabbing(false)}
    >
      <Canvas camera={{ position: [0, 0, 0.1] }}>
        <Panorama autoRotate={autoRotate} />
        <mesh>
          <sphereGeometry args={[500, 60, 40]} attach="geometry" />
          <meshBasicMaterial
            attach="material"
            map={new THREE.TextureLoader().load(imgURL)}
            side={THREE.BackSide}
          />
        </mesh>
      </Canvas>
    </div>
  );
}

export default PanoramaViewer;
