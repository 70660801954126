import { useState, useEffect, useRef, useCallback } from 'react';

//Debounce
export const useDebounce = (callback, delay = 300) => {
  const timeoutRef = useRef();
  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const debouncedCallback = (...args) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }

  return debouncedCallback;
};

//Throttle
export const useThrottle = (callback, delay = 300) => {
  const timeoutRef = useRef();
  const isWaitingRef = useRef(false);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const throttledCallback = (...args) => {
    if (!isWaitingRef.current) {
      callback(...args);
      isWaitingRef.current = true;

      timeoutRef.current = setTimeout(() => {
        isWaitingRef.current = false;
      }, delay);
    }
  }

  return throttledCallback;
}



