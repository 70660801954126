import style from './imageOverlay.module.css'
import React, { useState, useEffect } from 'react';

const ImageOverlay = ({ style: customStyle, src, alt, type }) => {

  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return <>
    {type === 'spinner' && <div style={customStyle || {
      position: 'relative',
      width: '100%',
      height: '100%',
    }}>
      {loading && (
        <div className={style.overlayBox}>
          <div className={style.spinner}></div>
        </div>
      )}

      <img
        src={src}
        alt={alt}
        style={{
          display: loading ? 'none' : 'block',
          width: '100%',
          height: '100%',
        }}
        onLoad={handleImageLoad}
      />
    </div>}
    {type === 'threeRotate' && <div style={customStyle || {
      position: 'relative',
      width: '100%',
      height: '100%',
    }} >
      {loading && (
        <div className={style.overlayBox}>
          <div className={style.loader}>
            <svg viewBox="0 0 80 80">
              <rect x="8" y="8" width="64" height="64"></rect>
            </svg>
          </div>
        </div>
      )}

      <img
        src={src}
        alt={alt}
        style={{
          display: loading ? 'none' : 'block',
          width: '100%',
          height: '100%',
          borderRadius: '8px',
        }}
        onLoad={handleImageLoad}
      />
    </div>}
    {!type && <div style={customStyle || {
      position: 'relative',
      width: '100%',
      height: '100%',
    }} >
      {loading && (
        <div className={style.overlayBox}>
          <div className={style.skeleton}>
          </div>
        </div>
      )}

      <img
        src={src}
        alt={alt}
        style={{
          display: loading ? 'none' : 'block',
          width: '100%',
          height: '100%',
          borderRadius: '8px',
        }}
        onLoad={handleImageLoad}
      />
    </div>}

  </>
};


export { ImageOverlay }