/* global _czc */
import style from './panoramaboard.module.css'
import { useState, useEffect } from 'react';
import ShareRender from "../SharePoster";
import { AiFillHeart, AiFillCheckCircle } from "react-icons/ai";
import { useTips } from "../../GlobalTips";
import { clickHideAtom, isEditorOpenAtom, bodyOpacityTransitionAtom } from '../../../store'
import { HiFolderArrowDown } from "react-icons/hi2";
import { FaLink } from "react-icons/fa";
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
const PanoramaBoard = () => {

  const [isLike, setIsLike] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [clickHide, setClickHide] = useRecoilState(clickHideAtom);
  const [isEditorOpen, setIsEditorOpen] = useRecoilState(isEditorOpenAtom);
  const setBodyOpacityTransition = useSetRecoilState(bodyOpacityTransitionAtom);
  const tip = useTips();
  const location = useLocation()

  useEffect(() => {
    if (isShared) {
      const asyncCopy = async () => {
        const url = `${window.location.origin}${location.pathname}`
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(url);
        } else {
          const textarea = document.createElement("textarea");
          document.body.appendChild(textarea);
          textarea.style.position = "fixed";
          textarea.zIndex = -999;
          textarea.style.top = "10px";
          textarea.value = url;
          textarea.select();
          document.execCommand("copy", true);
          document.body.removeChild(textarea);
        }
      }
      asyncCopy()
    }
  }, [isShared])

  const handleShare = (ev) => {
    setIsShared(true)
    setTimeout(() => {
      setIsShared(false)
    }, 1500);
  };


  const handlerRegenerate = () => {
    setBodyOpacityTransition(true);
    setTimeout(() => {
      setIsEditorOpen(!isEditorOpen);
    }, 300);
  }

  useEffect(() => {
    if (!isDownload) return
    const timer = setInterval(() => {
      // 模拟进度增长
      setDownloadProgress((prevProgress) => {
        if (prevProgress === 100) {
          clearInterval(timer);
          return prevProgress;
        }
        return prevProgress + 1;
      });
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, [isDownload]);

  return <><div className={`${style.PanoramaBoardWrapper} ${clickHide ? style.clickHide : style.clickShow}`}>
    <div className={style.iconWrapper}>
      <div className={style.regenerateBtn} onClick={handlerRegenerate}>Regenerate</div>
      <div className={style.remixBtn}>Remix This</div>
      <div className={style.addBtn}>Add</div>
      <div className={style.spaceholder}></div>

      <div
        className={`${style.shareCon} ${isShared ? '' : style.canHover}`}
        style={{
          background: isShared ? 'rgba(0, 255, 0, 0.15)' : '#F8F8F8',
        }}
        onClick={handleShare}>
        {isShared ?
          <AiFillCheckCircle style={{ color: "rgb(9, 185, 9)", fontSize: "12px" }} />
          :
          <FaLink style={{ fontSize: "10px", color: "#d5d5d5" }} />}
        <span>&nbsp;{isShared ? 'Copied' : 'Share'}</span>
      </div>
      <div
        className={`${style.iconBtn} ${isDownload ? '' : style.canHover}`}
        onClick={() => { setIsDownload(true) }}>
        <div
          className={style.downloadProgress}
          style={{
            height: isDownload ? `${downloadProgress}%` : `0`,
            borderRadius: downloadProgress < 100 ? "0 0 8px 8px" : '8px',
          }}></div>
        <HiFolderArrowDown
          style={{ color: isDownload ? 'rgb(70, 0, 217)' : "#d5d5d5", fontSize: "16px", zIndex: 2 }}
        />
      </div>
      <div
        className={`${style.iconBtn} ${isLike ? '' : style.canHover}`}
        onClick={() => { setIsLike(!isLike) }}
        style={{
          backgroundColor: isLike ? `rgba(250, 45, 83, 0.15)` : '#F8F8F8',
        }}>
        <AiFillHeart
          style={{
            color: isLike ? "#fa2d53" : "rgb(206, 206, 206)",
            fontSize: "16px",
            transition: 'all 0.2s ease'
          }}
        />
      </div>
    </div>
    <div className={style.info} >
      its torrent dashes down three thousand feet from high ; as if the silver river fell from azure sky,cinematic landscape, on a snowy day, natural light, ink painting, traditional......
    </div>
  </div>

    <div className={`${style.clickBtn} ${clickHide ? style.clickbtnShow : style.clickbtnHide}`}
      onClick={(e) => {
        e.stopPropagation();
        setClickHide(false)
      }}>
      <div><span className={style.arrow}></span> <span>Click</span></div>
    </div></>

}

export { PanoramaBoard }