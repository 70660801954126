/* global _czc */

import style from './header.module.css'
import logo from '../../assets/deemos.png'
import { useLocation } from 'react-router-dom'
import walletIcon from '../../assets/wallet.png'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { logInfoAtom, showLoginAtom, showUserAtom, isImgto3dAtom, modalObjAtom, clickHideAtom } from '../../store'
import { AiOutlineUser } from 'react-icons/ai'
import { useState } from 'react'
import { UserPopup } from './Popup'
import React, { useRef, useEffect } from 'react'
import RechargePopup from './RechargePopup'

function Header() {
	const logInfo = useRecoilValue(logInfoAtom)
	const setShowLogin = useSetRecoilState(showLoginAtom)
	const [showUser, setShowUser] = useState(false)
	const userPopupRef = useRef(null)
	const rechargePopupRef = useRef(null)
	const [showRechargePopup, setShowRechargePopup] = useState(false)
	const balanceRef = useRef(null)
	const location = useLocation()
	const isImgto3d = useRecoilValue(isImgto3dAtom)
	const [modalObj, setModalObj] = useRecoilState(modalObjAtom);
	const [clickHide, setClickHide] = useRecoilState(clickHideAtom);

	const handleAvatarClick = (ev) => {
		if (logInfo) {
			setShowUser((prevState) => !prevState)
			_czc.push(["_trackPageview", "/header/panel", window.location.href]);
		}
		else {
			setShowLogin(true)
			_czc.push(["_trackPageview", "/header/login", window.location.href]);
		}
	}
	const handleBalanceClick = (ev) => {
		if (logInfo) {
			setShowRechargePopup(true)
		}
	}

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (userPopupRef.current && !userPopupRef.current.contains(event.target)) {
				setShowUser(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (rechargePopupRef.current && !rechargePopupRef.current.contains(event.target)) {
				setShowRechargePopup(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])
	useEffect(() => {
		if (location.pathname.includes('/imageto3d')) {
			setModalObj({
				type: 'imgto3d',
			})
			document.documentElement.dataset.theme = 'light'
		} else if (location.pathname.includes('/panorama')) {
			document.documentElement.dataset.theme = 'panorama'
		} else {
			document.documentElement.dataset.theme = 'dark'
		}
	}, [isImgto3d])

	return (
		<header className={`${style.con} ${clickHide ? style.conHide : ''}`}>
			<div className={style.header}>
				<div className={style.logoCon}>
					<img className={style.logo} src={logo} alt='deemos logo' />
					<div>HYPERHUMAN</div>
				</div>
				<div className={style.spaceholder}></div>

				{logInfo ? (
					<div className={style.balance} onClick={
						handleBalanceClick
					} ref={balanceRef}
						onPointerDown={(ev) => {
							_czc.push(['_trackEvent', 'Index', 'Balance', 'Header Component']);
							_czc.push(["_trackPageview", "/header/balance", window.location.href]);
						}}>
						<img src={walletIcon} alt='wallet' />
						<div>{logInfo.balance}</div>
					</div>
				) : null}
				<div className={style.avatarContainer}>
					<RechargePopup
						ref={rechargePopupRef}
						className={`${showRechargePopup ? 'show' : ''} fade`}
					/>
				</div>
				<div className={style.avatarContainer} onClick={handleAvatarClick}
					onPointerDown={(ev) => {
						_czc.push(['_trackEvent', 'Index', 'User Avatar', 'Header Component']);
					}}
				>
					<div className={style.avatar}>
						{logInfo ? (
							<img
								src={logInfo.avatar_url}
								alt='avatar'
								onError={(e) => {
									e.target.onerror = null
									e.target.src = '../../assets/defaultAvatar.png'
								}}
								style={{
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									backgroundColor: 'transparent',
									backgroundImage: `url('../../assets/defaultAvatar.png')`,
								}}
							/>
						) : (
							<div>
								<AiOutlineUser size='1em' /> &nbsp; Login
							</div>
						)}
					</div>
					<UserPopup
						ref={userPopupRef}
						className={`${showUser ? 'show' : ''} fade`}
						userInfo={logInfo}
					/>
				</div>
			</div>

		</header>
	)
}

export { Header, logInfoAtom, showLoginAtom, showUserAtom }
