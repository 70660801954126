import { useRef, useState, useEffect, useCallback } from "react";
import * as THREE from "three";
import { extend } from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { saveAs } from "file-saver";

extend({ OrbitControls });

const Controls = ({ activeTool, onIntersect }) => {
  const ref = useRef();
  const { camera, gl, scene } = useThree();

  const [zoomFactor, setZoomFactor] = useState(1);

  useEffect(() => {
    const handleWheel = (event) => {
      let delta = Math.sign(event.deltaY);
      if (camera.fov < 10 && delta < 0) {
        delta = 0;
      } else if (camera.fov > 75 && delta > 0) {
        delta = 0;
      }
      camera.fov += delta;
      camera.updateProjectionMatrix();
    };
    window.addEventListener("wheel", handleWheel, { passive: true });
    return () => window.removeEventListener("wheel", handleWheel);
  }, []);

  useFrame(() => {
    ref.current.update();
  });

  useEffect(() => {
    if (ref.current) {
      ref.current.enableDamping = true;
      ref.current.dampingFactor = 0.09;
      ref.current.rotateSpeed = -0.3;
      ref.current.minDistance = 10;
      ref.current.maxDistance = 10;
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.enableRotate = activeTool === "pan";
      ref.current.enablePan = activeTool === "pan";
    }
  }, [activeTool]);

  return <orbitControls ref={ref} args={[camera, gl.domElement]} />;
};

const DrawBrush = ({
  activeTool,
  brushSize,
  bgMeshRef,
  brushes,
  setBrushes,
  action,
  setAction,
}) => {
  const { camera, scene, gl, raycaster } = useThree();
  const mouse = new THREE.Vector2();
  const [drawing, setDrawing] = useState(false);
  const [intersects, setIntersects] = useState([]);
  const [lastPosition, setLastPosition] = useState(null);
  const previewRef = useRef();
  const BRUSH_COLOR = 0xdcfe34;
  const [brushesHistory, setBrushesHistory] = useState([]);
  const currentHistoryIndex = useRef(0);
  const [currentStroke, setCurrentStroke] = useState([]);
  const [isMouseOverCanvas, setIsMouseOverCanvas] = useState(false);
  const [mouseSpeed, setMouseSpeed] = useState(0);

  const onMouseMove = useCallback((event) => {
    event.preventDefault();

    const [x, y] =
      event.targetTouches && event.targetTouches[0]
        ? [event.targetTouches[0].clientX, event.targetTouches[0].clientY]
        : [event.clientX, event.clientY];
    const canvasBounds = event.target.getBoundingClientRect();
    mouse.x = ((x - canvasBounds.left) / canvasBounds.width) * 2 - 1;
    mouse.y = -((y - canvasBounds.top) / canvasBounds.height) * 2 + 1;

    const elementUnderMouse = document.elementFromPoint(x, y);
    const isCanvas = elementUnderMouse === gl.domElement;
    setIsMouseOverCanvas(isCanvas);

    raycaster.setFromCamera(mouse, camera);
    setIntersects(raycaster.intersectObject(bgMeshRef.current, true));

    if (event.movementX && event.movementY) {
      const speed = Math.sqrt(event.movementX ** 2 + event.movementY ** 2);
      setMouseSpeed(speed);
    }
  }, []);

  const onMouseDown = useCallback((event) => {
    if (event.button === 0) setDrawing(true);
  }, []);

  const onMouseUp = useCallback(
    (event) => {
      if (drawing && currentStroke.length > 0) {
        setBrushes((prevBrushes) => [
          ...prevBrushes,
          { action: activeTool, objects: currentStroke },
        ]);
        setCurrentStroke([]);
      }
      if (event.button === 0) setDrawing(false);
    },
    [currentStroke, drawing, setBrushes, activeTool]
  );

  useEffect(() => {
    if (!isMouseOverCanvas) return;

    if (intersects.length > 0) {
      const point = intersects[0].point;
      const normal = intersects[0].face.normal;

      if (previewRef.current) {
        previewRef.current.visible =
          activeTool === "brush" || activeTool === "eraser";
        previewRef.current.position.copy(point);
        previewRef.current.position.add(
          normal.multiplyScalar(brushSize).multiplyScalar(3)
        );
      }

      if (activeTool === "brush" && drawing) {
        if (currentHistoryIndex.current !== 0) {
          setBrushes((prev) => prev.slice(0, currentHistoryIndex.current));
          currentHistoryIndex.current = 0;
        }

        const currentPosition = new THREE.Vector3().copy(point);

        if (lastPosition) {
          const distance = lastPosition.distanceTo(currentPosition);
          const intermediatePoints = Math.ceil(distance / (brushSize / 2));
          const DISTANCE_THRESHOLD = 210;

          if (distance > DISTANCE_THRESHOLD) {
            setLastPosition(currentPosition);
            return;
          }

          for (let i = 0; i < intermediatePoints; i++) {
            const t = i / intermediatePoints;
            const lastPositionNormalized = new THREE.Vector3()
              .copy(lastPosition)
              .normalize()
              .multiplyScalar(600);
            const currentPositionNormalized = new THREE.Vector3()
              .copy(currentPosition)
              .normalize()
              .multiplyScalar(600);
            const intermediatePosition = new THREE.Vector3().lerpVectors(
              lastPositionNormalized,
              currentPositionNormalized,
              t
            );
            const brushGeometry = new THREE.SphereGeometry(brushSize);
            const brushMaterial = new THREE.MeshBasicMaterial({
              color: BRUSH_COLOR,
              transparent: false,
              opacity: 0.9,
            });
            const brush = new THREE.Mesh(brushGeometry, brushMaterial);
            brush.position.copy(intermediatePosition);
            scene.add(brush);
            setCurrentStroke((prevStroke) => [...prevStroke, brush]);
          }
        }
        setLastPosition(currentPosition);
      } else if (activeTool === "eraser" && drawing) {
        if (currentHistoryIndex.current !== 0) {
          setBrushes((prev) => prev.slice(0, currentHistoryIndex.current));
          currentHistoryIndex.current = 0;
        }

        const eraserRadius = brushSize;
        const currentPosition = new THREE.Vector3().copy(intersects[0].point);

        if (lastPosition) {
          const distance = lastPosition.distanceTo(currentPosition);
          const intermediatePoints = Math.ceil(distance / (brushSize / 2));
          for (let i = 0; i < intermediatePoints; i++) {
            const t = i / intermediatePoints;
            const lastPositionNormalized = new THREE.Vector3()
              .copy(lastPosition)
              .normalize()
              .multiplyScalar(600);
            const currentPositionNormalized = new THREE.Vector3()
              .copy(currentPosition)
              .normalize()
              .multiplyScalar(600);
            const intermediatePosition = new THREE.Vector3().lerpVectors(
              lastPositionNormalized,
              currentPositionNormalized,
              t
            );
            const objectsToRemove = [];

            scene.traverse((object) => {
              if (
                object.type === "Mesh" &&
                object.geometry.type === "SphereGeometry"
              ) {
                const distance =
                  object.position.distanceTo(intermediatePosition);
                if (distance < eraserRadius) {
                  objectsToRemove.push(object);
                }
              }
            });
            if (objectsToRemove.length > 0) {
              objectsToRemove.forEach((object) => {
                scene.remove(object);
                setCurrentStroke((prevStroke) => [
                  ...prevStroke,
                  ...objectsToRemove,
                ]);
              });
            }
          }
        }
        setLastPosition(currentPosition);
      } else {
        setLastPosition(null);
      }
    }
  }, [intersects, activeTool, drawing, brushSize, mouseSpeed]);

  useEffect(() => {
    if (action === "clear") {
      const objectsToRemove = [];

      scene.traverse((object) => {
        if (
          object.type === "Mesh" &&
          object.geometry.type === "SphereGeometry" &&
          object.material.color.getHex() === BRUSH_COLOR
        ) {
          objectsToRemove.push(object);
        }
      });

      objectsToRemove.forEach((object) => {
        scene.remove(object);
      });

      setBrushes((prevBrushes) => [
        ...prevBrushes,
        { action: "clear", objects: objectsToRemove },
      ]);
    } else if (action === "undo") {
      let index = brushes.length - 1 - currentHistoryIndex.current;
      if (brushes[index].action === "brush") {
        brushes[index].objects.forEach((object) => {
          scene.remove(object);
        });
      } else if (brushes[index].action === "eraser") {
        brushes[index].objects.forEach((object) => {
          scene.add(object);
        });
      } else if (brushes[index].action === "clear") {
        brushes[index].objects.forEach((object) => {
          scene.add(object);
        });
      }
      if (currentHistoryIndex.current === brushes.length - 1) {
        setAction("");
        return;
      }
      currentHistoryIndex.current = currentHistoryIndex.current + 1;
    } else if (action === "redo") {
      let index = brushes.length - 1 - currentHistoryIndex.current;
      if (brushes[index].action === "brush") {
        brushes[index].objects.forEach((object) => {
          scene.add(object);
        });
      } else if (brushes[index].action === "eraser") {
        brushes[index].objects.forEach((object) => {
          scene.remove(object);
        });
      } else if (brushes[index].action === "clear") {
        brushes[index].objects.forEach((object) => {
          scene.remove(object);
        });
      }

      if (currentHistoryIndex.current === 0) {
        setAction("");
        return;
      }
      currentHistoryIndex.current = currentHistoryIndex.current - 1;
    } else if (action === "export") {
      exportDrawing();
    }
    setAction("");
  }, [action]);

  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("touchmove", onMouseMove);
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("touchmove", onMouseMove);
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [onMouseMove, onMouseDown, onMouseUp]);

  /* 导出用户绘制内容为图像
  Workflow: 
  -> Set action as "export"
  -> Wait for useEffect to trigger
  -> Render 6 images
  -> Stitch images together
  -> Trigger upload and progress checking
  -> Once done navigating to the result page
  nmd为什么拼不起来 */

  const exportDrawing = async () => {
    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = 1024;
    offscreenCanvas.height = 1024;
    const offscreenRenderer = new THREE.WebGLRenderer({
      canvas: offscreenCanvas,
    });

    const camera = new THREE.PerspectiveCamera(
      90,
      offscreenCanvas.width / offscreenCanvas.height,
      0.1,
      1000
    );
    camera.position.z = 5;
    const rotations = [
      { name: "front", x: 0, y: 0, z: 0 }, // front
      { name: "back", x: 0, y: Math.PI, z: 0 }, // back
      { name: "left", x: 0, y: Math.PI / 2, z: 0 }, // left
      { name: "right", x: 0, y: -(Math.PI / 2), z: 0 }, // right
      { name: "top", x: Math.PI / 2, y: 0, z: 0 }, // top
      { name: "bottom", x: -(Math.PI / 2), y: 0, z: 0 }, // bottom
    ];
    const filenames = ["front", "back", "left", "right", "top", "bottom"];
    const objectsToRemove = [];

    const renderAndExportImage = (rotation, filename) => {
      return new Promise(async (resolve) => {
        camera.rotation.set(rotation.x, rotation.y, rotation.z);

        scene.traverse((object) => {
          if (
            !(
              object.type === "Mesh" &&
              object?.geometry?.type === "SphereGeometry"
            )
          ) {
            objectsToRemove.push(object);
          }
        });

        if (objectsToRemove.length > 0) {
          objectsToRemove.forEach((object) => {
            scene.remove(object);
          });
        }

        offscreenRenderer.render(scene, camera);
        offscreenCanvas.toBlob((blob) => {
          saveAs(blob, `exported_${rotation.name}.jpg`);
          resolve();
        }, "image/jpeg");
      });
    };

    for (let i = 0; i < rotations.length; i++) {
      await renderAndExportImage(rotations[i], filenames[i]);
    }
  };

  return (
    <>
      <mesh ref={previewRef} visible={false}>
        {isMouseOverCanvas && (
          <>
            <sphereGeometry args={[brushSize, 35, 35]} />
            <meshBasicMaterial
              color={0xffffff}
              transparent
              opacity={0.5}
              depthWrite={false}
            />
          </>
        )}
      </mesh>
    </>
  );
};

const PanoramaEditor = ({
  backgroundVisible,
  activeTool,
  activeGrid,
  brushSize,
  activePlainGrid,
  currentAction,
  setCurrentAction,
}) => {
  const bgMeshRef = useRef();
  const [refImgPath, setRefImgPath] = useState("./panorama_default.jpg");
  const [brushes, setBrushes] = useState([]);

  const getLatitudeThickness = (lat) => {
    const maxThickness = 2;
    const minThickness = 0.3;
    const factor = Math.abs(Math.cos(lat));
    return minThickness + factor * (maxThickness - minThickness);
  };

  const createPlainGrid = (size, segments) => {
    const group = new THREE.Group();
    const material = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.1,
    });
    const lineThickness = 0.8;

    // TB plane lines
    for (let i = 0; i <= segments; ++i) {
      const line = new THREE.BoxGeometry(size, 1, lineThickness);
      line.translate(0, -115, (size / segments) * i - size / 2);
      const mesh = new THREE.Mesh(line, material);
      group.add(mesh);

      const line2 = new THREE.BoxGeometry(lineThickness, 1, size);
      line2.translate((size / segments) * i - size / 2, -115, 0);
      const mesh2 = new THREE.Mesh(line2, material);
      group.add(mesh2);

      const line3 = new THREE.BoxGeometry(size, 1, lineThickness);
      line3.translate(0, -115, (size / segments) * i - size / 2);
      const mesh3 = new THREE.Mesh(line3, material);
      group.add(mesh3);

      const line4 = new THREE.BoxGeometry(lineThickness, 1, size);
      line4.translate((size / segments) * i - size / 2, -115, 0);
      const mesh4 = new THREE.Mesh(line4, material);
      group.add(mesh4);
    }
    group.renderOrder = 2;

    return group;
  };

  const createSquareGrid = (size, segments) => {
    const group = new THREE.Group();
    const material = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.2,
    });
    const lineThickness = 0.8;
    let y_threshold = 0;
    let calc_y_threshold = -1000;
    let y_threshold_size = 0;
    if (activePlainGrid) {
      y_threshold = 165 - 82;
      y_threshold_size = 164;
      calc_y_threshold = -120;
    }

    // LR plane lines
    for (let i = 0; i <= segments; ++i) {
      if ((size / segments) * i - size / 2 > calc_y_threshold) {
        const line = new THREE.BoxGeometry(size, lineThickness, 1);
        line.translate(0, (size / segments) * i - size / 2, size / 2);
        const mesh = new THREE.Mesh(line, material);
        group.add(mesh);

        const line3 = new THREE.BoxGeometry(size, lineThickness, 1);
        line3.translate(0, (size / segments) * i - size / 2, -size / 2);
        const mesh3 = new THREE.Mesh(line3, material);
        group.add(mesh3);
      }

      const line2 = new THREE.BoxGeometry(
        lineThickness,
        size - y_threshold_size,
        1
      );
      line2.translate((size / segments) * i - size / 2, y_threshold, size / 2);
      const mesh2 = new THREE.Mesh(line2, material);
      group.add(mesh2);

      const line4 = new THREE.BoxGeometry(
        lineThickness,
        size - y_threshold_size,
        1
      );
      line4.translate((size / segments) * i - size / 2, y_threshold, -size / 2);
      const mesh4 = new THREE.Mesh(line4, material);
      group.add(mesh4);
    }

    // TB plane lines
    for (let i = 0; i <= segments; ++i) {
      const line = new THREE.BoxGeometry(size, 1, lineThickness);
      line.translate(0, size / 2, (size / segments) * i - size / 2);
      const mesh = new THREE.Mesh(line, material);
      group.add(mesh);

      const line2 = new THREE.BoxGeometry(lineThickness, 1, size);
      line2.translate((size / segments) * i - size / 2, size / 2, 0);
      const mesh2 = new THREE.Mesh(line2, material);
      group.add(mesh2);

      if (-size / 2 > calc_y_threshold) {
        const line3 = new THREE.BoxGeometry(size, 1, lineThickness);
        line3.translate(0, -size / 2, (size / segments) * i - size / 2);
        const mesh3 = new THREE.Mesh(line3, material);
        group.add(mesh3);

        const line4 = new THREE.BoxGeometry(lineThickness, 1, size);
        line4.translate((size / segments) * i - size / 2, -size / 2, 0);
        const mesh4 = new THREE.Mesh(line4, material);
        group.add(mesh4);
      }
    }

    // FB plane lines
    for (let i = 0; i <= segments; ++i) {
      const line = new THREE.BoxGeometry(
        1,
        size - y_threshold_size,
        lineThickness
      );
      line.translate(size / 2, y_threshold, (size / segments) * i - size / 2);
      const mesh = new THREE.Mesh(line, material);
      group.add(mesh);

      const line3 = new THREE.BoxGeometry(
        1,
        size - y_threshold_size,
        lineThickness
      );
      line3.translate(-size / 2, y_threshold, (size / segments) * i - size / 2);
      const mesh3 = new THREE.Mesh(line3, material);
      group.add(mesh3);

      if ((size / segments) * i - size / 2 > calc_y_threshold) {
        const line2 = new THREE.BoxGeometry(1, lineThickness, size);
        line2.translate(size / 2, (size / segments) * i - size / 2, 0);
        const mesh2 = new THREE.Mesh(line2, material);
        group.add(mesh2);

        const line4 = new THREE.BoxGeometry(1, lineThickness, size);
        line4.translate(-size / 2, (size / segments) * i - size / 2, 0);
        const mesh4 = new THREE.Mesh(line4, material);
        group.add(mesh4);
      }
    }

    // Black translucent cube
    const cubeGeometry = new THREE.BoxGeometry(size + 10, size + 10, size + 10);
    const cubeMaterial = new THREE.MeshBasicMaterial({
      color: 0x000000,
      transparent: true,
      opacity: 0.5,
      side: THREE.DoubleSide,
      depthWrite: false,
    });
    const cube = new THREE.Mesh(cubeGeometry, cubeMaterial);
    group.add(cube);
    group.renderOrder = 1;
    return group;
  };

  const createSphericalGrid = (radius, segments) => {
    const group = new THREE.Group();
    const material = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.4,
      depthWrite: false,
    });
    let lat_threshold = -10;
    let lon_threshold = 1;
    if (activePlainGrid) {
      lat_threshold = -0.1;
      lon_threshold = 0.575;
    }

    // Lines of longitude
    for (let i = 0; i < segments; ++i) {
      const lineThickness = 2;
      const line = new THREE.CylinderGeometry(
        radius,
        radius,
        lineThickness,
        50,
        1,
        true,
        Math.PI,
        Math.PI * lon_threshold
      );
      line.translate(0, -lineThickness / 2, 0);
      line.rotateX(Math.PI / 2);
      line.rotateY((i * Math.PI) / (segments / 2));
      const mesh = new THREE.Mesh(line, material);
      group.add(mesh);
    }

    // Lines of latitude
    for (let i = 1; i < segments / 2; ++i) {
      const lat = ((i - segments / 4) * Math.PI) / (segments / 2);
      if (lat > lat_threshold) {
        let lineThickness = getLatitudeThickness(lat);
        const r = radius * Math.cos(lat);
        const y = radius * Math.sin(lat);
        const r1 = r - (lineThickness * Math.sin(lat)) / 2;
        const r2 = r + (lineThickness * Math.sin(lat)) / 2;
        const line = new THREE.CylinderGeometry(
          r1,
          r2,
          Math.cos(lat) * lineThickness,
          50,
          8,
          true
        );
        line.translate(0, (-Math.cos(lat) * lineThickness) / 2 + y, 0);
        const mesh = new THREE.Mesh(line, material);
        group.add(mesh);
      }
    }

    // Black translucent sphere
    const sphereGeometry = new THREE.SphereGeometry(
      radius + 10,
      segments,
      segments
    );
    const sphereMaterial = new THREE.MeshBasicMaterial({
      color: 0x000000,
      transparent: true,
      opacity: 0.2,
      side: THREE.DoubleSide,
      depthWrite: false,
    });
    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    group.add(sphere);

    group.renderOrder = 1;

    return group;
  };

  useEffect(() => {
    if (backgroundVisible) {
      setRefImgPath("./panorama_default.jpg");
    } else {
      setRefImgPath("");
    }
  }, [backgroundVisible]);

  return (
    <div style={{ width: "100vw", height: "100vh", position: "relative" }}>
      <div
        style={{ zIndex: 10000, position: "absolute", marginTop: "100px" }}
      ></div>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: `100vw`,
            height: `100vh`,
            transition: "width 0.6s ease, height 0.6s ease",
          }}
        >
          <Canvas camera={{ position: [0, 0, 5], fov: 75 }}>
            <Controls activeTool={activeTool} />
            <mesh ref={bgMeshRef}>
              <sphereGeometry args={[500, 60, 40]} attach="geometry" />
              <meshBasicMaterial
                attach="material"
                map={new THREE.TextureLoader().load(refImgPath)}
                side={THREE.BackSide}
                depthWrite={false}
              />
            </mesh>
            {activeGrid === "sphere" && (
              <primitive object={createSphericalGrid(550, 20)} />
            )}
            {activeGrid === "cube" && (
              <primitive object={createSquareGrid(550, 20)} />
            )}
            {activePlainGrid && (
              <primitive object={createPlainGrid(2500, 40)} />
            )}
            <DrawBrush
              activeTool={activeTool}
              brushSize={brushSize}
              bgMeshRef={bgMeshRef}
              brushes={brushes}
              setBrushes={setBrushes}
              action={currentAction}
              setAction={setCurrentAction}
            />
          </Canvas>
        </div>
      </div>
    </div>
  );
};

export default PanoramaEditor;
