/* global _czc */
import style from "./imageTo3D.module.css";
import { useEffect, useRef, useState, useContext } from "react";
import { CardClickContext } from "../Context";
import { useParams, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { BsCloudArrowUpFill } from "react-icons/bs";
import {
  taskInitAtom,
  transitioningAtom,
  modalObjAtom,
  taskDetailAtom,
  isOpenImg3dGenerateAtom,
  imgUrlsArrAtom,
  showLoginAtom,
  isShowModalAtom,
  cardsAtom,
  isImgto3dAtom,
  taskUUidAtom,
  chatLangAtom
} from "../../store";
import { ImgButton } from "../widgets/ImgButton";
import { Gallery } from "../Gallery/ChatAvatar";
import { Img3dGenerateBoard } from "../Board/Img3dGenerateBoard";
import img3dBeforeCircle from "../../assets/img3dBeforeCircle.png";
import img3dAfterCircle from "../../assets/img3dAfterCircle.png";
import deletDefault from "../../assets/deletDefault.png";
import deletHover from "../../assets/deletHover.png";
import imgto3dPhoto from "../../assets/imgto3dPhoto.webp";
import {
  imaginefaceSubmit,
  imaginefaceViewSelection,
  getCards,
  imaginefacePreview
} from "../../utils/net";
import { useTips } from "../GlobalTips";

const ImageTo3D = ({ scrollPercentage }) => {
  const [transitioning, setTransitioning] = useRecoilState(transitioningAtom);
  const [taskUUid, setTaskUUid] = useRecoilState(taskUUidAtom);
  const fileInputRef = useRef(undefined);
  const imgWrapperRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [currentImgHover, setCurrentImgHover] = useState(-1);
  const [isDeletHover, setIsDeletHover] = useState(false);
  const [images, setImages] = useState([]);
  const [uploadImgBlob, setUploadImgBlob] = useState([]);
  const [showImgBox, setShowImgBox] = useState(false);
  const [fileDropHover, setFileDropHover] = useState(false);
  const [isOpenImg3dGenerate, setIsOpenImg3dGenerate] = useRecoilState(
    isOpenImg3dGenerateAtom
  );
  const [chatLang, setChatLang] = useRecoilState(chatLangAtom)
  const [cards, setCards] = useRecoilState(cardsAtom)
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [imgUrlsArr, setImgUrlsArr] = useRecoilState(imgUrlsArrAtom);
  const [showLogin, setShowLogin] = useRecoilState(showLoginAtom);
  const [modalObj, setModalObj] = useRecoilState(modalObjAtom);
  const [renderBeforeimg, setRenderBeforeimg] = useState(null)
  const [renderAfterimg, setRenderAfterimg] = useState(null)
  const [showBeforeRender, setShowBeforeRender] = useState(false)
  const [showAfterCircle, setShowAfterCircle] = useState(false)
  const [showAfterRender, setShowAfterRender] = useState(false)
  const [showBeforeCircle, setShowBeforeCircle] = useState(false)
  const [showImgto3dPhoto, setShowImgto3dPhoto] = useState(false)
  const [loadedArr, setLoadedArr] = useState([])
  const tip = useTips();
  const handleCardClick = useContext(CardClickContext);
  const params = useParams();

  useEffect(() => {
    if (!isOpenImg3dGenerate) {
      setShowImgBox(false);
      setIsDeletHover(false);
      setImages([]);
      setUploadImgBlob([]);
    }
  }, [isOpenImg3dGenerate]);

  useEffect(() => {
    setModalObj({
      type: "imgto3d",
    });
    if (params.taskUUid) {
      handleCardClick(params.taskUUid);
    }

    const getRenderBg = async () => {
      let randomIndex, randomUUid, renderingImg
      let retryCount = 0
      const randomGenerate = async () => {
        if (cards.length) {
          randomIndex = Math.floor(Math.random() * cards.length)
          randomUUid = cards[randomIndex].task_uuid
        } else {
          const data = await getCards({ type: 'Mine', page_num: 0, task_type: "ImagineFace" })
          randomIndex = Math.floor(Math.random() * data.data.length)
          randomUUid = data.data[randomIndex].task_uuid
        }
        renderingImg = await imaginefacePreview(randomUUid)
        if (renderingImg.data?.files?.[0]?.face && renderingImg.data?.files?.[0]?.render) {
          setRenderBeforeimg(renderingImg.data.files[0].face)
          setRenderAfterimg(renderingImg.data.files[0].render)
        } else {
          retryCount++
          if (retryCount < 3) { // 设置最大重试次数为3次
            await randomGenerate()
          } else {
            console.log('Reached max retry count, exiting recursion')
          }
        }
      }
      await randomGenerate()
    }
    getRenderBg()
  }, []);

  const handleFileInputChange = (e) => {
    _czc.push([
      "_trackEvent",
      "Rodin - Index",
      "File Drop",
      "Preview Component",
    ]);
    const fileList = [...e.target.files];
    handlerFiles(fileList);
  };

  const handleUploadIconClick = () => {
    fileInputRef.current.value = '';
    fileInputRef.current.click();
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    setFileDropHover(false);
    _czc.push([
      "_trackEvent",
      "Rodin - Index",
      "File Drop",
      "Preview Component",
    ]);
    const fileList = [...event.dataTransfer.files];
    handlerFiles(fileList);
  };

  const handlerFiles = (fileList) => {
    let oversizedFiles = fileList.some((file) => file.size > 10 * 1024 * 1024);
    if (oversizedFiles) {
      tip({
        type: "error",
        content: "File size should be less than 5MB",
      });
      return;
    }

    const newImages = fileList.filter((file) => file.type.startsWith("image/"));
    if (uploadImgBlob.length + newImages.length > 5) {
      tip({
        type: "error",
        content: "Upload up to five images",
      });
      return;
    }

    setUploadImgBlob((prev) => [...prev, ...newImages]);
    if (newImages.length) {
      Promise.all(
        newImages.map(
          (file) =>
            new Promise((resolve) => {
              const reader = new FileReader();
              reader.onload = () =>
                resolve({ fileName: file.name, data: reader.result });
              reader.readAsDataURL(file);
            })
        )
      ).then((data) => {
        setShowImgBox(true);
        setIsHover(false);
        setImages((prev) => [...prev, ...data]);
      });
    }
  };


  const handleUpload = async (file) => {
    const formData = new FormData();
    uploadImgBlob.forEach((file) => {
      if (file.size <= 10 * 1024 * 1024) {
        formData.append("images", file);
      } else {
        tip({
          type: "error",
          content: "File size exceeds 5MB limit.",
        });
        return;
      }
    });
    // for (let pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
    if (localStorage.getItem("user_uuid") === null) {
      setShowLogin(true);
      return;
    }
    setIsUploading(true);
    setUploadPercentage(0);
    try {
      const res = await imaginefaceSubmit(formData, (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        setUploadPercentage(percent);
      });

      if (res.data?.error === "USER_NOT_FOUND") {
        setIsUploading(false);
        setShowLogin(true);
        return;
      }
      const taskUUid = res.data.message;
      const intervalId = setInterval(async () => {
        const response = await imaginefaceViewSelection(taskUUid);
        if (response.data?.error === "TASK_INCOMPLETE") {
        } else if (response.data?.urls.length === 0) {
          clearInterval(intervalId);
          setIsUploading(false);
          tip({
            type: "error",
            content:
              "Detection unsuccessful. Please ensure a distinct and clear face is visible.",
          });
          return;
        } else if (response.data?.urls?.length !== uploadImgBlob.length) {
          tip({
            type: "error",
            content: "Detection failed for one or more of the images",
          });
        }

        if (response.data.urls && response.data.urls.length) {
          clearInterval(intervalId);
          setImgUrlsArr(response.data.urls);
          setIsUploading(false);
          setIsOpenImg3dGenerate(true);
          setTaskUUid(taskUUid)
        }
      }, 2000);
    } catch (err) {
      console.log(err);
      setIsUploading(false);
    }
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handlerDeletImg = (index, e) => {
    e.stopPropagation();
    images.splice(index, 1);
    uploadImgBlob.splice(index, 1);
    setImages([...images]);
    setUploadImgBlob([...uploadImgBlob]);
    if (images.length === 0) {
      setShowImgBox(false);
      setIsDeletHover(false);
    }
  };

  const handlerLoaded = (index) => {
    const newLoadedArr = loadedArr.slice()
    newLoadedArr[index] = true
    setLoadedArr(newLoadedArr)
  }

  useEffect(() => {
    if (loadedArr.length === 2) {
      setShowImgto3dPhoto(true)
      setTimeout(() => {
        setShowBeforeRender(true)
      }, 700);
      setTimeout(() => {
        setShowBeforeCircle(true)
      }, 400);
      setTimeout(() => {
        setShowAfterCircle(true)
      }, 1100);
      setTimeout(() => {
        setShowAfterRender(true)
      }, 1600);
    }
  }, [loadedArr])

  const handlerDeleteAll = (e) => {
    e.stopPropagation()
    setImages([]);
    setUploadImgBlob([]);
    setShowImgBox(false);
  }

  return (
    <div className={style.imag3dWrapper}>
      {/* <div className={style.img3dBg}>
      <img src={imgto3dBg} alt="bg img3d" />
    </div> */}
      <ImgButton></ImgButton>
      <div
        className={`${transitioning ? style.transitioning : ""} ${style.titleBox
          }`}
      >
        <div className={style.beforeBox}>
          <img
            src={img3dBeforeCircle}
            alt="img3dBeforeCircle"
            className={`${style.img3dBeforeCircle} ${showBeforeCircle ? style.showBeforeCircle : style.hideBeforeCircle}`}
          />
          <img
            src={imgto3dPhoto}
            alt="imgto3dPhoto"
            className={`${style.imgto3dPhoto} ${showImgto3dPhoto ? style.showImgto3dPhoto : style.hideImgto3dPhoto}`}
          />
        </div>
        <div className={style.title}>ChatAvatar</div>
        <div className={style.afterBox}>
          <div className={`${style.renderBeforeimg} ${showBeforeRender ? style.showBeforeBox : style.hideBeforeBox}`}>
            <img
              src={renderBeforeimg}
              alt="renderBeforeimg"
              style={{
                display: showBeforeRender ? 'block' : 'none'
              }}
              onLoad={handlerLoaded.bind(null, 0)} />
          </div>
          <img
            src={img3dAfterCircle}
            alt="img3dAfterCircle"
            className={`${style.img3dAfterCircle} ${showAfterCircle ? style.showAfterCircle : style.hideAfterCircle}`}
          />
          <div className={`${style.renderAfterimg} ${showAfterRender ? style.showAfterBox : style.hideAfterBox}`}>
            <img
              src={renderAfterimg}
              alt="renderBeforeimg"
              style={{
                display: showAfterRender ? 'block' : 'none'
              }}
              onLoad={handlerLoaded.bind(null, 1)} />
          </div>
        </div>
      </div>
      <div
        className={style.titleMsg}
        style={{
          transform: `translateY(${scrollPercentage * -200}px)`,
          opacity: 1 - scrollPercentage * 4,
        }}
      >
        <div className={style.descript}>
          Progressive Generation Of Animatable 3D Faces
          <br></br> <span className={style.descriptBg}>From Image</span>
        </div>
      </div>
      <input
        multiple
        type="file"
        key={Math.random}
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileInputChange}
        accept=".jpg, .jpeg, .png, .obj, .fbx, .gltf, .glb, .dae, .ply, .stl, .heic"
      />
      <div
        className={`${style.uploadWrapper} ${fileDropHover ? style.uploadHover : ""
          }`}
        style={{
          marginTop: `${Math.max(4 - scrollPercentage * 15, -1)}rem`,
        }}
        onDragOver={(event) => {
          event.preventDefault();
          setFileDropHover(true);
          setIsDragging(true);
        }}
        onDragEnter={(event) => {
          event.preventDefault();
          setFileDropHover(true);
          setIsDragging(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setFileDropHover(false);
          setIsDragging(false);
        }}
      >
        {isUploading ? (
          <>
            <div className={style.progressText}>
              {uploadPercentage === 100 ? "Processing..." : "Uploading..."}
            </div>
            <div className={style.ProgressBar}>
              <div
                className={style.filler}
                style={{
                  width: `${uploadPercentage}%`,
                }}
              ></div>
            </div>
          </>
        ) : showImgBox ? (
          <div
            className={style.thumbnailContainer}
            onDrop={handleFileDrop}
            onClick={handleUploadIconClick}
          >
            <div className={style.thumbnailTips}>
              Drop more images here to continue upload
            </div>
            <div className={style.imgWrapper} ref={imgWrapperRef}>
              {images.map((image, index) => (
                <div
                  key={index}
                  className={style.thumbnailItem}
                  onMouseEnter={() => {
                    setCurrentImgHover(index);
                  }}
                  onMouseLeave={() => {
                    setCurrentImgHover(-1);
                  }}
                >
                  <img src={image.data} alt="thumbnail" />
                  {currentImgHover === index && (
                    <div className={style.imgTodo}>
                      <div className={style.imgTodoTitle}>{image.fileName}</div>
                      <div
                        className={style.imgDelete}
                        onMouseEnter={() => setIsDeletHover(true)}
                        onMouseLeave={() => setIsDeletHover(false)}
                        onClick={handlerDeletImg.bind(null, index)}
                      >
                        <img
                          src={isDeletHover ? deletHover : deletDefault}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleUpload(images);
              }}
              className={style.uploadBtn}
            >
              Upload
            </div>
            <div className={style.deleteAll} onClick={handlerDeleteAll}>Delete All</div>
          </div>
        ) : (
          <div
            className={style.fileDropBox}
            onClick={handleUploadIconClick}
            onDrop={handleFileDrop}
          >
            <BsCloudArrowUpFill className={style.uploadIcon} />
            <div className={style.tipText}>
              Drag and drop file here or <span>upload</span> here
            </div>
            <div
              onMouseLeave={handleMouseLeave}
              className={style.placehoderText}
            >
              <span
                onMouseEnter={handleMouseEnter}
                className={`${isHover ? style.spanhover : ""} ${style.textspan}`}>
                {isHover ? <span className={style.hoverText}>Recommended photo reference</span>
                  : chatLang === 'Chinese'
                    ? <>请尽量提供<span>光线明亮</span>、<span>不带阴影</span>的清晰人像。</>
                    : <> Please provide a clear portrait with <span>bright lighting</span> and <span>no shadows.</span></>}
              </span>
              {isHover && (<>
                <div className={style.placeHodler}></div>
                <div className={style.previewWrapper}>
                  {[imgto3dPhoto, imgto3dPhoto, imgto3dPhoto].map((item, index) => {
                    return <div key={index} className={style.previewBox}>
                      <img src={item} alt="defaultImg" />
                    </div>;
                  })}
                </div>
              </>
              )}
            </div>
          </div>
        )}

      </div>
      <Gallery />
      {isOpenImg3dGenerate && <Img3dGenerateBoard />}
    </div>
  );
};

export { ImageTo3D };
