/* global _czc */

import style from './card.module.css'
import { useLocation } from 'react-router-dom'
import React, { useState, useContext, useEffect } from 'react'
import { CardClickContext } from '../../Context';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { getTaskDetail, } from '../../../utils/net'
import {
  logInfoAtom,
  isShowModalAtom,
  modalObjAtom,
  cardsProgressStateAtom,
  cardsTypeAtom,
  cardsTypeConst,
  taskDetailAtom,
  taskInitAtom,
  showDownloadAtom,
} from '../../../store'
import { HiFolderArrowDown, } from "react-icons/hi2";
import { useTips } from '../../GlobalTips'
import Private from '../../widgets/Private'
import licenseBig from '../../../assets/licenseBig.png'
import licenseSmall from '../../../assets/licenseSmall.png'
import { Like } from '../Like'
const Card = (props) => {
  const { card, card: {
    task_uuid: taskUuid,
    lisenced,
    num_like: numLike,
    image_url: imageUrl,
    video_url: videoUrl,
    is_like: isLike,
    is_private: privated,
    author: { avatar_url: avatarurl, username, user_uuid },
    prompt,
    suggestion }, index } = props
  const location = useLocation()
  const [hoverCard, setHoverCard] = useState(null)
  const [isAnimating, setIsAnimating] = useState(false)
  const [cardsProgressState, setCardsProgressState] = useRecoilState(cardsProgressStateAtom);
  const [loadedImages, setLoadedImages] = useState(0);
  const [isShowModal, setIsShowModal] = useRecoilState(isShowModalAtom)
  const [showDownload, setShowDownload] = useRecoilState(showDownloadAtom)
  const logInfo = useRecoilValue(logInfoAtom)
  const [taskDetail, setTaskDetail] = useRecoilState(taskDetailAtom)
  const setTaskInit = useSetRecoilState(taskInitAtom)
  const [cardsType, setCardsType] = useRecoilState(cardsTypeAtom)
  const tip = useTips()
  const [modalObj, setModalObj] = useRecoilState(modalObjAtom);
  const handleCardClick = useContext(CardClickContext);
  const handleImageLoad = () => {
    setLoadedImages((prevLoadedImages) => prevLoadedImages + 1);
  };
  const handleDownloadClick = async (event, task_uuid) => {
    _czc.push(['_trackEvent', 'Index', 'Download Icon', 'Gallery Component']);
    //console.log('Download')
    setShowDownload(true)
    //console.log("click card in download click", task_uuid)
    try {
      const rep = await getTaskDetail(task_uuid)
      //setPreviewImageURL([img_1, img_2])
      //console.log("Set Card")
      setTaskDetail(rep.data)
      setTaskInit(false)
      setModalObj({
        ...modalObj,
        taskuuid: task_uuid
      })
      setIsShowModal(true)
      // navi(`/chatavatar`, {
      //   state: {
      //     type: 'detail',
      //     taskuuid: task_uuid
      //   }
      // });
    } catch (e) {
      tip({
        type: 'error',
        content: 'Something went wrong: ' + e.message,
      })
    }
  }

  const renderStatusText = (card) => {
    const cardProgress = cardsProgressState.find(
      (item) => item.task_uuid === taskUuid
    );
    if (!cardProgress) return null;
    const { stage, on_progress } = cardProgress.progress.data;
    let isWaiting = false;
    let percentage = 0;
    switch (stage) {
      case 'Created':
        return 'Task Created';
      case 'Waiting':
        return 'Waiting for pack';
      case 'Generating':
        percentage = cardProgress.progress.data.percentage;
        return `Packing ${percentage}%`;
      case 'Done':
        return 'Pack Done';
      case 'Failed':
        return 'Pack Failed';
      default:
        return 'No Latest Info';
    }
  };

  const getPercentage = () => {
    const cardProgress = cardsProgressState.find((item) => item.task_uuid === taskUuid);
    if (!cardProgress) return null;
    const { stage, on_progress } = cardProgress.progress.data;
    let isWaiting = false;
    let percentage = 0;
    percentage = cardProgress.progress.data.percentage;
    return percentage;
  };


  return <div
    className={`${style.card}`}
    key={taskUuid}
    style={{ opacity: isAnimating ? 0 : 1 }}
    onMouseEnter={() => setHoverCard(taskUuid)}
    onMouseLeave={(ev) => setHoverCard(false)}>
    <div
      className={`${style.coverImg2} ${(card.user_state === 'Generating' && cardsType === cardsTypeConst.Mine) ? style.packing : ''
        }`}
      onPointerDown={handleCardClick.bind(null, taskUuid)}>
      {hoverCard === taskUuid ? (
        <img alt='fit' src={videoUrl}
          onLoad={suggestion && handleImageLoad}
        />
      ) : (
        <>
          <img alt='fit' src={imageUrl} />
          {lisenced && <img alt='license' src={licenseBig}
            className={style.licenseBig}
            style={{ display: hoverCard === taskUuid ? 'none' : 'block' }} />}
        </>
      )}
    </div>
    {lisenced && <div className={style.hoverLicense} style={{ display: hoverCard === taskUuid ? 'block' : 'none' }}>
      <img alt='license-hover' src={licenseSmall}
        className={style.licenseSmall}
      />
      <div>License</div>
    </div>}
    <div
      className={`${style.packingtext} `}
      style={
        (card.user_state === 'Generating' && cardsType === cardsTypeConst.Mine) ? {} : { display: 'none' }
      }>
      Packing
    </div>

    {cardsType === cardsTypeConst.Mine && card.user_state === 'Generating' && (
      <>
        {(getPercentage() && getPercentage() > 0) &&
          <div className={style.progressBar}>
            <div
              className={style.progress}
              style={{
                width: getPercentage() + '%',
                backgroundColor: '#4A00E0',
              }}
            ></div>
          </div>
        }
        <div className={(getPercentage() && getPercentage() > 0) ? style.statusText : style.statusTextNoProgress}>
          {renderStatusText() ? renderStatusText() : "Loading Status..."}
        </div>
      </>
    )
    }
    <div className={style.toolBarBox}>
      <Like card={card}></Like>
      {/* 下载 */}

      {!suggestion && <div
        className={`${style.downloadCon}`}
        style={
          ((card.user_state === 'DoneHigh' || card.user_state === 'DoneBasic' || card.user_state === "Done") && cardsType === cardsTypeConst.Mine) ? {} : { display: 'none' }
        }
        onClick={(event) => handleDownloadClick(event, taskUuid)}>
        <HiFolderArrowDown
          style={{ color: "#ebebeb", fontSize: "16px" }}
        />
      </div>}
      {/* private */}
      {!suggestion && user_uuid === localStorage.getItem('user_uuid') && privated && <div className={style.privateBox}>
        <Private
          type={'card'}
          card={{ ...card }}
          style={{
            width: '2rem',
            height: '2rem'
          }} ></Private>
      </div>}
    </div>

    <div
      className={`${style.infoCon} ${hoverCard === taskUuid ||
        (card.user_state === 'Generating' && cardsType === cardsTypeConst.Mine)
        ? style.hide
        : ''
        }`}>
      <div className={style.avatar}>
        <img
          src={avatarurl}
          alt='avatar'
          onError={(e) => {
            e.target.onerror = '../../assets/defaultAvatar.png'
            e.target.src = '../../assets/defaultAvatar.png'
          }}
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: 'transparent',
            backgroundImage: `url('../../assets/defaultAvatar.png')`,
          }}
        />
      </div>
      <div>
        {username?.length > 8
          ? username.slice(0, 8) + '...'
          : username}
      </div>
      <div className={style.spaceholder}></div>
      <div>{numLike} likes</div>
    </div>
    {prompt && (
      <div
        className={`${style.prompt} ${(hoverCard === taskUuid && card.user_state !== 'Generating') || (cardsType !== cardsTypeConst.Mine && hoverCard === taskUuid) ? style.show : ''
          }`}>
        {prompt}
      </div>
    )}
  </div>
}

export { Card }