import { atom, selector } from 'recoil'

export const logInfoAtom = atom({
  key: 'logInfoAtom',
  default: false,
})

export const showLoginAtom = atom({
  key: 'showLoginAtom',
  default: false,
})

export const showUserAtom = atom({
  key: 'showUserAtom',
  default: false,
})

export const initialLoginStageAtom = atom({
  key: 'initialLoginStageAtom',
  default: '',
})
export const showHistoryAtom = atom({
  key: 'showHistoryAtom',
  default: false,
})
export const totalHistoryAtom = atom({
  key: 'totalHistoryAtom',
  default: [],
})
export const currentPageAtom = atom({
  key: 'currentPage',
  default: 1,
})
export const totalPageAtom = atom({
  key: 'totalPageAtom',
  default: 1,
})

export const inHistoryAtom = atom({
  key: 'inHistoryAtom',
  default: [],
})

export const outHistoryAtom = atom({
  key: 'outHistoryAtom',
  default: [],
})

export const filterTypeAtom = atom({
  key: 'filterTypeAtom',
  default: 'all',
})

export const filterListAtom = atom({
  key: 'filterListAtom',
  default: [],
})
export const currentPageCounter = selector({
  key: 'currentPageCounter',
  default: [],
  get: ({ get }) => {
    let totalHistory = get(filterListAtom)
    return totalHistory.slice((get(currentPageAtom) - 1) * 12, get(currentPageAtom) * 12);
  },
});


