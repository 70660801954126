
import style from './nav.module.css'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { currentNavAtom, transitioningAtom, isImgto3dAtom, clickHideAtom, curThemeAtom, bodyOpacityTransitionAtom, userPermissionAtom } from '../../store'
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

const Nav = ({ isMobile }) => {
  const [userPermission, setUserPermission] = useRecoilState(userPermissionAtom);
  const [currentNav, setCurrentNav] = useRecoilState(currentNavAtom)
  const setIsImgto3d = useSetRecoilState(isImgto3dAtom)
  const [curTheme, setCurThem] = useRecoilState(curThemeAtom)
  const [bodyOpacityTransition, setBodyOpacityTransition] = useRecoilState(bodyOpacityTransitionAtom);
  const [transitioning, setTransitioning] = useRecoilState(transitioningAtom);
  const location = useLocation()
  const [clickHide, setClickHide] = useRecoilState(clickHideAtom);
  const navRef = useRef(null)
  const permissionPathMap = {
    chatavatar_text_access: "/chatavatar",
    panorama_access: "/panorama",
    rodin_access: "/rodin",
  };

  let NavMenu = ['Chatavatar', 'Panorama', 'Rodin'].filter((_, index) => {
    const accessKey = Object.keys(permissionPathMap)[index];
    return userPermission[accessKey];
  });

  let NavMenuHover = ["Text to 3D Avatar", 'Build Panorama', "3D Search Engine"].filter((_, index) => {
    const accessKey = Object.keys(permissionPathMap)[index];
    return userPermission[accessKey];
  });

  let pathMenu = ['chatavatar', 'panorama', 'rodin'].filter((path, index) => {
    const accessKey = Object.keys(permissionPathMap)[index];
    return userPermission[accessKey];
  });

  useEffect(() => {
    if (location.pathname.includes('/rodin')) {
      setCurrentNav('Rodin')
    } else if (location.pathname.includes('/panorama')) {
      setCurrentNav('Panorama')
    }
  }, [])

  useEffect(() => {
    if (currentNav === 'Rodin' || currentNav === 'Chatavatar') {
      setIsImgto3d(false)
      document.documentElement.dataset.theme = 'dark'
      setCurThem('dark')
    } else if (currentNav === 'Panorama') {
      document.documentElement.dataset.theme = 'panorama'
      setCurThem('panorama')
    }
  }, [currentNav])

  useEffect(() => {
    if (bodyOpacityTransition) {
      navRef.current.style.transform = 'translateX(-300px)'
    } else {
      navRef.current.style.transform = 'translateX(0)'
    }
  }, [bodyOpacityTransition])

  const handleClick = (index) => {
    return new Promise((resolve) => {
      setTransitioning(true);
      setTimeout(() => {
        resolve()
      }, 300)
    }).then(() => {
      setCurrentNav(NavMenu[index])
      return setTimeout(() => {
        setTransitioning(false);
      }, 100)
    }).catch((error) => {
      console.log(error);
    })
  }
  return <div
    className={`${isMobile ? style.mobileNavWrapper : style.NavWrapper} ${clickHide ? style.conHide : ''}`}>
    <div
      ref={navRef}
      className={`${isMobile ? style.mobileNavBox : style.NavBox}`}>
      {NavMenu.map((item, index) => {
        return <Link style={{
          display: 'inline-block'
        }} to={`/${pathMenu[index]}`}
          className={`${currentNav === NavMenu[index] ? style.selected : style.canHover} ${`${isMobile ? style.mobileNormal : style.normal}`}`}
          key={index}
          onClick={handleClick.bind(null, index)}>
          <div className={style.beforeText}>{item}</div>
          {!isMobile && <div className={style.afterText}>{NavMenuHover[index]}</div>}
        </Link>
      })}
    </div>
  </div>
}

export default Nav