import { atom } from 'recoil'

export const cardsTypeConst = {
  Recent: 'Recent',
  Featured: 'Featured',
  Mine: 'Mine',
  Search: 'Search  ',
}

export const cardsAtom = atom({
  default: [],
  key: 'cardsAtom',
})

export const cardsTypeAtom = atom({
  default: cardsTypeConst.Featured,
  key: 'cardsTypeAtom',
})

export const searchKeyWordAtom = atom({
  default: '',
  key: 'searchKeyWordAtom',
})

export const rodinSearchKeyWordAtom = atom({
  default: '',
  key: 'rodinSearchKeyWordAtom',
})

export const showSearchAtom = atom({
  default: '',
  key: 'showSearchAtom',
})
export const rodinCardsTypeAtom = atom({
  default: "Sketchfab",
  key: 'rodinCardsTypeAtom',
})

export const isImgto3dAtom = atom({
  key: 'isImgto3dAtom',
  default: false,
})

export const showCardsConAtom = atom({
  key: 'showCardsConAtom',
  default: false,
})

export const bodyOpacityTransitionAtom = atom({
  key: 'bodyOpacityTransitionAtom',
  default: false,
})

export const isEditorOpenAtom = atom({
  key: 'isEditorOpenAtom',
  default: false,
})

export const panoramaCardsTypeAtom = atom({
  key: 'panoramaCardsTypeAtom',
  default: 'Featured',
})