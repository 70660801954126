import { useEffect, useState, useRef } from "react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import {
  generateDownload,
  getGenerateProgress,
  getTaskDownload,
  getUserInfo,
  getPosterImage,
  likeCard,
  getTaskDetail,
} from "../../../utils/net";
import {
  taskDetailAtom,
  downloadStageAtom,
  isShowModalAtom,
  showDownloadAtom,
  downloadProgressAtom,
  promptAtom,
  logInfoAtom,
  showLoginAtom,
  cardsTypeAtom,
  cardsTypeConst,
  cardsAtom
} from "../../../store";
import style from "./bundlePanel.module.css";
import { useTips } from "../../GlobalTips";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import walletIcon from "../../../assets/wallet.png";
import finishIconSrc from "../../../assets/finish.png";
import axios from "axios";
import { deepClone } from '../../../utils/format'

function BundlePanel() {
  const [downloadStage, setDownloadStage] = useRecoilState(downloadStageAtom);
  const [downloadOpt0, setDownloadOpt0] = useState(0);
  const [cards, setCards] = useRecoilState(cardsAtom);
  const [downloadOpt1, setDownloadOpt1] = useState([]);
  const [downloadOpt2, setDownloadOpt2] = useState(0);
  const [bodyOpt, setBodyOpt] = useState({ gender: 2 });
  const [totalCost, setTotalCost] = useState(0);
  const [isPaid, setIsPaid] = useState(null);
  const [isBundleFinished, setIsBundleFinished] = useState(false);
  const [taskDetail, setTaskDetail] = useRecoilState(taskDetailAtom);
  const [logInfo, setLogInfo] = useRecoilState(logInfoAtom);
  const [downloadProgress, setDownloadProgress] =
    useRecoilState(downloadProgressAtom);
  const setShowLogin = useSetRecoilState(showLoginAtom);
  const [isShowModal, setIsShowModal] = useRecoilState(isShowModalAtom);
  const setShowDownload = useSetRecoilState(showDownloadAtom);
  const setCardType = useSetRecoilState(cardsTypeAtom);
  const prompt = useRecoilValue(promptAtom);
  const tip = useTips();
  const [autoParams, setAutoParams] = useState(0);
  const progressRef = useRef({});

  useEffect(() => {
    getGenerateProgress(taskDetail.task_uuid).then((data) => {
      const res = data.data;
      if (res.stage !== "Done") {
        setIsPaid(true);
        setIsBundleFinished(false);
      } else if (
        res.stage === "Done" &&
        ["DoneBasic", "DoneHigh"].includes(taskDetail.user_state)
      ) {
        setIsPaid(true);
        setIsBundleFinished(true);
      }
    });
    return () => {
      setTotalCost(0);
      setDownloadOpt0(0);
      setDownloadOpt1([]);
      setDownloadOpt2(0);
      setDownloadStage(2);
      setIsPaid(false);
      setIsBundleFinished(false);
    };
  }, []);

  useEffect(() => {
    if (!isShowModal) {
      setShowDownload(false);
    }
  }, [isShowModal]);

  useEffect(() => {
    let sum = 0;

    downloadOpt2 === 0 ? (sum += 20) : (sum += 40);

    if (downloadOpt0 === 1) {
      sum += 20;
      setTotalCost(sum);
      return;
    }

    sum = sum + downloadOpt1.length * 10;
    setTotalCost(sum);
  }, [downloadOpt0, downloadOpt1, downloadOpt2]);

  const handlePay = async (ev) => {
    if (!logInfo) {
      setShowLogin(true);
      return;
    }
    try {
      const req = {
        task_uuid: taskDetail.task_uuid,
        resolution: downloadOpt2 === 0 ? "Basic" : "High",
        topology: downloadOpt0 === 0 ? "USC" : "MetaHuman",
      };
      if (downloadOpt0 === 0 && downloadOpt1.length > 0) {
        req.additional = downloadOpt1.map((opt) => {
          switch (opt) {
            case 0:
              // break
              return "AddBody";
            case 1:
              return "AddFacialComponent";
            case 2:
              return "AddBS";
            case 3:
              return "AddBackHead";
          }
        });
      }

      if (downloadOpt0 === 0 && downloadOpt1.includes(0)) {
        req.settings = {
          height: Number(bodyOpt.height),
          weight: Number(bodyOpt.weight),
          gender: bodyOpt.gender === 1,
        };
      }
      const res = await generateDownload(req);
      if (res.data.error) throw new Error(res.data.error);
      setIsPaid(true);

      const response = await getTaskDetail(taskDetail.task_uuid);
      setTaskDetail(response.data);


      const newCards = deepClone(cards)
      const index = cards.findIndex(item => item.task_uuid === taskDetail.task_uuid)
      if (index === -1) return
      newCards[index].user_state = response.data.user_state
      setCards(newCards);

      const userData = (
        await getUserInfo({ user_uuid: localStorage.getItem("user_uuid") })
      ).data;
      if (userData.error) throw new Error(userData.error);
      setLogInfo({ ...userData.meta, token: localStorage.getItem("token") });
      await likeCard(taskDetail.task_uuid);

      // setTimeout(
      //   (e) =>
      //     setMeshProfile({
      //       ...meshProfile,
      //       paid: true,
      //     }),
      //   1000
      // );
    } catch (e) {
      tip({
        type: "error",
        content: "Something went wrong: " + e.message,
      });
    }
  };

  async function fetchWithProgress(url, onProgress) {
    const response = await fetch(url);
    const reader = response.body.getReader();
    const contentLength = +response.headers.get("Content-Length");
    let receivedLength = 0;

    const chunks = [];
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      chunks.push(value);
      receivedLength += value.length;
      onProgress(receivedLength / contentLength);
    }

    const chunksAll = new Uint8Array(receivedLength);
    let position = 0;
    for (const chunk of chunks) {
      chunksAll.set(chunk, position);
      position += chunk.length;
    }

    return new Blob([chunksAll]);
  }

  const handleDownload = async (ev) => {
    setDownloadProgress(0.01);
    try {
      const urls = await Promise.all(
        taskDetail.resources
          .filter((r) => r)
          .map((obj) => {
            return getTaskDownload({ task_uuid: taskDetail.task_uuid, ...obj });
          })
      );

      const err = urls.find((url) => url.error);
      if (err) {
        throw new Error(err.error);
      }

      const zip = new JSZip();

      zip.file("prompt.txt", prompt);

      const downloadImage = async (url, filename) => {
        const response = await axios({
          url,
          method: "GET",
          responseType: "arraybuffer",
        });
        const data = new Uint8Array(response.data);
        zip.file(filename, data);
      };

      const downloadImageWithTimeout = async (
        { task_uuid, type, name },
        filename
      ) => {
        const timeoutPromise = new Promise((_, reject) =>
          setTimeout(() => reject(new Error("Timeout")), 2000)
        );

        const dataPromise = getPosterImage({ task_uuid, type, name })
          .then((rep) => downloadImage(rep.data.url, filename))
          .catch((err) => console.error(err));

        await Promise.race([dataPromise, timeoutPromise]);
      };

      await downloadImageWithTimeout(
        { task_uuid: taskDetail.task_uuid, type: "Static", name: "image" },
        "image.png"
      );
      await downloadImageWithTimeout(
        { task_uuid: taskDetail.task_uuid, type: "Static", name: "video" },
        "video.png"
      );
      await downloadImageWithTimeout(
        { task_uuid: taskDetail.task_uuid, type: "Static", name: "geometry" },
        "geometry.png"
      );

      const totalFiles = Math.max(urls.length, 1);
      let completedFiles = 0;

      const responses = await Promise.all(
        urls.map(async (urlObj) => {
          const { url, name, type } = urlObj;
          if (!url) {
            tip({
              type: "info",
              content: `${type}/${name} not found`,
            });
            return null;
          }
          const blob = await fetchWithProgress(url, (progress) => {
            progress = Math.min(progress, 1);
            progressRef.current[`${type}/${name}`] = progress;
            const totalProgress = parseFloat(
              Object.values(progressRef.current)
                .reduce((sum, p) => sum + p / totalFiles, 0)
                .toFixed(2)
            );
            setDownloadProgress(totalProgress);
          });
          completedFiles++;
          return { blob, name, type };
        })
      );

      for (const responseObj of responses) {
        if (!responseObj) continue;
        const { blob, name, type } = responseObj;
        zip.file(`${type}/${name}`, blob);
      }

      const content = await zip.generateAsync({ type: "blob" });
      let timestamp = Date.now();
      saveAs(content, `${timestamp}.zip`);

      setDownloadProgress(1);
      progressRef.current = {};
      setTimeout(() => {
        setDownloadProgress(0);
      }, 3000);
    } catch (e) {
      tip({
        type: "error",
        content: e.message + " Retry!",
      });
      setDownloadProgress(0);
    }
  };

  const handleRepack = (ev) => {
    setTotalCost(0);
    setDownloadOpt0(0);
    setDownloadOpt1([]);
    setDownloadOpt2(0);
    setDownloadStage(2);
    setIsPaid(false);
    setIsBundleFinished(false);
  };
  const handleBack = (ev) => {
    if (!logInfo) {
      setShowLogin(true);
      return;
    }
    if (downloadStage === 0) setDownloadStage(2);
    else if (downloadStage === 1) {
      setDownloadStage(0);
    }
  };

  const handleToMine = (ev) => {
    setIsShowModal(false);
    setCardType(cardsTypeConst.Mine);
  };

  const handleAdditionalClick = (opt) => (ev) => {
    downloadOpt1.includes(opt)
      ? setDownloadOpt1(downloadOpt1.filter((cur) => cur !== opt))
      : setDownloadOpt1([...downloadOpt1, opt]);
  };

  return (
    <div className={style.downloadCon}>
      {isPaid === null ? (
        <div className={style.loading}>Loading...</div>
      ) : isPaid ? <>
        {!isBundleFinished ? (
          <div className={style.paidCon}>
            <img className={style.paidIcon} src={finishIconSrc} alt="finish" />
            <div className={style.paidTitle}>Payment Successful!</div>
            <div className={style.paidSubtitle}>
              View in the list of{" "}
              <span className={style.clickable} onPointerDown={handleToMine}>
                mine
              </span>
            </div>
            <div className={style.paidProgress}>
              Estimated waiting time is 10 minutes...
            </div>
          </div>
        ) : (
          <div className={style.paidCon}>
            <img className={style.paidIcon} src={finishIconSrc} alt="finish" />
            <div className={style.paidTitle}>
              {downloadProgress === 0
                ? "Pack finished!"
                : downloadProgress === 1
                  ? "Download finished!"
                  : "Downloading..."}
            </div>
            <div className={style.paidSubtitle}>
              <span
                className={style.clickable}
                onPointerDown={(ev) =>
                  window.open(
                    Intl.DateTimeFormat().resolvedOptions().timeZone ===
                      "Asia/Shanghai"
                      ? "https://cowtransfer.com/s/1994387abbd74f"
                      : "https://drive.google.com/drive/folders/1kyhe5ZI40yrGyyNfzboTF_6cU539p10m?usp=share_link",
                    "_blank"
                  )
                }
              >
                Click here{" "}
              </span>
              to get Shader Examples
            </div>
            {downloadProgress !== 0 ? (
              <div style={{ position: "relative", width: "100%" }}>
                <div
                  style={{
                    zIndex: 2,
                    color: "var(--theme-color)",
                    position: "absolute",
                    bottom: ".5rem",
                    fontSize: ".8rem",
                    left: `${downloadProgress * 100 <= 10
                      ? 1
                      : downloadProgress * 100 >= 85
                        ? 85
                        : downloadProgress * 100
                      }%`,
                  }}
                >
                  {downloadProgress.toFixed(2) * 100}%
                </div>
                <div className={style.progressCon}>
                  <div
                    className={style.progressBar}
                    style={{ width: `${downloadProgress * 100}%` }}
                  ></div>
                </div>
              </div>
            ) : (
              <>
                <div className={style.downloadBtn} onPointerDown={handleDownload}>
                  Download
                </div>
                <div className={style.downloadBtn} onPointerDown={handleRepack}>
                  Repack
                </div>
              </>
            )}
          </div>
        )}
      </> : <>
        <div className={style.downloadTitle}>
          <div>Export Options</div>
          {downloadStage !== 2 ? (
            <div onPointerDown={handleBack}>{"< "}Back</div>
          ) : null}
        </div>
        {downloadStage === 0 && (
          <>
            <div
              className={`${style.row} ${downloadOpt0 === 0 ? style.selected : ""
                }`}
              onPointerDown={(ev) => setDownloadOpt0(0)}
            >
              <div className={style.priceCon}>
                <img className={style.icon} src={walletIcon} alt="wallet" />
                <div className={style.price}>0</div>
              </div>
              <div className={style.option}>Default</div>
              <div className={`${style.corner} ${style.sec}`}>More options</div>
            </div>
            <div
              className={`${style.row} ${downloadOpt0 === 1 ? style.selected : ""
                }`}
              onPointerDown={(ev) => setDownloadOpt0(1)}
            >
              <div className={style.priceCon}>
                <img className={style.icon} src={walletIcon} alt="wallet" />
                <div className={style.price}>20</div>
              </div>
              <div className={style.option}>MetaHuman</div>
              <div className={style.corner}>Default 2K</div>
            </div>
            {downloadOpt0 === 0 ? (
              <div
                className={style.downloadBtn}
                onPointerDown={(ev) => setDownloadStage(1)}
              >
                NEXT
              </div>
            ) : (
              <div
                className={style.downloadBtn}
                onPointerDown={(ev) => handlePay()}
              >
                PAY
              </div>
            )}
          </>
        )}
        {downloadStage === 1 && (
          <>
            <div style={{ overflowY: "auto", maxHeight: "24rem" }}>
              <div
                // className={`${style.row} ${style.disabled}`}
                className={`${style.row} ${downloadOpt1.includes(0) ? style.selected : ""
                  }`}
                onPointerDown={handleAdditionalClick(0)}
              >
                <div className={style.priceCon}>
                  <img className={style.icon} src={walletIcon} alt="wallet" />
                  <div className={style.price}>10</div>
                </div>
                <div className={style.option}>Rigged Body</div>
              </div>

              <div
                className={`${style.bodyOptCon} ${downloadOpt1.includes(0) ? "" : style.hide
                  }`}
              >
                <div className={`${style.subRow}`}>
                  <div
                    className={`${style.genderBtn} ${bodyOpt.gender === 0 ? style.selected : ""
                      }`}
                    onPointerDown={(ev) => setBodyOpt({ ...bodyOpt, gender: 0 })}
                  >
                    Male
                  </div>
                  <div
                    className={`${style.genderBtn} ${bodyOpt.gender === 1 ? style.selected : ""
                      }`}
                    onPointerDown={(ev) => setBodyOpt({ ...bodyOpt, gender: 1 })}
                  >
                    Female
                  </div>
                  <div
                    className={`${style.genderBtn} ${bodyOpt.gender === 2 ? style.selected : ""
                      }`}
                    onPointerDown={(ev) => setBodyOpt({ ...bodyOpt, gender: 2 })}
                  >
                    Others
                  </div>
                </div>
                <div className={`${style.subRow}`}>
                  <div
                    className={`${style.genderBtn} ${autoParams === 0 ? style.selected : ""
                      }`}
                    onPointerDown={(ev) => {
                      setAutoParams(0);
                      setBodyOpt({ ...bodyOpt, weight: null, height: null });
                    }}
                  >
                    Auto
                  </div>
                  <div
                    className={`${style.genderBtn} ${autoParams === 1 ? style.selected : ""
                      }`}
                    onPointerDown={(ev) => setAutoParams(1)}
                  >
                    Manual
                  </div>
                </div>
                <div>
                  <div className={`${style.subRow}`}>
                    <div className={style.label}>Height</div>

                    <div className={style.inputCon}>
                      <input
                        type="range"
                        min="140"
                        max="220"
                        value={bodyOpt.height}
                        onChange={(ev) =>
                          setBodyOpt({
                            ...bodyOpt,
                            height: ev.currentTarget.value,
                          })
                        }
                        className={style.slider}
                        disabled={autoParams === 0}
                      />
                      <div style={{ width: "4.4rem", fontSize: "14.5px" }}>
                        <span className={style.suffixHeight}>
                          {autoParams === 1
                            ? bodyOpt.height
                              ? bodyOpt.height + " cm"
                              : "" + " cm"
                            : "Auto"}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className={`${style.subRow}`}>
                    <div className={style.label}>Weight</div>

                    <div className={style.inputCon}>
                      <input
                        type="range"
                        min="40"
                        max="110"
                        value={bodyOpt.weight}
                        onChange={(ev) =>
                          setBodyOpt({
                            ...bodyOpt,
                            weight: ev.currentTarget.value,
                          })
                        }
                        className={style.slider}
                        disabled={autoParams === 0}
                      />
                      <div style={{ width: "4.4rem", fontSize: "14.5px" }}>
                        <span className={style.suffixWeight}>
                          {autoParams === 1
                            ? bodyOpt.weight
                              ? bodyOpt.weight + " kg"
                              : "" + " kg"
                            : "Auto"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${style.row} ${downloadOpt1.includes(1) ? style.selected : ""
                  }`}
                onPointerDown={handleAdditionalClick(1)}
              >
                <div className={style.priceCon}>
                  <img className={style.icon} src={walletIcon} alt="wallet" />
                  <div className={style.price}>10</div>
                </div>
                <div className={style.option}>Eye & Teeth</div>
              </div>
              <div
                className={`${style.row} ${downloadOpt1.includes(2) ? style.selected : ""
                  }`}
                onPointerDown={handleAdditionalClick(2)}
              >
                <div className={style.priceCon}>
                  <img className={style.icon} src={walletIcon} alt="wallet" />
                  <div className={style.price}>10</div>
                </div>
                <div className={style.option}>Expression BlendShapes</div>
              </div>
              <div
                className={`${style.row} ${downloadOpt1.includes(3) ? style.selected : ""
                  }`}
                onPointerDown={handleAdditionalClick(3)}
              >
                <div className={style.priceCon}>
                  <img className={style.icon} src={walletIcon} alt="wallet" />
                  <div className={style.price}>10</div>
                </div>
                <div className={style.option}>Back head Textures</div>
              </div>
            </div>
            <div
              className={style.downloadBtn}
              onPointerDown={(ev) => handlePay()}
            >
              PAY
            </div>
          </>
        )}
        {downloadStage === 2 && (
          <>
            <div
              className={`${style.row} ${downloadOpt2 === 0 ? style.selected : ""
                }`}
              onPointerDown={(ev) => setDownloadOpt2(0)}
            >
              <div className={style.priceCon}>
                <img className={style.icon} src={walletIcon} alt="wallet" />
                <div className={style.price}>20</div>
              </div>
              <div className={style.option}>2 K</div>
            </div>
            <div
              className={`${style.row} ${downloadOpt2 === 1 ? style.selected : ""
                }`}
              onPointerDown={(ev) => setDownloadOpt2(1)}
            >
              <div className={style.priceCon}>
                <img className={style.icon} src={walletIcon} alt="wallet" />
                <div className={style.price}>40</div>
              </div>
              <div className={style.option}>4 K</div>
            </div>
            <div
              className={style.downloadBtn}
              onPointerDown={() => setDownloadStage(0)}
            >
              NEXT
            </div>
          </>
        )}
      </>}
    </div>
  );
}

export { BundlePanel };
