function ErrorPage() {

	const pageStyle = {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		background: 'white',
		color: 'black',
		fontSize: '20px',
	};

	const errorStyle = {
		fontSize: '20px',
	};

	const errorStyleDetail = {
		fontSize: '12px',
		marginBottom: '1rem',
	};

	return (
		<div style={pageStyle}>
			<div style={errorStyle}>HyperHuman</div>
			<br></br>
			<div style={errorStyleDetail}>We are sorry to inform you that the resource you are trying to access is currently inaccessible.</div>
			<div style={errorStyleDetail}>If you have any questions or concerns, feel free to reach out to us at hello@deemos.com.</div>
		</div>
	);
}

export { ErrorPage }
