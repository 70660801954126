/* eslint-disable no-unused-vars */
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  showLoginAtom,
  isShowModalAtom,
  taskDetailAtom,
  taskInitAtom,
  modalObjAtom
} from "../../store";
import { getTaskDetail } from "../../utils/net";


const SharePraser = ({ scrollPercentage, isMobile }) => {
  const { taskUUid } = useParams();
  const [taskInit, setTaskInit] = useRecoilState(taskInitAtom);
  const [taskDetail, setTaskDetail] = useRecoilState(taskDetailAtom)
  const [modalObj, setModalObj] = useRecoilState(modalObjAtom);
  const location = useLocation()
	const navi = useNavigate()

  const handleClickCard = async (task_uuid, img_1, img_2) => {
    try {
      const rep = await getTaskDetail(task_uuid)
      setTaskDetail(rep.data)
      setTaskInit(false)
      if (location.pathname.includes('/imageto3d')) {
        setModalObj({
          ...modalObj,
          type: 'imgto3d',
          taskuuid: task_uuid
        })
      }else if(location.pathname.includes('/chatavatar') || location.pathname.includes('/result/detail')){
        setModalObj({
          ...modalObj,
          type: 'detail',
          taskuuid: task_uuid
        })
      } else {
        setModalObj({
          ...modalObj,
          type: 'detail',
          taskuuid: task_uuid
        })
      }
    } catch (e) {

    }
  }

  if (taskUUid) {
    handleClickCard(taskUUid);
    navi(`/chatavatar/${taskUUid}`, {
		});
  }

  return <div></div>;
};

export {SharePraser};
