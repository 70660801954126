import React from 'react';
import style from './pagination.module.css'

function Pagination({ currentPage, totalPages, onPageChange }) {
  const pageNumbers = Array(totalPages).fill().map((_, index) => index + 1);
  let startPage = currentPage - 2;
  if (startPage < 1) {
    startPage = 1;
  }

  let endPage = startPage + 4;
  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = endPage - 4;
    if (startPage < 1) {
      startPage = 1;
    }
  }

  return (
    <div className={style.pagination}>
      {currentPage > 1 && (<div
        className={style.pageNext}
        onPointerDown={() => onPageChange(currentPage - 1)}
      >
        &lt;
      </div>)}
      {pageNumbers.slice(startPage - 1, endPage).map(pageNumber => (
        <div
          key={pageNumber}
          className={`${style.pageNumber}  ${pageNumber === currentPage ? style.activeBg : ""}`}
          onPointerDown={() => onPageChange(pageNumber)}
        >
          {pageNumber}
        </div>
      ))}
      {currentPage < endPage && (<div
        className={style.pageNext}
        onPointerDown={() => onPageChange(currentPage + 1)}
      >
        &gt;
      </div>)
      }
    </div>
  );
}

export default Pagination;