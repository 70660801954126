import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import {
  generateDownload,
  getGenerateProgress,
  getUserInfo,
  likeCard,
  getTaskDetail
} from "../../../utils/net";
import {
  taskDetailAtom,
  downloadStageAtom,
  isShowModalAtom,
  showDownloadAtom,
  logInfoAtom,
  showLoginAtom,
  cardsTypeAtom,
  cardsTypeConst,
  meshProfileAtom,
  showPayCardAtom,
  cardsAtom
} from "../../../store";
import style from "./bundlePanelFade.module.css";
import { useTips } from "../../GlobalTips";
import walletIcon from "../../../assets/wallet.png";
import finishIconSrc from "../../../assets/finish.png";
import { deepClone } from "../../../utils/format";

function BundlePanelFade() {
  const [step, setStep] = useRecoilState(downloadStageAtom);
  const [cards, setCards] = useRecoilState(cardsAtom);
  const [downloadOpt0, setDownloadOpt0] = useState(0);
  const [downloadOpt1, setDownloadOpt1] = useState([]);
  const [downloadOpt2, setDownloadOpt2] = useState(0);
  const [bodyOpt, setBodyOpt] = useState({ gender: 2 });
  const [totalCost, setTotalCost] = useState(0);
  const [isPaid, setIsPaid] = useState(null);
  const [isBundleFinished, setIsBundleFinished] = useState(false);
  const [taskDetail, setTaskDetail] = useRecoilState(taskDetailAtom);
  const [logInfo, setLogInfo] = useRecoilState(logInfoAtom);
  const setShowLogin = useSetRecoilState(showLoginAtom);
  const [isShowModal, setIsShowModal] = useRecoilState(isShowModalAtom);
  const setShowDownload = useSetRecoilState(showDownloadAtom);
  const setCardType = useSetRecoilState(cardsTypeAtom);
  const tip = useTips();
  const [autoParams, setAutoParams] = useState(0);
  const [meshProfile, setMeshProfile] = useRecoilState(meshProfileAtom);
  const [showPayCard, setShowPayCard] = useRecoilState(showPayCardAtom);


  useEffect(() => {
    getGenerateProgress(taskDetail.task_uuid).then((data) => {
      const res = data.data;
      if (res.stage !== "Done") {
        setIsPaid(true);
        setIsBundleFinished(false);
      } else if (
        res.stage === "Done" &&
        ["DoneBasic", "DoneHigh"].includes(taskDetail.user_state)
      ) {
        setIsPaid(true);
        setIsBundleFinished(true);
      }
    });
    return () => {
      setTotalCost(0);
      setDownloadOpt0(0);
      setDownloadOpt1([]);
      setDownloadOpt2(0);
      setStep(2);
      setIsPaid(false);
      setIsBundleFinished(false);
    };
  }, []);

  useEffect(() => {
    if (!isShowModal) {
      setShowDownload(false);
    }
  }, [isShowModal]);

  useEffect(() => {
    let sum = 0;
    downloadOpt2 === 0 ? (sum += 20) : (sum += 40);
    if (downloadOpt0 === 1) {
      sum += 20;
      setTotalCost(sum);
      return;
    }

    sum = sum + downloadOpt1.length * 10;
    setTotalCost(sum);
  }, [downloadOpt0, downloadOpt1, downloadOpt2]);

  const handlePay = async (ev) => {
    if (!logInfo) {
      setShowLogin(true);
      return;
    }
    try {
      const req = {
        task_uuid: taskDetail.task_uuid,
        resolution: downloadOpt2 === 0 ? "Basic" : "High",
        topology: downloadOpt0 === 0 ? "USC" : "MetaHuman",
      };
      if (downloadOpt0 === 0 && downloadOpt1.length > 0) {
        req.additional = downloadOpt1.map((opt) => {
          switch (opt) {
            case 0:
              // break
              return "AddBody";
            case 1:
              return "AddFacialComponent";
            case 2:
              return "AddBS";
            case 3:
              return "AddBackHead";
          }
        });
      }

      if (downloadOpt0 === 0 && downloadOpt1.includes(0)) {
        req.settings = {
          height: Number(bodyOpt.height),
          weight: Number(bodyOpt.weight),
          gender: bodyOpt.gender === 1,
        };
      }
      const res = await generateDownload(req);
      if (res.data.error) throw new Error(res.data.error);
      setIsPaid(true);
      const response = await getTaskDetail(taskDetail.task_uuid);
      setTaskDetail(response.data);

      const newCards = deepClone(cards)
      const index = cards.findIndex(item => item.task_uuid === taskDetail.task_uuid)
      if (index === -1) return
      newCards[index].user_state = response.data.user_state
      setCards(newCards);

      const userData = (
        await getUserInfo({ user_uuid: localStorage.getItem("user_uuid") })
      ).data;
      if (userData.error) throw new Error(userData.error);
      setLogInfo({ ...userData.meta, token: localStorage.getItem("token") });
      await likeCard(taskDetail.task_uuid);

      // setTimeout(
      //   (e) =>
      //     setMeshProfile({
      //       ...meshProfile,
      //       ...taskDetail.resources,
      //       paid: true,
      //     }),
      //   1000
      // );
    } catch (e) {
      tip({
        type: "error",
        content: "Something went wrong: " + e.message,
      });
    }
  };

  const handleBack = (ev) => {
    if (!logInfo) {
      setShowLogin(true);
      return;
    }
    if (step === 0) setStep(2);
    else if (step === 1) {
      setStep(0);
    } else if (step === 2) {
      setShowPayCard(false)
    }

  };

  const handleToMine = (ev) => {
    setIsShowModal(false);
    setCardType(cardsTypeConst.Mine);
  };

  const handleAdditionalClick = (opt) => (ev) => {
    downloadOpt1.includes(opt)
      ? setDownloadOpt1(downloadOpt1.filter((cur) => cur !== opt))
      : setDownloadOpt1([...downloadOpt1, opt]);
  };

  return (
    <div className={style.downloadCon}>
      {/* 2 first  0 second  1 three*/}
      {!isPaid && <>
        {<div className={`${style.stepOneBox} ${step === 2 && style.fadeInOne} ${(step === 0 || step === 1) && style.fadeOutOne}`}>
          <div className={style.downloadTitle}>
            <div>Export Options</div>
            <div onPointerDown={handleBack}>{"< "}Back</div>
          </div>
          <div
            className={`${style.row} ${downloadOpt2 === 0 ? style.selected : ""
              }`}
            onPointerDown={(ev) => setDownloadOpt2(0)}
          >
            <div className={style.priceCon}>
              <img className={style.icon} src={walletIcon} alt="wallet" />
              <div className={style.price}>20</div>
            </div>
            <div className={style.option}>2 K</div>
          </div>
          <div
            className={`${style.row} ${downloadOpt2 === 1 ? style.selected : ""
              }`}
            onPointerDown={(ev) => setDownloadOpt2(1)}
          >
            <div className={style.priceCon}>
              <img className={style.icon} src={walletIcon} alt="wallet" />
              <div className={style.price}>40</div>
            </div>
            <div className={style.option}>4 K</div>
          </div>
          <div className={style.costCon}>
            Payment amount: <span>{totalCost}</span>
          </div>
          <div
            className={style.downloadBtn}
            onPointerDown={() => { setStep(0) }}
          >
            NEXT
          </div>
        </div>}
        <div className={`${style.stepTwoBox}  ${step === 0 && style.fadeInTwo} ${step === 1 && style.fadeOutTwo}`}>
          <div className={style.downloadTitle}>
            <div>Export Options</div>
            <div onPointerDown={handleBack}>{"< "}Back</div>
          </div>
          <div
            className={`${style.row} ${downloadOpt0 === 0 ? style.selected : ""
              }`}
            onPointerDown={(ev) => setDownloadOpt0(0)}
          >
            <div className={style.priceCon}>
              <img className={style.icon} src={walletIcon} alt="wallet" />
              <div className={style.price}>0</div>
            </div>
            <div className={style.option}>Default</div>
            <div className={`${style.corner} ${style.sec}`}>More options</div>
          </div>
          <div
            className={`${style.row} ${downloadOpt0 === 1 ? style.selected : ""
              }`}
            onPointerDown={(ev) => setDownloadOpt0(1)}
          >
            <div className={style.priceCon}>
              <img className={style.icon} src={walletIcon} alt="wallet" />
              <div className={style.price}>20</div>
            </div>
            <div className={style.option}>MetaHuman</div>
            <div className={style.corner}>Default 2K</div>
          </div>
          <div className={style.costCon}>
            Payment amount: <span>{totalCost}</span>
          </div>
          {downloadOpt0 === 0 ? (
            <div
              className={style.downloadBtn}
              onPointerDown={(ev) => {
                setStep(1);
              }}
            >
              NEXT
            </div>
          ) : (
            <div
              className={style.downloadBtn}
              onPointerDown={(ev) => handlePay()}
            >
              PAY
            </div>
          )}</div>
        <div className={`${style.stepThreeBox}  ${step === 1 && style.fadeInThree}
        `}>
          <div className={style.downloadTitle}>
            <div>Export Options</div>
            <div onPointerDown={handleBack}>{"< "}Back</div>
          </div>
          <div style={{ overflowY: "auto", maxHeight: "20.5rem" }}>
            <div
              className={`${style.row} ${downloadOpt1.includes(0) ? style.selected : ""
                }`}
              onPointerDown={handleAdditionalClick(0)}
            >
              <div className={style.priceCon}>
                <img className={style.icon} src={walletIcon} alt="wallet" />
                <div className={style.price}>10</div>
              </div>
              <div className={style.option}>Rigged Body</div>
            </div>

            <div
              className={`${style.bodyOptCon} ${downloadOpt1.includes(0) ? "" : style.hide
                }`}
            >
              <div className={`${style.subRow}`}>
                <div
                  className={`${style.genderBtn} ${bodyOpt.gender === 0 ? style.selected : ""
                    }`}
                  onPointerDown={(ev) => setBodyOpt({ ...bodyOpt, gender: 0 })}
                >
                  Male
                </div>
                <div
                  className={`${style.genderBtn} ${bodyOpt.gender === 1 ? style.selected : ""
                    }`}
                  onPointerDown={(ev) => setBodyOpt({ ...bodyOpt, gender: 1 })}
                >
                  Female
                </div>
                <div
                  className={`${style.genderBtn} ${bodyOpt.gender === 2 ? style.selected : ""
                    }`}
                  onPointerDown={(ev) => setBodyOpt({ ...bodyOpt, gender: 2 })}
                >
                  Others
                </div>
              </div>
              <div className={`${style.subRow}`}>
                <div
                  className={`${style.genderBtn} ${autoParams === 0 ? style.selected : ""
                    }`}
                  onPointerDown={(ev) => {
                    setAutoParams(0);
                    setBodyOpt({ ...bodyOpt, weight: null, height: null });
                  }}
                >
                  Auto
                </div>
                <div
                  className={`${style.genderBtn} ${autoParams === 1 ? style.selected : ""
                    }`}
                  onPointerDown={(ev) => setAutoParams(1)}
                >
                  Manual
                </div>
              </div>
              <div>
                <div className={`${style.subRow}`}>
                  <div className={style.label}>Height</div>

                  <div className={style.inputCon}>
                    <input
                      type="range"
                      min="140"
                      max="220"
                      value={bodyOpt.height}
                      onChange={(ev) =>
                        setBodyOpt({
                          ...bodyOpt,
                          height: ev.currentTarget.value,
                        })
                      }
                      className={style.slider}
                      disabled={autoParams === 0}
                    />
                    <div style={{ width: "4.4rem", fontSize: "14.5px" }}>
                      <span className={style.suffixHeight}>
                        {autoParams === 1
                          ? bodyOpt.height
                            ? bodyOpt.height + " cm"
                            : "" + " cm"
                          : "Auto"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className={`${style.subRow}`}>
                  <div className={style.label}>Weight</div>

                  <div className={style.inputCon}>
                    <input
                      type="range"
                      min="40"
                      max="110"
                      value={bodyOpt.weight}
                      onChange={(ev) =>
                        setBodyOpt({
                          ...bodyOpt,
                          weight: ev.currentTarget.value,
                        })
                      }
                      className={style.slider}
                      disabled={autoParams === 0}
                    />
                    <div style={{ width: "4.4rem", fontSize: "14.5px" }}>
                      <span className={style.suffixWeight}>
                        {autoParams === 1
                          ? bodyOpt.weight
                            ? bodyOpt.weight + " kg"
                            : "" + " kg"
                          : "Auto"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${style.row} ${downloadOpt1.includes(1) ? style.selected : ""
                }`}
              onPointerDown={handleAdditionalClick(1)}
            >
              <div className={style.priceCon}>
                <img className={style.icon} src={walletIcon} alt="wallet" />
                <div className={style.price}>10</div>
              </div>
              <div className={style.option}>Eye & Teeth</div>
            </div>
            <div
              className={`${style.row} ${downloadOpt1.includes(2) ? style.selected : ""
                }`}
              onPointerDown={handleAdditionalClick(2)}
            >
              <div className={style.priceCon}>
                <img className={style.icon} src={walletIcon} alt="wallet" />
                <div className={style.price}>10</div>
              </div>
              <div className={style.option}>Expression BlendShapes</div>
            </div>
            <div
              className={`${style.row} ${downloadOpt1.includes(3) ? style.selected : ""
                }`}
              onPointerDown={handleAdditionalClick(3)}
            >
              <div className={style.priceCon}>
                <img className={style.icon} src={walletIcon} alt="wallet" />
                <div className={style.price}>10</div>
              </div>
              <div className={style.option}>Back head Textures</div>
            </div>
          </div>
          <div className={style.costCon}>
            Payment amount: <span>{totalCost}</span>
          </div>
          <div
            className={style.downloadBtn}
            onPointerDown={(ev) => handlePay()}
          >
            PAY
          </div>
        </div>
      </>}

      {/* paid tips */}
      {isPaid && !isBundleFinished ? (
        <div className={style.paidCon}>
          <img className={style.paidIcon} src={finishIconSrc} alt="finish" />
          <div className={style.paidTitle}>Payment Successful!</div>
          <div className={style.paidProgress}>
            Redirecting to model preview...
          </div>
        </div>
      ) : null}
    </div>
  );
}

export { BundlePanelFade };
