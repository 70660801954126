/* global _czc */
import style from './like.module.css'
import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { AiFillHeart } from "react-icons/ai";
import { logInfoAtom, showLoginAtom, cardsAtom, cardsTypeConst, cardsTypeAtom } from '../../../store'
import { likeCard } from '../../../utils/net'
import { useTips } from '../../GlobalTips'

const Like = (props) => {
  const { card: { is_like, task_uuid, user_state, num_like } } = props
  const logInfo = useRecoilValue(logInfoAtom)
  const setShowLogin = useSetRecoilState(showLoginAtom)
  const [cardsType, setCardsType] = useRecoilState(cardsTypeAtom)
  const [cards, setCards] = useRecoilState(cardsAtom);
  const [Like, setLike] = useState(is_like);
  const tip = useTips()

  const handleLikeClick = async (event, task_uuid) => {
    _czc.push(['_trackEvent', 'Index', 'Like Icon', 'Gallery Component']);
    if (!logInfo) setShowLogin(true)
    try {
      const res = await likeCard(task_uuid)
      if (res.data.message === 'SUCCESS_LIKE' || res.data.message === 'SUCCESS_DELIKE') {
        setLike(!Like)
        const newCards = JSON.parse(JSON.stringify(cards))
        const index = cards.findIndex(item => item.task_uuid === task_uuid)
        if (index === -1) return
        newCards[index].is_like = res.data.message === 'SUCCESS_LIKE'
        newCards[index].num_like = res.data.message === 'SUCCESS_LIKE' ? num_like + 1 : num_like - 1
        setCards(newCards)
      } else {
        throw new Error(res.data.message)
      }
    } catch (e) {
      tip({
        type: 'error',
        content: 'Something went wrong: ' + e.message,
      })
    }
  }
  useEffect(() => {
    setLike(is_like)
  }, [is_like])

  return <div
    className={`${style.likeCon} ${Like ? style.like : ''}`}
    onPointerDown={(event) => handleLikeClick(event, task_uuid)}
    style={
      (user_state === 'Generating' && cardsType === cardsTypeConst.Mine) ? { display: 'none' } : {}
    }
  >
    {Like ? (
      <AiFillHeart style={{ color: "red", fontSize: "16px" }} />
    ) : (
      <AiFillHeart
        style={{ color: "rgb(255,255,255)", fontSize: "16px" }}
      />
    )}
  </div>
}

export { Like }