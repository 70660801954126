/* global _czc */

import React, { useState } from "react";
import "./rechargepopup.css";
import { BsPaypal } from "react-icons/bs";
import { BsAlipay, BsGift } from "react-icons/bs";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  reedemCode,
  getUserInfo,
  fetchPaymentURLAlipay,
  fetchPaymentURLPaypal,
  fetchPriceAlipay,
  fetchPricePaypal,
  fetchPriceAlipayCNY,
  fetchPricePaypalUSD,
  getTradeHistory,
  getExpenseHistory,
} from "../../../utils/net";
import { isLoadingAtom, logInfoAtom, showHistoryAtom, filterListAtom, totalHistoryAtom, totalPageAtom, inHistoryAtom, outHistoryAtom } from '../../../store'
import { useTips } from "../../GlobalTips";
import HistoryPopup from '../HistoryPopup'
import { error } from "toastr";
import Loading from "../../widgets/Loading";

const RechargePopup = React.forwardRef((props, ref) => {
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("TOKEN");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [code, setCode] = useState("");
  const [logInfo, setLogInfo] = useRecoilState(logInfoAtom);
  const [showHistory, setShowHistory] = useRecoilState(showHistoryAtom);
  const setTotalPage = useSetRecoilState(totalPageAtom);
  const setTotalHistoryList = useSetRecoilState(totalHistoryAtom);
  const setInHistoryList = useSetRecoilState(inHistoryAtom);
  const setOutHistoryList = useSetRecoilState(outHistoryAtom);
  const setFilterList = useSetRecoilState(filterListAtom)
  const [paymentAmount, setPaymentAmount] = useState("");
  const [isLoading, setIsLoading] = useRecoilState(isLoadingAtom);
  const tip = useTips();

  const handleClose = () => {
    setTimeout(() => {
      setStep(1);
      setPaymentMethod("");
      setCurrency("TOKEN");
      setPaymentAmount(0)
      setAmount("")
      setCode("");
    }, 100);
  };

  const handleNext = async () => {
    if (amount) {
      try {
        let price = "";
        setPaymentAmount(0);
        if (paymentMethod === "Alipay") {
          if (currency === "CNY") {
            price = await fetchPriceAlipayCNY(Math.round(amount * 100));
            setPaymentAmount(price.data.currency_cny_cents);
            setAmount(price.data.token_amount);
          } else {
            price = await fetchPriceAlipay(Math.round(amount));
            if (price.data.error) {
              tip({
                type: "error",
                content: "Invalid request. Tokens must be an integer greater than 0.",
              });
              return;
            }
            setPaymentAmount(price.data.currency_cny_cents);
          }
        } else if (paymentMethod === "Paypal") {
          if (currency === "USD") {
            price = await fetchPricePaypalUSD(Math.round(amount * 100));
            setPaymentAmount(price.data.currency_usd_cents);
            setAmount(price.data.token_amount);
          } else {
            price = await fetchPricePaypal(Math.round(amount));
            if (price.data.error) {
              tip({
                type: "error",
                content: "Invalid request. Tokens must be an integer greater than 0.",
              });
              return;
            }
            setPaymentAmount(price.data.currency_usd_cents);
          }
        }
        setStep(step + 1);
      } catch (e) {
        tip({
          type: "error",
          content: "Fetch payment URL failed: " + e.message,
        });
      }
    }
  };

  const handleBack = async () => {
    setStep(step - 1);
  };

  const handleRedeem = async () => {
    _czc.push(["_trackEvent", "Pop Up", "Redeem", "Top Up Panel"]);
    try {
      const res = await reedemCode({ tcode: code });
      if (res.data.error === undefined) {
        const newInfo = await getUserInfo({
          user_uuid: localStorage.getItem("user_uuid"),
        });
        const newBal = newInfo.data.meta.balance;
        const codeVal = newInfo.data.meta.balance - logInfo.balance;
        setLogInfo({ ...logInfo, balance: newBal });
        setCode(codeVal + " TOKEN REDEEMED");
      }
      if (res.data.error) {
        throw new Error(res.data.error);
      }
    } catch (e) {
      if (e.message === "CODE_NOT_EXIST") setCode("CODE NOT EXIST");
    }
  };

  const handleAmountChange = (event) => {
    const inputValue = parseFloat(event.target.value);

    if (inputValue <= 0) {
      setAmount("1");
    } else {
      const roundedValue = isNaN(inputValue) ? "0" : Number(inputValue.toFixed(2)).toString();
      setAmount(roundedValue);
    }
  };


  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handlePaymentMethodChange = (method) => {
    setCurrency("TOKEN");
    if (method == "Alipay") {
      _czc.push(["_trackEvent", "Pop Up", "Alipay", "Top Up Panel"]);
    } else if (method == "Paypal") {
      _czc.push(["_trackEvent", "Pop Up", "Paypal", "Top Up Panel"]);
    } else {
      _czc.push(["_trackEvent", "Pop Up", "Redemption", "Top Up Panel"]);
    }

    setPaymentMethod(method);
    if (method === "Code") setStep(5);
    else setStep(step + 1);
  };

  const handlePaymentConfirmed = async () => {
    try {
      let redirectUrl = "";
      if (paymentMethod === "Alipay") {
        redirectUrl = await fetchPaymentURLAlipay({
          token_amount: Math.round(amount),
        });
      } else if (paymentMethod === "Paypal") {
        redirectUrl = await fetchPaymentURLPaypal({
          token_amount: Math.round(amount),
        });
      }

      if (redirectUrl.data.error) {
        throw new Error(redirectUrl.data.error);
      }
      window.location.href = redirectUrl.data.payment_url;
      handleClose();
    } catch (e) {
      tip({
        type: "error",
        content: "Fetch payment URL failed: " + e.message,
      });
    }
  };

  const handlePaymentFailure = () => {
    handleClose();
  };

  const handleGetRedeem = () => {
    _czc.push(["_trackEvent", "Pop Up", "Get Redeem", "Top Up Panel"]);
    window.open(
      "https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAANAAUIQFilUNjNGM0NLUUhEUEkxVlRFTkw0OVA3NzlVNS4u"
    );
  };

  const showHistoryDetail = async () => {
    setIsLoading(true)
    const [eradeHistory, expenseHistory] = await Promise.all([
      getTradeHistory({
        // userId:localStorage.getItem("token"),
        user_uuid: localStorage.getItem('user_uuid'),
        username: '',
      }),
      getExpenseHistory({
        // userId:localStorage.getItem("token"),
        user_uuid: localStorage.getItem('user_uuid'),
        username: '',
      })
    ]);
    const inList = [...(await eradeHistory).data]
    const outList = [...(await expenseHistory).data]
    const total = mergeSortArray(inList, outList)
    setTotalHistoryList(total)
    setInHistoryList(inList)
    setOutHistoryList(outList)
    setFilterList(total)
    setTotalPage(Math.ceil(total.length / 12))
    setIsLoading(false)
    setShowHistory(true)
  }

  const mergeSortArray = (arr1, arr2) => {
    const mergedArray = [];
    let i = 0, j = 0;
    while (i < arr1.length && j < arr2.length) {
      if (arr1[i].date <= arr2[j].date) {
        mergedArray.push(arr1[i]);
        i++;
      } else {
        mergedArray.push(arr2[j]);
        j++;
      }
    }
    while (i < arr1.length) {
      mergedArray.push(arr1[i]);
      i++;
    }
    while (j < arr2.length) {
      mergedArray.push(arr2[j]);
      j++;
    }
    return mergedArray;
  }

  return (
    <>
      <div className={`recharge-popup ${props.className}`} ref={ref}>
        <div>
          {step === 1 && (
            <div className={`recharge-popup-step2`}>
              <div className="recharge-popup-history">
                <h2 >Payment Method</h2>
                <span className="recharge-popup-history-title" onClick={showHistoryDetail}>History</span>
              </div>
              <div className="recharge-popup-payment-methods">
                <button
                  className={`recharge-popup-button
                    }`}
                  onClick={() => handlePaymentMethodChange("Paypal")}
                >
                  <BsPaypal size={24} />
                  Paypal
                </button>
                <button
                  className={`recharge-popup-button
                    }`}
                  onClick={() => handlePaymentMethodChange("Alipay")}
                >
                  <BsAlipay size={24} />
                  Alipay
                </button>

                <button
                  className={`recharge-popup-button ${paymentMethod === "Code" ? "selected" : ""
                    }`}
                  onClick={() => handlePaymentMethodChange("Code")}
                >
                  <BsGift size={24} />
                  Code Redemption
                </button>
                <button
                  className={`get-redeem-button`}
                  onClick={() => handleGetRedeem()}
                >
                  Looking for redeem code?
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className={`recharge-popup-step1`}>
              <h2>Top Up Amount</h2>
              <div className="recharge-popup-input-container">
                <input
                  className="recharge-popup-input"
                  type="number"
                  min="1"
                  step="1"
                  value={amount}
                  onChange={handleAmountChange}
                  placeholder="Amount"
                />
                <select
                  className="recharge-popup-select"
                  value={currency}
                  onChange={handleCurrencyChange}
                >
                  <option value="TOKEN">TOKEN</option>
                  {paymentMethod === "Alipay" && <option value="CNY">CNY</option>}
                  {paymentMethod === "Paypal" && <option value="USD">USD</option>}
                </select>
              </div>
              <button className="recharge-popup-button" onClick={handleNext}>
                Next Step
              </button>
              <button className="recharge-popup-button" onClick={handleBack}>
                Back
              </button>
            </div>
          )}

          {step === 3 && (
            <div className={`recharge-popup-step3`}>
              {currency === "TOKEN" && (
                <h2>
                  Price for {Math.round(amount)} token is {parseFloat(paymentAmount) / 100}{" "}
                  {paymentMethod === "Alipay" ? "CNY" : "USD"}.
                </h2>
              )}
              {(currency === "USD" || currency === "CNY") && (
                <h2>
                  You will get {amount} token(s) for {parseFloat(paymentAmount) / 100}{" "}
                  {paymentMethod === "Alipay" ? "CNY" : "USD"}.
                </h2>
              )}
              <p>Continue?</p>

              <button
                className="recharge-popup-button"
                onClick={handlePaymentConfirmed}
              >
                Yes
              </button>
              <button
                className="recharge-popup-button"
                onClick={handlePaymentFailure}
              >
                No
              </button>
            </div>
          )}

          {step === 4 && (
            <div className={`recharge-popup-step3`}>
              <h2>Top Up Success, thanks.</h2>
              <button className="recharge-popup-button" onClick={handleClose}>
                Back
              </button>
            </div>
          )}

          {step === 5 && (
            <div className={`recharge-popup-step1`}>
              <h2>Code Redemption</h2>
              <div className="recharge-popup-input-container">
                <input
                  className="recharge-popup-input"
                  value={code}
                  onChange={handleCodeChange}
                  placeholder="Code"
                />
              </div>
              <button className="recharge-popup-button" onClick={handleRedeem}>
                Redeem
              </button>
              <button className="recharge-popup-button" onClick={handleClose}>
                Back
              </button>
            </div>
          )}
          <Loading />
          {<HistoryPopup title={'Account Activity History'}></HistoryPopup>}
        </div>
      </div>
    </>
  );
});

export default RechargePopup;
