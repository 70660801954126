/*
import PanoramaViewer from "../widgets/PanoramaViewer";

const Panorama = () => {
  return <div style={{marginTop:"-80px"}}>
    <PanoramaViewer autoRotate={true} imgURL={"./panorama_default.jpg"}/>
  </div>;
};

export { Panorama };
*/

// src/components/Toolbar.js

import React, { useEffect, useState, useRef } from "react";
import style from "./panorama.module.css";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  bodyOpacityTransitionAtom,
  showPanoramaBordAtom,
  showCardsConAtom,
  clickHideAtom,
  isEditorOpenAtom
} from "../../store";
import { PanoramaGallery } from "../Gallery/Panorama";
import PanoramaEditor from "../widgets/PanoramaEditor";
import PanoramaViewer from "../widgets/PanoramaViewer";
import { PanoramaBoard } from "../Board/PanoramaBoard";
import { panoramaGenerate, getBanner, } from '../../utils/net'


const Panorama = () => {
  const brushThumbRef = useRef(null);
  const eraserThumbRef = useRef(null);
  const generateBtnRef = useRef(null);
  const styleKeyWordsBoxRef = useRef(null);
  const panoramaRef = useRef(null);
  const setBodyOpacityTransition = useSetRecoilState(bodyOpacityTransitionAtom);
  const [showPanoramaBord, setShowPanoramaBord] = useRecoilState(showPanoramaBordAtom);
  const [isEditorOpen, setIsEditorOpen] = useRecoilState(isEditorOpenAtom);
  const [tranform, setTranform] = useState(false);
  const [backgroundVisible, setBackgroundVisible] = useState(false);
  const [moveTransition, setMoveTransition] = useState(false);
  const [brushController, setBrushController] = useState(false);
  const [eraserController, setEraserController] = useState(false);
  const [activeTool, setActiveTool] = useState("pan");
  const [activeGrid, setActiveGrid] = useState("sphere");
  const [plainGrid, setPlainGrid] = useState(false);
  const [cursor, setCursor] = useState("move");
  const [showPanoramaViewer, setShowPanoramaViewer] = useState(true);
  const [currentAction, setCurrentAction] = useState("none");
  const [brushSize, setBrushSize] = useState(10);
  const [currentBrushValue, setCurrentBrushValue] = useState(57);
  const [currentEraserValue, setcurrentEraserValue] = useState(57);
  const [currentStyleKeyIndex, setCurrentStyleKeyIndex] = useState(-1);
  const [isDepth, setIsDepth] = useState(false);
  const [generateAnima, setGenerateAnima] = useState(false);
  const [showCardsCon, setShowCardsCon] = useRecoilState(showCardsConAtom);
  const [showMenuTips, setShowMenuTips] = useState(false);
  const [generateProgress, setGenerateProgress] = useState(0)
  const [isAutoRotate, setIsAutoRotate] = useState(true)
  const [generateflag, setGenerateflag] = useState(false)
  const [clickHide, setClickHide] = useRecoilState(clickHideAtom);
  const [inputPromptValue, setInputPromptValue] = useState('');
  const [showPromptEmpty, setShowPromptEmpty] = useState(false);

  // Implement handlers for the toolbar buttons

  useEffect(() => {
    if (isEditorOpen) {
      const timer = setTimeout(() => {
        setShowPanoramaViewer(false);
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    } else {
      setShowPanoramaViewer(true);
      return () => {
        setActiveTool("pan")
        setActiveGrid('sphere')
        setCurrentAction('none')
        setInputPromptValue('')
        setBrushController(false)
        setEraserController(false)
      }
    }
  }, [isEditorOpen]);


  useEffect(() => {
    const getBannerImg = async () => {
      const banner = await getBanner()
      console.log(banner, 'banner');
    }
    getBannerImg()
    setIsAutoRotate(true)
    setTimeout(() => {
      setShowMenuTips(true)
    }, 500)
    setTimeout(() => {
      setShowMenuTips(false)
    }, 3500);
    setTranform(true);
    setShowPanoramaBord(false);
    const handleClickOutside = (e) => {
      if (
        (styleKeyWordsBoxRef.current && !styleKeyWordsBoxRef.current.contains(e.target))
        &&
        (generateBtnRef.current && !generateBtnRef.current.contains(e.target))) {
        setMoveTransition(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      setIsEditorOpen(false)
    };
  }, []);

  const handleToolClick = (target) => {
    console.log(target, 'targettargettargettargettarget');
    const eventMap = {
      pan: () => {
        setActiveTool("pan");
        setBrushController(false);
        setEraserController(false);
        setCursor("move");
      },
      brush: () => {
        setActiveTool("brush");
        setBrushController(true);
        setEraserController(false);
        setCursor("default");
      },
      eraser: () => {
        setActiveTool("eraser");
        setEraserController(true);
        setBrushController(false);
        setCursor("default");
      },
      clear: () => {
        setCurrentAction("clear");
      },
      undo: () => {
        setCurrentAction("undo");
      },
      redo: () => {
        setCurrentAction("redo");
      },
      sphere: () => {
        activeGrid !== "sphere"
          ? setActiveGrid("sphere")
          : setActiveGrid("none");
      },
      cube: () => {
        activeGrid !== "cube" ? setActiveGrid("cube") : setActiveGrid("none");
      },
      viewbg: () => {
        setBackgroundVisible(!backgroundVisible);
      },
      plain: () => {
        setPlainGrid(!plainGrid);
      },
    };
    console.log(target);
    if (eventMap[target]) {
      eventMap[target]();
    }
  };

  const handlerOpen = () => {
    setShowMenuTips(false)
    setIsAutoRotate(false)
    setBodyOpacityTransition(true);
    setTimeout(() => {
      setIsEditorOpen(true);
    }, 300);
  };

  const handlerClickInput = (e) => {
    e.stopPropagation();
    setMoveTransition(true);
  };

  const handleSlideStart = (target, event) => {
    if (event.button !== 0) return;
    const startX = event.clientX;
    const curPosition =
      target === "brush"
        ? brushThumbRef.current.offsetLeft
        : eraserThumbRef.current.offsetLeft;
    let newLeft
    function handleMouseMove(e) {
      const endX = e.clientX;
      newLeft = curPosition + endX - startX;
      if (newLeft < 0) {
        newLeft = 0;
      }
      if (newLeft > 143) {
        newLeft = 143;
      }
      target === "brush"
        ? setCurrentBrushValue(newLeft)
        : setcurrentEraserValue(newLeft);
      setBrushSize(~~((newLeft / 143) * 20) + 5);
    }

    function handleMouseUp() {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }
    event.stopPropagation()
    event.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

  };

  const handlerGenerate = async (e) => {
    e.stopPropagation()
    const promptValue = inputPromptValue.trim()
    if (!promptValue) {
      setShowPromptEmpty(true)
      setTimeout(() => {
        setShowPromptEmpty(false)
      }, 1000);
      return
    }

    // const res = await panoramaGenerate(promptValue,styleKeyWordsArr[currentStyleKeyIndex],isDepth)
    // console.log(res);

    //GET PROGRESS
    // const progress = await checkProgress(uuid)

    setGenerateAnima(true);
    setGenerateflag(true)
    handleToolClick('pan')

    setTimeout(() => {
      setMoveTransition(false)
      setInputPromptValue('')
      setGenerateProgress(0)
      setBodyOpacityTransition(true);
      setGenerateflag(false)
      setGenerateAnima(false)
      setTimeout(() => {
        setIsEditorOpen(false)
        setShowPanoramaBord(true)
      }, 500);
    }, 5000);
  }


  useEffect(() => {
    if (!generateflag) return
    const timer = setInterval(() => {
      // 模拟进度增长
      setGenerateProgress((prevProgress) => {
        if (prevProgress === 200) {
          clearInterval(timer);
          return prevProgress;
        }
        return prevProgress + 1;
      });
    }, 25);

    return () => {
      clearInterval(timer);
    };
  }, [generateflag]);

  const handlerPointerDown = (e) => {
    // setShowCardsCon(false)
    if (panoramaRef.current && panoramaRef.current.contains(e.target)) {
      if (showPanoramaBord) {
        setClickHide(true)
      }
    }
  }

  const styleKeyWordsArr = [
    "Cyberpuck",
    "Line",
    "Painting",
    "Digital Camera",
    "Style 1",
    "Style 2",
    "Style 3",
    "Style 4",
    "Style 5",
  ];

  return (
    <div className={style.panoramaContainer} onClick={(handlerPointerDown)}>
      <div className={style.panoramaWrapper}>
        {/* <div className={`${style.panoramaLandingPage} ${showPanoramaViewer ? style.showPanoramaViewer : style.hidePanoramaViewer}`} ref={panoramaRef} >
          <PanoramaViewer
            autoRotate={isAutoRotate}
            imgURL={"./panorama_default.jpg"}
          />
        </div> */}

        {showPanoramaViewer && <div className={style.panoramaLandingPage} ref={panoramaRef} >
          <PanoramaViewer
            autoRotate={isAutoRotate}
            imgURL={"./panorama_default.jpg"}
          />
        </div>}
        {/* Editor */}
        <div className={`${isEditorOpen ? style.editorshow : style.editorhidden}`}>
          <div
            className={`${style.toolWrapper} ${generateflag ? style.disabledStyle : ''}`}
            style={{
              pointerEvents: generateflag ? 'none' : 'auto'
            }}>
            <div
              className={style.toolTransformBox}
              style={{
                transform: moveTransition
                  ? "translateY(-79px)"
                  : "translateY(0)",
              }}
            >
              <div className={style.toolBar}>
                <div
                  className={`${style.tool} ${activeTool === "pan"
                    ? style.activeTool
                    : style.unActiveTool
                    }`}
                  onClick={handleToolClick.bind(null, "pan")}
                  style={{
                    backgroundColor:
                      activeTool === "pan"
                        ? "rgba(215,255,55,0.2)"
                        : "#525252",
                  }}
                >
                  <div className={style.toolTip} style={{ width: 41 }}>
                    <span>Move</span>
                  </div>
                  <svg
                    width="16"
                    height="16"
                    style={{
                      fill: activeTool === "pan" ? "#D7FF37" : "#ABABAB",
                    }}
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14.8922 5.17952C14.8922 5.17952 14.9052 4.02552 13.9542 3.96952C13.4562 3.94052 13.3472 4.22952 13.1592 5.06952L12.6242 6.55152L13.0102 3.74152C13.1152 2.83252 12.6172 2.29952 11.9332 2.22352C11.2482 2.14852 10.6592 2.49452 10.5322 3.46952L10.0932 6.23052L10.0912 2.58152C10.0322 1.81052 9.5842 1.22652 8.8332 1.18952C8.0812 1.15252 7.4732 1.69852 7.4052 2.44852L7.3792 6.14552L6.2712 2.70652C5.9302 1.90552 5.2872 1.62252 4.6062 1.89452C3.9252 2.16652 3.7032 2.88252 3.8662 3.61952L5.2292 8.81452L3.5072 7.04152C3.0572 6.54752 2.2472 6.31552 1.6532 6.74552C1.0602 7.17552 0.928202 7.90952 1.1812 8.57152C1.1812 8.57152 1.2612 8.86952 2.1582 10.0035C3.1062 11.2015 5.4592 13.4105 5.8562 14.8135L12.7612 14.8145C12.7612 14.8145 13.1832 13.3725 13.8932 11.5475C14.6822 9.51452 15.0912 6.50952 14.8922 5.17952Z" />
                  </svg>
                </div>
                <div
                  className={`${style.tool} ${activeTool === "brush"
                    ? style.activeTool
                    : style.unActiveTool
                    }`}
                  onClick={handleToolClick.bind(null, "brush")}
                  style={{
                    backgroundColor:
                      activeTool === "brush"
                        ? "rgba(215,255,55,0.2)"
                        : "#525252",
                  }}
                >
                  <div
                    className={`${style.brushController} ${brushController && !eraserController
                      ? style.controllerShow
                      : style.controllerHide
                      }`}
                  >
                    <div
                      className={style.fillBox}
                      style={{
                        width: `${currentBrushValue}px`,
                      }}
                    ></div>
                    <div
                      className={style.thumb}
                      ref={brushThumbRef}
                      onMouseDown={handleSlideStart.bind(null, "brush")}
                      style={{
                        left: `${currentBrushValue}px`,
                      }}
                    ></div>
                  </div>
                  <div className={style.toolTip} style={{ width: 43 }}>
                    <span>Brush</span>
                  </div>
                  <svg
                    width="16"
                    style={{
                      fill: activeTool === "brush" ? "#D7FF37" : "#ABABAB",
                    }}
                    height="17"
                    viewBox="0 0 16 17"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.47292 9.77338C7.81336 10.0996 8.25812 10.2831 8.72127 10.2886C8.76528 10.2924 8.80955 10.2862 8.85101 10.2704C8.89247 10.2546 8.93012 10.2296 8.96134 10.1972L14.1708 5.01971C14.3619 4.82382 14.5119 4.58894 14.6114 4.32987C14.7108 4.0708 14.7574 3.79315 14.7484 3.51442C14.7394 3.2357 14.6749 2.96193 14.5589 2.71038C14.443 2.45882 14.2782 2.23493 14.0748 2.05282C13.8777 1.87623 13.6483 1.74273 13.4004 1.66028C13.1525 1.57783 12.8912 1.54813 12.632 1.57295C12.3728 1.59778 12.121 1.67661 11.8918 1.80476C11.6626 1.9329 11.4607 2.10772 11.298 2.31876L7.00879 8.41875C6.98236 8.4567 6.96374 8.4999 6.9541 8.54566C6.94446 8.59141 6.94401 8.63874 6.95278 8.68469C7.0139 9.09461 7.19558 9.47487 7.47292 9.77338ZM1.43923 12.6904C1.61449 12.7402 1.80018 12.7334 1.97167 12.6711C2.14317 12.6088 2.29237 12.4938 2.3995 12.3414C2.7516 11.8095 2.85563 10.6792 3.61584 9.98114C3.89549 9.71239 4.22724 9.50884 4.58884 9.38414C4.95044 9.25944 5.33353 9.21647 5.71243 9.25812C5.75671 9.26391 5.79817 9.28379 5.83113 9.31504C5.86408 9.34629 5.88691 9.38737 5.89648 9.43264C5.96891 9.97833 6.23071 10.4778 6.63269 10.8371C7.09809 11.1441 7.61323 11.3609 8.15311 11.4771C8.20045 11.485 8.24346 11.5104 8.27431 11.5485C8.30515 11.5866 8.32178 11.635 8.32116 11.6848C8.36917 12.4162 8.32116 15.1753 4.81618 15.0589C0.799048 14.9924 1.08713 12.5574 1.43923 12.6904Z" />
                  </svg>
                </div>
                <div
                  className={`${style.tool} ${activeTool === "eraser"
                    ? style.activeTool
                    : style.unActiveTool
                    }`}
                  onClick={handleToolClick.bind(null, "eraser")}
                  style={{
                    backgroundColor:
                      activeTool === "eraser"
                        ? "rgba(215,255,55,0.2)"
                        : "#525252",
                  }}
                >
                  <div
                    className={`${style.eraserController} ${eraserController && !brushController
                      ? style.controllerShow
                      : style.controllerHide
                      }`}
                  >
                    <div
                      className={style.fillBox}
                      style={{
                        width: `${currentEraserValue}px`,
                      }}
                    ></div>
                    <div
                      className={style.thumb}
                      ref={eraserThumbRef}
                      onMouseDown={handleSlideStart.bind(null, "eraser")}
                      style={{
                        left: `${currentEraserValue}px`,
                      }}
                    ></div>
                  </div>
                  <div className={style.toolTip} style={{ width: 46 }}>
                    <span>Eraser</span>
                  </div>
                  <svg
                    width="16"
                    height="17"
                    style={{
                      fill: activeTool === "eraser" ? "#D7FF37" : "#ABABAB",
                    }}
                    viewBox="0 0 16 17"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.40284 15.8096C5.94495 15.8818 5.46003 15.7415 5.10716 15.3887L1.33534 11.6168C0.74955 11.031 0.749549 10.0813 1.33534 9.49552L8.77115 2.0597C9.35694 1.47391 10.3067 1.47392 10.8925 2.0597L14.6643 5.83153C15.2501 6.41732 15.2501 7.36706 14.6643 7.95285L8.61699 14.0002H12.4314C12.9266 14.0002 13.328 14.4097 13.328 14.9049C13.328 15.4 12.9266 15.8096 12.4314 15.8096H6.40284ZM6.42565 14.0002C6.6212 13.966 6.80854 13.8733 6.95958 13.7223L8.36975 12.3121C8.76027 11.9216 8.76027 11.2884 8.36975 10.8979L5.82611 8.35426C5.43558 7.96374 4.80242 7.96374 4.4119 8.35426L3.00172 9.76443C2.6112 10.155 2.6112 10.7881 3.00172 11.1786L5.54537 13.7223C5.71064 13.8876 5.91937 13.9829 6.13477 14.0083C6.17401 14.0029 6.21406 14.0002 6.25474 14.0002H6.42565Z"
                    />
                  </svg>
                </div>
                <div
                  className={`${style.tool} ${style.unActiveTool}`}
                  onClick={handleToolClick.bind(null, "clear")}
                >
                  <div className={style.toolTip} style={{ width: 53 }}>
                    <span>Clear all</span>
                  </div>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.58172 1.11353C9.13401 1.11353 9.58172 1.56124 9.58172 2.11353V4.30374H13.3189C13.7607 4.30374 14.1189 4.66191 14.1189 5.10374V6.15862C14.1189 6.60044 13.7607 6.95862 13.3189 6.95862H2.68184C2.24001 6.95862 1.88184 6.60044 1.88184 6.15862V5.10374C1.88184 4.66191 2.24001 4.30374 2.68184 4.30374H6.41839V2.11353C6.41839 1.56124 6.86611 1.11353 7.41839 1.11353L8.58172 1.11353ZM2.578 9.29498C2.578 8.74269 3.02571 8.29498 3.578 8.29498H12.4226C12.9749 8.29498 13.4226 8.74269 13.4226 9.29498V14.8863H11.5943V11.0937C11.5943 10.7357 11.3041 10.4454 10.9461 10.4454C10.5881 10.4454 10.2978 10.7357 10.2978 11.0937V14.8863H8.64857V11.0937C8.64857 10.7357 8.35835 10.4454 8.00033 10.4454C7.64232 10.4454 7.35209 10.7357 7.35209 11.0937V14.8863H5.70282V11.0937C5.70282 10.7357 5.41259 10.4454 5.05458 10.4454C4.69657 10.4454 4.40634 10.7357 4.40634 11.0937V14.8863H2.578V9.29498Z"
                    />
                  </svg>
                </div>
                <div
                  className={`${style.tool} ${style.unActiveTool}`}
                  onClick={handleToolClick.bind(null, "undo")}
                >
                  <div className={style.toolTip} style={{ width: 40 }}>
                    <span>Undo</span>
                  </div>
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.42942 4.58904V2.51247C7.24274 1.64389 6.53152 2.17306 6.53152 2.17306L1.59156 6.43883C0.505448 7.19325 1.51751 7.76099 1.51751 7.76099L6.38342 11.9897C7.35691 12.7071 7.43096 11.6118 7.43096 11.6118V9.68637C12.3709 8.13742 14.392 14.3317 14.392 14.3317C14.5786 14.6711 14.6913 14.3317 14.6913 14.3317C16.5997 5.04262 7.42942 4.58904 7.42942 4.58904Z" />
                  </svg>
                </div>
                <div
                  className={`${style.tool} ${style.unActiveTool}`}
                  onClick={handleToolClick.bind(null, "redo")}
                >
                  <div className={style.toolTip} style={{ width: 40 }}>
                    <span>Redo</span>
                  </div>
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.57058 4.96331V2.88674C8.75726 2.01816 9.46848 2.54733 9.46848 2.54733L14.4084 6.8131C15.4946 7.56751 14.4825 8.13525 14.4825 8.13525L9.61658 12.364C8.64309 13.0814 8.56904 11.986 8.56904 11.986V10.0606C3.62908 8.51169 1.60805 14.7059 1.60805 14.7059C1.42137 15.0453 1.30875 14.7059 1.30875 14.7059C-0.599662 5.41689 8.57058 4.96331 8.57058 4.96331Z" />
                  </svg>
                </div>
                <div
                  className={`${style.tool} ${activeGrid === "sphere"
                    ? style.activeTool
                    : style.unActiveTool
                    }`}
                  onClick={handleToolClick.bind(null, "sphere")}
                  style={{
                    backgroundColor:
                      activeGrid === "sphere"
                        ? "rgba(215,255,55,0.2)"
                        : "#525252",
                  }}
                >
                  <div className={style.toolTip} style={{ width: 68 }}>
                    <span>Sphere grid</span>
                  </div>
                  <svg
                    width="16"
                    height="17"
                    style={{
                      fill: activeGrid === "sphere" ? "#D7FF37" : "#ABABAB",
                    }}
                    viewBox="0 0 16 17"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.4079 5.49437C10.0133 3.0639 9.08204 1.35815 7.99958 1.35815C6.91712 1.35815 5.98587 3.0639 5.59124 5.49437H10.4079ZM4.29267 8.29147C4.29267 7.70691 4.32587 7.1205 4.38303 6.55068H1.26105C1.11353 7.10943 1.02686 7.68847 1.02686 8.29147C1.02686 8.89448 1.11353 9.47535 1.26105 10.0323H4.37934C4.32587 9.46244 4.29267 8.87788 4.29267 8.29147ZM10.1444 1.35822C12.0918 1.93541 13.6666 3.36455 14.4448 5.21229H11.5054C11.2656 3.66328 10.8083 2.2784 10.1444 1.35822ZM1.66256 5.34738C2.43707 3.49964 4.01558 2.07049 5.95921 1.49331C5.29535 2.41349 4.83802 3.79837 4.60199 5.34738H1.66256ZM14.7404 6.42777H11.6203C11.6775 6.99943 11.7107 7.58399 11.7107 8.16856C11.7107 8.75312 11.6775 9.33953 11.6203 9.90934H14.7386C14.888 9.35059 14.9728 8.77156 14.9728 8.16856C14.9728 7.56555 14.888 6.98467 14.7404 6.42777ZM14.6258 11.1249C13.8476 12.9726 12.2691 14.4018 10.3255 14.979C10.9893 14.0606 11.4448 12.6757 11.6846 11.1249H14.6258ZM1.7063 11.1249C2.48449 12.9726 4.05931 14.4018 6.00663 14.979C5.34277 14.0588 4.88545 12.6739 4.64572 11.1249H1.7063ZM5.38852 8.3132C5.38852 8.91805 5.42171 9.49708 5.47887 10.054H10.5205C10.5777 9.49523 10.6109 8.9162 10.6109 8.3132C10.6109 7.71019 10.5777 7.12931 10.5205 6.57241H5.47887C5.42171 7.12931 5.38852 7.71019 5.38852 8.3132ZM7.99977 15.2683C6.91731 15.2683 5.98606 13.5625 5.59143 11.1321H10.4081C10.0135 13.5625 9.08223 15.2683 7.99977 15.2683Z"
                    />
                  </svg>
                </div>
                <div
                  className={`${style.tool} ${activeGrid === "cube"
                    ? style.activeTool
                    : style.unActiveTool
                    }`}
                  onClick={handleToolClick.bind(null, "cube")}
                  style={{
                    backgroundColor:
                      activeGrid === "cube"
                        ? "rgba(215,255,55,0.2)"
                        : "#525252",
                  }}
                >
                  <div className={style.toolTip} style={{ width: 60 }}>
                    <span>Cube grid</span>
                  </div>
                  <svg
                    width="16"
                    height="17"
                    style={{
                      fill: activeGrid === "cube" ? "#D7FF37" : "#ABABAB",
                    }}
                    viewBox="0 0 16 17"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.601 4.61955C12.8083 4.51093 12.9071 4.32331 12.8873 4.14556C12.9071 3.95794 12.8083 3.78019 12.601 3.67157L8.13753 1.3411C7.94004 1.23248 7.70304 1.18311 7.46604 1.18311C7.22905 1.18311 6.99205 1.23248 6.79455 1.3411L2.33112 3.66169C2.12375 3.77032 2.03487 3.95794 2.04475 4.13569C2.03487 4.32331 2.12375 4.51093 2.33112 4.61955L6.79455 6.94014C6.99205 7.04877 7.22905 7.09814 7.46604 7.09814C7.70304 7.09814 7.94004 7.04877 8.13753 6.94014L12.601 4.61955ZM6.81468 8.42512L2.43024 6.05515C2.02537 5.83791 1.5415 6.1539 1.5415 6.64764V11.2691C1.5415 11.7529 1.78838 12.1874 2.19324 12.4047L6.57768 14.7746C6.98255 14.9919 7.46642 14.666 7.46642 14.1723V9.56073C7.46642 9.07686 7.21954 8.64236 6.81468 8.42512ZM9.18524 7.94344L13.5697 5.5636C13.9745 5.34635 14.4584 5.66235 14.4584 6.16597V10.7578C14.4584 11.2416 14.2017 11.6761 13.8067 11.8934L9.42223 14.2732C9.01736 14.5003 8.5335 14.1745 8.5335 13.6807V9.07905C8.5335 8.59518 8.78037 8.16069 9.18524 7.94344Z"
                    />
                  </svg>
                </div>
                <div
                  className={`${style.tool} ${plainGrid ? style.activeTool : style.unActiveTool
                    }`}
                  onClick={handleToolClick.bind(null, "plain")}
                  style={{
                    backgroundColor: plainGrid
                      ? "rgba(215,255,55,0.2)"
                      : "#525252",
                  }}
                >
                  <div className={style.toolTip} style={{ width: 62 }}>
                    <span>Plane grid</span>
                  </div>
                  <svg
                    width="16"
                    height="17"
                    style={{
                      fill: plainGrid ? "#D7FF37" : "#ABABAB",
                    }}
                    viewBox="0 0 16 17"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.8055 13.1509H15.747L14.2597 9.96984H10.3926L10.8055 13.1509ZM10.2758 9.06984L9.98566 6.83492H12.794L13.8389 9.06984H10.2758ZM9.38279 9.06984H6.08596L6.37606 6.83492L9.09269 6.83492L9.38279 9.06984ZM5.96913 9.96984H9.49962L9.91253 13.1509H5.55622L5.96913 9.96984ZM5.19299 9.06984L5.48309 6.83492H2.90354L1.96562 9.06984H5.19299ZM1.58792 9.96984H5.07617L4.66325 13.1509H0.25293L1.58792 9.96984ZM11.3694 3.78784L12.3733 5.93492H9.86883L9.59013 3.78784H11.3694ZM8.69716 3.78784L8.97587 5.93492H6.49288L6.77158 3.78784H8.69716ZM5.87862 3.78784L5.59992 5.93492H3.28124L4.1823 3.78784H5.87862Z"
                    />
                  </svg>
                </div>
                <div
                  className={`${style.tool} ${backgroundVisible ? style.activeTool : style.unActiveTool
                    }`}
                  onClick={handleToolClick.bind(null, "viewbg")}
                  style={{
                    backgroundColor: backgroundVisible
                      ? "rgba(215,255,55,0.2)"
                      : "#525252",
                  }}
                >
                  <div className={style.toolTip} style={{ width: 72 }}>
                    <span>View skybox</span>
                  </div>
                  <svg
                    width="16"
                    height="17"
                    style={{
                      fill: backgroundVisible ? "#D7FF37" : "#ABABAB",
                    }}
                    viewBox="0 0 16 17"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14.508 5.59509C14.508 4.40197 13.5319 3.42578 12.3387 3.42578H3.6615C2.46838 3.42578 1.49219 4.40197 1.49219 5.59509V12.103C1.49219 13.2961 2.46838 14.2723 3.6615 14.2723H12.3387C13.5319 14.2723 14.508 13.2961 14.508 12.103V5.59509ZM4.74615 5.59509C5.34271 5.59509 5.83081 6.08319 5.83081 6.67975C5.83081 7.27631 5.34271 7.7644 4.74615 7.7644C4.14959 7.7644 3.6615 7.27631 3.6615 6.67975C3.6615 6.08319 4.14959 5.59509 4.74615 5.59509ZM8.02867 10.9711C8.02867 10.9711 7.58755 11.4418 7.32268 11.5716C7.05781 11.7014 6.77899 11.6609 6.47062 11.4661C6.16226 11.2713 6.74846 11.6416 6.47062 11.4661C6.19279 11.2906 6.11357 11.1699 5.89853 11.1699C5.68349 11.1699 5.47224 11.4617 5.34266 11.5959C5.1339 11.8123 4.93287 12.022 4.93287 12.022C4.93287 12.022 4.41352 12.5413 4.10516 12.805C3.79679 13.0688 3.6345 13.0972 3.44786 12.9187C3.28556 12.7361 3.3353 12.4292 3.4988 12.2657L5.45118 10.3133C5.66811 10.0964 5.99351 10.0964 6.21044 10.3133L6.53583 10.6387C6.75276 10.8557 7.07816 10.8557 7.29509 10.6387L10.9141 7.01977C11.131 6.80283 11.3799 6.7833 11.5441 6.96221C11.7084 7.14112 11.5441 7.4362 11.5441 7.4362L10.8308 8.21659L8.02867 10.9711Z" />
                  </svg>
                </div>
                <div
                  className={`${style.depthTool} ${isDepth ? style.showDepth : ""
                    }`}
                  onClick={() => setIsDepth(!isDepth)}
                >
                  Generate depth
                </div>
              </div>
              <div className={style.gradientRadius}
                style={{
                  background: `radial-gradient(farthest-corner at 100% 100%, #D7FF37, #80DFA3, transparent ${generateProgress}%)`,
                }}
              >
                <div className={`${style.generateInput} ${showPromptEmpty ? style.promptEmpty : style.prompt}`}>
                  <input
                    onClick={(e) => handlerClickInput(e)}
                    onChange={(e) => setInputPromptValue(e.target.value)}
                    value={inputPromptValue}
                    type="text"
                    disabled={showPromptEmpty}
                    placeholder={showPromptEmpty ? 'The prompt is required' : "Unleash your imagination"}
                  />
                  <div
                    className={style.generateBtn}
                    ref={generateBtnRef}
                  >
                    {generateAnima ? <div className={style.generateAnimaBox}>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div> : <span
                      onClick={handlerGenerate}
                    >Generate</span>}
                  </div>
                </div>
              </div>
              <div
                className={style.styleKeyWordsBox}
                ref={styleKeyWordsBoxRef}
              >
                {styleKeyWordsArr.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setCurrentStyleKeyIndex(index)}
                      className={`${style.styleKeyWords} ${currentStyleKeyIndex === index ? style.selected : ""
                        }`}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={style.panoramaEditor} style={{ cursor: cursor }}>
            <PanoramaEditor
              backgroundVisible={backgroundVisible}
              activeTool={activeTool}
              activeGrid={activeGrid}
              brushSize={brushSize}
              activePlainGrid={plainGrid}
              currentAction={currentAction}
              setCurrentAction={setCurrentAction}
            />
          </div>
        </div>
        {/* Home Page */}
        <div className={`${!showPanoramaBord && !isEditorOpen ? style.homeshow : style.homehidden}`}>
          <div
            className={` ${style.titleBox} ${tranform ? style.tranform : ""}`}
          >
            <div className={style.title}>Panorama</div>
            <div className={`${style.versionTitle}`}>
              Unleash Your Imagination <br></br>Shape Your{" "}
              <u>
                <b>Virtual Cosmos</b>
              </u>{" "}
              through Line Drawings
            </div>
          </div>
          <div
            className={`${style.exploreBtn}  ${style.titleBox} ${tranform ? style.tranform : ""
              }`}
            onClick={handlerOpen}
          >
            Explore
          </div>
        </div>
        {/* Board */}
        <div className={`${showPanoramaBord && !isEditorOpen ? style.boadrshow : style.boardhidden}`}>
          <PanoramaBoard></PanoramaBoard>
        </div>
        <PanoramaGallery></PanoramaGallery>

        <div className={`${style.menuTips} ${showMenuTips ? style.menuTipsShow : style.menuTipsHide} ${style.smallScreen}`}>
          Hi , all the assets are here !
        </div>
      </div>
    </div>
  );
};

export default Panorama;