import style from './panoramaGallery.module.css'
import { useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  logInfoAtom,
  showCardsConAtom,
  showPanoramaBordAtom,
  panoramaCardsTypeAtom,
  bodyOpacityTransitionAtom,
  isEditorOpenAtom
} from '../../../store'
import { Menu } from '../../widgets/Menu'
import { XScroll } from '../../widgets/XScroll'
import backBtn from '../../../assets/backBtn.png'
import { queryPanaromaCards } from '../../../utils/net'


const PanoramaGallery = () => {
  const logInfo = useRecoilValue(logInfoAtom)
  const [panoramaCardsType, setPanoramaCardsType] = useRecoilState(panoramaCardsTypeAtom);
  const [showCardsCon, setShowCardsCon] = useRecoilState(showCardsConAtom);
  const [showPanoramaBord, setShowPanoramaBord] = useRecoilState(showPanoramaBordAtom);
  const setBodyOpacityTransition = useSetRecoilState(bodyOpacityTransitionAtom);
  const [isEditorOpen, setIsEditorOpen] = useRecoilState(isEditorOpenAtom);
  const cardsConRef = useRef(null);
  useEffect(() => {
    return () => {
      setShowCardsCon(false)
    }
  }, [])

  useEffect(() => {
    document.documentElement.style.overflowY = showCardsCon ? 'hidden' : 'overlay'
  }, [showCardsCon])

  useEffect(() => {
    fetchData(panoramaCardsType)
  }, [panoramaCardsType])

  const fetchData = async (type) => {
    const result = await queryPanaromaCards(0, type)
    console.log(result, 'result');
  }

  const handlerClickCard = () => {
    setShowCardsCon(false)
    setShowPanoramaBord(true)
    if (isEditorOpen) {
      setBodyOpacityTransition(true);
      setTimeout(() => {
        setIsEditorOpen(false);
      }, 300);
    }
  }

  return <div className={style.panoramaGalleryWrapper}>
    <Menu menuType={'panorama'}></Menu>
    <div
      className={`${style.cardsCon} ${showCardsCon ? style.showCardsCon : style.hideCardsCon}`}
      style={{
        pointerEvents: showCardsCon ? 'all' : 'none',
      }}
      ref={cardsConRef}>
      <div className={style.backBox} onClick={() => { setShowCardsCon(false) }}>
        <img src={backBtn} alt="back" />
      </div>
      <XScroll
        customStyle={{
          width: '100%',
          height: '100%',
        }}
        contentStyle={'widthAuto'}
      >
        <div
          className={style.mainBox}>
          {new Array(20).fill(0).map((item, index) => {
            return <div
              onClick={handlerClickCard}
              className={style.cards}
              key={index}>
            </div>
          })}
        </div>
      </XScroll>


    </div>
  </div>
}

export { PanoramaGallery }