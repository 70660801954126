/* global _czc */

import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import heic2any from "heic2any";
import {
  startChat,
  getTaskDetail,
  getTotal,
  searchModels,
} from "../../utils/net";
import {
  transitioningAtom,
  rodinSearchClickedAtom,
  fileListAtom,
  fileMapAtom,
  urlListAtom,
  hashAtom,
  cardLoadingAtom,
  croppedImageAtom,
  currentDBAtom,
  currentNavAtom,
  isMobileAtom
} from "../../store";
import style from "./rodin.module.css";
import rodinBg from "../../assets/background-rodin.png";
import { BsCloudArrowUpFill } from "react-icons/bs";
import { useTips } from "../GlobalTips";
import Viewer from "../widgets/render/viewer";
import { LineWave, Grid } from "react-loader-spinner";
import {
  AiOutlineSearch,
  AiOutlineUpload,
  AiOutlineCloudUpload,
  AiFillDelete,
  AiOutlineHistory,
  AiOutlineFileText,
} from "react-icons/ai";
import { RodinGallery } from '../Gallery/Rodin/index'

function Rodin({ scrollPercentage, isMobile }) {
  const [description, setDescription] = useState("");
  const timeStampRef = useRef(0);
  const tip = useTips();
  const [totalModel, setTotalModel] = useState(0);
  const fileInputRef = useRef(undefined);
  const [uploadType, setUploadType] = useState(undefined);
  const [showUploadPreview, setShowUploadPreview] = useState(false);
  const [showNewProductHint, setShowNewProductHint] = useState(false);
  const [uploadHover, setUploadHover] = useState(false);
  const [inputExpanded, setInputExpanded] = useState(false);
  const uploadMessage = isMobile ? "Search by image" : "Drop your image or 3D model";
  const [uploadMessageIndex, setUploadMessageIndex] = useState(30);
  const [uploadPreviewActive, setUploadPreviewActive] = useState(false);
  const [uploadPreviewUrl, setUploadPreviewUrl] = useState(undefined);
  const [uploadFileExtension, setUploadFileExtension] = useState(undefined);
  const [isDragging, setIsDragging] = useState(false);
  const [rodinSearchClicked, setRodinSearchClicked] = useRecoilState(rodinSearchClickedAtom);
  const [cardLoading, setCardLoading] = useRecoilState(cardLoadingAtom);
  const [fileList, setFileList] = useRecoilState(fileListAtom);
  const [fileMap, setFileMap] = useRecoilState(fileMapAtom);
  const [currentUploadingMtl, setCurrentUploadingMtl] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [urlList, setUrlList] = useRecoilState(urlListAtom);
  const [hashTag, setHashTag] = useRecoilState(hashAtom);
  const [objectFileMap, setObjectFileMap] = useRecoilState(croppedImageAtom);
  const [originalFileMap, setOriginalFileMap] = useState(new Map());
  const [currentDB, setCurrentDB] = useRecoilState(currentDBAtom);
  const [currentNav, setCurrentNav] = useRecoilState(currentNavAtom);
  const [transitioning, setTransitioning] = useRecoilState(transitioningAtom);
  const [cropEnabled, setCropEnabled] = useState(false);
  const inputRef = useRef();
  const location = useLocation()

  const newProductHint = showNewProductHint ? (
    <div className={style.newProductHint}>
      Welcome to our new product - Rodin! Experience it now.
    </div>
  ) : null;

  useEffect(() => {
    if (objectFileMap.size === 0) {
      setOriginalFileMap(new Map());
    }
  }, [objectFileMap]);

  useEffect(() => {
    if (
      uploadHover &&
      uploadMessageIndex >= 0 &&
      uploadMessageIndex < uploadMessage.length
    ) {
      const timer = setTimeout(() => {
        setUploadMessageIndex((prevIndex) => prevIndex + 1);
      }, 50);

      return () => clearTimeout(timer);
    }
  }, [uploadHover, uploadMessageIndex]);
  useEffect(() => {
    setTotalModelInTitle();
    setRodinSearchClicked(false);
    setObjectFileMap(new Map());
    setFileList([]);
    //Rodin提示
    setTimeout(() => {
      setShowNewProductHint(true);
    }, 1500);
    setTimeout(() => {
      setShowNewProductHint(false);
    }, 6000);
    _czc.push(["_trackEvent", "Rodin - Index", "Switch Tab", "Welcome Component"]);
    // window.history.pushState(null, "", "/rodin");
    setDescription("");
  }, []);

  useEffect(() => {
    if (!showUploadPreview) {
      setTimeout(() => {
        setUploadPreviewActive(false);
      }, 400);
    } else {
      setUploadPreviewActive(true);
    }
  }, [showUploadPreview]);

  useEffect(() => {
    if (inputExpanded) {
      inputRef.current.focus();
    }
  }, [inputExpanded]);

  const handleMtlFilesUpload = (event) => {
    _czc.push(["_trackEvent", "Rodin - Index", "Upload", "Preview Component"]);
    const files = event.target.files;
    let newMtlFilesArray = [];

    for (let i = 0; i < files.length; i++) {
      const newFile = files[i];

      let totalSize = 0;
      for (let file of fileMap.values()) {
        totalSize += file.size / 1024 / 1024;
      }
      console.log(totalSize);

      if (totalSize > 20) {
        tip({
          type: "warning",
          content:
            "The total size of files exceeds 20MB. Please reduce file size.",
        });
      } else {
        setFileMap((prevMap) => {
          const newMap = new Map(prevMap);
          newMap.set(currentUploadingMtl.toLocaleLowerCase(), newFile);
          return newMap;
        });
        newMtlFilesArray.push(newFile);
      }
    }
    event.target.value = "";
  };

  const handleUploadHover = () => {
    if (!inputExpanded) {
      setUploadHover(true);
      setUploadMessageIndex(0);
    }
  };

  const handleUploadTextHover = () => { setUploadHover(true) };

  const renderUploadMessage = () => uploadMessage.slice(0, uploadMessageIndex);

  const handleUploadBlur = () => {
    setUploadMessageIndex(30);
    setUploadHover(false);
  };

  const setTotalModelInTitle = async () => {
    const res = await getTotal();
    setTotalModel(res.data.total);
  };

  const handleInput = (ev) => {
    setDescription(ev.currentTarget.value);
  };

  const uploadProgressHandler = (event) => {
    const percentage = event.progress * 100;
    setUploadPercentage(percentage);
  };

  const handleSearch = async (e) => {
    if (Date.now() - timeStampRef.current >= 1000) {
      setCardLoading(true);
      setRodinSearchClicked(true);
      let inputType = null;
      let inputFile = new Map(objectFileMap);
      console.log(objectFileMap);
      fileMap.forEach((value, key) => {
        inputFile.set(key, value);
      });
      if (uploadType === "3d") {
        inputType = "model";
      } else if (uploadType === "image") {
        inputType = "image";
      } else {
        inputType = "text";
        setFileList([]);
        setObjectFileMap(new Map());
        if (description === "") return;
        inputFile = new Map();
      }
      // Set prompt (text search)
      let promptText = description;
      // Rodin Search
      try {
        const params = {
          database_name: currentDB,
          input_type: inputType,
          fileMap: inputFile,
          prompt: promptText,
          page_num: 0,
          onUploadProgress: uploadProgressHandler,
        };
        const response = await searchModels({
          ...params,
        });
        setUrlList(response.data.url);
        //setImageList(response.data?.image)
        setHashTag(response.data.hash);
        setRodinSearchClicked(true);
      } catch (e) {
        tip({
          type: "error",
          content: "Something went wrong: " + e.message,
        });
      }
      setCardLoading(false);
    }
  };

  const handleReUpload = () => {
    _czc.push(["_trackEvent", "Rodin - Index", "Re-upload", "Preview Component"]);
    setObjectFileMap(new Map());
    setFileMap(new Map());
    setFileList([]);
    fileInputRef.current.click();
  };

  const showLastSearch = (e) => {
    _czc.push(["_trackEvent", "Rodin - Index", "Last Search", "Welcome Component"]);
    if (originalFileMap.size > 0) {
      let file = originalFileMap.get("image")
        ? originalFileMap.get("image")
        : originalFileMap.get("model");
      originalFileMap.get("image")
        ? setUploadType("image")
        : setUploadType("3d");

      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadPreviewUrl(e.target.result);
      };
      reader.readAsDataURL(file);
      setShowUploadPreview(true);
    }
  };


  const handleUploadIconClick = () => {
    isMobile ? tip({
      type: "warning",
      content:
        "Please access the desktop version for this feature.",
    }) : fileInputRef.current.click();
  };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const maxSize = 20 * 1024 * 1024;
      if (file.size > maxSize) {
        tip({
          type: "warning",
          content: "File size exceeds the limit of 20MB.",
        });
        return;
      }

      const allowedExtensions = [
        "jpg",
        "jpeg",
        "png",
        "obj",
        "fbx",
        "gltf",
        "glb",
        "dae",
        "ply",
        "stl",
        "heic",
      ];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        if (
          fileExtension === "jpg" ||
          fileExtension === "jpeg" ||
          fileExtension === "png" ||
          fileExtension === "heic"
        ) {
          setObjectFileMap(new Map([["image", file]]));
          setOriginalFileMap(new Map([["image", file]]));
          console.log(file);
          setUploadType("image");

          const reader = new FileReader();
          reader.onload = async (e) => {
            if (fileExtension === "heic") {
              tip({
                type: "primary",
                content:
                  "Please note that the HEIC image may take some time to load.",
              });
              let jpegUrl = "";
              let jpegBlob = "";
              await fetch(e.target.result)
                .then((res) => res.blob())
                .then((blob) =>
                  heic2any({
                    blob,
                    toType: "image/jpeg",
                    quality: 0.8,
                  })
                )
                .then((conversionResult) => {
                  jpegBlob = conversionResult;
                  jpegUrl = URL.createObjectURL(jpegBlob);
                });
              setObjectFileMap(new Map([["image", jpegBlob]]));
              setOriginalFileMap(new Map([["image", jpegBlob]]));
              setUploadPreviewUrl(jpegUrl);
            } else {
              setUploadPreviewUrl(e.target.result);
            }
          };

          reader.readAsDataURL(file);
        } else {
          setObjectFileMap(new Map([["model", file]]));
          setOriginalFileMap(new Map([["model", file]]));
          setUploadType("3d");
          const reader = new FileReader();
          reader.onload = async (e) => {
            setUploadPreviewUrl(e.target.result);
            setUploadFileExtension(fileExtension);
          };
          reader.readAsDataURL(file);
        }
        setShowUploadPreview(true);
      } else {
        tip({
          type: "error",
          content:
            "Invalid file format. Please upload .jpg, .jpeg, .png, .obj, .fbx, .gltf, .glb, .dae, .ply, .stl, .heic files.",
        });
      }
    }
    event.target.value = "";
  };

  const handleTextSearchClick = (e) => {
    e.preventDefault();
    if (!inputExpanded) {
      setInputExpanded(!inputExpanded);
    }
  };

  const handleBlur = () => {
    if (!cardLoading) {
      setInputExpanded(false);
    }
  };

  const handleCloseTextureWindow = (e) => {
    setUploadPreviewUrl("");
    setShowUploadPreview(false);
    setUploadingFiles(false);
    setUploadType("text");
    setFileMap(new Map());
    setFileList([]);
  };

  const confirmClick = async () => {
    _czc.push(["_trackEvent", "Rodin - Index", "Confirm", "Preview Component"]);
    let confirmStatus = false;
    for (let name of fileList) {
      if (name.split("|").pop() === "CRITICAL") {
        confirmStatus = true;
      }
    }
    if (confirmStatus) {
      tip({
        type: "warning",
        content: "Please add mandatory texture for better match.",
      });
      return;
    }

    let totalSize = 0;
    for (let file of fileMap.values()) {
      totalSize += file.size / 1024 / 1024;
    }

    if (totalSize > 20) {
      tip({
        type: "warning",
        content:
          "The total size of files exceeds 20MB. Please reduce file size.",
      });
      return;
    }

    setUploadingFiles(true);
    setUploadPercentage(0);

    await handleSearch(); // Search
    setUploadingFiles(false);
    setShowUploadPreview(false);
    setUploadType("text");
    setUploadPreviewUrl("");
    setRodinSearchClicked(true);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    _czc.push(["_trackEvent", "Rodin - Index", "File Drop", "Preview Component"]);
    const file = event.dataTransfer.files[0];
    handleFileInputChange({ target: { files: [file] } });
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
      //setInputExpanded(false);
    }
  };
  const handleUploadTexture = (e, str) => {
    document.getElementById("mtl-file-upload").click();
    setCurrentUploadingMtl(str.split("|").slice(-2, -1)[0]);
  };

  const handleDeleteTexture = (key) => {
    setFileMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.delete(key);
      return newMap;
    });
  };

  const matchFiles = (files) => {
    _czc.push(["_trackEvent", "Rodin - Index", "Auto Match", "Preview Component"]);
    const matchedFiles = [];

    for (const file of files) {
      const fileName = file.name;
      const formatFileName = new Set();
      fileList.forEach((fileItem) => {
        const fileName = fileItem.split("|").slice(-2, -1)[0];
        formatFileName.add(fileName);
      });

      const matchedKey = Array.from(formatFileName).find(
        (key) => key.toLowerCase() === fileName.toLowerCase()
      );

      if (matchedKey) {
        let totalSize = 0;
        for (let file of fileMap.values()) {
          totalSize += file.size / 1024 / 1024;
        }

        //console.log(totalSize);

        if (totalSize > 20) {
          tip({
            type: "warning",
            content:
              "The total size of files exceeds 20MB. Please reduce file size.",
          });
          return;
        }

        matchedFiles.push(matchedKey);

        setFileMap((prevMap) => {
          const newMap = new Map(prevMap);
          newMap.set(matchedKey, file);
          return newMap;
        });
      }
    }

    return matchedFiles;
  };

  const showMessage = (matchedFiles) => {
    if (matchedFiles.length > 0) {
      const matchedFilenames = matchedFiles.join(", ");
      tip({
        type: "success",
        content: `These files were automatically matched: ${matchedFilenames}`,
      });
    } else {
      tip({
        type: "warning",
        content: `Unable to match automatically. Please upload the files manually.`,
      });
    }
  };

  const handleFileDropTextureUpload = (event) => {
    const files = event.target.files
    const matchedFiles = matchFiles(files);
    showMessage(matchedFiles);
    event.target.value = "";
  };

  const handleFileDropTexture = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const matchedFiles = matchFiles(files);
    showMessage(matchedFiles);
    event.target.value = "";
  };


  return (
    // transitioning 标题的过度
    <div
      className={style.con}
      style={{ paddingTop: !rodinSearchClicked ? "calc(50vh - 13rem)" : "calc(50vh - 16rem)" }}
      onClick={handleCloseTextureWindow}
    >
      {newProductHint}
      <div className={style.conRodinBgImg}>
        <img alt="bg img" src={rodinBg} />
      </div>
      <div className={`${style.titleBox} ${transitioning ? style.transitioning : ""}`}>
        <div className={style.title}>Rodin</div>
        <div className={style.versionTitle}>Gen0 v0.1 (Beta)</div>
      </div>
      <div
        style={{
          transform: `translateY(${scrollPercentage * -200}px)`,
          opacity: 1 - scrollPercentage * 4,
        }}
      >
        <div>
          Multimodal 3D <del>Generative AI</del> Search Engine <br></br>{" "}
        </div>
      </div>
      <input
        type="file"
        key={Math.random}
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileInputChange}
        accept=".jpg, .jpeg, .png, .obj, .fbx, .gltf, .glb, .dae, .ply, .stl, .heic"
      />
      <div
        className={`${showUploadPreview && !isMobile ? style.iptConWithoutHover : style.iptCon
          } ${style.animated}`}
        style={{
          marginTop: `${Math.max(4 - scrollPercentage * 15, -1)}rem`,
          width: isMobile ? "22rem" : "33rem",
          height: isMobile ? "4.5rem" : "5rem",
          borderRadius: "4rem",
        }}
        onDrop={handleFileDrop}
        onDragOver={(event) => {
          event.preventDefault();
          setIsDragging(true);
        }}
        onDragEnter={(event) => {
          event.preventDefault();
          setIsDragging(true);
        }}
        onDragLeave={handleDragLeave}
      >
        <>
          {isDragging && (
            <div className={style.dropOverlay}>
              <div className={style.overlayIcon}>
                <AiOutlineUpload />
              </div>
            </div>
          )}
          <div style={{ zIndex: "5" }}> </div>
          <div
            onClick={handleUploadIconClick}
            style={{
              position: "absolute",
              background: "#fff",
              borderRadius: "50px",
              height: "4.5rem",
              width: "22rem",
            }}
          >
            {" "}
            <BsCloudArrowUpFill
              className={style.uploadIcon}
              onMouseEnter={handleUploadHover}
              onMouseLeave={handleUploadBlur}
              onClick={handleUploadIconClick}
              style={inputExpanded ? { display: "none" } : {}}
            />
          </div>

          <div
            className={style.inputText}
            style={inputExpanded ? { display: "none" } : {}}
            onMouseEnter={handleUploadTextHover}
            onMouseLeave={handleUploadBlur}
            onClick={handleUploadIconClick}
          >
            {renderUploadMessage()}
          </div>

          <div
            className={`${style.textSearchButton} ${style.animated}`}
            onClick={(e) => handleTextSearchClick(e)}
            onBlur={handleBlur}
            style={{
              height: "3rem",
              width: inputExpanded ? "94%" : '7rem',
              borderRadius: inputExpanded ? "3rem" : "3rem",
              left: inputExpanded ? "0rem" : "0",
              background: uploadHover
                ? "linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))"
                : "",
              opacity: uploadHover ? 0 : 100,
            }}
          >
            {inputExpanded ? (
              <>
                <input
                  ref={inputRef}
                  className={style.rodinInput}
                  placeholder="Describe the model you want to search"
                  value={description}
                  onChange={handleInput}
                  autoFocus="autofocus"
                  onKeyPress={handleKeyPress}
                  style={{ outline: "none" }}
                />

                <div
                  className={style.rodinSearchIconOutline}
                  style={{ height: isMobile ? "1.9rem" : '2.3em', width: isMobile ? "1.9rem" : "2.3em" }}
                  onPointerDown={(e) => handleSearch(e)}
                >
                  <AiOutlineSearch size="1.5em" color="rgb(134, 89, 225)" />
                </div>
              </>
            ) : (
              "Text Search"
            )}
          </div>
        </>
      </div>

      {originalFileMap.size > 0 && (
        <div
          className={`${style.lastSearch}`}
          onClick={(e) => e.stopPropagation()}
          onPointerDown={(e) => showLastSearch(e)}
        >
          <AiOutlineHistory
            style={{
              marginTop: "10px",
              height: "28px",
              width: "40px",
              color: "rgb(255, 255, 255)",
            }}
          >
            {" "}
          </AiOutlineHistory>
        </div>
      )}

      {uploadPreviewActive && (
        <>
          <div
            className={`${style.uploadPreview} ${showUploadPreview
              ? style.uploadPreviewActive
              : style.uploadPreviewHide
              }`}
            style={{ zIndex: "10000" }}
            onClick={(e) => e.stopPropagation()}
          >
            {uploadingFiles === true && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(240, 240, 240, 0.9)",
                  position: "absolute",
                  zIndex: 2,
                  backdropFilter: "blur(5px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  borderRadius: "0.5rem",
                }}
              >
                <React.Fragment>
                  {uploadPercentage < 100 ? (
                    <div
                      style={{
                        position: "absolute",
                        marginLeft: "10rem",
                        marginTop: "-10rem",
                        zIndex: 1000,
                      }}
                    >
                      <LineWave
                        height="400"
                        width="400"
                        color="rgba(72, 0, 220)"
                        ariaLabel="line-wave"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        firstLineColor=""
                        middleLineColor=""
                        lastLineColor=""
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        marginLeft: "1rem",
                        marginTop: "-3rem",
                        zIndex: 1000,
                      }}
                    >
                      <Grid
                        color="rgba(72, 0, 220)"
                        width="400"
                        visible={true}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      width: "100%",
                      height: `${uploadPercentage}%`,
                      backgroundColor: "rgba(72, 0, 220, 0.05)",
                      position: "absolute",
                      bottom: 0,

                      borderRadius: "inherit",
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  />

                  <div
                    style={{
                      fontSize: "18px",
                      fontFamily: "Arial-Regular, Arial",
                      color: "rgba(72, 0, 220)",
                      marginTop: "7rem",
                      marginLeft: "1.5rem",
                    }}
                  >
                    {uploadPercentage < 100 ? "Uploading..." : "Processing..."}
                    <div
                      style={{
                        marginTop: "5px",
                      }}
                    ></div>
                  </div>
                </React.Fragment>
              </div>
            )}

            {showUploadPreview ? (
              <Viewer
                src={uploadPreviewUrl}
                fileSuffix={uploadFileExtension}
                uploadType={uploadType}
                cropMode={cropEnabled}
              />
            ) : (
              ""
            )}

            <div className={style.uploadPreviewTitle}>
              {uploadType === "image"
                ? "Confirm upload the image?"
                : "Confirm upload the model?"}
            </div>
            {uploadType === "image" && (
              <div className={style.switch}>
                <input
                  type="checkbox"
                  checked={cropEnabled}
                  onChange={() => setCropEnabled(!cropEnabled)}
                />
                <label>Custom cropping</label>
              </div>
            )}

            <div className={style.uploadPreviewButtons}>
              <div
                className={`${style.button} ${style.reUploadButton}`}
                onClick={handleReUpload}
              >
                Re-upload
              </div>
              <div
                className={`${style.button} ${style.confirmButton}`}
                onClick={() => confirmClick()}

              >
                Confirm
              </div>
            </div>
          </div>
          {uploadType === "3d" && fileList.size > 0 && (
            <div
              className={`${style.textureWindow}  ${showUploadPreview
                ? style.uploadPreviewActive
                : style.uploadPreviewHide
                }`}
              style={{ zIndex: "10000" }}
              onClick={(e) => e.stopPropagation()}
              onDrop={handleFileDropTexture}
              onDragEnter={(event) => event.preventDefault()}
              onDragOver={(event) => {
                event.preventDefault();
              }}
            >
              <div
                className={style.mtlUploadArea}
                onPointerDown={(e) =>
                  document.getElementById("mtl-file-upload-text").click()
                }
              >
                <AiOutlineCloudUpload className={style.uploadIconTexture} />
                <div>Auto match</div>
              </div>

              {fileList.size > 0 && (
                <div className={style.mtlTitle}>
                  <div>Click to upload</div>
                </div>
              )}

              <div className={style.mtlFileListList}>
                {[...fileList].map((file, index) => (
                  <div
                    key={index}
                    className={style.mtlFileItemList}
                    onClick={(e) => {
                      handleUploadTexture(e, file.toString());
                    }}
                    title={file.toString().split(/[/\\]/).pop()}
                  >
                    <div
                      className={style.hintBar}
                      style={{
                        background:
                          file.split("|").pop() === "CRITICAL"
                            ? "rgb(242, 67, 97)"
                            : "#4b00e09d",
                      }}
                    >
                      {file.split("|").pop() === "CRITICAL" ? "MUP" : "OPT"}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <AiOutlineFileText className={style.fileIconList} />
                      {file
                        .toString()
                        .split(/[/\\]/)
                        .pop()
                        .split("|")
                        .slice(-2, -1)[0]
                        .slice(0, 15)}
                      {file
                        .toString()
                        .split(/[/\\]/)
                        .pop()
                        .split("|")
                        .slice(-2, -1)[0].length > 15
                        ? "..."
                        : ""}
                    </div>
                  </div>
                ))}
                {Array.from(fileMap).map(([key, file], index) => (
                  <div
                    key={index}
                    className={style.mtlFileItem}
                    title={file.name}
                  >
                    <AiOutlineFileText className={style.fileIcon} />
                    <span>
                      {file.name.slice(0, 8).split("/").pop()}
                      {file.name.length > 8 ? "..." : ""}
                    </span>
                    <AiFillDelete
                      className={style.mtlDeleteBtn}
                      onPointerDown={() => {
                        handleDeleteTexture(key);
                      }}
                    />
                  </div>
                ))}
              </div>
              <input
                type="file"
                id="mtl-file-upload"
                style={{ display: "none" }}
                accept=".mtl, .png, .jpg, .png, .jpeg, .bin"
                onChange={handleMtlFilesUpload}
              />
              <input
                type="file"
                id="mtl-file-upload-text"
                style={{ display: "none" }}
                accept=".mtl, .png, .jpg, .png, .jpeg, .bin"
                onChange={handleFileDropTextureUpload}
                multiple
              />
            </div>
          )}
        </>
      )}
      {rodinSearchClicked && <RodinGallery />}
    </div>
  );
}
export default Rodin;
