import React, { useRef, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import useModelLoader from "./modelLoader";
import "./viewer.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import * as THREE from "three";
import { useTips } from "../../GlobalTips";
import { croppedImageAtom } from "../../../store";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

function Model({ url, type }) {
  const { model, status } = useModelLoader(url, type);
  const modelRef = useRef();

  useEffect(() => {
    if (model) {

      model.traverse(obj => {
        if (obj.geometry) {
          obj.geometry.userData.morphAttributes = obj.geometry.morphAttributes;
          obj.geometry.morphAttributes = {};
          obj.geometry.boundingBox = null;
        }
      });

      model.scale.set(1, 1, 1);

      const boundingBox = new THREE.Box3().setFromObject(model);
      const size = new THREE.Vector3();
      boundingBox.getSize(size);
      const desiredSize = 7;
      const largestSize = Math.max(size.x, size.y, size.z);
      const scaleFactor = desiredSize / largestSize;
      const scale = new THREE.Vector3(scaleFactor, scaleFactor, scaleFactor);
      model.scale.copy(scale);
      boundingBox.setFromObject(model);
      boundingBox.getCenter(model.position).multiplyScalar(-1);
    }
  }, [model]);

  return (
    <>
      {status !== true && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(240, 240, 240, 0.9)",
            position: "absolute",
            zIndex: 2,
            backdropFilter: "blur(5px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "0.5rem",
          }}
        >
          <React.Fragment>
            <svg
              width="150"
              height="150"
              viewBox="0 0 49 49"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.5 0C38.031573 0 49 10.957452 49 24.471613c0 13.517276-10.968427 24.473393-24.5 24.473393S0 37.98889 0 24.471613C0 10.957453 10.968427 0 24.5 0zm11.959118 16.936752L24.5 9.619472l-11.958673 7.31728L24.5 24.253585l11.959118-7.316833zM12.027273 20.28993v12.179297l10.69091 6.66275V26.952235l-10.69091-6.662305zm13.80909 18.842047l11.136364-6.66275V20.28993l-11.136363 6.662305v12.179742z"
                fillRule="evenodd"
                fillOpacity=".03"
              />
            </svg>
            <div
              style={{
                fontSize: "20px",
                fontFamily: "Arial-Regular, Arial",
                color: "rgba(210, 210, 210, 0.9)",
                marginTop: "15px",
              }}
            >
              Loading
            </div>
          </React.Fragment>
        </div>
      )}

      <Canvas>
        <OrbitControls />
        <ambientLight intensity={0.5} />
        <directionalLight position={[0, 10, 5]} intensity={1} />
        {model && <primitive ref={modelRef} object={model} dispose={null} />}
      </Canvas>
    </>
  );
}

function Viewer({ src, fileSuffix, uploadType, cropMode }) {
  const cropperRef = React.useRef(null);
  const tip = useTips();
  const [cropExtend, setCropExtend] = useState(false)
  const [objectFileMap, setObjectFileMap] = useRecoilState(croppedImageAtom);
  const [preprocessedImage, setPreprocessedImage] = useState(null);

  const canvasToFile = (canvas, fileName) => {
    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          const file = new File([blob], fileName, { type: "image/png" });
          resolve(file);
        },
        "image/png",
        1
      );
    });
  };

  const onCrop = async () => {
    if (cropperRef.current) {
      const canvas = cropperRef.current.cropper.getCroppedCanvas({
        width: 500,
        height: 500,
        fillColor: "#fff",
      });
      const file = await canvasToFile(canvas, "croppedImage.png");
      setObjectFileMap(new Map([["image", file]]));
    }
  };

  const getPreprocessedImage = (image) => {
    const canvas = document.createElement("canvas");
    const width = image.width;
    const height = image.height;
    const context = canvas.getContext("2d");

    const targetSize = Math.max(width, height);
    canvas.width = targetSize;
    canvas.height = targetSize;
    context.fillStyle = "#fff";
    context.fillRect(0, 0, targetSize, targetSize);
    context.drawImage(
      image,
      (targetSize - width) / 2,
      (targetSize - height) / 2,
      width,
      height
    );

    return canvas.toDataURL();
  };

  const getOriginalImage = (image) => {
    const canvas = document.createElement("canvas");
    const width = image.width;
    const height = image.height;
    const context = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;
    context.fillRect(0, 0, width, height);
    context.drawImage(image, 0, 0, width, height);

    return canvas.toDataURL();
  };

  useEffect(() => {
    if (cropperRef.current && uploadType === "image") {
      const cropperInstance = cropperRef.current.cropper;
      cropperInstance.setAspectRatio(cropExtend ? 0 : 1);
      cropperInstance.crop();
      onCrop();
    }
  }, [cropExtend]);

  useEffect(() => {
    if (uploadType === "image" && src) {

      const image = new Image();
      image.src = src;
      setCropExtend(cropMode)
      if (cropMode === true) {
        image.onload = () => {
          //const preprocessedImg = getPreprocessedImage(image);
          //setPreprocessedImage(preprocessedImg);
          const originalImg = getOriginalImage(image);
          setPreprocessedImage(originalImg);
        };
      } else {
        image.onload = () => {
          const originalImg = getOriginalImage(image);
          setPreprocessedImage(originalImg);
        };
      }
      setTimeout(() => onCrop(), 500);
    }
  }, [src, uploadType, cropMode]);

  if (!src) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        width: "35rem",
        height: "20rem",
        marginTop: "-1rem",
        borderRadius: "0.5rem",
      }}
    >
      {uploadType === "image" ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "0.5rem",
            left: "0rem",
            top: "0rem",
            maxWidth: "100%",
          }}
        >
          <Cropper
            ref={cropperRef}
            src={preprocessedImage}
            style={{ width: "100%", height: "100%" }}
            aspectRatio={cropExtend ? 0 : 1}
            viewMode={1}
            preview=".img-preview"
            minCropBoxHeight={50}
            minCropBoxWidth={50}
            autoCropArea={1}
            background={true}
            guides={true}
            checkOrientation={false}
            highlight={false}
            cropBoxMovable={true}
            cropBoxResizable={true}
            cropend={onCrop}
          />
        </div>
      ) : (
        <Model url={src} type={fileSuffix} />
      )}
    </div>
  );
}

export default Viewer;
