import { atom } from 'recoil'

export const workflowSelectedAtom = atom({
  key: 'workflowSelectedAtom',
  default: "ChatAvatar",
})

export const rodinSearchClickedAtom = atom({
  key: 'rodinSearchClickedAtom',
  default: false,
})

export const fileListAtom = atom({
  key: 'fileListAtom',
  default: new Set(),
})

export const fileMapAtom = atom({
  key: 'fileMapAtom',
  default: new Map(),
})

export const urlListAtom = atom({
  key: 'urlListAtom',
  default: [],
})

export const hashAtom = atom({
  key: 'hashAtom',
  default: "",
})

export const cardLoadingAtom = atom({
  key: 'cardLoadingAtom',
  default: false,
})

export const croppedImageAtom = atom({
  key: 'croppedImageAtom',
  default: "",
})

export const currentDBAtom = atom({
  key: 'currentDBAtom',
  default: "sketchfab",
})

export const imageListAtom = atom({
  key: 'imageListAtom',
  default: [],
})

export const taskUUidAtom = atom({
  key: 'taskUUidAtom',
  default: '',
})
